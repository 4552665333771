window.Control.ImageControl = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
  },

  onrender: function () {
    trace.log();
    _.bindAllFunctions(this);

    if (this.get('field')) {
      this.set('status', 'complete');
    } else {
      this.set('status', 'waiting');
    }

    this.set('upload_progress', 0);
    this.dragCounter = 0;

    this.on({
      _uploadImage: this._uploadImage,
      _confirmDeleteImage: this._confirmDeleteImage,
      _dragEnter: this._dragEnter,
      _dragLeave: this._dragLeave,
    });

    this.$upload_input = $(this.find('.ctrl-Image_Input'));

    this.$upload_input.fileupload({
      url: FORKLIFT_UPLOAD_URL + '/files',
      dataType: 'json',
      replaceFileInput: false,
      paramName: 'file',
      dropZone: $(this.el),
      maxNumberOfFiles: 1,
      formData: {
        auth: FORKLIFT_AUTH_TOKEN,
      },
      add: this.fileUploadQueued,
    });

    setTimeout(
      _.bind(function () {
        this.resetControlHeight();
      }, this),
      50
    );
  },

  onteardown: function () {
    trace.log();

    if (this.imageEditor && this.imageEditor.teardown) {
      this.imageEditor.off('done', this.doneEditing);
      this.imageEditor.teardown();
    }

    this.$upload_input.fileupload('destroy');
  },

  _uploadImage: function (e) {
    this.$upload_input.click();
  },

  _confirmDeleteImage: function (e) {
    e.original.preventDefault();

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this image!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.deleteImage();
      }
    });
  },

  deleteImage: function () {
    trace.log();

    this.dragCounter = 0;
    this.set({
      status: 'waiting',
      upload_progress: 0,
      dragging: false,
      field: null,
      file_fk: null,
    });

    this.resetControlHeight();
  },

  fileUploadQueued: function (e, data) {
    trace.log(e, data);

    var uploadErrors = [];
    var acceptFileTypes = /^image\/(jpe?g|png)$/i;

    if (data.files[0].type && !acceptFileTypes.test(data.files[0].type)) {
      uploadErrors.push('Only jpg and png images can be uploaded');
    }
    if (data.files[0].size && data.files[0].size > 2000000) {
      uploadErrors.push('File size must be below 2MB');
    }

    if (uploadErrors.length > 0) {
      Swal.fire('Invalid File', uploadErrors.join(' and ') + '. Please try again.', 'error');
    } else {
      this.sendImage(data);
    }
  },

  sendImage: function (data) {
    $.each(data.files, function (index, file) {
      console.log('Added file: ' + file.name);
    });

    var jqXHR = data.submit().success(this.fileUploadSuccess).error(this.fileUploadError);

    this.set('status', 'processing');
    this.set('dragging', false);
    this.dragCounter = 0;
    this.set('show_progress', true);
    this.refreshInterval = setInterval(this.refreshProgress, 100);
  },

  refreshProgress: function () {
    var progress = this.$upload_input.fileupload('progress');
    var percentComplete = progress.loaded / progress.total;
    this.animate('upload_progress', percentComplete * 100.0, {
      duration: 1000.0 * percentComplete,
    });
    if (percentComplete >= 1) {
      clearInterval(this.refreshInterval);
    }
  },

  fileUploadSuccess: function (result, textStatus, jqXHR) {
    trace.log(result, textStatus, jqXHR);

    if (!result.url) {
      this.fileUploadError(jqXHR, textStatus, null);
      return;
    }

    this.set({
      field: this.resizedImageURL(result),
      file_fk: result.file_pk,
      show_progress: false,
      show_check: true,
      status: 'complete',
    });

    $(this.find('.ctrl-Image_PreviewImage')).load(this.resetControlHeight);

    _.delay(
      _.bind(function () {
        this.set('show_check', false);
      }, this),
      5000
    );
  },

  fileUploadError: function (jqXHR, textStatus, errorThrown) {
    trace.log(jqXHR, textStatus, errorThrown);

    var status = this.get('field') ? 'complete' : 'waiting';
    var verb = this.get('field') ? 'replace' : 'upload';

    this.set({
      status: status,
      show_progress: false,
      show_check: false,
      error: true,
    });

    _.delay(
      _.bind(function () {
        this.set('error', false);
      }, this),
      5000
    );

    if (verb === 'replace') {
      $('.overlay').removeClass('show');
      this.imageEditor.off('done', this.doneEditing);
      this.imageEditor.teardown();
    }

    this.imageEditor = null;
  },

  _dragEnter: function (e) {
    e.original.preventDefault();
    this.dragCounter++;
    this.set('dragging', true);
  },

  _dragLeave: function (e) {
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.set('dragging', false);
    }
    return false;
  },

  resetControlHeight: function () {
    $(this.find('.ctrl-Image')).css(
      'height',
      $(this.find('.ctrl-Image_PreviewImage')).height() + 10
    );
  },

  // helps prevent overuse of bandwith for cloudinary
  // max width of a portals screen is 1000px
  // so we scale bigger images down to prevent unessecary bandwith usage
  //
  // http://cloudinary.com/documentation/image_transformations#resizing_and_cropping_images
  resizedImageURL: function (result) {
    if (result.cloud_provider === 'Cloudinary') {
      // generate a resized url based of the original url
      const originalUrl = result.url;
      const resizeParam = "w_1000,c_lfill,f_auto,fl_lossy";
      const startIndex = originalUrl.indexOf('upload/') + 'upload/'.length;
      const endIndex = originalUrl.indexOf('/v1');
      const oldParam = originalUrl.substring(startIndex, endIndex);
      const resizedUrl = originalUrl.replace(oldParam, resizeParam);
      return resizedUrl;
    } else {
      return result.url;
    }
  },

  convertDataUriToBlob: function (dataURI) {
    // serialize the base64/URLEncoded data
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // parse the mime type
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // construct a Blob of the image data
    var array = [];
    for (var i = 0; i < byteString.length; i++) {
      array.push(byteString.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mimeString });
  },
});
