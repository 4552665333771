window.Control.Modal = Ractive.extend({
  onconstruct: function (options) {
    trace.log();
    options.append = true;
    options.el = 'body';
    options.template = require('./template.ractive');
  },

  data: {
    margin: 200,
    width: 640,
  },

  oninit: function () {
    $(document).on('keyup.modal', _.bind(this.escPressed, this));
  },

  onrender: function () {
    // set width
    if (this.get('width')) {
      $(this.find('.vx-modal')).css({
        width: this.get('width') + 'px',
        marginLeft: -this.get('width') / 2 + 'px',
      });
    }

    // set height
    if (this.get('height')) {
      $(this.find('.vx-modal')).css({
        height: this.get('height') + 'px',
        marginTop: -this.get('height') / 2 + 'px',
      });
    }
    // margin with auto adjusting height
    else {
      $(this.find('.vx-modal')).css({
        height: 'calc(100vh - ' + this.get('margin') + 'px)',
        marginTop: 'calc(-50vh + ' + this.get('margin') / 2 + 'px)',
      });
    }

    $('body').addClass('vx-modal-open');
    $('<div class="vx-overlay"></div>').hide().appendTo('body').fadeIn();
    $('.vx-overlay').on('click', _.bind(this.close, this));

    this.on({
      _close: this.close,
    });
  },

  show: function () {
    this.set('show', true);
  },

  close: function (e) {
    this.fire('modal:closed');
    $(document).off('keyup.modal');
    $('body').removeClass('vx-modal-open');
    $('.vx-overlay').remove();
    this.teardown();
  },

  escPressed: function (e) {
    if (e.keyCode === 27) {
      this.close();
    }
  },
});
