/* eslint-disable camelcase */
import React from 'react';
import ReactSelect from 'react-select';
// This is a new version of ./SearchBar.js for use in new ClassNavigatorModal
// Should remove old file once Gradebook is no longer in beta

import { HTTP_METHOD, useFetch } from '../../../hooks/useFetch';
import { useFocus } from '../../../hooks/useFocus';

const STATUS_CLASS_ACTIVE = 1;

const ClassSearch = ({ onFocus, onSelect, className, navText }) => {
  const [selectRef, setSelectFocus] = useFocus();
  const {
    loading,
    data,
    sendFetchRequest: getFacultyProfileClassNavigationListRequest,
  } = useFetch();

  React.useEffect(() => {
    fetchClasses();
  }, []);

  React.useEffect(() => {
    if (onFocus) setSelectFocus();
  }, [onFocus]);

  const fetchClasses = async () => {
    const url = Routes.class_navigation_list_path({
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
    });

    await getFacultyProfileClassNavigationListRequest({
      url,
      method: HTTP_METHOD.get,
    });
  };

  const mapClasses = (classes) => {
    if (!classes) return [];

    return (
      classes
        // Map specific values to display on dropdown
        .map(({ class_pk, class_id, description, school_year, status }) => ({
          classPk: class_pk,
          value: class_pk,
          label: `${class_id}: ${description}`,
          schoolYear: school_year,
          isActive: status == STATUS_CLASS_ACTIVE,
        }))
        // Sort by schoolYear
        .sort((a, b) => b.schoolYear - a.schoolYear)
    );
  };

  const formatOptionLabel = ({ label, isActive }) => (
    <div
      className={`vx-ClassSearch__option ${!isActive ? 'vx-ClassSearch__option--non-active' : ''}`}
    >
      <div>{label}</div>
    </div>
  );

  const handleOptionChange = ({ classPk, label }) => {
    onSelect(classPk, label);
  };

  return (
    <div className="relative">
      <svg
        className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
          clipRule="evenodd"
        />
      </svg>
      <input
        type="text"
        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
        placeholder="Search..."
        role="combobox"
        aria-expanded="false"
        aria-controls="options"
      >
        {navText}
      </input>
    </div>
  );
};

export default ClassSearch;
