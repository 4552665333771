function requireAll(r) {
  r.keys().forEach(r);
}
requireAll(require.context('../controls/', true, /\.js$/));

window.PreviewToolbar = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./templates/preview-toolbar.ractive');
  },

  computed: {
    published_ago: function () {
      if (this.get('screen.publish_date')) {
        return moment(this.get('screen.publish_date'), 'YYYY-MM-DD HH-mm-ss').fromNow();
      }
      return null;
    },
  },

  oninit: function () {
    this.set({
      last_saved: moment(this.get('screen.last_saved_date')).format(),
      is_dirty: false,
      saving_error: false,
      saving: false,
      in_draft: this.get('screen.unpublished_data') != null,
      layouts: [
        'single',
        'columns_two',
        'columns_three',
        'columns_four',
        'sidebar_left',
        'sidebar_right',
        'sidebar_left_right',
        'hero_sidebar_left',
        'hero_sidebar_right',
        'hero_left_columns_three',
      ],
      imageName: this.imageName,
    });
  },

  onrender: function () {
    _.bindAllFunctions(this);

    this.on({
      _editScreenLayout: this._editScreenLayout,
      _resizeSidebar: this._resizeSidebar,
      _publish: this._publish,
      _discardChanges: this._discardChanges,
      _closeLayouts: this._closeLayouts,
    });

    $(window).resize(this.windowResized);
    this.windowResized();
  },

  imageName: function (layout_name) {
    return _.kebabCase(layout_name);
  },

  _publish: function (e) {
    if (this.get('in_draft')) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This will make all your existing changes live in the portals. Good luck!',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#33C96F',
        confirmButtonText: 'Publish Changes',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          window.location = Routes.screen_publish_path({
            client: Portals.config.client,
            portal_name: Portals.config.portal_name,
            screen_name: Portals.config.screen_name,
          });
          // just wait forever
          return new Promise(function (resolve, reject) {});
        },
      }).then(Swal.noop);
    }
  },

  _discardChanges: function (e) {
    Swal.fire({
      title: 'Discard Changes?',
      text: 'This will revert this screen to its most recent published version. You will not be able to recover these changes!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, discard my changes!',
      showLoaderOnConfirm: true,
      preConfirm: function () {
        window.location = Routes.screen_discard_draft_path({
          client: Portals.config.client,
          portal_name: Portals.config.portal_name,
          screen_name: Portals.config.screen_name,
        });
        return new Promise(function (resolve, reject) {});
      },
    }).then(Swal.noop);
  },

  _editScreenLayout: function (e) {
    // open the layout picker
    this.toggle('show_layouts');

    if (this.get('show_layouts')) {
      $(document).on('click', _.bind(this.hidePopover, this));
    } else {
      return true;
    }

    return false;
  },

  _closeLayouts: function (e) {
    this.set('show_layouts', false);
    $(document).off('click');
  },

  hidePopover: function (e) {
    var popover = $('.es-Toolbar_Layouts');
    if (!popover.is(e.target) && popover.has(e.target).length === 0) {
      trace.log();
      this.set('show_layouts', false);
      $(document).off('click');
    }
  },

  _resizeSidebar: function (e) {
    trace.log(e);

    this.set('resized', true);
    var me = this;

    $(document).mousemove(function (e) {
      e.preventDefault();
      var x = $(window).width() - e.pageX;

      if (x <= 300) {
        x = 300;
      } else if (x >= 810) {
        x = 810;
      }

      me.setSidebarWidth(x);
    });

    $(document).mouseup(function (e) {
      $(document).off('mousemove');
    });

    return false;
  },

  windowResized: function (e) {
    if (this.get('resized')) return;

    if ($(window).width() <= 1120) {
      this.setSidebarWidth(320);
    } else if ($(window).width() > 1120) {
      this.setSidebarWidth(400);
    }
  },

  setSidebarWidth: function (width) {
    $('.es-Sidebar').css('width', width);
    $('.es-SidebarToolbar').css('width', width);
    $('.es-Preview').css('right', width);
    $('.es-Toolbar').css('right', width);
  },
});
