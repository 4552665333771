require('lodash');

_.mixin({
  stringToClass: function (str) {
    var arr = str.split('.');
    var fn = window || this;

    for (var i = 0, len = arr.length; i < len; i++) {
      var f = fn[arr[i]];
      if (f) {
        fn = f;
      }
    }

    if (typeof fn !== 'function') {
      trace.error('You have not created your new component yet!');
      return false;
    }

    return fn;
  },

  bindAllFunctions: function (obj) {
    var functions = [];
    for (var key in obj) {
      if (key == 'data') continue;
      if (typeof obj[key] == 'function' || false) functions.push(key);
    }
    return _.bindAll(obj, functions);
  },

  modulo: function (num, modulo) {
    return ((num % modulo) + modulo) % modulo;
  },

  isBlank: function (value) {
    return (_.isEmpty(_.trim(value)) && !_.isNumber(value)) || _.isNaN(value);
  },

  newGUID: function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },

  baseURL: function (target) {
    var target_domain = APP_DOMAINS[target]; // defined in javascript/lib/app-domains
    var tld = location.hostname.split('.').pop(); // com, eu, dev ...

    if (_.isNil(target_domain)) {
      var current_subdomain = window.location.host.split('.')[0];
      return (
        window.location.protocol +
        '//' +
        window.location.host.replace(current_subdomain, target) +
        '/' +
        Portals.config.client
      );
    }

    var host_with_proper_tld = target_domain.replace('{tld}', tld);
    return window.location.protocol + '//' + host_with_proper_tld + '/' + Portals.config.client;
  },
});
