import React from 'react';
import classnames from 'classnames';
import VxLabel from './VxLabel';
import HelpText from './HelpText';
import PlainTextField from './PlainTextField';
import { useField, useFormikContext } from 'formik';
import VxErrorMessage from './VxErrorMessage';
import { ElementMetadata } from '../types';

import { useDebounceOnChange } from '../utils/useDebounceOnChange';

const TextboxField = ({ element, metadata }: ElementMetadata) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(metadata.dataKeypath);
  const hasError = meta.touched && meta.error;

  const { fieldValue, handleOnChange } = useDebounceOnChange({ field });

  return (
    <>
      <VxLabel
        element={element}
        htmlFor={metadata.dataKeypath}
        hasError={hasError}
      />
      <HelpText
        element={element}
        hasError={hasError}
      />
      <div
        className={classnames('vx-form-control vx-text-field', {
          'vx-form-control--error': hasError,
        })}
        data-field-type="textbox"
      >
        {metadata.isPlainText ? (
          <PlainTextField>{field.value}</PlainTextField>
        ) : (
          <>
            <input
              type="text"
              className={`vx-text-field__input`}
              id={metadata.dataKeypath}
              name={metadata.dataKeypath}
              placeholder={element.placeholder_text}
              {...field}
              value={fieldValue}
              onChange={handleOnChange}
              disabled={isSubmitting}
            />
            <VxErrorMessage
              element={element}
              metadata={metadata}
            />
          </>
        )}
      </div>
    </>
  );
};

export default TextboxField;
