window.Control.ColorControl = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
  },

  onrender: function () {
    trace.log();
    $(this.find('.color-box')).spectrum({
      color: this.get('field'),
      showInput: true,
      clickoutFiresChange: true,
      showButtons: false,
      preferredFormat: 'hex',
      change: _.bind(this.updateColor, this),
      move: _.bind(this.updateColor, this),
      showPalette: true,
      showSelectionPalette: false,
      allowEmpty: true,
      showAlpha: true,
      palette: [
        ['#000000', '#ffffff', '#7f8c8d', '#95a5a6', '#bdc3c7'],
        ['#2c3e50', '#34495e', '#2980b9', '#3498db', '#89D0ff'],
        ['#1C7B44', '#27ae60', '#2ecc71', '#16a085', '#1abc9c'],
        ['#765431', '#d35400', '#e67e22', '#f39c12', '#f1c40f'],
        ['#f04736', '#c0392b', '#e74c3c', '#8e44ad', '#9b59b6'],
      ],
    });
  },

  onteardown: function () {
    trace.log();
    $(this.find('.color-box')).spectrum('hide');
    $(this.find('.color-box')).spectrum('destroy');
  },

  updateColor: function (color) {
    this.set('field', color ? color.toRgbString() : 'transparent');
  },
});
