import React from 'react';

import AuthTokenContext from 'components/shared/AuthTokenContext';

import SearchFilters from './SearchFilters';
import IntegrationCardList from './IntegrationCardList';

export default function PartnerSchoolIntegrationsPage(props) {
  const { authToken, data, config } = props;

  // const PAGE_STATES = ['LOADING', 'LOADING_MORE', 'SEARCHING', 'SEARCHED', 'ERROR']
  const [pageState, setPageState] = React.useState('LOADING');

  /***************************************************************************************************
   ** Utility
   ***************************************************************************************************/

  const isObjectEmpty = (obj) => Object.values(obj).filter((value) => value).length === 0;

  const compactify = (data) => {
    if (!_.isObject(data)) return data;

    return Object.entries(data).reduce((obj, [name, value]) => {
      if (value) obj[name] = compactify(value);
      return obj;
    }, {});
  };

  /***************************************************************************************************
   ** REQUEST: GET school-integrations/:partner_id/data
   ***************************************************************************************************/

  const REQUEST_HEADERS = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache, no-store',
    'X-CSRF-Token': authToken,
  };

  const PORTALS_REQUEST_URL_PARAMS = {
    client: Portals.config.client,
    portal_name: Portals.config.portal_name,
    partner_id: data.partner_id,
  };

  const sendGetIntegrationsRequest = async (params) => {
    const compactParams = compactify(params);

    const url = Routes.partner_school_integrations_data_path({
      ...PORTALS_REQUEST_URL_PARAMS,
      ...compactParams,
    });

    const response = await fetch(url, {
      method: 'GET',
      headers: REQUEST_HEADERS,
    });

    const responseBody = await response.json();

    if (response.ok) {
      return responseBody;
    } else {
      throw new Error(responseBody.status);
    }
  };

  /***************************************************************************************************
   ** Records
   ***************************************************************************************************/

  const INITIAL_PAGE = 1;
  const CARDS_PER_PAGE = 10;

  const [searchFilterValues, setSearchFilterValues] = React.useState({});

  const handleSearchFilterValueChange = (event) => {
    const { name, value } = event.target;
    setSearchFilterValues((values) => ({ ...values, [name]: value }));
  };

  const [records, setRecords] = React.useState([]);
  const addRecords = (v) => setRecords((values) => values.concat(v));

  const [currentPage, setCurrentPage] = React.useState(INITIAL_PAGE);
  const [canLoadMoreRecords, setCanLoadMoreRecords] = React.useState(false);

  const getRecords = async (filters = {}, page = INITIAL_PAGE) => {
    const records = await sendGetIntegrationsRequest({
      filters: filters,
      page: page,
    })
      .then((response) => {
        return response.records;
      })
      .catch((error) => {
        console.log('Error: ', error);
        return [];
      });

    setCanLoadMoreRecords(records.length > CARDS_PER_PAGE);

    return records.slice(0, CARDS_PER_PAGE);
  };

  // Initial Load
  React.useEffect(() => handleRecordsLoad(), []);

  // Records Load
  const handleRecordsLoad = (event) => {
    getRecords().then((records) => {
      setRecords(records);
      setPageState('LOADED');
    });
  };

  // Records Load More
  const handleRecordsLoadMore = (event) => {
    setPageState('LOADING_MORE');
    const page = currentPage + 1;
    const hasSearchFilterValues = isObjectEmpty(searchFilterValues);

    getRecords(searchFilterValues, page).then((records) => {
      setCurrentPage(page);
      addRecords(records);
      setPageState(hasSearchFilterValues ? 'LOADED' : 'SEARCHED');
    });
  };

  // Records Search
  const handleRecordsSearch = (event) => {
    event.preventDefault();
    setPageState('SEARCHING');
    const page = INITIAL_PAGE;

    getRecords(searchFilterValues, page).then((records) => {
      setCurrentPage(page);
      setRecords(records);
      setPageState('SEARCHED');
    });
  };

  // Clear Records Search
  const handleRecordsSearchClear = (event) => {
    setPageState('LOADING');
    setSearchFilterValues({});
    const page = INITIAL_PAGE;

    getRecords({}, page).then((records) => {
      setCurrentPage(page);
      setRecords(records);
      setPageState('LOADED');
    });
  };

  /***************************************************************************************************
   ** Render
   ***************************************************************************************************/

  return (
    <div className="partner-school-integrations-page">
      <AuthTokenContext.Provider value={authToken}>
        <SearchFilters
          config={config.search_filters}
          values={searchFilterValues}
          pageState={pageState}
          handleChange={handleSearchFilterValueChange}
          handleSearch={handleRecordsSearch}
          handleSearchClear={handleRecordsSearchClear}
        />
        <IntegrationCardList
          config={config.integration_cards}
          data={{ records }}
          pageState={pageState}
          canLoadMoreRecords={canLoadMoreRecords}
          handleRecordsLoadMore={handleRecordsLoadMore}
          setRecords={setRecords}
        />
      </AuthTokenContext.Provider>
    </div>
  );
}
