// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`DateOfBirthField should display correctly when there's an error 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label vx-form-label--required vx-form-label--error"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions vx-form-label-instructions--error"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control vx-text-field vx-form-control--error"
    data-field-type="textbox"
  >
    <input
      class="vx-text-field__input"
      id="section-888#0__header"
      name="section-888#0__header"
      placeholder="Some placeholder text"
      type="text"
      value=""
    />
    <div
      class="vx-validation-message vx-validation-message--display"
      data-testid="vx-error-message"
    >
      Required.
    </div>
  </div>
</div>
`;

exports[`DateOfBirthField should display plaintext if readonly 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control vx-text-field"
    data-field-type="textbox"
  >
    <div
      class="plaintext-data"
      data-testid="plain-text-field"
    >
      10/01/2018
    </div>
  </div>
</div>
`;

exports[`DateOfBirthField should not display a placeholder if it's empty 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control vx-text-field"
    data-field-type="textbox"
  >
    <input
      class="vx-text-field__input"
      id="section-888#0__header"
      name="section-888#0__header"
      placeholder=""
      type="text"
      value="10/01/2018"
    />
  </div>
</div>
`;

exports[`DateOfBirthField should not display a placeholder if it's not there 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control vx-text-field"
    data-field-type="textbox"
  >
    <input
      class="vx-text-field__input"
      id="section-888#0__header"
      name="section-888#0__header"
      type="text"
      value="10/01/2018"
    />
  </div>
</div>
`;

exports[`DateOfBirthField should not display help text if it's not there 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-control vx-text-field"
    data-field-type="textbox"
  >
    <input
      class="vx-text-field__input"
      id="section-888#0__header"
      name="section-888#0__header"
      placeholder="Some placeholder text"
      type="text"
      value="10/01/2018"
    />
  </div>
</div>
`;

exports[`DateOfBirthField should render correctly 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control vx-text-field"
    data-field-type="textbox"
  >
    <input
      class="vx-text-field__input"
      id="section-888#0__header"
      name="section-888#0__header"
      placeholder="Some placeholder text"
      type="text"
      value="10/01/2018"
    />
  </div>
</div>
`;
