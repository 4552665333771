window.SidebarToolbar = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./templates/sidebar-toolbar.ractive');
  },

  oninit: function () {
    trace.log();
    this.on({
      _chooseRegion: this._chooseRegion,
      _copyComponent: this._copyComponent,
    });
  },

  _chooseRegion: function (e) {
    this.toggle('show_regions');
    this.set('regions', $('.portal-screen-region').toArray());

    if (this.get('show_regions')) {
      $(document).on('click', _.bind(this.hidePopover, this));
    } else {
      return false;
    }

    return false;
  },

  _copyComponent: function () {
    this.set('show_regions', false);
    $(document).off('click');
  },

  hidePopover: function (e) {
    var popover = $('.es-RegionPopover');
    if (!popover.is(e.target) && popover.has(e.target).length === 0) {
      trace.log();
      this.set('show_regions', false);
      $(document).off('click');
    }
  },
});
