window.Sys.BillingPreference = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./payment-account-selection.ractive');
    options.partials = {
      account_item: require('./account-item.ractive'),
    };
  },

  computed: {
    wallet_path: function () {
      return Routes.wallet_path({
        client: this.get('client'),
        portal_name: this.get('portal_name'),
        key: this.get('key'),
        workflow: this.get('workflow'),
        year: this.get('school_year'),
      });
    },
    available_household_payment_accounts: function () {
      return _.filter(this.get('household_payment_accounts'), {
        read_only: false,
      });
    },
  },

  oninit: function () {
    trace.log();

    _.bindAllFunctions(this);

    this.set({
      selected_account: this.selected_account,
      tag_type: this.tag_type,
      card_types: ['', 'visa', 'master', 'american_express', 'discover'],
      card_descriptions: ['', 'Visa', 'Mastercard', 'American Express', 'Discover'],
    });

    this.on({
      _openCard: this._openCard,
      _selectAccount: this._selectAccount,
    });
  },

  // Determines additional tag styling based on account status
  tag_type: function (status) {
    switch (status) {
      case 2: // hold
        return 'vx-tag--warning';
      case 3: // error
      case 4: // expired
        return 'vx-tag--danger';
    }
  },

  selected_account: function (checkout_payment_account_pk) {
    return _.find(this.get('household_payment_accounts'), {
      checkout_payment_account_pk: checkout_payment_account_pk,
    });
  },

  _openCard: function (e) {
    e.original.preventDefault();
    this.set('open', true);
    return false;
  },

  _selectAccount: function (e) {
    e.original.preventDefault();

    var current_payment_account = _.find(this.get('household_payment_accounts'), {
      checkout_payment_account_pk: this.get('billing_preference.checkout_payment_account_fk'),
    });

    if (current_payment_account && current_payment_account.read_only) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The current payment account is read-only and has been assigned by another parent or guardian. If you decide to change the configured account you will not be able to switch it back.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Change Account',
      }).then(
        _.bind(function (result) {
          if (result.value) {
            this.updateAccountForBillingPreference(e.context.checkout_payment_account_pk);
          } else if (result.dismiss) {
            this.set('open', false);
          }
        }, this)
      );
    } else {
      this.updateAccountForBillingPreference(e.context.checkout_payment_account_pk);
    }

    return false;
  },

  updateAccountForBillingPreference: function (checkout_payment_account_pk) {
    if (this.get('saving')) {
      // latch to prevent duplicate POSTs (can be caused by a double click)
      return;
    }

    var initial_payment_account_pk = this.get('billing_preference.checkout_payment_account_fk');

    this.set({
      'billing_preference.checkout_payment_account_fk': checkout_payment_account_pk,
      saving: true,
      open: false,
    });

    // update the value in the db
    $.ajax({
      type: 'POST',
      url: Routes.billing_preferences_save_path({
        client: this.get('client'),
        portal_name: this.get('portal_name'),
        key: this.get('key'),
      }),
      data: this.get('billing_preference'),
      dataType: 'json',
      context: this,
      error: function (xhr, status, data) {
        Swal.fire('Unable to Save', 'Something went wrong! ' + xhr.responseText, 'error');

        // revert to the previous account before update if there is an error
        this.set('billing_preference.checkout_payment_account_fk', initial_payment_account_pk);
      },
      complete: function () {
        this.set('saving', false);
      },
    });
  },
});
