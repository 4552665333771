import React, { FC } from 'react';
import { ComponentElementMetadata } from '../types';
import { WebFormField } from './MultiFieldElement';

const DatePickerElement: FC<ComponentElementMetadata> = (props) => (
  <div
    className="ae-grid"
    data-testid="date_picker-element"
  >
    <div className="ae-grid__item item-sm-6">
      <WebFormField {...props} />
    </div>
  </div>
);

export default DatePickerElement;
