// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ParagraphElement should render correctly when the component is "paragraph" 1`] = `
<div
  class="ae-grid"
  data-testid="paragraph-element"
>
  <div
    class="ae-grid__item item-sm-12"
  >
    <h6
      class="vx-heading-6"
    >
      A basic element
    </h6>
  </div>
</div>
`;
