window.Sys.ResourceReservations = {
  init: function () {
    $('.delete-link').click(function (e) {
      e.preventDefault();

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to recover this reservation!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c63736',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          window.location = Routes.resource_reservations_delete_path({
            client: Portals.config.client,
            portal_name: Portals.config.portal_name,
            resource_reservation_pk: $(e.currentTarget).data('reservation-pk'),
          });
        }
      });
    });
  },
};
