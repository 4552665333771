require('../portal-component');
require('../../controls/load-more/load-more');

window.Portal.EventVerticalSchedule.Component = PortalComponent.extend({
  onconstruct: function (options) {
    this._super(options);
    _.merge(options.components, {
      LoadMore: Control.LoadMore,
    });
  },

  oninit: function () {
    trace.log();
    this.set({
      prev_day_is_different: this.prev_day_is_different,
      prev_time_is_different: this.prev_time_is_different,
    });
    this.reload();
  },

  reload: function () {
    trace.log();
    this.set('events', []);
    this.load_data().then(_.bind(this._dataLoaded, this));
  },

  _dataLoaded: function (data) {
    this.set('events', _.concat(this.get('events'), data.events));
    this.componentDoneLoading();
  },

  prev_day_is_different: function (index) {
    if (index == 0) {
      return true;
    } else {
      var current_event = this.get('events')[index];
      var previous_event = this.get('events')[index - 1];

      return current_event.month_day !== previous_event.month_day;
    }
  },

  prev_time_is_different: function (index) {
    if (index == 0) {
      return true;
    } else {
      var current_event = this.get('events')[index];
      var previous_event = this.get('events')[index - 1];

      return (
        current_event.month_day !== previous_event.month_day ||
        current_event.start_time !== previous_event.start_time
      );
    }
  },
});
