/* eslint-disable no-invalid-this */
const { formatMonetaryValue } = require('../components/shared/utils');

window.RegistrationSeason.Payments = {
  init: function () {
    this.formatFees();
    this.formatDiscount();
    this.formatNetAmount();

    const locale = Portals.config.locale;
    const currency_code = Portals.config.currency_code;
    const checkboxes = $('.payments-container .charge-item-checkbox');

    // Total Payment Due
    const calculateTotal = function () {
      const feeTotal = _.reduce(
        checkboxes,
        function (result, val) {
          const feeAmount = Number($(val).data('amount')) || 0;
          return result + (val.checked ? feeAmount : 0);
        },
        0
      );

      const updatedTotal = feeTotal < 0 ? 0 : feeTotal;
      return formatMonetaryValue(updatedTotal, { locale: locale, code: currency_code });
    };

    checkboxes.change(function () {
      $('#checkout-total').html(calculateTotal());
      $('#submit-pay').attr(
        'disabled',
        _.every(checkboxes, function (c) {
          return !c.checked;
        })
      );
      const tr = $('#tr-' + $(this).data('pa-pk'));
      tr.attr('disabled', !this.checked);
    });

    $('#submit-pay').click(function (e) {
      e.preventDefault();
      $('form.payments-form').submit();
    });
  },

  // Fees and Totals
  formatFees: function () {
    const self = this;
    const selectors = ['.fee-amount', '#checkout-total'];
    selectors.forEach(function (selector) {
      $(selector).each(function () {
        const formattedAmount = self.formatAmount($(this).data('amount'));
        $(this).html(formattedAmount);
      });
    });
  },

  // Discounts
  formatDiscount: function () {
    const self = this;
    $('.discount').each(function () {
      const amount = $(this).data('discount');
      const formattedAmount = amount === '' ? '--' : self.formatAmount(amount);
      $(this).html(formattedAmount);
    });
  },

  // Net Amount
  formatNetAmount: function () {
    const self = this;
    $('.net-amount').each(function () {
      const formattedAmount = self.formatAmount($(this).data('amount'));
      $(this).html(formattedAmount);
    });
  },

  formatAmount: function (amount) {
    return formatMonetaryValue(amount, {
      locale: Portals.config.locale,
      code: Portals.config.currency_code,
    });
  },
};

_.bindAll(RegistrationSeason.Payments, [
  'init',
  'formatFees',
  'formatDiscount',
  'formatNetAmount',
  'formatAmount',
]);
