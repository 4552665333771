import React, { FC } from 'react';
import { ComponentElementMetadata } from '../types';
import { WebFormField } from './MultiFieldElement';

const NumberElement: FC<ComponentElementMetadata> = (props) => (
  <div
    className="ae-grid"
    data-testid="number-element"
  >
    <div className="ae-grid__item item-sm-6">
      <WebFormField {...props} />
    </div>
  </div>
);

export default NumberElement;
