import React from 'react';
import ForkliftUploader from 'forklift-ui';
import ChecklistFileUploaderFile from './ChecklistFileUploaderFile';

const ErrorBanner = ({ text }) => (
  <div className="error-container vx-message-banner vx-message-banner--danger">{text}</div>
);

class ChecklistFileUploader extends React.Component {
  static defaultProps = {
    uploaderOptions: null,
    checklistItem: null,
    files: [],
  };

  constructor(props) {
    super();

    if (!window.Sys.failedFiles) {
      window.Sys.failedFiles = [];
    }

    this.state = {
      checklist_ui: window.Sys.checklist_items[props.checklistItem.checklist_item_id],
      files: props.files,
      // exception use case of window.Sys to maintain state after
      // ReactRailsUJS.mountComponents
      failedFiles: window.Sys.failedFiles,
    };
  }

  // refresh checklist item (replaces checklist dom)
  // ReactRailsUJS.mountComponents is needed so that the react component is reloaded
  // react-rails only intiates this for react components once during page load
  // https://github.com/reactjs/react-rails#mounting--unmounting
  refreshChecklistItem() {
    this.state.checklist_ui.refresh().then(() => {
      ReactRailsUJS.mountComponents();
    });
  }

  updateChecklistItem = async (file_pk, action) => {
    const { files, checklistItem } = this.props;
    const filesNumber = files.length;
    // if last file being removed, complete = 0 otherwise = 1
    let checklistItemComplete = 1;
    if (action === 'remove' && filesNumber - 1 === 0) {
      checklistItemComplete = 0;
    }

    await $.ajax({
      method: 'POST',
      url: Routes.admissions_checklist_update_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
        access_name: checklistItem.registration_season_access_name,
        person_fk: checklistItem.candidate_fk,
        school_year: checklistItem.school_year,
        checklist_item_id: checklistItem.checklist_item_id,
      }),
      data: {
        file_fk: file_pk,
        file_action: action,
        checklist_item_complete: checklistItemComplete,
      },
    });
  };

  removeFile = (e, recordFk) => {
    e.preventDefault();

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.value) {
        this.state.checklist_ui.showLoading();
        await this.updateChecklistItem(recordFk, 'remove');
        this.refreshChecklistItem();
      }
    });
  };

  onUpload = (file) => {
    this.state.checklist_ui.showLoading();
  };

  onFileAdded = () => {
    // reset failedFiles in window and state
    window.Sys.failedFiles = [];
    this.setState({ failedFiles: [] });
  };

  onComplete = async ({ successful, failed }) => {
    for (const file of successful) {
      await this.updateChecklistItem(file.fileInfo.file_pk, 'add');
    }
    window.Sys.failedFiles = [...window.Sys.failedFiles, ...failed];
    this.refreshChecklistItem();
  };

  render() {
    const { files, uploaderOptions } = this.props;
    const { failedFiles } = this.state;

    return (
      <div className="checklist-item-file-upload">
        {files.map(({ name, public_url, record_fk }) => (
          <ChecklistFileUploaderFile
            key={record_fk}
            recordFk={record_fk}
            name={name}
            public_url={public_url}
            onRemoveClick={this.removeFile}
          />
        ))}
        {failedFiles.map(({ meta: { name } }) => (
          <ErrorBanner
            key={name}
            text={name}
          />
        ))}
        <ForkliftUploader
          {...{
            ...uploaderOptions,
            onUpload: this.onUpload,
            onComplete: this.onComplete,
            onFileAdded: this.onFileAdded,
          }}
        />
      </div>
    );
  }
}

export default ChecklistFileUploader;
