require('./lib/bluebird_defer');
require('./lib/lodash_extensions');
unflatten = require('flat').unflatten;
function requireAll(r) {
  r.keys().forEach(r);
}
requireAll(require.context('./portal-components', true, /\.js$/));

window.PortalScreen = Ractive.extend({
  oninit: function () {
    trace.log();

    _.bindAllFunctions(this);

    this.promises_for_components = [];
    this.component_instances = [];
    this.sidebar = null;

    _.each(
      this.get('components'),
      _.bind(function (component) {
        var component_instance = this.loadComponent(component);
        if (component_instance) {
          this.promises_for_components.push(component_instance.getLoadingPromise());
          this.component_instances.push(component_instance);
        }
      }, this)
    );

    Promise.all(this.promises_for_components).done(this.hideLoading);
  },

  onteardown: function () {
    trace.log();

    _.each(this.component_instances, function (component) {
      component.teardown();
    });
  },

  hideLoading: function () {
    trace.log();

    $('.screen-loading').removeClass('show');
    _.delay(function () {
      $('.screen-loading').remove();
    }, 200);
  },

  loadComponent: function (component) {
    trace.log();

    var element = $(
      '.screen-component[data-component-pk=' + component.portal_screen_component_pk + ']'
    ).get(0);
    var access_name = 'Portal.' + component.system_access_name + '.Component';
    var kebab_name = _.kebabCase(component.system_access_name);
    var ComponentKlass = _.stringToClass(access_name);

    // unflatten configuration
    component.configuration = unflatten(component.configuration || {});

    if (!ComponentKlass) {
      return;
    }

    return new ComponentKlass({
      el: element,
      template: require('./portal-components/' +
        kebab_name +
        '/templates/' +
        kebab_name +
        '.ractive'),
      data: component,
    });
  },

  getScreenComponent: function (portal_screen_component_pk) {
    return _.find(this.component_instances, function (component) {
      return component.get('portal_screen_component_pk') == portal_screen_component_pk;
    });
  },
});
