require('../portal-component');
require('../../controls/load-more/load-more');
Masonry = require('masonry-layout');

window.Portal.ArticleMasonryCardList.Component = PortalComponent.extend({
  onconstruct: function (options) {
    this._super(options);
    _.merge(options.components, {
      LoadMore: Control.LoadMore,
    });
  },

  oninit: function () {
    trace.log();
    this.grid_options = {
      itemSelector: '.article',
      columnWidth: '.article',
      gutter: '.gutter-sizer',
      percentPosition: true,
      horizontalOrder: true,
      transitionDuration: 0,
    };
  },

  onrender: function () {
    if (this.get('admin')) {
      this.observe('configuration.columns', this.columnsChanged, {
        init: false,
        defer: true,
      });
    }

    this.reload();
  },

  onteardown: function () {
    if (this.grid) {
      this.grid.destroy();
    }
  },

  reload: function () {
    trace.log();
    this.set('articles', []);
    this.load_data().then(this._dataLoaded);
  },

  _dataLoaded: function (data) {
    this.set('articles', _.concat(this.get('articles'), data.articles));

    if (!this.grid) {
      this.grid = new Masonry(this.find('.article-list'), this.grid_options);
    } else {
      trace.log('reloading items in the masonry grid');
      this.grid.reloadItems();
      this.grid.layout();
    }
    this.componentDoneLoading();
  },

  columnsChanged: function (newValue, oldValue, keypath) {
    trace.log(newValue, oldValue, keypath);
    this.grid.destroy();
    this.grid = new Masonry(this.find('.article-list'), this.grid_options);
  },
});
