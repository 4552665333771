import React from 'react';

const MySchedule = (props) => {
  return (
    <>
      <div data-testid="mySchedule-mock-schedule">{JSON.stringify(props.schedule)}</div>
      <div data-testid="mySchedule-mock-conflicts">{JSON.stringify(props.timeConflicts)}</div>
    </>
  );
};

export default MySchedule;
