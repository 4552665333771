import React, { FC } from 'react';
import { ComponentElementMetadata } from '../types';
import { WebFormField } from './MultiFieldElement';

const DateOfBirthElement: FC<ComponentElementMetadata> = (props) => {
  return (
    <div
      className="ae-grid"
      data-testid="date_of_birth-element"
    >
      <div className="ae-grid__item item-sm-3">
        <WebFormField {...props} />
      </div>
    </div>
  );
};

export default DateOfBirthElement;
