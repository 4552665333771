import React from 'react';
import WebFormSections from './sections/WebFormSections';
import WebFormErrors from './WebFormErrors';
import WebFormAdminEditWarning from './WebFormAdminEditWarning';
import WebFormFooter from './WebFormFooter';
import WebFormScrollToError from './WebFormScrollToError';

const WebForm = () => {
  return (
    <div className="vx-form">
      <div className="vx-form-content">
        <WebFormErrors />
        <WebFormScrollToError />
        <WebFormAdminEditWarning />
        <WebFormSections />
        <WebFormFooter />
      </div>
    </div>
  );
};

export default WebForm;
