window.RegistrationSeason.ChecklistItem = function (params) {
  var item_container = '#checklist-item-' + params.checklist_item_id + '-container';

  var descriptionInit = function () {
    _.each($('.portal-description', item_container), function (pd) {
      if ($('.content', pd).outerHeight(true) > $(pd).height() * 1.5) {
        $('.expander', pd).fadeIn();
      } else {
        $('.expander', pd).remove();
        $(pd).removeClass('collapsed');
      }
    });

    $('.portal-description.collapsed', item_container).click(function () {
      var $el = $(this);

      // measure how tall inside should be by adding together heights of all inside paragraphs (except read-more paragraph)
      var totalHeight = $('.content', $el).outerHeight(true); // need to add some extra for outerheight not including margins of inner <p> tags

      $el
        .css({
          // Set height to prevent instant jumpdown when max height is removed
          height: $el.height(),
          'max-height': 9999,
        })
        .animate({
          height: totalHeight,
        });

      // fade out read-more
      $el.unbind();
      $('.expander', this).fadeOut(function () {
        $el.removeClass('collapsed');
      });

      // prevent jump-down
      return false;
    });
  };

  var highlight = function () {
    if (window.location.hash == item_container) {
      $('.vx-card', item_container).addClass('highlight');
    }
  };

  this.showLoading = function () {
    $('.vx-card', item_container).addClass('loading');
  };

  this.refresh = function () {
    this.showLoading();
    return $.ajax({
      method: 'get',
      url: Routes.admissions_checklist_item_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
        access_name: params.access_name,
        person_fk: params.person_fk,
        school_year: params.school_year,
        checklist_item_id: params.checklist_item_id,
      }),
    }).then(
      function (data) {
        // update the checklist item
        $('#checklist-item-' + params.checklist_item_id + '-container').html(data.partial_html);
        // update the checklist item in the sidebar
        $('#sidebar-checklist-item-' + params.checklist_item_id).html(
          data.sidebar_checklist_item_html
        );
        descriptionInit('#checklist-item-' + params.checklist_item_id + '-container');

        // update required complete count
        $('#checklist-complete-numerator').text(
          $('.checklist-sidebar-container').find(
            '.sidebar-checklist-item.required-item.show-checked'
          ).length
        );
      }.bind(this)
    );
  }.bind(this);

  descriptionInit();
};
