// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`WebFormFooter renders button if there are elements 1`] = `
<footer
  class="vx-form-footer web-form-footer"
  data-testid="web-form-footer"
>
  <button
    class="vx-button vx-button--success vx-button--submit form-submit"
    id="form-submit"
    type="submit"
  >
    Submit
  </button>
</footer>
`;

exports[`WebFormFooter renders no button if no elements 1`] = `
<footer
  class="vx-form-footer web-form-footer"
  data-testid="web-form-footer"
/>
`;

exports[`WebFormFooter renders the correct button label 1`] = `
<footer
  class="vx-form-footer web-form-footer"
  data-testid="web-form-footer"
>
  <button
    class="vx-button vx-button--success vx-button--submit form-submit"
    id="form-submit"
    type="submit"
  >
    Submit this form!
  </button>
</footer>
`;
