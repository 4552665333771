import React from 'react';

import {
  MessageBanner,
  SelectField,
  TextAreaField,
  TextField,
  Button,
} from 'components/shared/form_elements';
import useForm from 'components/shared/useForm';

import IntegratedPartnerEnrollment from './IntegratedPartnerEnrollment';

export default function PartnerCompanyProfileForm(props) {
  const { authToken, data, config } = props;

  const updatePartnerCompanyProfileUrl = Routes.update_partner_company_profile_path({
    client: Portals.config.client,
    portal_name: Portals.config.portal_name,
    partner_id: data.partner.partner_pk,
  });

  const {
    formState,
    formMessage,
    formValues,
    formErrors,
    handleFormFieldChange,
    handleFormSubmit,
  } = useForm({
    url: updatePartnerCompanyProfileUrl,
    initialFormValues: data.partner,
    authToken: authToken,
    config: {
      requestMethod: 'PUT',
      fieldValidations: {
        contact_name: ['isRequired'],
        contact_email: ['isRequired', 'hasEmailFormat'],
        website: ['hasUrlFormat'],
        logo_url: ['hasUrlFormat'],
      },
      buildSubmitData: (values) => ({ partner: values }),
      submitSuccessMessageText: 'Company Profile was successfully saved!',
      submitFailureMessageText:
        'Error: Unable to save changes. Please refresh the page and try again.',
    },
  });

  const isFormSaving = formState === 'SAVING';
  const formIsDisabled = isFormSaving;
  const submitButtonText = isFormSaving ? 'Saving...' : 'Save Company Profile';
  const hasFormMessage = !!formMessage.text;

  const FormInstructions = (
    <p className="vx-form-instruction">
      Please update your contact information, as Veracross may need to contact you. If enrolled as
      Integrated Partner, your company details below will be visible to all Veracross schools.
      <br />
    </p>
  );

  return (
    <>
      <form
        className="partner-company-profile-form vx-form"
        noValidate
      >
        <div className="ae-grid">
          {hasFormMessage && (
            <div className="ae-grid__item item-sm-12">
              <MessageBanner
                type={formMessage.type}
                text={formMessage.text}
              />
            </div>
          )}
          <div className="ae-grid__item item-sm-12">{FormInstructions}</div>
          <div className="ae-grid__item item-sm-6">
            <TextField
              name="description"
              value={formValues.description}
              config={config.description}
              error={formErrors.description}
              onChange={handleFormFieldChange}
            />
          </div>
          <div className="ae-grid__item item-sm-6">
            <SelectField
              name="service_area"
              value={formValues.service_area}
              config={config.service_area}
              error={formErrors.service_area}
              onChange={handleFormFieldChange}
            />
          </div>
          <div className="ae-grid__item item-sm-6">
            <TextField
              name="contact_name"
              value={formValues.contact_name}
              config={config.contact_name}
              error={formErrors.contact_name}
              onChange={handleFormFieldChange}
            />
          </div>
          <div className="ae-grid__item item-sm-6">
            <TextField
              name="contact_email"
              value={formValues.contact_email}
              config={config.contact_email}
              error={formErrors.contact_email}
              onChange={handleFormFieldChange}
            />
          </div>
          <div className="ae-grid__item item-sm-6">
            <TextField
              name="website"
              value={formValues.website}
              config={config.website}
              error={formErrors.website}
              onChange={handleFormFieldChange}
            />
          </div>
          <div className="ae-grid__item item-sm-6">
            <TextField
              name="logo_url"
              value={formValues.logo_url}
              config={config.logo_url}
              error={formErrors.logo_url}
              onChange={handleFormFieldChange}
            />
          </div>
          <div className="ae-grid__item item-sm-12">
            <TextAreaField
              name="public_notes"
              value={formValues.public_notes}
              config={config.public_notes}
              error={formErrors.public_notes}
              onChange={handleFormFieldChange}
            />
          </div>
          <div className="ae-grid__item item-sm--offset-4 item-sm-4">
            <Button
              className="vx-button vx-button--primary"
              disabled={formIsDisabled}
              onClick={handleFormSubmit}
              value={submitButtonText}
            />
          </div>
          <div className="ae-grid__item item-sm-12">
            <IntegratedPartnerEnrollment
              authToken={authToken}
              data={data}
            />
          </div>
        </div>
      </form>
    </>
  );
}
