import React from 'react';
import classnames from 'classnames';
import VxLabel from './VxLabel';
import HelpText from './HelpText';
import PlainTextField from './PlainTextField';
import { SingleDatePicker } from 'react-dates';
import { Field, useField, useFormikContext } from 'formik';
import VxErrorMessage from './VxErrorMessage';
import moment, { Moment } from 'moment';
import { ElementMetadata } from '../types';

const DATE_FORMAT_DATA = 'M/D/YYYY';

const isOutsideRange = () => false;

const DatePickerField = ({ element, metadata }: ElementMetadata) => {
  const [focused, setFocused] = React.useState(false);
  const [field, meta] = useField(metadata.dataKeypath);
  const [date, setDate] = React.useState(() => {
    return field.value ? moment(field.value, DATE_FORMAT_DATA) : null;
  });
  const hasError = meta.touched && meta.error;
  const { setFieldValue, setFieldTouched, isSubmitting } = useFormikContext();

  const onDateChange = (date: Moment) => {
    setDate(date);
    setFieldTouched(metadata.dataKeypath, true);
    setFieldValue(metadata.dataKeypath, date ? date.format(DATE_FORMAT_DATA) : '');
  };

  const onFocusChange = ({ focused }) => setFocused(focused);

  return (
    <>
      <VxLabel
        element={element}
        htmlFor={metadata.dataKeypath}
        hasError={hasError}
      />
      <HelpText
        element={element}
        hasError={hasError}
      />
      <div
        className={classnames('vx-form-control vx-date-field', {
          'vx-form-control--error': hasError,
        })}
      >
        {metadata.isPlainText ? (
          <PlainTextField>{field.value}</PlainTextField>
        ) : (
          <Field name={metadata.dataKeypath}>
            {() => (
              <>
                <SingleDatePicker
                  id={metadata.dataKeypath}
                  date={date}
                  focused={focused}
                  onFocusChange={onFocusChange}
                  onDateChange={onDateChange}
                  isOutsideRange={isOutsideRange}
                  numberOfMonths={1}
                  noBorder
                  displayFormat={DATE_FORMAT_DATA}
                  ariaLabel={element.placeholder_text || 'Date'}
                  placeholder={element.placeholder_text || ''}
                  disabled={isSubmitting}
                />
                <i
                  onClick={() => setFocused(true)}
                  className="nc-icon-outline ui-1_calendar-grid-61 vx-date-field__icon"
                />
                <VxErrorMessage
                  element={element}
                  metadata={metadata}
                />
              </>
            )}
          </Field>
        )}
      </div>
    </>
  );
};

export default DatePickerField;
