import React, { useCallback } from 'react';
import classnames from 'classnames';
import CreatebleSelect from 'react-select/creatable';

const EMPTY_SCHOOL_VALUE = { value: '' };
const NEW_SCHOOL_VALUE = { value: '0' };
const CREATE_SCHOOL_LABEL = () => `My school is not listed`;
const CREATE_NEW_SCHOOL_LABEL = 'School is not listed. Enter school information.';
const SCHOOL_ELEMENT_KEYPATHS = new Set([
  'school:organization_name',
  'school:school_type',
  'school:address_1',
  'school:address_2',
  'school:address_3',
  'school:city',
  'school:state_province',
  'school:postal_code',
  'school:country',
  'school:organization_phone',
  'school:website',
  'school:make_prior_school',
]);

const DROPDOWN_CUSTOM_STYLES = {
  menu: (provided) => ({
    ...provided,
    zIndex: 120,
  }),
};

const FeederSchoolElement = ({ schoolOptions, keypath, initialValue, placeholderText }) => {
  const schools = React.useMemo(
    () =>
      schoolOptions
        .sort((a, b) => a.sort_key - b.sort_key)
        .map((item) => ({ value: item.id, label: item.description })),
    []
  );
  const [selectedSchool, setSelectedSchool] = React.useState(() => {
    const currentSchool = schools.find((s) => s.value === initialValue);
    return currentSchool || EMPTY_SCHOOL_VALUE;
  });
  const [hasRendered, setHasRendered] = React.useState(false);
  const [isNewSchool, setIsNewSchool] = React.useState(false);
  const [sectionKeypath, sectionIndex] = keypath.split('.');

  React.useEffect(() => {
    const childElements = Array.from($('[data-child-field-container]'));

    // filter [data-child-field-container] divs that matches the same
    // section keypath and section index as the current feeder school element
    const schoolElements = childElements.filter((element) => {
      const childFieldContainer = element.dataset.childFieldContainer;
      const child = childFieldContainer.split('.');
      const [childSectionKeypath, childSectionIndex, keypath] = child;
      return (
        sectionKeypath === childSectionKeypath &&
        sectionIndex === childSectionIndex &&
        SCHOOL_ELEMENT_KEYPATHS.has(keypath)
      );
    });

    schoolElements.forEach((element) => {
      if (isNewSchool) {
        $(element).show();
      } else {
        $(element).hide();
      }
    });
  }, [isNewSchool]);

  React.useEffect(() => {
    if (hasRendered) {
      $(`[id="${keypath}"]`).val(selectedSchool.value).change();
    }
    setHasRendered(true);
  }, [selectedSchool]);

  const onCreateOption = useCallback(() => {
    setSelectedSchool(NEW_SCHOOL_VALUE);
    setIsNewSchool(true);
  }, [schools]);

  const onClearSchoolOption = useCallback(() => {
    setSelectedSchool(EMPTY_SCHOOL_VALUE);
    setIsNewSchool((prev) => !prev);
  }, []);

  return (
    <>
      <CreatebleSelect
        className={classnames({ hidden: isNewSchool })}
        createOptionPosition={'last'}
        formatCreateLabel={CREATE_SCHOOL_LABEL}
        isClearable={selectedSchool.value}
        isMulti={false}
        isValidNewOption={() => true} // always show the "I don't see my school" option
        onChange={(school) => setSelectedSchool(school || EMPTY_SCHOOL_VALUE)}
        onCreateOption={onCreateOption}
        options={schools}
        placeholder={placeholderText}
        styles={DROPDOWN_CUSTOM_STYLES}
        value={selectedSchool.value ? selectedSchool : null}
      />
      <label
        className={classnames('vx-checkbox-group__item', {
          hidden: !isNewSchool,
        })}
      >
        <input
          type="checkbox"
          className="vx-checkbox-input"
          checked={isNewSchool}
          onChange={onClearSchoolOption}
        />
        <span
          data-option-value="planes"
          className="vx-checkbox-group__label"
        >
          {CREATE_NEW_SCHOOL_LABEL}
        </span>
      </label>
    </>
  );
};

export default FeederSchoolElement;
