import React from 'react';
import LmsMobileSidebar from './LmsMobileSidebar';
import ClassNavigatorModal from './ClassNavigatorModal';
import { SectionType, SubTabType, TabType } from './types';
import LmsDesktopSidebar from './LmsDesktopSidebar';
import useNavigationStore from './useNavigationStore';
import LmsMobileNavbar from './LmsMobileNavbar';

// based on example at https://tailwindui.com/components/application-ui/application-shells/sidebar

type LmsSidebarContainerProps = {
  portalName: string;
  portalUrl: string;
  tabs: TabType[];
  subTabs?: SubTabType[];
  className: string;
  currentSection: SectionType;
};

export const LmsSidebarContainer = ({
  portalName,
  portalUrl,
  tabs,
  subTabs = [],
  className,
  currentSection,
}: LmsSidebarContainerProps) => {
  const initialize = useNavigationStore((state) => state.initialize);

  React.useEffect(() => {
    initialize({ className, tabs, subTabs, currentSection });
  }, []);

  return (
    <>
      <LmsMobileSidebar
        portalName={portalName}
        portalUrl={portalUrl}
      />
      <LmsDesktopSidebar />
      <LmsMobileNavbar />
      <ClassNavigatorModal />
    </>
  );
};

export default LmsSidebarContainer;
