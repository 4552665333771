// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`WebFormSuccess renders correctly 1`] = `
<div>
  <div
    class="admin-edit-form-success"
  >
    <i
      class="nc-icon-outline ui-1_check-circle-08 check-icon"
    />
    <h2
      class="vx-heading-2"
    >
      The form has been submitted successfully!
    </h2>
    <div
      class="success-message"
    >
      You can now close this window.
    </div>
  </div>
</div>
`;
