import React from 'react';

import AuthTokenContext from 'components/shared/AuthTokenContext';
import SearchFilters from './SearchFilters';
import OpportunityCardList from './OpportunityCardList';

export default function SolicitorConstituentsPage(props) {
  const { authToken, config } = props;

  // const PAGE_STATES = ['LOADING', 'LOADING_MORE', 'SEARCHING', 'SEARCHED', 'ERROR']
  const [pageState, setPageState] = React.useState('LOADING');

  /***************************************************************************************************
   ** Utility
   ***************************************************************************************************/

  const isObjectEmpty = (obj) => Object.values(obj).filter((value) => value).length === 0;

  const compactify = (data) => {
    if (!_.isObject(data)) return data;

    return Object.entries(data).reduce((obj, [name, value]) => {
      if (value) obj[name] = compactify(value);
      return obj;
    }, {});
  };

  /***************************************************************************************************
   ** REQUEST: GET solicitor-constituents/opportunities
   ***************************************************************************************************/

  const REQUEST_HEADERS = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache, no-store',
    'X-CSRF-Token': authToken,
  };

  const PORTALS_REQUEST_URL_PARAMS = {
    client: Portals.config.client,
    portal_name: Portals.config.portal_name,
  };

  const sendGetOpportunitiesRequest = async (params) => {
    const compactParams = compactify(params);

    const url = Routes.solicitor_constituents_opportunities_path({
      ...PORTALS_REQUEST_URL_PARAMS,
      ...compactParams,
    });

    const response = await fetch(url, {
      method: 'GET',
      headers: REQUEST_HEADERS,
    });

    const responseBody = await response.json();

    if (response.ok) {
      return responseBody;
    } else {
      throw new Error(responseBody.status);
    }
  };

  /***************************************************************************************************
   ** Opportunities
   ***************************************************************************************************/

  const INITIAL_PAGE = 1;
  const CARDS_PER_PAGE = 10;

  const [searchFilterValues, setSearchFilterValues] = React.useState({});

  const handleSearchFilterValueChange = (event) => {
    const { name, value } = event.target;
    setSearchFilterValues((values) => ({ ...values, [name]: value }));
  };

  const [opportunities, setOpportunities] = React.useState([]);
  const addOpportunities = (v) => setOpportunities((values) => values.concat(v));

  const [currentPage, setCurrentPage] = React.useState(INITIAL_PAGE);
  const [canLoadMoreOpportunities, setCanLoadMoreOpportunities] = React.useState(false);

  const getOpportunities = async (filters = {}, page = INITIAL_PAGE) => {
    const opportunities = await sendGetOpportunitiesRequest({
      filters: filters,
      page: page,
    })
      .then((response) => {
        return response.opportunities;
      })
      .catch((error) => {
        console.log('Error: ', error);
        return [];
      });

    setCanLoadMoreOpportunities(opportunities.length > CARDS_PER_PAGE);

    return opportunities.slice(0, CARDS_PER_PAGE);
  };

  // Initial Load Opportunities
  React.useEffect(() => {
    getOpportunities().then((opportunities) => {
      setOpportunities(opportunities);
      setPageState('LOADED');
    });
  }, []);

  // Search Opportunities
  const handleSearch = (event) => {
    event.preventDefault();
    setPageState('SEARCHING');
    const page = INITIAL_PAGE;

    getOpportunities(searchFilterValues, page).then((opportunities) => {
      setCurrentPage(page);
      setOpportunities(opportunities);
      setPageState('SEARCHED');
    });
  };

  // Load More Opportunities
  const handleLoadMoreOpportunities = (event) => {
    setPageState('LOADING_MORE');
    const page = currentPage + 1;
    const hasSearchFilterValues = isObjectEmpty(searchFilterValues);

    getOpportunities(searchFilterValues, page).then((opportunities) => {
      setCurrentPage(page);
      addOpportunities(opportunities);
      setPageState(hasSearchFilterValues ? 'LOADED' : 'SEARCHED');
    });
  };

  // Clear Opportunities Search
  const handleClearSearch = (event) => {
    setPageState('LOADING');
    setSearchFilterValues({});
    const page = INITIAL_PAGE;

    getOpportunities({}, page).then((opportunities) => {
      setCurrentPage(page);
      setOpportunities(opportunities);
      setPageState('LOADED');
    });
  };

  /***************************************************************************************************
   ** Render
   ***************************************************************************************************/

  return (
    <div className="solicitor-constituents-page">
      <AuthTokenContext.Provider value={authToken}>
        <SearchFilters
          config={config.search_filters}
          values={searchFilterValues}
          pageState={pageState}
          handleChange={handleSearchFilterValueChange}
          handleSearch={handleSearch}
          handleClearSearch={handleClearSearch}
        />
        <OpportunityCardList
          config={config.opportunity_cards}
          data={{ opportunities }}
          pageState={pageState}
          canLoadMoreOpportunities={canLoadMoreOpportunities}
          handleLoadMoreOpportunities={handleLoadMoreOpportunities}
          setOpportunities={setOpportunities}
        />
      </AuthTokenContext.Provider>
    </div>
  );
}
