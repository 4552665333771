require('../portal-component');
require('../../controls/pagination/pagination');

window.Portal.EventHorizontalSchedule.Component = PortalComponent.extend({
  onconstruct: function (options) {
    this._super(options);
    _.merge(options.components, {
      Pagination: Control.Pagination,
    });
  },

  computed: {
    grouped_events: function () {
      return _.groupBy(this.get('events'), 'month_day');
    },
  },

  oninit: function () {
    trace.log();
    this.set({
      prev_time_is_different: this.prev_time_is_different,
    });
    this.reload();
  },

  reload: function () {
    trace.log();
    this.set('events', []);
    this.findComponent('Pagination').set('page_num', 0);
    this.load_data().then(this._dataLoaded);
  },

  _dataLoaded: function (data) {
    this.set({
      events: data.events,
    });
    this.componentDoneLoading();
  },

  prev_time_is_different: function (index) {
    if (index == 0) {
      return true;
    } else {
      var current_event = this.get('events')[index];
      var previous_event = this.get('events')[index - 1];
      return (
        current_event.month_day !== previous_event.month_day ||
        current_event.start_time !== previous_event.start_time
      );
    }
  },
});
