import React from 'react';
import { useWebForm } from './WebFormContext';
import { useFormikContext } from 'formik';
import {
  trimForm,
  FORM_MODE_VIEW_SUBMISSION,
  getAllElements,
  setRelationshipDefaults,
} from './utils/form-helpers';
import { getFieldKeypath, submitButtonType } from './utils/element-helpers';

const WebFormFooter = () => {
  const { form, submission } = useWebForm();
  const { isSubmitting, values, setValues } = useFormikContext();

  const elementsLength = React.useMemo(() => getAllElements(form).length, []);

  const preprocess = React.useCallback(() => {
    const processedValues = {
      ...trimForm(values),
      ...setRelationshipDefaults(form, values),
    };
    setValues(processedValues, true);
  }, [values, setValues]);

  const buttonType = submitButtonType(form.mode, submission);
  const submitButtonLabel =
    (form.form_configuration || []).find((config) => config.keypath === buttonType.keypath)
      ?.value ?? buttonType.defaultLabel;

  return (
    <footer
      className="vx-form-footer web-form-footer"
      data-testid="web-form-footer"
    >
      {elementsLength !== 0 && form.mode !== FORM_MODE_VIEW_SUBMISSION && (
        <button
          id="form-submit"
          type="submit"
          className="vx-button vx-button--success vx-button--submit form-submit"
          disabled={isSubmitting}
          onClick={preprocess}
        >
          {submitButtonLabel}
        </button>
      )}
    </footer>
  );
};

export default WebFormFooter;
