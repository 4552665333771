window.AddModal = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./templates/add-modal.ractive');
  },

  computed: {
    categories: function () {
      var components = this.componentsAllowedForPortal(
        this.get('component_types'),
        Portals.config.portal.portal_type
      );
      return _.groupBy(components, 'category_description');
    },
    current_memo: function () {
      var selected_component = _.find(this.get('component_types'), {
        portal_component_type_pk: this.get('selected_component_type_pk'),
      });
      if (selected_component) {
        return selected_component.memo;
      } else {
        return '';
      }
    },
  },

  onrender: function () {
    this.set({
      selected_component_type_pk: 1,
      active_category: 'General',
      image_name: this.image_name,
    });
    this.on({
      _selectCategory: this._selectCategory,
      _selectComponentType: this._selectComponentType,
      _confirmSelection: this._confirmSelection,
      _close: this.close,
    });
  },

  show: function () {
    $('.es-Modal_Overlay').show();
    $('.es-Modal').addClass('show');
  },

  image_name: function (system_access_name) {
    return _.kebabCase(system_access_name);
  },

  close: function (e) {
    if (e.original) {
      e.original.preventDefault();
    }
    $('.es-Modal').removeClass('show');
    this.teardown();
  },

  _selectCategory: function (e) {
    trace.log(e);
    this.set('active_category', e.index.category_name);
    // automatically select the first component in the list
    this.set('selected_component_type_pk', e.context[0].portal_component_type_pk);
  },

  _selectComponentType: function (e) {
    trace.log(e);
    this.set('selected_component_type_pk', e.context.portal_component_type_pk);
  },

  _confirmSelection: function (e) {
    trace.log(e);
    this.fire('component_selected', this.get('selected_component_type_pk'), this.get('region'));
    this.close(e);
  },

  componentsAllowedForPortal: function (component_types, portal_type) {
    return _.filter(component_types, function (c) {
      if (c.allowed_portal_types == null) {
        return true;
      }

      var allowed_portal_types = _.map(c.allowed_portal_types.split(','), _.parseInt);
      return _.includes(allowed_portal_types, portal_type);
    });
  },
});
