window.WebForm.RadioField = (dataKeypath) => {
  $(`input[name="${dataKeypath}"]`).on('change', (event) => {
    handleNoSelectedRadio(event.target.name);
  });

  handleNoSelectedRadio(dataKeypath);
};

// creates a hidden input if there is not a
// selected radio button
const handleNoSelectedRadio = (inputName) => {
  const radioSelector = `input[name="${inputName}"]`;
  const hasSelectedRadio = $(`${radioSelector}:checked`).length > 0;
  const hiddenInput = $(`input[type="hidden"][name="${inputName}"]`);

  if (hasSelectedRadio) {
    if (hiddenInput.length) {
      hiddenInput.remove();
    }
  } else {
    if (!hiddenInput.length) {
      const hiddenInput = document.createElement('input');
      hiddenInput.type = 'hidden';
      hiddenInput.name = `${inputName}`;
      hiddenInput.value = null;
      $(radioSelector).last().after($(hiddenInput));
    }
  }
};

export { handleNoSelectedRadio };
