window.MobileNav = Ractive.extend({
  oninit: function () {
    $('.vx-mobile-menu').click(this.toggleNav);
    $('.vx-portal-nav__item-link').click(this.categoryClicked);
  },

  toggleNav: function (e) {
    if ($(window).width() <= 768) {
      $('body').toggleClass('vx-modal-open');
      $('.vx-nav').toggleClass('vx-nav--menu-is-open');
      $('.vx-portal-nav').toggleClass('vx-portal-nav--show');
    }
  },

  categoryClicked: function (e) {
    if ($(window).width() <= 768 && $(e.currentTarget).attr('href') === '#') {
      e.preventDefault();

      var category = $(e.currentTarget).closest('.vx-portal-nav__item');
      var menu = category.find('.vx-hover-menu');

      $('.vx-hover-menu').not(menu).slideUp(200);
      $('.vx-nav__item-drop-arrow')
        .removeClass('arrows-3_small-up')
        .addClass('arrows-3_small-down');

      if (menu.is(':hidden')) {
        category
          .find('.vx-nav__item-drop-arrow')
          .removeClass('arrows-3_small-down')
          .addClass('arrows-3_small-up');
      } else {
        category
          .find('.vx-nav__item-drop-arrow')
          .removeClass('arrows-3_small-up')
          .addClass('arrows-3_small-down');
      }

      menu.slideToggle(200);
    }
  },
});
