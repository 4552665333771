$(document).ready(function () {
  $('[data-action=updateDirectoryPreference]').change(function () {
    dp = new DirectoryPreference(this);
    dp.update();
  });
});

var DirectoryPreference = function (selector) {
  (el = $(selector)),
    (member_preference_pk = el.data('id')),
    (directory_type = el.data('directorytype')),
    (preference_keypath = el.data('keypath')),
    (token = el.data('token')),
    (form = (function () {
      if (el.data('formtype') == 'select_form') {
        return new DirectoryPreferenceSelectForm(el);
      } else if (el.data('formtype') == 'switch_form') {
        return new DirectoryPreferenceSwitchForm(el);
      }
    })()),
    (preference_value = form.preference_value),
    (this.update = function () {
      sendAjaxRequest();
      form.responsePendingHandler();
    }),
    (sendAjaxRequest = function () {
      $.ajax({
        type: 'POST',
        url: $(el).data('url'),
        data: {
          member_preference_pk: member_preference_pk,
          directory_type: directory_type,
          preference_keypath: preference_keypath,
          preference_value: preference_value,
          token: token,
        },
        success: function () {
          form.responseSuccessHandler();
        },
        error: function () {
          form.responseErrorHandler();
        },
      });
    });
};

var DirectoryPreferenceSelectForm = function (el) {
  (this.preference_value = el.val()),
    (this.responsePendingHandler = function () {
      $(el).addClass('DirectoryTypePreference_SelectTag--pending');
    }),
    (this.responseSuccessHandler = function () {
      $(el).removeClass('DirectoryTypePreference_SelectTag--pending');
      $(el).addClass('DirectoryTypePreference_SelectTag--success');
      setTimeout(function () {
        $(el).removeClass('DirectoryTypePreference_SelectTag--success');
      }, 3000);
    }),
    (this.responseErrorHandler = function () {
      $(el).removeClass('DirectoryTypePreference_SelectTag--pending');
      $(el).addClass('DirectoryTypePreference_SelectTag--error');
    });
};

var DirectoryPreferenceSwitchForm = function (el) {
  (this.preference_value = el.prop('checked') ? 2 : 1),
    (this.responsePendingHandler = function () {}),
    (this.responseSuccessHandler = function () {
      toggleEntrySelectForms();
    }),
    (this.responseErrorHandler = function () {
      el.prop('checked', function (i, val) {
        return !val;
      });
    }),
    (toggleEntrySelectForms = function () {
      directory_type_entry = $('#directory_type_' + el.data('directorytype'));
      updatable_select_forms_selector = '[data-formtype="select_form"][data-policy="3"]';

      if (el.prop('checked')) {
        directory_type_entry.removeClass('DirectoryTypePreferences--hidden');
        directory_type_entry.find(updatable_select_forms_selector).prop('disabled', false);
      } else {
        directory_type_entry.addClass('DirectoryTypePreferences--hidden');
        directory_type_entry.find(updatable_select_forms_selector).prop('disabled', true);
      }
    });
};
