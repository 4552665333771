require('../portal-component');

window.Portal.GoogleAuthorization.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.on({
      _openPopup: this._openPopup,
    });

    if (!this.get('admin')) {
      this.load_data().then(this._dataLoaded);
    } else {
      this.componentDoneLoading();
    }
  },

  _openPopup: function (e) {
    var url = [_.baseURL('portals_app'), 'google', 'authorize'].join('/');
    var top = screen.height / 2 - 640 / 2;
    var left = screen.width / 2 - 580 / 2;
    window.open(
      url,
      '_blank',
      'height=640,width=580,top=' +
        top +
        ',left=' +
        left +
        ',menubar=0,toolbar=0,resizable=0,scrollbars=0,status=0,titlebar=0'
    );
    return false;
  },

  _dataLoaded: function (data) {
    this.componentDoneLoading();

    // only show if auth status is set to pending
    if (data.auth_status != 1) {
      $(this.el).closest('.ae-grid__item').remove();
      this.teardown();
    }
  },
});
