if (typeof RedactorPlugins === 'undefined') window.RedactorPlugins = {};

RedactorPlugins.fontsize = {
  init: function () {
    var fonts = [10, 11, 12, 14, 16, 18, 20, 24, 28, 30];
    var that = this;
    var dropdown = {};

    jQuery.each(fonts, function (i, s) {
      dropdown['s' + i] = {
        title: s + 'px',
        callback: function () {
          that.setFontsize(s);
        },
      };
    });
    dropdown['remove'] = {
      title: 'Remove font size',
      callback: function () {
        that.resetFontsize();
      },
    };
    this.buttonAddAfter('underline', 'fontsize', 'Change font size', false, dropdown);
  },
  setFontsize: function (size) {
    this.inlineSetStyle('font-size', size + 'px');
  },
  resetFontsize: function () {
    this.inlineSetStyle('font-size', '14px');
  },
};
