import React from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import Field from 'components/shared/form_elements/Field';
import useAsyncRequest from 'components/shared/hooks/useAsyncRequest';
import AuthTokenContext from 'components/shared/AuthTokenContext';

// prettier-ignore
const noOptionsMessage = (input) => `No vendors found for "${input.inputValue}"`;

// prettier-ignore
const highlightSearchTerm = (text, inputValue) => {
  if (!text) return '';
  if (_.isBlank(inputValue)) return text;

  const escapedSearchTerm = inputValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  const searchTermRegex = new RegExp(`(${escapedSearchTerm})`, 'ig');

  return (
    <span>
      {text.split(searchTermRegex).map((part, i) => part.match(searchTermRegex) ? (
        <span className="vendor-select__highlighted" key={i}>
          {part}
        </span>
      ) : (
        part
      ))}
    </span>
  );
};

// prettier-ignore
const formatOptionLabel = (option, { context, inputValue }) => {
  if (context === 'value') {
    return (
      <div>
        {option.label}
      </div>
    );
  } else if (context === 'menu') {
    return (
      <div>
        <div className="vendor-name">
          {highlightSearchTerm(option.label, inputValue)}
        </div>
        <div className="vendor-type">
          {option.type}
        </div>
        <div className="vendor-address">
          {highlightSearchTerm(option.address, inputValue)}
        </div>
      </div>
    );
  }
};

const DEFAULT_VALUE = 0;

// prettier-ignore
function PurchaseRequestVendorSelectField(props) {
  const {
    name,
    value,
    onChange,
    setShowNewVendorFields,
    isDisabled,
  } = props;

  const authToken = React.useContext(AuthTokenContext);

  /**************************************************************************************************/

  const getPurchaseRequestVendors = useAsyncRequest({
    pathName: 'purchase_requests_vendors_path',
    method: 'GET',
    authorizationToken: authToken,
  });

  const [initialSelectedOption, setInitialSelectedOption] = React.useState(null);

  React.useEffect(() => {
    if (value) {
      getPurchaseRequestVendors({ vendor_id: value }).then((response) => {
        setInitialSelectedOption(response);
      });
    } else {
      setInitialSelectedOption(null);
    }
  }, [value]);

  const loadVendorOptions = _.debounce((inputValue, callback) => {
    if (_.isBlank(inputValue)) callback([]);

    getPurchaseRequestVendors({ search: encodeURIComponent(inputValue) }).then((response) => {
      callback(response);
    });
  }, 500);

  /**************************************************************************************************/

  const handleChange = React.useCallback((newOption) => {
    const newValue = newOption?.value || DEFAULT_VALUE;
    onChange({ target: { name: name, value: newValue } });
  }, [name, onChange]);

  const handleClickAddNewVendorLink = (event) => {
    event.preventDefault();

    onChange({ target: { name: name, value: DEFAULT_VALUE } });
    setShowNewVendorFields(true);
  };

  const CustomNoOptionsMessage = (innerProps) => {
    return (
      <>
        <components.NoOptionsMessage {...innerProps} />
        <div
          className="vendor-select__add-new-option"
          onClick={handleClickAddNewVendorLink}
        >
          <i className="nc-icon-glyph ui-1_circle-add" />
          <span>Request New Vendor</span>
        </div>
      </>
    );
  };

  /**************************************************************************************************/

  return (
    <Field className="vx-select-field" {...props}>
      <AsyncSelect
        classNamePrefix="vendor-select"
        value={initialSelectedOption}
        loadOptions={loadVendorOptions}
        onChange={handleChange}
        placeholder="Search vendors..."
        noOptionsMessage={noOptionsMessage}
        formatOptionLabel={formatOptionLabel}
        components={{
          NoOptionsMessage: CustomNoOptionsMessage,
        }}
        cacheOptions={true}
        defaultOptions={true}
        hideSelectedOptions={false}
        captureMenuScroll={true}
        closeMenuOnSelect={true}
        isClearable={true}
        isSearchable={true}
        maxMenuHeight={300}
        isDisabled={isDisabled}
      />
    </Field>
  );
}

export default React.memo(PurchaseRequestVendorSelectField);
