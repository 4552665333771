// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`VxLabel should render correctly when it's optional 1`] = `
<label
  class="vx-form-label"
  data-testid="vx-label"
  for="some-id-goes-here"
>
  Here's a description for the label
</label>
`;

exports[`VxLabel should render correctly when it's required 1`] = `
<label
  class="vx-form-label vx-form-label--required"
  data-testid="vx-label"
  for="some-id-goes-here"
>
  Here's a description for the label
</label>
`;

exports[`VxLabel should render correctly when there's an error 1`] = `
<label
  class="vx-form-label vx-form-label--required vx-form-label--error"
  data-testid="vx-label"
  for="some-id-goes-here"
>
  Here's a description for the label
</label>
`;
