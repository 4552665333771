window.RegistrationSeason.AdmissionVisitV1 = Ractive.extend({
  oninit: function () {
    var form_el = $('form.admission-visit-v1-form', this.get('checklist_el'));
    var data_el = $('.admission-visit-v1-data', this.get('checklist_el'));

    this.get('start_button_el').click(
      function (e) {
        e.preventDefault();
        this.get('start_button_el').hide();
        form_el.show();
        data_el.hide();
      }.bind(this)
    );

    form_el.submit(
      function (e) {
        e.preventDefault();
        // extract data from the form
        var submit_button = $('.vx-button.submit-button', form_el);

        submit_button.addClass('vx-button--loading');

        var data = _.reduce(
          _.map($(':input', form_el).serializeArray(), function (d) {
            return { [d.name]: d.value };
          }),
          _.merge
        );

        this.submitForm(data).error(function () {
          submit_button.removeClass('vx-button--loading');
        });
      }.bind(this)
    );
  },

  submitForm: function (data) {
    return $.ajax({
      type: 'POST',
      url: Routes.admissions_checklist_update_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
        access_name: this.get('access_name'),
        person_fk: this.get('person_fk'),
        school_year: this.get('school_year'),
        checklist_item_id: this.get('checklist_item'),
      }),
      data: data,
    })
      .success(
        function () {
          this.fire('success');
        }.bind(this)
      )
      .error(
        function (e, msg) {
          this.fire('error', e);
        }.bind(this)
      );
  },
});
