window.Control.ToggleControl = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
  },

  oninit: function () {
    var options = _.defaults(
      {
        on_value: this.get('on_value'),
        on_icon: this.get('on_icon'),
        on_text: this.get('on_text'),
        off_value: this.get('off_value'),
        off_icon: this.get('off_icon'),
        off_text: this.get('off_text'),
      },
      {
        on_value: 'show',
        on_icon: 'ui-1_eye-17',
        on_text: 'Show',
        off_value: 'hide',
        off_icon: 'ui-1_eye-ban-18',
        off_text: 'Hide',
      }
    );

    this.set(options);

    this.on({
      _toggle: this._toggle,
    });
  },

  _toggle: function (e) {
    if (this.get('field') == this.get('on_value')) {
      this.set('field', this.get('off_value'));
    } else {
      this.set('field', this.get('on_value'));
    }
  },
});
