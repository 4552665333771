import React from 'react';

import useForm from './useForm';

import MessageBanner from './MessageBanner';
import FormLoader from './FormLoader';
import FormSubmission from './FormSubmission';
import TextField from './field-types/TextField';
import NumberField from './field-types/NumberField';
import DateField from './field-types/DateField';
import TextAreaField from './field-types/TextAreaField';
import SelectField from './field-types/SelectField';
import RadioGroupField from './field-types/RadioGroupField';

export default function Form(props) {
  const { fields } = props;

  const { formState, message, values, errors, handleChange, handleSubmit } = useForm(props);

  switch (formState) {
    case 'submitting':
      return (
        <FormLoader
          formTitle={props.formTitle}
          message="Submitting..."
        />
      );
    case 'submitted':
      return (
        <FormSubmission
          formTitle={props.formTitle}
          formSubmissionMessage={props.formSubmissionMessage}
          formRecommendationMessage={props.formRecommendationMessage}
          fields={fields}
          values={values}
        />
      );
    default:
      return (
        <form
          className="vx-form"
          onSubmit={handleSubmit}
          noValidate
        >
          <div className="vx-form-header">
            <h2>{props.formTitle}</h2>
          </div>

          <div className="vx-form-content">
            <div className="vx-field-group">
              <div className="ae-grid">
                <div className="ae-grid__item item-sm-12">
                  <div className="vx-form-step-header">
                    <div className="vx-form-step-header-text">New Screening</div>
                  </div>
                </div>

                {props.formInstructions && (
                  <div className="ae-grid__item item-sm-12">
                    <div
                      className="vx-form-instructions"
                      dangerouslySetInnerHTML={{
                        __html: props.formInstructions,
                      }}
                    />
                  </div>
                )}

                <MessageBanner {...message} />

                <div className="ae-grid__item item-sm-6">
                  <SelectField
                    name="screeningPerson"
                    value={values.screeningPerson}
                    error={errors.screeningPerson}
                    config={fields.screeningPerson}
                    onChange={handleChange}
                  />
                </div>

                <div className="ae-grid__item item-sm-6">
                  <DateField
                    name="screeningDate"
                    value={values.screeningDate}
                    error={errors.screeningDate}
                    config={fields.screeningDate}
                    onChange={handleChange}
                  />
                </div>

                <div className="ae-grid__item item-sm-6">
                  <RadioGroupField
                    name="symptomsStatus"
                    value={values.symptomsStatus}
                    error={errors.symptomsStatus}
                    config={fields.symptomsStatus}
                    onChange={handleChange}
                  />
                </div>

                <div className="ae-grid__item item-sm-6">
                  <NumberField
                    name="screeningTemperature"
                    value={values.screeningTemperature}
                    error={errors.screeningTemperature}
                    config={fields.screeningTemperature}
                    onChange={handleChange}
                  />
                </div>

                {fields.exposureStatus.displayed && (
                  <div className="ae-grid__item item-sm-6">
                    <RadioGroupField
                      name="exposureStatus"
                      value={values.exposureStatus}
                      error={errors.exposureStatus}
                      config={fields.exposureStatus}
                      onChange={handleChange}
                    />
                  </div>
                )}

                {fields.exposureStatus.displayed && <div className="ae-grid__item item-sm-6"></div>}

                <div className="ae-grid__item item-sm-6">
                  <RadioGroupField
                    name="travelStatus"
                    value={values.travelStatus}
                    error={errors.travelStatus}
                    config={fields.travelStatus}
                    onChange={handleChange}
                  />
                </div>

                <div className="ae-grid__item item-sm-6">
                  {values.travelStatus == 2 && (
                    <TextField
                      name="travelCountries"
                      value={values.travelCountries}
                      error={errors.travelCountries}
                      config={fields.travelCountries}
                      onChange={handleChange}
                    />
                  )}
                </div>

                <div className="ae-grid__item item-sm-6">
                  <RadioGroupField
                    name="testStatus"
                    value={values.testStatus}
                    error={errors.testStatus}
                    config={fields.testStatus}
                    onChange={handleChange}
                  />
                </div>

                <div className="ae-grid__item item-sm-3">
                  {values.testStatus == 2 && (
                    <SelectField
                      name="testResult"
                      value={values.testResult}
                      error={errors.testResult}
                      config={fields.testResult}
                      onChange={handleChange}
                    />
                  )}
                </div>

                <div className="ae-grid__item item-sm-3">
                  {values.testStatus == 2 && (
                    <DateField
                      name="testDate"
                      value={values.testDate}
                      error={errors.testDate}
                      config={fields.testDate}
                      onChange={handleChange}
                    />
                  )}
                </div>

                <div className="ae-grid__item item-sm-12">
                  <TextAreaField
                    name="screeningComments"
                    value={values.screeningComments}
                    error={errors.screeningComments}
                    config={fields.screeningComments}
                    onChange={handleChange}
                  />
                </div>

                {props.formDisclaimer && (
                  <div className="ae-grid__item item-sm-12">
                    <div
                      className="vx-form-disclaimer"
                      dangerouslySetInnerHTML={{ __html: props.formDisclaimer }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer className="vx-form-footer">
            <input
              className="vx-button vx-button--large vx-button--success"
              type="submit"
              value="Submit"
            />
          </footer>
        </form>
      );
  }
}
