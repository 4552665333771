require('../portal-component');

window.Portal.PortalLinks.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.set({
      urlWithImpersonation: this.urlWithImpersonation,
      urlTarget: this.urlTarget,
    });
    this.reload();
    // TODO @ebauer - what data to show on the edit screen? Is there a way to show dummy data?
  },

  computed: {
    columnIndexes: function () {
      // replicate Portals and only show columns 1 and 2
      // 0 is in there to support faculty links
      return [0, 1, 2];
    },
    anyLinks: function () {
      return this.get('linkColumns') && this.get('linkColumns').length > 0;
    },
    linkColumns: function () {
      var link_columns = [];
      var self = this;
      _.each(this.get('columnIndexes'), function (col) {
        var links = self.linksForColumn(col);
        if (links.length > 0) {
          link_columns.push(links);
        }
      });
      return link_columns;
    },
  },

  linksForColumn: function (col) {
    var links = [];

    // add the old hyperlinks at the beginning of the first column (to replicate Portals 2 behavior)
    if (col === 1 && this.get('old_hyperlinks')) {
      links = _.concat(links, this.get('old_hyperlinks'));
    }

    links = _.concat(
      links,
      _.filter(this.get('portal_links'), function (l) {
        return l.column_number === col;
      })
    );
    return links;
  },

  // include impersonation for relative/portal urls (if impersonation is occuring)
  urlWithImpersonation: function (url) {
    var url_regex = /^(https?:)?\/\//i;
    var portals_regex = /^(https?:)?\/\/portals\.veracross\./i;

    if (url_regex.test(url) && !portals_regex.test(url)) {
      return url;
    } else {
      var client_only = _.last(_.split(Portals.config.client, '@', 2));
      return url.replace('/' + client_only + '/', '/' + Portals.config.client + '/');
    }
  },

  // open new window/tab unless open_in_new_tab is turned off
  urlTarget: function (link) {
    // old hyperlinks won't have this defined (would take too much effort to update across all custom procedures).
    // because of this we need to make sure it is defined before doing our check to see if it is off
    if (typeof link.open_in_new_tab !== 'undefined' && !link.open_in_new_tab) {
      return '_self';
    }
    return '_blank';
  },

  _dataLoaded: function (data) {
    this.set({
      portal_links: data.portal_links,
      old_hyperlinks: data.old_hyperlinks,
    });

    this.componentDoneLoading();
  },

  reload: function () {
    this.load_data().then(this._dataLoaded);
  },
});
