import React from 'react';

const ChecklistFileUploaderFile = ({ public_url, name, onRemoveClick, recordFk }) => {
  const handleClick = (event) => {
    onRemoveClick(event, recordFk);
  };

  return (
    <div className="checklist-item-file">
      <i className="nc-icon-outline files_single-content-03 file-icon" />
      <a
        href={public_url}
        target="_blank"
      >
        {' '}
        {name}{' '}
      </a>
      <a
        href="#"
        className="file-remove-link"
        onClick={handleClick}
      >
        Remove
      </a>
    </div>
  );
};

export default ChecklistFileUploaderFile;
