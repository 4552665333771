import React from 'react';

import { TextField, SelectField } from 'components/shared/form_elements';
import useAsyncRequest from 'components/shared/hooks/useAsyncRequest';
import AuthTokenContext from 'components/shared/AuthTokenContext';

import { REQUEST_STATUSES } from 'components/system/purchase_requests/constants';
import PurchaseRequestVendorSelectField from 'components/system/purchase_requests/PurchaseRequestVendorSelectField';

const UPDATABLE_REQUEST_STATUSES = [REQUEST_STATUSES.pending, REQUEST_STATUSES.changes_needed].map(
  (e) => e.value
);

// prettier-ignore
function PurchaseRequestVendorSection(props) {
  const {
    data,
    config,
    formValues,
    formErrors,
    formChanges,
    handleFormFieldChange,
  } = props;

  const authToken = React.useContext(AuthTokenContext);

  const getPurchaseRequestVendor = useAsyncRequest({
    pathName: 'purchase_requests_vendor_path',
    params: {
      vendor_id: data.vendor_fk,
    },
    method: 'GET',
    authorizationToken: authToken,
  });

  const isUpdatable = UPDATABLE_REQUEST_STATUSES.includes(data.request_status);

  /**************************************************************************************************/

  const hasNewVendorFieldValues = data.vendor_fk === 0 && !!data.vendor_name;

  const [showNewVendorFields, setShowNewVendorFields] = React.useState(hasNewVendorFieldValues);

  const handleClickSelectExistingVendor = (event) => {
    event.preventDefault();
    setShowNewVendorFields(false);
  };

  const handleClickAddNewVendor = (event) => {
    event.preventDefault();
    setShowNewVendorFields(true);
  };

  const [selectedVendorData, setSelectedVendorData] = React.useState({});

  React.useEffect(() => {
    if (formValues.vendor_fk) {
      getPurchaseRequestVendor({ vendor_id: formValues.vendor_fk }).then((response) => {
        setSelectedVendorData(response);
      });
    } else {
      setSelectedVendorData({});
    }
  }, [formValues.vendor_fk]);

  /**************************************************************************************************/

  if (showNewVendorFields) {
    return (
      <div className="purchase-request-detail-section">
        <div className="purchase-request-detail-section-header">
          <div className="purchase-request-detail-section-header-title">
            Vendor
          </div>
        </div>

        <div className="ae-grid">
          <div className="ae-grid__item item-sm-12">
            <div className="vx-form-step-header">
              <span className="vx-form-step-header-text">
                Request a New Vendor
              </span>
              {isUpdatable && (
                <span>
                  (
                  <a
                    className="vx-form-step-header-link"
                    onClick={handleClickSelectExistingVendor}
                  >
                    Select an existing Vendor
                  </a>
                  )
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="ae-grid">
          <div className="ae-grid__item item-sm-6">
            <TextField
              name="vendor_name"
              value={formValues.vendor_name}
              error={formErrors.vendor_name}
              config={config.vendor_name}
              onChange={handleFormFieldChange}
              hasChange={formChanges.vendor_name}
              disabled={!isUpdatable}
            />
          </div>
          <div className="ae-grid__item item-sm-6" />
        </div>

        <div className="ae-grid">
          <div className="ae-grid__item item-sm-6">
            <TextField
              name="vendor_address_1"
              value={formValues.vendor_address_1}
              error={formErrors.vendor_address_1}
              config={config.vendor_address_1}
              onChange={handleFormFieldChange}
              hasChange={formChanges.vendor_address_1}
              disabled={!isUpdatable}
            />
          </div>
          <div className="ae-grid__item item-sm-6">
            <TextField
              name="vendor_address_2"
              value={formValues.vendor_address_2}
              error={formErrors.vendor_address_2}
              config={config.vendor_address_2}
              onChange={handleFormFieldChange}
              hasChange={formChanges.vendor_address_2}
              disabled={!isUpdatable}
            />
          </div>
          <div className="ae-grid__item item-sm-3">
            <TextField
              name="vendor_city"
              value={formValues.vendor_city}
              error={formErrors.vendor_city}
              config={config.vendor_city}
              onChange={handleFormFieldChange}
              hasChange={formChanges.vendor_city}
              disabled={!isUpdatable}
            />
          </div>
          <div className="ae-grid__item item-sm-3">
            <TextField
              name="vendor_state_province"
              value={formValues.vendor_state_province}
              error={formErrors.vendor_state_province}
              config={config.vendor_state_province}
              onChange={handleFormFieldChange}
              hasChange={formChanges.vendor_state_province}
              disabled={!isUpdatable}
            />
          </div>
          <div className="ae-grid__item item-sm-3">
            <TextField
              name="vendor_postal_code"
              value={formValues.vendor_postal_code}
              error={formErrors.vendor_postal_code}
              config={config.vendor_postal_code}
              onChange={handleFormFieldChange}
              hasChange={formChanges.vendor_postal_code}
              disabled={!isUpdatable}
            />
          </div>
          <div className="ae-grid__item item-sm-3">
            <SelectField
              name="vendor_country"
              value={formValues.vendor_country}
              error={formErrors.vendor_country}
              config={config.vendor_country}
              onChange={handleFormFieldChange}
              hasChange={formChanges.vendor_country}
              disabled={!isUpdatable}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="purchase-request-detail-section">
        <div className="purchase-request-detail-section-header">
          <div className="purchase-request-detail-section-header-title">
            Vendor
          </div>
        </div>

        <div className="ae-grid">
          <div className="ae-grid__item item-sm-12">
            <div className="vx-form-step-header">
              <span className="vx-form-step-header-text">
                Select an existing Vendor
              </span>
              {isUpdatable && (
                <span>
                  (
                  <a
                    className="vx-form-step-header-link"
                    onClick={handleClickAddNewVendor}
                  >
                    Request a New Vendor
                  </a>
                  )
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="ae-grid">
          <div className="ae-grid__item item-sm-6">
            <PurchaseRequestVendorSelectField
              name="vendor_fk"
              value={formValues.vendor_fk}
              error={formErrors.vendor_fk}
              config={config.vendor_fk}
              onChange={handleFormFieldChange}
              setShowNewVendorFields={setShowNewVendorFields}
              hasChange={formChanges.vendor_fk}
              isDisabled={!isUpdatable}
            />
          </div>
        </div>

        <div className="ae-grid">
          <div className="ae-grid__item item-sm-6">
            <TextField
              name="vendor_address_1"
              value={selectedVendorData.address_1}
              config={config.vendor_address_1}
              disabled={true}
            />
          </div>
          <div className="ae-grid__item item-sm-6">
            <TextField
              name="vendor_address_2"
              value={selectedVendorData.address_2}
              config={config.vendor_address_2}
              disabled={true}
            />
          </div>
          <div className="ae-grid__item item-sm-3">
            <TextField
              name="vendor_city"
              value={selectedVendorData.city}
              config={config.vendor_city}
              disabled={true}
            />
          </div>
          <div className="ae-grid__item item-sm-3">
            <TextField
              name="vendor_state_province"
              value={selectedVendorData.state_province}
              config={config.vendor_state_province}
              disabled={true}
            />
          </div>
          <div className="ae-grid__item item-sm-3">
            <TextField
              name="vendor_postal_code"
              value={selectedVendorData.postal_code}
              config={config.vendor_postal_code}
              disabled={true}
            />
          </div>
          <div className="ae-grid__item item-sm-3">
            <SelectField
              name="vendor_country"
              value={selectedVendorData.country}
              config={config.vendor_country}
              disabled={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default React.memo(PurchaseRequestVendorSection);
