// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`HiddenFieldBadge renders correctly 1`] = `
<div>
  <span
    class="hidded-field-instructions vx-badge vx-badge--yellow"
    data-testid="hidden-field-badge"
  >
    <span
      aria-label="This question will not appear on the real form. Select your preset value(s) that will be default for all submissions."
      class="vx-tooltipped vx-tooltipped--s"
    >
      HIDDEN FIELD
    </span>
  </span>
</div>
`;
