import React from 'react';

import useAsyncRequest from 'components/shared/hooks/useAsyncRequest';
import { formatMonetaryValue } from 'components/shared/utils';
import { Button, MessageBanner } from 'components/shared/form_elements';
import AuthTokenContext from 'components/shared/AuthTokenContext';

import PurchaseRequestItem from './PurchaseRequestItem';
import NewPurchaseRequestItem from './NewPurchaseRequestItem';

// prettier-ignore
function PurchaseRequestItemsSection(props) {
  const {
    data,
    items,
    subtotalAmount,
    error,
    loadItems,
    isDisabled,
    purchaseRequestWorkflowFk,
  } = props;
  const authToken = React.useContext(AuthTokenContext);
  const [glAccounts, setGlAccounts] = React.useState([]);

  const isLoadingItems = items === undefined;
  const hasNoItems = items?.length === 0;
  const isPosted = data.posted;
  const numColumns = isDisabled && !isPosted ? 5 : 6;

  const getGlAccountsRequest = useAsyncRequest({
    pathName: 'purchase_requests_gl_accounts_path',
    method: 'GET',
    authorizationToken: authToken,
  });

  React.useEffect(() => loadItems(), []);

  React.useEffect(() => {
    const getAccounts = async () => {
      const accounts = await getGlAccountsRequest({ purchase_request_workflow_fk: purchaseRequestWorkflowFk });
      setGlAccounts(accounts);
    };

    if (Number(purchaseRequestWorkflowFk)) {
      getAccounts();
    }
  }, [purchaseRequestWorkflowFk]);

  /**************************************************************************************************/

  const [showNewItemForm, setShowNewItemForm] = React.useState(false);

  const handleClickNewItemButton = React.useCallback((event) => {
    event.preventDefault();

    if (!showNewItemForm) {
      setShowNewItemForm(true);
    }
  }, [showNewItemForm]);

  /**************************************************************************************************/

  return (
    <div className="purchase-request-detail-section">
      <div className="purchase-request-detail-section-header">
        <div className="purchase-request-detail-section-header-title">
          Line Items
          <span className="purchase-request-detail-section-header-badge vx-badge vx-badge--blue">{items?.length || 0}</span>
        </div>
        <div className="purchase-request-detail-section-header-actions">
          {!isDisabled && (
            <Button
              className="vx-button vx-button--success form-button"
              onClick={handleClickNewItemButton}
              value="Add Item"
              disabled={showNewItemForm}
            />
          )}
        </div>
      </div>
      {error && (
        <MessageBanner type="error" text={error} />
      )}
      <div id="table-items-container" className="ae-grid">
        <div className="ae-grid__item item-sm-12">
          <div className="purchase-requests__table_wrapper">
            <table className="purchase-requests__table" style={{ overflowX: 'scroll' }}>
              <thead>
                <tr>
                  <th className="text-align-left description-th">
                    Description
                  </th>
                  <th className="text-align-left model-th">
                    Model Number
                  </th>
                  <th className="text-align-left gl-account-th">
                    GL Account
                  </th>
                  <th className="text-align-right unit-cost-th">
                    Unit Cost
                  </th>
                  <th className="text-align-center quantity-th">
                    Quantity
                  </th>
                  <th className="text-align-right total-th">
                    Total
                  </th>
                  {(!isDisabled || isPosted) && (
                    <th className="text-align-left actions-th">
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {isLoadingItems && (
                  <tr>
                    {Array.from({ length: numColumns }, (element, index) => (
                      <td key={index}>
                        <div className="placeholder-loader" />
                      </td>
                    ))}
                  </tr>
                )}
                {(hasNoItems && !showNewItemForm) && (
                  <tr>
                    <td colSpan={numColumns}>
                      <div className="empty-list-message">
                        No items
                      </div>
                    </td>
                  </tr>
                )}
                {items?.map((item, index) => (
                  <PurchaseRequestItem
                    key={item.id}
                    data={item}
                    reloadItems={loadItems}
                    glAccounts={glAccounts}
                    isDisabled={isDisabled}
                    isPosted={isPosted}
                  />
                ))}
                {showNewItemForm && (
                  <NewPurchaseRequestItem
                    data={data}
                    setShowNewItemForm={setShowNewItemForm}
                    reloadItems={loadItems}
                    glAccounts={glAccounts}
                  />
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={5}>Subtotal</th>
                  <td className="text-align-right">
                    {subtotalAmount && formatMonetaryValue(subtotalAmount, {
                      locale: data.currency_locale,
                      code: data.currency_code,
                    })}
                  </td>
                  {(!isDisabled || isPosted) && (
                    <th></th>
                  )}
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      {props.children}
    </div>
  );
}

export default React.memo(PurchaseRequestItemsSection);
