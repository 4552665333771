import React from 'react';
import { useTranslation } from 'react-i18next';

const LOCALES_TO_TEST = ['en', 'en-GB', 'en-AU', 'pseudo'];

const LocalizationPreview = () => {
  const { t, i18n } = useTranslation('words');

  const changeLocale = async (language) => {
    await i18n.changeLanguage(language);
  };

  return (
    <div>
      {LOCALES_TO_TEST.map((locale) => (
        <button
          key={locale}
          onClick={() => changeLocale(locale)}
        >
          {locale}
        </button>
      ))}
      <h1>{i18n.language}</h1>
      <pre>{JSON.stringify(i18n.getResourceBundle(i18n.language || 'en', 'words'), null, 2)}</pre>
    </div>
  );
};

export default LocalizationPreview;
