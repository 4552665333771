require('../portal-component');

window.Portal.CampaignReport.Component = PortalComponent.extend({
  onconstruct: function (options) {
    this._super(options);
    options.partials = {
      CampaignReportTableDisplay: require('./partials/campaign-report-table-display.ractive'),
      CampaignReportCardsDisplay: require('./partials/campaign-report-cards-display.ractive'),
      AmountCard: require('./partials/amount-card.ractive'),
    };
  },

  oninit: function () {
    trace.log();
    this.reload();
    this.set({
      formatMoney: this.formatMoney,
    });
  },

  reload: function () {
    trace.log();
    this.set('campaign_report', null);
    if (this.get('configuration.data.fundraising_activity_id') == 0) {
      this.componentDoneLoading();
    } else {
      this.load_data().then(this._dataLoaded);
    }
  },

  formatMoney: function (amount) {
    const formatter = new Intl.NumberFormat(this.get('campaign_report.currency_locale'), {
      style: 'currency',
      currency: this.get('campaign_report.currency_code'),
    });
    return formatter.format(amount);
  },

  _dataLoaded: function (data) {
    this.set({
      campaign_report: data.campaign_report,
    });

    this.componentDoneLoading();
  },
});
