require('../portal-component');

window.Portal.ArticleBannerCarousel.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.on({
      _next: this._next,
      _goToSlide: this._goToSlide,
    });
    this.reload();
  },

  reload: function () {
    trace.log();
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.set('active_index', 0);
    this.load_data().then(_.bind(this._dataLoaded, this));
  },

  onteardown: function () {
    trace.log();
    clearInterval(this.timer);
  },

  _dataLoaded: function (data) {
    this.set('articles', data.articles);
    this.componentDoneLoading();

    if (data.articles.length > 1) {
      // start timer to cycle through list
      this.timer = setInterval(_.bind(this.autoAdvance, this), 10000);
    }
  },

  autoAdvance: function () {
    this._next(null, 1);
  },

  _next: function (e, direction) {
    // cancel existing timer if this was user initiated. e will be null in the case of autoAdvance
    if (e) {
      trace.log(e);
      clearInterval(this.timer);
    }

    var next_index = _.modulo(this.get('active_index') + direction, this.get('articles').length);
    this.set('active_index', next_index);
  },

  _goToSlide: function (e, index) {
    trace.log(e, index);
    clearInterval(this.timer); // cancel existing timer
    this.set('active_index', index);
  },
});
