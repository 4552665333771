// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`RadioElement should render correctly when the component is "radio" 1`] = `
<div
  class="ae-grid"
  data-testid="radio-element"
>
  <div
    class="ae-grid__item item-sm-12"
  >
    <label
      class="vx-form-label"
      data-testid="vx-label"
      for="section-888#0__header"
    >
      A basic element
    </label>
    <div
      class="vx-form-label-instructions"
      data-testid="help-text"
    >
      A help text
    </div>
    <div
      class="vx-form-control vx-radio-group"
    >
      <label
        class="vx-radio-group__item"
        data-testid="section-888#0__header_0"
        for="section-888#0__header_0"
      >
        <input
          class="vx-radio-input"
          id="section-888#0__header_0"
          name="section-888#0__header"
          type="radio"
          value="1"
        />
        <span
          class="vx-radio-group__label"
          data-option-value="One"
        >
          One
        </span>
      </label>
      <label
        class="vx-radio-group__item"
        data-testid="section-888#0__header_1"
        for="section-888#0__header_1"
      >
        <input
          class="vx-radio-input"
          id="section-888#0__header_1"
          name="section-888#0__header"
          type="radio"
          value="2"
        />
        <span
          class="vx-radio-group__label"
          data-option-value="Two"
        >
          Two
        </span>
      </label>
      <label
        class="vx-radio-group__item"
        data-testid="section-888#0__header_2"
        for="section-888#0__header_2"
      >
        <input
          class="vx-radio-input"
          id="section-888#0__header_2"
          name="section-888#0__header"
          type="radio"
          value="3"
        />
        <span
          class="vx-radio-group__label"
          data-option-value="Three"
        >
          Three
        </span>
      </label>
    </div>
  </div>
</div>
`;
