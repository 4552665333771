// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ToggleField should display plaintext if readonly when toggled OFF 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__toggle"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control"
  >
    <div
      class="plaintext-data"
      data-testid="plain-text-field"
    >
      No
    </div>
  </div>
</div>
`;

exports[`ToggleField should display plaintext if readonly when toggled ON 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__toggle"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control"
  >
    <div
      class="plaintext-data"
      data-testid="plain-text-field"
    >
      Yes
    </div>
  </div>
</div>
`;

exports[`ToggleField should not display help text if it's not there 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__toggle"
  >
    A basic element
  </label>
  <div
    class="vx-form-control"
  >
    <label
      class="vx-switch"
    >
      <input
        class="vx-switch__input"
        name="section-888#0__toggle"
        type="checkbox"
        value="false"
      />
      <div
        class="vx-switch__slider"
      >
        <span
          class="vx-switch__text-on"
          data-option-value="on"
        >
          YES
        </span>
        <span
          class="vx-switch__text-off"
          data-option-value="off"
        >
          NO
        </span>
      </div>
    </label>
  </div>
</div>
`;

exports[`ToggleField should render correctly when toggled OFF 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__toggle"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control"
  >
    <label
      class="vx-switch"
    >
      <input
        class="vx-switch__input"
        name="section-888#0__toggle"
        type="checkbox"
        value="false"
      />
      <div
        class="vx-switch__slider"
      >
        <span
          class="vx-switch__text-on"
          data-option-value="on"
        >
          YES
        </span>
        <span
          class="vx-switch__text-off"
          data-option-value="off"
        >
          NO
        </span>
      </div>
    </label>
  </div>
</div>
`;

exports[`ToggleField should render correctly when toggled ON 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__toggle"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control"
  >
    <label
      class="vx-switch"
    >
      <input
        checked=""
        class="vx-switch__input"
        name="section-888#0__toggle"
        type="checkbox"
        value="true"
      />
      <div
        class="vx-switch__slider"
      >
        <span
          class="vx-switch__text-on"
          data-option-value="on"
        >
          YES
        </span>
        <span
          class="vx-switch__text-off"
          data-option-value="off"
        >
          NO
        </span>
      </div>
    </label>
  </div>
</div>
`;
