const _ = require('lodash');
const Swal = require('sweetalert2');
/* eslint-env jquery */

window.Sys.BillingPayment = {
  init: function () {
    const currencyCode = Portals.config.currency_code || 'USD';
    const currencyFormatLocale = Portals.config.locale || 'en';

    this.currencyFormatter = new Intl.NumberFormat(currencyFormatLocale, {
      style: 'currency',
      currency: currencyCode,
    });

    this.decimalFormatter = new Intl.NumberFormat(currencyFormatLocale, {
      style: 'decimal',
      minimumFractionDigits: 2,
    });

    this.applyCurrencyFormattingToAmounts();
    this.applyCurrencyFormattingToInputs();
    this.renderInputCurrencySymbols();
    this.calculateTotalAmounts();

    // Debounce to ensure paste event works
    const $amountInputDiv = $('.amount-input');
    $amountInputDiv.on('change paste', _.debounce(this.applyCurrencyFormattingToInputs, 100));
    $amountInputDiv.on('change paste keyup', _.debounce(this.calculateTotalAmounts, 100));

    $('.pay-now').click(this.payButtonClicked);
    $('.unlock-auto-pay-button').click(this.unlockAutoPay);
  },

  applyCurrencyFormattingToAmounts: function () {
    const t = this;

    $('[data-amount]').each(function (_index, element) {
      const amount = $(element).data('amount');
      const formattedAmount = t.formatMonetaryAmount(amount);
      $(element).html(formattedAmount).show();
    });
  },

  applyCurrencyFormattingToInputs: function () {
    const t = this;

    $('.amount-input').each(function (_index, element) {
      let amount = t.parseMonetaryAmount($(element).val());

      if (amount < 0) amount = 0;

      const formattedAmount = t.formatMonetaryAmount(amount, { hideSymbol: true });
      $(element).html(formattedAmount).show();
      $(element).val(formattedAmount);
    });
  },

  renderInputCurrencySymbols: function () {
    const currencySymbol = this.currencyFormatter
      .formatToParts(1)
      .find((part) => part.type === 'currency')?.value;

    const currencySymbolClassName = this.isCurrencySymbolPlacedAfterInteger()
      ? 'currency-symbol-right'
      : 'currency-symbol-left';

    $('.amount-input').before(`<div class="${currencySymbolClassName}">${currencySymbol}</div>`);
  },

  calculateTotalAmounts: function () {
    const t = this;

    let totalAmount = 0;

    $('.amount-input').each(function (_index, element) {
      let amount = t.parseMonetaryAmount($(element).val());

      // Set value to 0 if negative
      if (amount < 0) amount = 0;

      const remainingBalance = $(element).closest('tr').find('.remaining').data('remaining');

      // Add warning if amount is greater than invoice balance
      // NOTE: Total billed amount can be negative if the parent has credits on the invoice
      if (amount > 0 && amount > remainingBalance) {
        $(element).addClass('warning');
      } else {
        $(element).removeClass('warning');
      }

      totalAmount += amount;
    });

    $('.total.payment-amount, .total-payment-amount').attr('data-amount', totalAmount);

    const formattedTotalAmount = this.formatMonetaryAmount(totalAmount);
    $('.total.payment-amount, .total-payment-amount').text(formattedTotalAmount);

    // Show warning message if any input fields have warnings
    if ($('.amount-input.warning').length > 0) {
      $('.overpay-warning').fadeIn();
    } else {
      $('.overpay-warning').hide();
    }

    // Prevent submission if no amounts are inputed
    $('.pay-now').prop('disabled', totalAmount <= 0);
  },

  payButtonClicked: function (e) {
    e.preventDefault();

    // Show warning message if any input fields have warnings
    if ($('.amount-input.warning').length > 0) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to pay more than the amount owed on one or more of your billing accounts.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, overpay!',
      }).then((result) => {
        if (result.value) {
          $('form').submit();
        }
      });
    } else {
      $('form').submit();
    }
  },

  unlockAutoPay: function (e) {
    $(e.currentTarget).remove(); // remove the button entirely
    $('.payment-amount.locked').removeClass('locked');
  },

  formatMonetaryAmount: function (amount, options = {}) {
    if (options.hideSymbol) {
      return this.decimalFormatter.format(amount);
    } else {
      return this.currencyFormatter.format(amount);
    }
  },

  parseMonetaryAmount: function (amount) {
    if (typeof amount === 'number') return amount;
    if (typeof amount !== 'string') return 0;

    // Identify "group" & "decimal" symbols by using 1111.1 as sample
    formatParts = this.currencyFormatter.formatToParts(1111.1);
    groupSymbol = formatParts.find((part) => part.type === 'group')?.value;
    decimalSymbol = formatParts.find((part) => part.type === 'decimal')?.value;

    parsedAmount = amount
      // Remove "group" symbol from amount
      .replaceAll(groupSymbol, '')
      // Replace "decimal" symbol with default(period)
      .replaceAll(decimalSymbol, '.')
      // Remove any character that is not a number, period, or negative sign
      .replaceAll(/[^0-9.-]+/g, '');

    return Number(parsedAmount) || 0;
  },

  isCurrencySymbolPlacedAfterInteger: function () {
    // Identify index of "integer" and "currency" parts by using 1111.1 as sample
    const formatParts = this.currencyFormatter.formatToParts(1111.1);
    const integerPartIndex = formatParts.findIndex((part) => part.type === 'integer');
    const currencyPartIndex = formatParts.findIndex((part) => part.type === 'currency');

    // Compare indices to determine if currency symbol is placed after the amount
    return currencyPartIndex > integerPartIndex;
  },
};

_.bindAll(Sys.BillingPayment, [
  'init',
  'applyCurrencyFormattingToAmounts',
  'applyCurrencyFormattingToInputs',
  'renderInputCurrencySymbols',
  'calculateTotalAmounts',
  'payButtonClicked',
  'unlockAutoPay',
  'formatMonetaryAmount',
  'parseMonetaryAmount',
  'isCurrencySymbolPlacedAfterInteger',
]);
