import { GradingMethodDescription, LETTER, POINTS } from './constants';
import { initAssignmentsObject } from './helpers';
import {
  Student,
  Assignment,
  GridData,
  Grade,
  ZgStatus,
  ClassState,
  AssignmentFeedback,
  ClassGrades,
  ConversionScalesResponseObject,
  ClassGradingConfiguration,
  ConversionScale,
  AssignmentFeedbackCondensed,
  GradingMethod,
  AccessStatuses,
} from './types';

export const classGradingConfigurations: ClassGradingConfiguration = {
  1: [
    {
      weight: '.35',
      drop_lowest: false,
      assignment_type: 1,
      grading_period: 1,
      class_grading_configuration_pk: 1,
    },
    {
      weight: '.40',
      drop_lowest: false,
      assignment_type: 2,
      grading_period: 1,
      class_grading_configuration_pk: 2,
    },
    {
      weight: '0',
      drop_lowest: false,
      assignment_type: 3,
      grading_period: 2,
      class_grading_configuration_pk: 3,
    },
    {
      weight: '0',
      drop_lowest: true,
      assignment_type: 4,
      grading_period: 2,
      class_grading_configuration_pk: 4,
    },
  ],
  2: [
    {
      weight: '0',
      drop_lowest: false,
      assignment_type: 1,
      grading_period: 1,
      class_grading_configuration_pk: 1,
    },
    {
      weight: '0',
      drop_lowest: false,
      assignment_type: 2,
      grading_period: 1,
      class_grading_configuration_pk: 2,
    },
    {
      weight: '.50',
      drop_lowest: false,
      assignment_type: 3,
      grading_period: 2,
      class_grading_configuration_pk: 3,
    },
    {
      weight: '.667999',
      drop_lowest: true,
      assignment_type: 4,
      grading_period: 2,
      class_grading_configuration_pk: 4,
    },
  ],
};

export const gradeConversionScaleLevels = [
  {
    id: 1,
    letter: 'A',
    minimum_value: 95,
    stored_value: 4,
  },
  {
    id: 2,
    letter: 'B',
    minimum_value: 80,
    stored_value: 85,
  },
  {
    id: 3,
    letter: 'C',
    minimum_value: 70,
    stored_value: 75,
  },
  {
    id: 4,
    letter: 'D',
    minimum_value: 60,
    stored_value: 65,
  },
  {
    id: 5,
    letter: 'F',
    minimum_value: 0,
    stored_value: 50,
  },
];

export const students: Student[] = [
  {
    class_person_pk: 123,
    person_pk: 100,
    first_name: 'John',
    last_name: 'Reed',
    person_fk: 100,
    photo_url: 'https://example.com/john-reed.jpg',
    grade_detail_url: 'https://example.com/grade-detail/100-50',
  },
  {
    class_person_pk: 234,
    person_pk: 200,
    first_name: 'Jane',
    last_name: 'Lane',
    person_fk: 200,
    photo_url: 'https://example.com/jane-lane.jpg',
    grade_detail_url: 'https://example.com/grade-detail/200-50',
  },
  {
    class_person_pk: 345,
    person_pk: 300,
    first_name: 'Bob',
    last_name: 'Smith',
    person_fk: 300,
    photo_url: 'https://example.com/bob-smith.jpg',
    grade_detail_url: 'https://example.com/grade-detail/300-50',
  },
  {
    class_person_pk: 456,
    person_pk: 400,
    first_name: 'Alice',
    last_name: 'Johnson',
    person_fk: 400,
    photo_url: 'https://example.com/alice-johnson.jpg',
    grade_detail_url: 'https://example.com/grade-detail/400-50',
  },
  {
    class_person_pk: 567,
    person_pk: 500,
    first_name: 'David',
    last_name: 'Lee',
    person_fk: 500,
    photo_url: 'https://example.com/david-lee.jpg',
    grade_detail_url: 'https://example.com/grade-detail/500-50',
  },
];

export const assignments: Assignment[] = [
  {
    applies_to_all_sections: true,
    assignment_date: '2022-01-15',
    assignment_type: 1,
    grading_method: GradingMethodDescription.POINTS,
    class_assignment_pk: 1234,
    class_fk: 5678,
    description: 'Chapter 5 Reading Assignment',
    display_status: true,
    due_date: '2022-01-22',
    extra_credit: false,
    teacher_notes: 'This assignment is worth 10% of the final grade.',
    grading_period: 3,
    maximum_score: 100,
    nextAssignmentId: 1235,
    include_in_calc_grade: true,
    notes: 'Read Chapter 5 and answer questions 1-10.',
    prevAssignmentId: 1233,
    weight: 0.2,
    abbreviation: 'HW',
    type_description: 'Homework',
    display_on_report_card: true,
    id: 2,
    color: '#ffffff',
    zg_grade_conversion_scale_levels: gradeConversionScaleLevels,
    grade_conversion_scale: 2,
  },
  {
    applies_to_all_sections: false,
    assignment_date: '2022-02-05',
    assignment_type: 2,
    grading_method: GradingMethodDescription.POINTS,
    class_assignment_pk: 2345,
    class_fk: 6789,
    description: 'Identity Paper',
    display_status: true,
    due_date: '2022-02-19',
    extra_credit: true,
    teacher_notes: '',
    grading_period: 3,
    maximum_score: 50,
    nextAssignmentId: 2346,
    include_in_calc_grade: true,
    notes: 'Write a 3-page paper on the theme of identity in the novel.',
    prevAssignmentId: 2344,
    weight: 0.3,
    abbreviation: 'HW',
    type_description: 'Homework',
    display_on_report_card: true,
    id: 2,
    color: '#ffffff',
    zg_grade_conversion_scale_levels: gradeConversionScaleLevels,
    grade_conversion_scale: 2,
  },
  {
    applies_to_all_sections: true,
    assignment_date: '2022-03-01',
    assignment_type: 3,
    grading_method: GradingMethodDescription.LETTER,
    class_assignment_pk: 3456,
    class_fk: 7890,
    description: 'Midterm Exam',
    display_status: true,
    due_date: '2022-03-15',
    extra_credit: false,
    teacher_notes: '',
    grading_period: 3,
    maximum_score: 200,
    include_in_calc_grade: true,
    notes: '',
    prevAssignmentId: 3455,
    weight: 0.5,
    abbreviation: 'CW',
    type_description: 'Classwork',
    display_on_report_card: true,
    id: 3,
    color: '#ffffff',
    zg_grade_conversion_scale_levels: gradeConversionScaleLevels,
    grade_conversion_scale: 2,
  },
];

export const gradeConversionScale: ConversionScale = {
  description: 'Lower School Scale',
  id: 2,
  minimum_passing_grade: '50.00',
  zg_grade_conversion_scale_levels: [
    {
      id: 1,
      letter: 'A',
      minimum_value: 95,
      stored_value: 4,
    },
    {
      id: 2,
      letter: 'B',
      minimum_value: 80,
      stored_value: 85,
    },
  ],
};
export const conversionScalesResponse: ConversionScalesResponseObject = {
  class_scale: gradeConversionScale,
  grade_conversion_scales: [gradeConversionScale],
};

export const classGrades: ClassGrades = {
  '100-3': {
    class_person_grade_pk: 100,
    calculated_grade: '95',
    predicted_grade: 'A',
    grading_period: 3,
    person_fk: 100,
  },
  '200-3': {
    class_person_grade_pk: 200,
    calculated_grade: '89',
    predicted_grade: 'B+',
    grading_period: 3,
    person_fk: 200,
  },
  '300-3': {
    class_person_grade_pk: 300,
    calculated_grade: '91',
    predicted_grade: 'A-',
    grading_period: 3,
    person_fk: 300,
  },
  '400-3': {
    class_person_grade_pk: 400,
    calculated_grade: '75',
    predicted_grade: 'C+',
    grading_period: 3,
    person_fk: 400,
  },
  '30-3': {
    class_person_grade_pk: 30,
    calculated_grade: '',
    predicted_grade: '',
    grading_period: 3,
    person_fk: 30,
  },
};

export const newAssignmentMock = {
  form_values: {
    applies_to_all_sections: true,
    assignment_date: '2023-02-02',
    assignment_type: 1,
    description: 'New Description',
    display_status: true,
    display_on_report_card: false,
    due_date: '2023-02-02',
    extra_credit: false,
    grade_conversion_scale: 2,
    grading_method: 1,
    maximum_score: 100,
    include_in_calc_grade: true,
    notes: '',
    teacher_notes: '',
    weight: 100,
  },
};

export const allAssignmentFeedback: AssignmentFeedbackCondensed = [79779, 7991];

export const assignmentGradesByStudent: Record<string, Grade> = {
  '100-1234': {
    class_assignment_person_pk: 79779,
    class_assignment_fk: 1234,
    person_fk: 100,
    completion_status: 1,
    dropbox_status: 'closed',
    dropbox_status_int: 2,
    notes: '',
    score: '90',
    publish_status: 1,
    notification_count: 1,
  },
  '100-2345': {
    class_assignment_person_pk: 4946,
    class_assignment_fk: 2345,
    person_fk: 100,
    completion_status: 2,
    dropbox_status: 'received',
    dropbox_status_int: 0,
    notes: 'did not do well',
    score: '100',
    publish_status: 1,
    notification_count: 0,
  },
  '100-3456': {
    class_assignment_person_pk: 7991,
    class_assignment_fk: 3456,
    person_fk: 100,
    completion_status: 3,
    dropbox_status: 'closed',
    dropbox_status_int: 0,
    notes: '',
    score: '85',
    publish_status: 1,
  },
  '200-1234': {
    class_assignment_person_pk: 78733,
    class_assignment_fk: 1234,
    person_fk: 200,
    completion_status: 2,
    dropbox_status: 'late',
    dropbox_status_int: 2,
    notes: '',
    score: '75',
    publish_status: 0,
  },
  '200-2345': {
    class_assignment_person_pk: 76603,
    class_assignment_fk: 2345,
    person_fk: 200,
    completion_status: 1,
    dropbox_status: '',
    dropbox_status_int: 0,
    notes: '',
    score: '75',
    publish_status: 1,
  },
  '200-3456': {
    class_assignment_person_pk: 89109,
    class_assignment_fk: 3456,
    person_fk: 200,
    completion_status: 4,
    dropbox_status: '',
    dropbox_status_int: 0,
    notes: '',
    score: '85',
    publish_status: 1,
  },
  '300-1234': {
    class_assignment_person_pk: 44871,
    class_assignment_fk: 1234,
    person_fk: 300,
    completion_status: 3,
    dropbox_status: '',
    dropbox_status_int: 0,
    notes: '',
    score: '88',
    publish_status: 1,
  },
  '300-2345': {
    class_assignment_person_pk: 70414,
    class_assignment_fk: 2345,
    person_fk: 300,
    completion_status: 1,
    dropbox_status: '',
    dropbox_status_int: 0,
    notes: '',
    score: '91',
    publish_status: 1,
  },
  '300-3456': {
    class_assignment_person_pk: 32034,
    class_assignment_fk: 3456,
    person_fk: 300,
    completion_status: 1,
    dropbox_status: '',
    dropbox_status_int: 0,
    notes: '',
    score: '92',
    publish_status: 1,
  },
  '400-1234': {
    class_assignment_person_pk: 84908,
    class_assignment_fk: 1234,
    person_fk: 400,
    completion_status: 4,
    dropbox_status: '',
    dropbox_status_int: 1,
    notes: '',
    score: '54',
    publish_status: 1,
  },
  '400-2345': {
    class_assignment_person_pk: 45832,
    class_assignment_fk: 2345,
    person_fk: 400,
    completion_status: 1,
    dropbox_status: '',
    dropbox_status_int: 0,
    notes: '',
    score: '10',
    publish_status: 1,
  },
  '400-3456': {
    class_assignment_person_pk: 13392,
    class_assignment_fk: 3456,
    person_fk: 400,
    completion_status: 1,
    dropbox_status: '',
    dropbox_status_int: 0,
    notes: '',
    score: '11',
    publish_status: 1,
  },
  '500-1234': {
    class_assignment_person_pk: 84908,
    class_assignment_fk: 1234,
    person_fk: 500,
    completion_status: 5,
    dropbox_status: '',
    dropbox_status_int: 0,
    notes: '',
    score: '80',
    publish_status: 1,
  },
  '500-2345': {
    class_assignment_person_pk: 45832,
    class_assignment_fk: 2345,
    person_fk: 500,
    completion_status: 1,
    dropbox_status: '',
    dropbox_status_int: 0,
    notes: '',
    score: '90',
    publish_status: 1,
  },
  '500-3456': {
    class_assignment_person_pk: 13392,
    class_assignment_fk: 3456,
    person_fk: 500,
    completion_status: 1,
    dropbox_status: '',
    dropbox_status_int: 0,
    notes: '',
    score: '99',
    publish_status: 1,
  },
};

export const classState: ClassState = {
  authToken: '123',
  classId: '123',
  configurations: {
    calculated_grade_label: '',
    display_calculated_grade_column: true,
    display_predicted_grade_column: true,
    predicted_grade_format: 'letter',
    predicted_grade_grading_period: 'all',
    enabled: true,
    gradebook_label: 'Gradebook',
    hide_gradebook_photos: false,
    predicted_grade_label: '',
    enable_keyboard_shortcuts: true,
    show_display_on_report_card_flag: false,
    default_assignment_max_score_value: 100,
    default_assignment_weight_value: 100,
    assignment_max_score_teacher_access_status: AccessStatuses.ENABLED,
    assignment_weight_teacher_access_status: AccessStatuses.ENABLED,
    display_class_mean_row: false,
    display_class_median_row: false,
    display_class_standard_deviation_row: false,
    display_date_withdrawn: false,
    display_late_date_enrolled: false,
  },
  startDate: '2023-02-02',
  endDate: '2023-02-02',
  today: '2023-02-02',
  uploaderOptions: {},
  teacherPhotoUrl: 'asdf.com',
  className: 'Math',
  filters: {
    gradingPeriod: 0,
    assignmentType: 1,
    sortAssignmentsByNewest: true,
  },
};

export const gradingMethods: GradingMethod[] = [
  { id: 1, description: POINTS, active: true },
  { id: 2, description: LETTER, active: true },
];

export const assignmentTypes: ZgStatus[] = [
  { id: 1, description: 'Homework' },
  { id: 2, description: 'Quiz' },
  { id: 3, description: 'Exam' },
  { id: 4, description: 'Test' },
];

export const gradingPeriods: ZgStatus[] = [
  { id: 1, description: 'Quarter 1', begin_date: '2022-02-02', end_date: '2022-02-05' },
  { id: 2, description: 'Quarter 2', begin_date: '2022-02-06', end_date: '2022-03-10' },
  { id: 3, description: 'Quarter 3', begin_date: '2022-03-10', end_date: '2022-03-15' },
  { id: 50, description: 'All' },
];

export const assignmentStatuses: ZgStatus[] = [
  { id: 0, description: 'Pending', keyboard_shortcut: 'p' },
  { id: 3, description: 'Complete', keyboard_shortcut: 'c' },
  { id: 6, description: 'Turned In/Not Graded' },
  { id: 1, description: 'Not Turned In' },
  { id: 2, description: 'Incomplete', keyboard_shortcut: '$' },
  { id: 7, description: 'Late' },
  { id: 5, description: 'Complete/No Credit' },
  { id: 4, description: 'Not Required to Complete' },
];

export const dropboxStatuses: ZgStatus[] = [
  { id: 0, description: 'Closed' },
  { id: 1, description: 'Expecting' },
  { id: 2, description: 'Received' },
];

export const assignmentFiles = [
  {
    file_pk: 2022,
    description: 'Screenshot 2023-03-18 at 2.49.02 AM.png',
    feedback_id: 684,
    id: 5802,
    url: 'https://veracross-files.s3.amazonaws.com/private/vcdemo_client_development_sandbox/cea4fea4-f1b4-45c9-8a72-152af7f83de5/Screenshot%202023-03-18%20at%202.49.02%20AM.png',
  },
];

export const gridData: GridData = {
  assignments,
  assignment_grades_by_student: assignmentGradesByStudent,
  assignment_statuses: assignmentStatuses,
  dropbox_statuses: dropboxStatuses,
  students: students,
  grading_period: 1,
  assignmentsObj: initAssignmentsObject(assignments, '2022-02-02', true),
  assignment_files: { '123': assignmentFiles },
};

export const feedbackMessage: AssignmentFeedback = {
  messages: [
    {
      feedback_id: 684,
      notes: 'testsuduyjfiu',
      record_type: 'teacher',
      student_read_status: false,
      submission_date: '2022-02-02',
      submission_user: 'Sarah Green',
      teacher_read_status: false,
    },
  ],
  files: [
    {
      file_pk: 2022,
      description: 'Screenshot 2023-03-18 at 2.49.02 AM.png',
      feedback_id: 684,
      id: 5802,
      url: 'https://veracross-files.s3.amazonaws.com/private/vcdemo_client_development_sandbox/cea4fea4-f1b4-45c9-8a72-152af7f83de5/Screenshot%202023-03-18%20at%202.49.02%20AM.png',
    },
  ],
};

export const emailNotifications = { parents: true, student: true, homeroom: true, advisor: false };

export const assignmentsObject: Record<string, Assignment> = initAssignmentsObject(
  assignments,
  '2022-02-24',
  false
);

export const addFiles = {
  class_assignment_pk: 123,
  files: ['12312312'],
};

export const addFeedback = {
  class_assignment_person_pk: 123,
  message: 'test',
};
