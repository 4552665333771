import React from 'react';
import Select from './Select';

const DISPLAY_DATE_FORMAT = 'ddd MMM DD, YYYY';
const DIPLAY_TIME_FORMAT = 'hh:mm a';

const CustomOptionTemplate = ({ label, value, customData }) => {
  let endTimeComponent;
  let endDateAndTimeComponent;
  let fullMessage;

  if (customData.end_date && customData.end_time) {
    endDateAndTimeComponent = (
      <div className="event-details">
        <div>{moment(customData.end_date).format(DISPLAY_DATE_FORMAT)}</div>
        <div className="event-details-time">
          {moment(customData.end_time).format(DIPLAY_TIME_FORMAT)}
        </div>
      </div>
    );
  } else if (customData.end_time) {
    endTimeComponent = <div>- {moment(customData.end_time).format(DIPLAY_TIME_FORMAT)}</div>;
  }

  if (customData.is_full) {
    fullMessage = <span className="event-details-full">Full</span>;
  }

  return (
    <div
      data-option-value={value}
      className="event-signup-option-container"
    >
      <div className="event-name">{label}</div>
      <div className="event-details">
        <div>{moment(customData.start_date).format(DISPLAY_DATE_FORMAT)}</div>
        <div className="event-details-time">
          {moment(customData.start_time).format(DIPLAY_TIME_FORMAT)}
        </div>
        {endTimeComponent}
        {fullMessage}
      </div>
      {endDateAndTimeComponent}
    </div>
  );
};

const isOptionDisabled = (option) => option.custom_data.is_full;

const customStyles = {
  control: (base) => ({
    ...base,
    height: 45,
  }),
};

const EventSignUp = ({
  dataKeypath,
  placeholderText,
  groupByCategory,
  valueList,
  plaintext = false,
  value,
  startBlank,
}) => {
  const selectedOption = valueList.find((option) => option.id === value);
  if (plaintext) {
    const {
      description,
      custom_data: { start_date, start_time },
    } = selectedOption;
    return (
      <div className="plaintext-data">
        <CustomOptionTemplate
          label={description}
          customData={{ start_date, start_time }}
        />
      </div>
    );
  }

  // if startBlank, select the first option
  if (!startBlank && !value && valueList.length) {
    value = valueList[0].id;
  }

  return (
    <Select
      dataKeypath={dataKeypath}
      groupByCategory={groupByCategory}
      valueList={valueList}
      customOptionTemplate={CustomOptionTemplate}
      defaultValue={value}
      // if placeholderText == null do not include it, that way Select can use its default value
      {...(placeholderText ? { placeholderText } : {})}
      isOptionDisabled={isOptionDisabled}
      customStyles={customStyles}
    />
  );
};

export default EventSignUp;
