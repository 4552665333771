import React, { FC } from 'react';
import { ElementMetadata } from '../types';
import { WebFormField } from './MultiFieldElement';

const TextareaElement: FC<ElementMetadata> = (props) => (
  <div
    className="ae-grid"
    data-testid="textarea-element"
  >
    <div className="ae-grid__item item-sm-12">
      <WebFormField {...props} />
    </div>
  </div>
);

export default TextareaElement;
