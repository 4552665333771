import React from 'react';

interface FileProps {
  filePk: number;
  downloadUrl: string;
  name: string;
  onDeleteClick: (filePk: number) => void;
}

const File = ({ filePk, downloadUrl, name, onDeleteClick }: FileProps) => {
  return (
    <div
      className="file-container"
      key={filePk}
    >
      <i className="nc-icon-outline files_single icon" />
      <a
        href={`${downloadUrl}`}
        target="_blank"
        rel="noreferrer"
      >
        {name}
      </a>
      <div className="delete-button-container">
        <i
          className="nc-icon-glyph ui-1_circle-bold-remove delete-button"
          onClick={() => onDeleteClick(filePk)}
        />
      </div>
    </div>
  );
};

export default File;
