import React from 'react';

const HiddenFieldBadge = () => (
  <span
    className="hidded-field-instructions vx-badge vx-badge--yellow"
    data-testid="hidden-field-badge"
  >
    <span
      className="vx-tooltipped vx-tooltipped--s"
      aria-label="This question will not appear on the real form. Select your preset value(s) that will be default for all submissions."
    >
      HIDDEN FIELD
    </span>
  </span>
);

export default HiddenFieldBadge;
