window.Control.RichTextControl = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
  },

  onrender: function () {
    trace.log();
    var me = this;
    this.$textarea = $(this.find('.text-box')).redactor({
      plugins: ['fullscreen', 'fontsize'],
      buttons: [
        'bold',
        'italic',
        'underline',
        'alignment',
        'horizontalrule',
        'link',
        'unorderedlist',
        'orderedlist',
      ],
      iframe: false,
      minHeight: 100,
      removeEmptyTags: true,
      paragraphize: false,
      linebreaks: true,
      allowedTags: [
        'a',
        'b',
        'strong',
        'em',
        'i',
        'u',
        's',
        'strike',
        'br',
        'inline',
        'span',
        'div',
        'ul',
        'ol',
        'li',
        'table',
        'thead',
        'tbody',
        'tr',
        'th',
        'td',
        'tfoot',
        'hr',
      ],
      changeCallback: function (e) {
        this.$editor.trigger('richTextEdited');
        var html = this.get();
        me.set('field', html);
      },
      focusCallback: function (e) {
        this.$box.addClass('focus');
      },
      blurCallback: function (e) {
        this.$box.removeClass('focus');
      },
    });
  },

  onteardown: function () {
    trace.log();
    if (this.$textarea) {
      this.$textarea.redactor('destroy');
    }
  },
});
