export const RELATIONSHIPS_ELEMENT = {
  element_pk: 17,
  description: 'Relationships',
  insert_status: 2,
  update_status: 2,
  sort_key: 2,
  data_keypath: 'relationships',
  posted: false,
  start_blank: true,
  placeholder_text: '-- Select One --',
  collection_fk: 12,
  parent_element_fk: 0,
  section_data_keypath: 'relationship',
  field_size: 3,
  force_line_break_before: true,
  element_system_name: 'relationships',
  component: 'relationship',
  element_value_list_items: [
    {
      table_name: 'zg_Person_Relationship',
      value: '0',
      description: 'No Relation',
      raw_description: '<None Specified>',
      sort_key: 0,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '1',
      description: 'Husband',
      raw_description: 'Husband',
      sort_key: 1,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '2',
      description: 'Wife',
      raw_description: 'Wife',
      sort_key: 2,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '6',
      description: 'Son',
      raw_description: 'Son',
      sort_key: 6,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '7',
      description: 'Daughter',
      raw_description: 'Daughter',
      sort_key: 7,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '21',
      description: 'Son-In-Law',
      raw_description: 'Son-In-Law',
      sort_key: 21,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '22',
      description: 'Daughter-In-Law',
      raw_description: 'Daughter-In-Law',
      sort_key: 22,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '30',
      description: 'Ex-Husband',
      raw_description: 'Ex-Husband',
      sort_key: 30,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '31',
      description: 'Ex-Wife',
      raw_description: 'Ex-Wife',
      sort_key: 31,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '34',
      description: 'Step-Son',
      raw_description: 'Step-Son',
      sort_key: 34,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '35',
      description: 'Step-Daughter',
      raw_description: 'Step-Daughter',
      sort_key: 35,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '84',
      description: 'Partner',
      raw_description: 'Partner',
      sort_key: 84,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '87',
      description: 'Spouse',
      raw_description: 'Spouse',
      sort_key: 87,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '96',
      description: 'Ex-Spouse',
      raw_description: 'Ex-Spouse',
      sort_key: 90,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '8',
      description: 'Brother',
      raw_description: 'Brother',
      sort_key: 100,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '9',
      description: 'Sister',
      raw_description: 'Sister',
      sort_key: 100,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '3',
      description: 'Father',
      raw_description: 'Father',
      sort_key: 100,
    },
    {
      table_name: 'zg_Person_Relationship',
      value: '4',
      description: 'Mother',
      raw_description: 'Mother',
      sort_key: 100,
    },
    {
      table_name: 'related_people',
      value: '63527',
      description: 'Alice',
      custom_data: {
        photo_url: 'https://example.com/picture.png',
        is_candidate: false,
      },
      sort_key: 1,
    },
    {
      table_name: 'related_people',
      value: '63553',
      description: 'Bob',
      custom_data: {
        photo_url: 'https://example.com/picture2.jpg',
        is_candidate: false,
      },
      sort_key: 2,
    },
    {
      table_name: 'related_people',
      value: '63544',
      description: 'Carol',
      custom_data: {
        photo_url: 'https://example.com/picture3.png',
        is_candidate: true,
      },
      sort_key: 3,
    },
    {
      table_name: 'related_people',
      value: '63549',
      description: 'Daniel',
      custom_data: {
        photo_url: 'https://example.com/picture4.jpg',
        is_candidate: true,
      },
      sort_key: 4,
    },
  ],
};

export const RELATIONSHIPS_SECTION = {
  description: 'Relationships',
  sort_key: 5,
  data_keypath: 'relationship',
  display: 1,
  repeatable: 0,
  posted: false,
  detail_override_index: 0,
  section_system_name: 'relationships',
  elements: [
    RELATIONSHIPS_ELEMENT,
    {
      element_pk: 18,
      description: 'Relationships',
      insert_status: 1,
      update_status: 1,
      sort_key: 1,
      data_keypath: 'relationships_header',
      posted: false,
      help_text: 'Manage relationships for this parent.',
      start_blank: false,
      collection_fk: 0,
      parent_element_fk: 0,
      section_data_keypath: 'relationship',
      field_size: 3,
      force_line_break_before: true,
      element_system_name: 'header',
      component: 'header',
    },
  ],
};
