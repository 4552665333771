import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';

import CheckboxesColumnsElement from './CheckboxesColumnsElement';
import DataConsentPolicyCheckboxElement from './DataConsentPolicyCheckboxElement';
import DateOfBirthElement from './DateOfBirthElement';
import DatePickerElement from './DatePickerElement';
import EmailElement from './EmailElement';
import HeaderElement from './HeaderElement';
import HiddenFieldBadge from '../fields/HiddenFieldBadge';
import MultiFieldElement from './MultiFieldElement';
import NumberElement from './NumberElement';
import ParagraphElement from './ParagraphElement';
import ProfileCodeMultipleElement from './ProfileCodeMultipleElement';
import RadioElement from './RadioElement';
import RelationshipElement from './RelationshipElement';
import ResponseGridElement from './ResponseGridElement';
import SectionDisplayElement from './SectionDisplayElement';
import SelectElement from './SelectElement';
import TextareaElement from './TextareaElement';
import TextboxElement from './TextboxElement';
import ToggleElement from './ToggleElement';
import WebFormScrollToError from '../WebFormScrollToError';
import { useField } from 'formik';
import { useWebForm } from '../WebFormContext';
import { getFieldKeypath, getFieldMetadata } from '../utils/element-helpers';
import { ComponentType, ElementProps } from '../types';
import CreateAccount from './CreateAccountElement';

export const COMPONENT_TO_ELEMENT_MAP: Record<ComponentType, ReactNode> = {
  checkboxes_columns: CheckboxesColumnsElement,
  create_account: CreateAccount,
  data_consent_policy_checkbox: DataConsentPolicyCheckboxElement,
  date_of_birth: DateOfBirthElement,
  date_picker: DatePickerElement,
  email: EmailElement,
  header: HeaderElement,
  multi_field: MultiFieldElement,
  number: NumberElement,
  paragraph: ParagraphElement,
  profile_code_multiple: ProfileCodeMultipleElement,
  radio: RadioElement,
  relationship: RelationshipElement,
  response_grid: ResponseGridElement,
  section_display: SectionDisplayElement,
  select: SelectElement,
  textarea: TextareaElement,
  textbox: TextboxElement,
  toggle: ToggleElement,
};
type NotImplementedProps = { component: string; description: string };

const NotYetImplementedElement = ({ component, description }: NotImplementedProps) => (
  <div
    className="ae-grid"
    data-testid="not-yet-implemented-element"
  >
    <div className="ae-grid__item item-sm-12">
      <h2 className="vx-heading-2">
        {description} - NOT YET IMPLEMENTED [{component}]
      </h2>
    </div>
  </div>
);

const WebFormElement: FC<{ element: ElementProps }> = ({ element }) => {
  const { form, submission } = useWebForm();
  const dataKeypath = getFieldKeypath(element);
  const [field, meta] = useField(dataKeypath);
  const TheComponent = COMPONENT_TO_ELEMENT_MAP[element.component] || NotYetImplementedElement;

  const metadata = React.useMemo(
    () =>
      getFieldMetadata({
        element,
        formMode: form.mode,
        operationType: submission.operation_type,
        submission,
      }),
    [element, form, submission]
  );

  return (
    <div
      data-testid="web-form-element"
      className={classnames('element-container', {
        highlighted: metadata.isFieldHiddenDefault,
        hidden: !metadata.isFieldVisible,
        [WebFormScrollToError.className]: meta.touched && meta.error,
      })}
    >
      {metadata.isFieldHiddenDefault && <HiddenFieldBadge />}
      <TheComponent
        component={element.component}
        element={element}
        metadata={metadata}
      />
    </div>
  );
};

export default WebFormElement;
