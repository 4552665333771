// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ToggleElement should render correctly when the component is "toggle" 1`] = `
<div
  class="ae-grid"
  data-testid="toggle-element"
>
  <div
    class="ae-grid__item item-sm-6"
  >
    <label
      class="vx-form-label"
      data-testid="vx-label"
      for="section-888#0__header"
    >
      A basic element
    </label>
    <div
      class="vx-form-label-instructions"
      data-testid="help-text"
    >
      A help text
    </div>
    <div
      class="vx-form-control"
    >
      <label
        class="vx-switch"
      >
        <input
          class="vx-switch__input"
          name="section-888#0__header"
          type="checkbox"
          value=""
        />
        <div
          class="vx-switch__slider"
        >
          <span
            class="vx-switch__text-on"
            data-option-value="on"
          >
            YES
          </span>
          <span
            class="vx-switch__text-off"
            data-option-value="off"
          >
            NO
          </span>
        </div>
      </label>
    </div>
  </div>
</div>
`;
