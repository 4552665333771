import React, { useState } from 'react';
import ConferenceDropdown from './ConferenceDropdown';
import { getDisplayName } from './helpers';
import classNames from 'classnames';
import ConferenceMoreOptions from './ConferenceMoreOptions';
import { useNotification } from '../../../hooks/useNotification';
import {
  SUCCESS,
  FAILED,
  UNAVAILABLE,
  FAILURE_MESSAGE_DELAY,
  OUTDATED,
  LOCKED,
  ERROR_MESSAGE_OUTDATED,
  ERROR_MESSAGE_UNAVAILABLE,
  ERROR_MESSAGE_LOCKED,
  ERROR_MESSAGE_FAILED,
  SUCCESS_MESSAGE_DELAY,
} from './constants';

const ConferenceTeacherRow = ({
  teacher,
  timeConflicts,
  handleSelectionChange,
  toggleLocation,
  schedule,
  canSelect,
  studentId,
  dateFormat,
  timeFormat,
  allowConflicts,
  isReadOnly,
  isMobile,
  hasAttendanceOption,
}) => {
  const [isLoadingDropdown, setIsLoadingDropdown] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { sendNotification, isNotifying: isNotifying } = useNotification();
  const teacherId = teacher.person_fk;

  const handleChangeConference = async (newValue, prevValue) => {
    setErrorMessage(null);
    setIsLoadingDropdown(true);
    const response = await handleSelectionChange(studentId, teacherId, newValue, prevValue);
    setIsLoadingDropdown(false);
    handleResponse(response);
    return response;
  };

  const handleChangeLocation = async (conference, newLocation) => {
    setErrorMessage(null);
    setIsLoadingDropdown(true);
    const response = await toggleLocation(conference, newLocation);
    setIsLoadingDropdown(false);
    handleResponse(response);
    return response;
  };

  const handleResponse = (response) => {
    if (response === SUCCESS) {
      sendNotification(SUCCESS_MESSAGE_DELAY);
      return;
    }

    if (response === OUTDATED) {
      setErrorMessage(ERROR_MESSAGE_OUTDATED);
    } else if (response === UNAVAILABLE) {
      setErrorMessage(ERROR_MESSAGE_UNAVAILABLE);
    } else if (response === LOCKED) {
      setErrorMessage(ERROR_MESSAGE_LOCKED);
    } else if (response === FAILED) {
      setErrorMessage(ERROR_MESSAGE_FAILED);
    } else {
      setErrorMessage(response || ERROR_MESSAGE_FAILED);
    }
    setTimeout(() => {
      setErrorMessage(null);
    }, FAILURE_MESSAGE_DELAY);
  };

  const teacherHeaderClasses = classNames({
    'ae-grid__item item-md-6 item-sm-12 teacher-info': true,
    'teacher-disabled': !canSelect,
  });
  const teacherConferences = teacher.conferences || [];
  const selectedConference =
    teacherConferences && teacherConferences.find((conference) => conference.selected);

  const isLocked =
    isReadOnly || teacherConferences.some((conference) => conference.selected && conference.locked);
  const hasMultipleSelections =
    teacherConferences.filter((conference) => conference.selected).length > 1;

  return (
    <div
      className="ae-grid teacher-row"
      key={teacherId}
    >
      <div
        className={teacherHeaderClasses}
        data-testid="conferencesList-teacher"
      >
        <img
          className="vx-avatar vx-avatar--small avatar-fit teacher-avatar"
          src={teacher.photo_url}
        />
        <span>
          <h6>{getDisplayName(teacher)}</h6>
          <small className="vx-subtitle">{teacher.classes}</small>
        </span>
      </div>
      <div className="ae-grid__item item-md-5 item-sm-11 item-xs-11">
        <ConferenceDropdown
          conferenceTimes={teacherConferences}
          schedule={schedule}
          timeConflicts={timeConflicts}
          handleSelectionChange={handleChangeConference}
          canSelect={canSelect}
          teacherId={teacherId}
          studentId={studentId}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          allowConflicts={allowConflicts}
          isLocked={isLocked}
          isMobile={isMobile}
          hasAttendanceOption={hasAttendanceOption}
          loading={isLoadingDropdown}
          isNotifying={isNotifying}
          errorMessage={errorMessage}
          hasMultipleSelections={hasMultipleSelections}
        />
        {hasAttendanceOption && selectedConference && !hasMultipleSelections && (
          <ConferenceMoreOptions
            conference={selectedConference}
            toggleLocation={handleChangeLocation}
            isReadOnly={isLocked || isLoadingDropdown || isNotifying}
          />
        )}
      </div>
    </div>
  );
};

export default ConferenceTeacherRow;
