window.ComponentTest = Ractive.extend({
  oninit: function () {
    trace.log();

    _.bindAllFunctions(this);

    // adjust some css to make it look better
    $('.es-Preview, .es-SidebarConfig').css({ top: '0px' });

    // find the information for the component type
    this.component_type_info = _.find(this.get('component_types'), {
      system_access_name: this.get('component_type'),
    });

    if (!this.component_type_info) {
      Swal.fire(
        'Oops!',
        "You haven't set up a zs_Portal_Component_Type for this component yet. " +
          'Please make sure the url matches an existing zs_Portal_Component_Type.system_access_name value in the database.',
        'error'
      );
    }

    // unflatten configuration
    this.component_type_info.configuration = unflatten(this.component_type_info.configuration);

    // load all the components into each grid item
    this.component_instances = [];

    _.each(
      $('.ae-grid__item'),
      _.bind(function (element) {
        var component_instance = this.loadComponent(element);
        if (component_instance) {
          this.component_instances.push(component_instance);
        }
      }, this)
    );

    // show a sidebar where you can edit and play with configuration values
    this.sidebar = new ComponentEditor({
      el: '.es-SidebarConfig',
      template: this.getComponentAdminTemplate(this.component_type_info),
      data: {
        configuration: this.component_type_info.configuration,
        component: this.component_instances[0],
      },
    });
  },

  loadComponent: function (element) {
    trace.log();

    var access_name = 'Portal.' + this.component_type_info.system_access_name + '.Component';
    var template_name = this.templateName(this.component_type_info.system_access_name);
    var ComponentKlass = _.stringToClass(access_name);

    if (!ComponentKlass) {
      return;
    }

    var component_data = {
      admin: true,
      portal_screen_component_pk: _.newGUID(),
      component_type_fk: this.component_type_info.portal_component_type_pk,
      configuration: this.component_type_info.configuration,
      type_description: this.component_type_info.description,
      system_access_name: this.component_type_info.system_access_name,
      reload_keypaths: this.component_type_info.reload_keypaths,
      endpoints: ['load_data'],
    };

    return new ComponentKlass({
      el: element,
      template: require('./' + template_name + '.ractive'),
      data: component_data,
    });
  },

  templateName: function (component_name) {
    var folder_name = _.kebabCase(component_name);
    return ['portal-components', folder_name, 'templates', folder_name].join('/');
  },

  getComponentAdminTemplate: function (component) {
    var folder_name = _.kebabCase(component.system_access_name);
    return require('./portal-component-editors/templates/' + folder_name + '.ractive');
  },
});
