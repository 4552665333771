window.RegistrationSeason.Decision = {
  init: function (show_decline_notes) {
    let decision_container = $('.registration-season.decision');

    $('input[name="response"]', decision_container).change(function (e) {
      let response = $(this).val();
      let decline_notes = $('.decline-notes', decision_container);
      let submit_button = $('.vx-button.submit', decision_container);

      submit_button.attr('disabled', response == 'remain_on_waitlist');

      if (show_decline_notes && (response == 'decline_offer' || response == 'decline_waitlist')) {
        decline_notes.show();
      } else {
        decline_notes.hide();
      }
    });

    // preselect the remain on waitlist option because that is your current "state"
    $('input[name="response"][value="remain_on_waitlist"]', decision_container).prop(
      'checked',
      true
    );
  },
};
