import React from 'react';

import {
  SelectField,
  TextField,
  DateField,
  NumberField,
  Button,
} from 'components/shared/form_elements';

// prettier-ignore
function SearchFilters(props) {
  const {
    config,
    values,
    handleChange,
    handleSearch,
    handleClearSearch,
    pageState,
  } = props;

  const isDisabled = pageState.isLoading || pageState.isSearching;
  const searchButtonText = pageState.isSearching ? 'Searching...' : 'Search';
  const showClearSearchButton = pageState.isSearched;

  /**************************************************************************************************/

  return (
    <form className="vx-form search-filters" onSubmit={handleSearch} noValidate>
      <div className="ae-grid">
        <div className="ae-grid__item item-sm-4">
          <TextField
            name="description"
            value={values.description}
            config={config.description}
            onChange={handleChange}
          />
        </div>
        <div className="ae-grid__item item-sm-4">
          <SelectField
            name="request_status"
            value={values.request_status}
            config={config.request_status}
            onChange={handleChange}
          />
        </div>
        <div className="ae-grid__item item-sm-4">
          <DateField
            name="request_date"
            value={values.request_date}
            config={config.request_date}
            onChange={handleChange}
          />
        </div>
        <div className="ae-grid__item item-sm-2">
          <NumberField
            name="total_amount_min"
            value={values.total_amount_min}
            config={config.total_amount_min}
            onChange={handleChange}
          />
        </div>
        <div className="ae-grid__item item-sm-2">
          <NumberField
            name="total_amount_max"
            value={values.total_amount_max}
            config={config.total_amount_max}
            onChange={handleChange}
          />
        </div>
        <div className="ae-grid__item item-sm-4">
          <SelectField
            name="purchase_request_workflow"
            value={values.purchase_request_workflow}
            config={config.purchase_request_workflow}
            onChange={handleChange}
          />
        </div>
        <div className="ae-grid__item item-sm--auto">
          <input
            className="vx-button vx-button--primary"
            type="submit"
            value={searchButtonText}
            disabled={isDisabled}
          />
        </div>
        {showClearSearchButton && (
          <div className="ae-grid__item item-sm--auto">
            <Button
              className="vx-button vx-button--danger"
              disabled={isDisabled}
              onClick={handleClearSearch}
              value="Clear"
            />
          </div>
        )}
      </div>
    </form>
  );
}

export default React.memo(SearchFilters);
