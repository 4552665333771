require('./portal-component-editors/component-editor');
require('./screen-admin/preview-toolbar');
require('./screen-admin/sidebar-toolbar');
require('./screen-admin/region-editor');
require('./screen-admin/add-modal');

unflatten = require('flat').unflatten;
function requireAll(r) {
  r.keys().forEach(r);
}
requireAll(require.context('./portal-components', true, /\.js$/));

window.PortalScreenAdmin = Ractive.extend({
  onconstruct: function (options) {
    options.magic = true;
    options.template = require('./screen-admin/templates/portal-screen.ractive');
    options.adapt = 'Ractive';
  },

  oninit: function () {
    trace.log(this.get());
    _.bindAllFunctions(this);

    this.set({
      components_for_region: this.components_for_region,
    });

    this.on({
      _showAddModal: this._showAddModal,
      _editRegion: this._editRegion,
      _selectComponent: this._selectComponent,
    });

    // setup toolbar at the top of the screen
    this.screen_toolbar = new PreviewToolbar({
      el: '.es-Toolbar',
      data: {
        screen: this.get('screen'),
        layout: this.get('layout'),
      },
    });
    this.screen_toolbar.observe('layout', this._changeLayout, { init: false });

    // setup sidebar toolbar and respond to those events
    this.sidebar_toolbar = new SidebarToolbar({
      el: '.es-SidebarToolbar',
    });

    this.sidebar_toolbar.on({
      _copyComponent: this._copyComponent,
      _showDeleteConfirm: this._showDeleteConfirm,
      _changeWidth: this._changeWidth,
    });

    this.showSidebarRoot();

    // notify user if they're leaving the page before anything is saved
    $(window).bind(
      'beforeunload',
      _.bind(function () {
        if (this.screen_toolbar.get('is_dirty')) {
          return 'Your recent changes to this page will be lost if you continue.';
        }
      }, this)
    );
  },

  onrender: function () {
    this.promises_for_components = [];
    this.component_instances = [];

    _.each(
      this.get('components'),
      _.bind(function (component) {
        var component_instance = this.loadComponent(component);
        if (component_instance) {
          this.promises_for_components.push(component_instance.getLoadingPromise());
          this.component_instances.push(component_instance);
        }
      }, this)
    );

    Promise.all(this.promises_for_components).done(this.hideLoading);

    // observe keypaths for auto save
    this.debouncedSave = _.debounce(this.save, 5000);
    var save_keypaths = [
      'layout',
      'components.*.width',
      'components.*.layout_region_index',
      'components.*.layout_region_sort_key',
      'components.*.configuration.*',
      'components.*.configuration.*.*',
      'components.*.configuration.*.*.*',
    ];
    this.observe(save_keypaths.join(' '), this.startSave, { init: false });
  },

  components_for_region: function (region_index) {
    return _.sortBy(
      _.filter(this.get('components'), { layout_region_index: region_index }),
      'layout_region_sort_key'
    );
  },

  hideLoading: function () {
    trace.log();

    $('.screen-loading').removeClass('show');
    _.delay(function () {
      $('.screen-loading').remove();
    }, 200);
  },

  loadComponent: function (component) {
    trace.log();

    var element = $(
      '.screen-component[data-component-pk=' + component.portal_screen_component_pk + ']'
    ).get(0);
    var access_name = 'Portal.' + component.system_access_name + '.Component';
    var kebab_name = _.kebabCase(component.system_access_name);
    var ComponentKlass = _.stringToClass(access_name);

    // unflatten configuration
    component.configuration = unflatten(component.configuration || {});
    component.admin = true;

    if (!ComponentKlass) {
      return;
    }

    return new ComponentKlass({
      el: element,
      append: true,
      template: require('./portal-components/' +
        kebab_name +
        '/templates/' +
        kebab_name +
        '.ractive'),
      data: component,
    });
  },

  getComponentAdminTemplate: function (component) {
    var folder_name = _.kebabCase(component.get('system_access_name'));
    return require('./portal-component-editors/templates/' + folder_name + '.ractive');
  },

  getScreenComponent: function (portal_screen_component_pk) {
    return _.find(this.component_instances, function (component) {
      return component.get('portal_screen_component_pk') == portal_screen_component_pk;
    });
  },

  detachComponents: function () {
    _.each(this.component_instances, function (component) {
      component.detach();
    });
  },

  reattachComponents: function () {
    _.each(this.component_instances, function (component) {
      var element = $(
        '.screen-component[data-component-pk=' + component.get('portal_screen_component_pk') + ']'
      ).get(0);
      component.insert(element);
    });
  },

  _selectComponent: function (e) {
    trace.log(e);

    if (this.sidebar) {
      this.sidebar.teardown();
      this.sidebar = null;
    }

    $('.screen-component').removeClass('selected');
    $(e.node).addClass('selected');

    var component_pk = e.context.portal_screen_component_pk;
    var component = _.find(this.component_instances, function (component) {
      return component.get('portal_screen_component_pk') == component_pk;
    });

    this.sidebar_toolbar.set({
      title: null,
      show_buttons: true,
    });

    this.sidebar = new ComponentEditor({
      el: '.es-SidebarConfig',
      template: this.getComponentAdminTemplate(component),
      data: {
        configuration: component.get('configuration'),
        component: component,
      },
    });

    this.transitionSidebarIn();
  },

  _editRegion: function (e) {
    trace.log(e);

    // remove 'active' border
    $('.screen-component').removeClass('selected');

    if (this.sidebar) {
      this.sidebar.teardown();
      this.sidebar = null;
    }

    this.sidebar_toolbar.set({
      title: 'Region ' + (e.context.region + 1),
      show_buttons: false,
    });

    this.sidebar = new RegionEditor({
      el: '.es-SidebarConfig',
      data: {
        region: e.context.region,
        components: this.components_for_region(e.context.region),
        screen_admin: this,
      },
    });

    this.transitionSidebarIn();
  },

  _showAddModal: function (e) {
    trace.log();
    var modal = new AddModal({
      el: 'body',
      append: true,
      data: {
        region: e.context.region,
        component_types: this.get('component_types'),
      },
    });
    modal.show();
    modal.on('component_selected', this.addNewComponent);
  },

  _copyComponent: function (e) {
    trace.log(e, e.index.i);

    var component_pk = this.sidebar.get('component').get('portal_screen_component_pk');
    var component = _.find(this.get('components'), {
      portal_screen_component_pk: component_pk,
    });
    var new_component = _.pick(_.cloneDeep(component), [
      'admin',
      'system_access_name',
      'reload_keypaths',
      'type_description',
      'portal_screen_component_pk',
      'component_type_fk',
      'portal_screen_fk',
      'layout_region_index',
      'layout_region_sort_key',
      'width',
      'configuration',
      'endpoints',
    ]);

    new_component.portal_screen_component_pk = _.newGUID();
    new_component.layout_region_index = e.index.i;
    new_component.layout_region_sort_key = 1000;

    if (new_component.configuration.photo_file_fk) {
      $.ajax({
        url: Routes.screen_copy_image_path({
          client: Portals.config.client,
          portal_name: Portals.config.portal_name,
          screen_name: Portals.config.screen_name,
          photo_file_fk: new_component.configuration.photo_file_fk,
        }),
        type: 'POST',
        context: this,
      }).success(function (data) {
        var component_instance = _.find(this.component_instances, function (component) {
          return (
            component.get('portal_screen_component_pk') == new_component.portal_screen_component_pk
          );
        });

        component_instance.set('configuration.photo_file_fk', data.photo_file_fk);
      });
    }

    this.addComponentToScreen(new_component);
  },

  _changeWidth: function (e, direction) {
    trace.log(e, direction);
    var widths = [2, 3, 4, 6, 8, 10, 12];
    var current_index = _.indexOf(widths, parseInt(this.sidebar.get('component.width')));
    var next_index = _.modulo(current_index + direction, widths.length);
    this.sidebar.set('component.width', widths[next_index]);
  },

  _changeLayout: function (newRegion, oldRegion, keypath) {
    trace.log(newRegion, oldRegion, keypath);

    // each array contains the region indexes for the indicated layout
    // order of the array specifies which regions have more importance
    // region indexes specified at the beginning of each array
    // are considered more important
    var region_ranking = {
      columns_four: [0, 1, 2, 3],
      columns_three: [0, 1, 2],
      columns_two: [0, 1],
      hero_left_columns_three: [1, 2, 3, 0],
      hero_sidebar_left: [2, 1, 0],
      hero_sidebar_right: [1, 2, 0],
      sidebar_left: [1, 0],
      sidebar_right: [0, 1],
      sidebar_left_right: [1, 0, 2],
      single: [0],
      masonry: [0],
    };

    this.detachComponents();

    // move components to regions with matching priority
    var old_layout_ranking = region_ranking[oldRegion];
    var new_layout_ranking = region_ranking[newRegion];

    _.each(this.get('components'), function (component) {
      // find the matching priority in the new region
      var old_region_rank = _.indexOf(old_layout_ranking, component.layout_region_index);
      var new_region_index = new_layout_ranking[old_region_rank];

      // components that don't have a matching region should just go to the least important region
      if (new_region_index == undefined) {
        new_region_index = _.last(new_layout_ranking);
        // we want to retain order based on priority
        component.layout_region_sort_key =
          old_region_rank * 1200 + component.layout_region_sort_key;
      }

      component.layout_region_index = new_region_index;
    });

    var me = this;

    this.set('layout', newRegion).then(function () {
      me.reattachComponents();
    });
  },

  _showDeleteConfirm: function (e) {
    trace.log(e);

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this component!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.deleteComponent();
      }
    });
  },

  addNewComponent: function (component_type_pk, region) {
    // get the new component type/configuration data
    var component_config = _.find(this.get('component_types'), {
      portal_component_type_pk: component_type_pk,
    });
    var new_component = {
      admin: true,
      portal_screen_component_pk: _.newGUID(), // needed so we can reference individual components in the dom that don't exist on the server yet
      component_type_fk: component_type_pk,
      layout_region_index: region,
      layout_region_sort_key: 1000,
      width: 12,
      configuration: unflatten(_.cloneDeep(component_config.configuration)),
      type_description: component_config.description,
      system_access_name: component_config.system_access_name,
      reload_keypaths: component_config.reload_keypaths,
      endpoints: ['load_data'],
    };

    this.addComponentToScreen(new_component);
  },

  addComponentToScreen: function (component_data) {
    // insert so that dom we want to render component into is created
    this.push('components', component_data);

    // create the ractive instance
    var element = $(
      '.screen-component[data-component-pk="' + component_data.portal_screen_component_pk + '"]'
    ).get(0);
    $(element).addClass('new'); // set up for css scale in animation
    var access_name = 'Portal.' + component_data.system_access_name + '.Component';
    var kebab_name = _.kebabCase(component_data.system_access_name);
    var ComponentKlass = _.stringToClass(access_name);

    var component_instance = new ComponentKlass({
      el: element,
      append: true,
      template: require('./portal-components/' +
        kebab_name +
        '/templates/' +
        kebab_name +
        '.ractive'),
      data: component_data,
    });

    // add to the components array and the component instances array
    this.component_instances.push(component_instance);

    // show the component editor once component is added
    this._selectComponent({ node: element, context: component_data });

    // scroll to the new one
    $(element).scrollintoview();
    _.delay(function () {
      $(element).removeClass('new');
    }, 200);
  },

  deleteComponent: function (e) {
    var component_pk = this.sidebar.get('component.portal_screen_component_pk');
    var index = _.findIndex(this.get('components'), {
      portal_screen_component_pk: component_pk,
    });

    if (this.sidebar) {
      this.sidebar.teardown();
      this.sidebar = null;
    }

    this.sidebar_toolbar.set({
      title: null,
      show_buttons: false,
    });

    $('.screen-component').removeClass('active');

    this.detachComponents();
    this.splice('components', index, 1);
    this.component_instances.splice(index, 1);
    this.reattachComponents();

    this.showSidebarRoot();
  },

  startSave: function (newValue, oldValue, keypath) {
    if (oldValue == undefined) return;

    this.screen_toolbar.set('is_dirty', true);
    this.screen_toolbar.set('saving_error', false);
    this.debouncedSave(newValue, oldValue, keypath);
  },

  save: function (newValue, oldValue, keypath) {
    trace.log(newValue, oldValue, keypath);

    this.screen_toolbar.set('saving', true);

    //trace.log(data);
    var screen_data = {
      layout: this.get('layout'),
    };

    // flatten the component configuration
    // only use keys that we want to save
    screen_data.components = _.map(_.cloneDeep(this.get('components')), function (component) {
      component.configuration = flatten(component.configuration);
      return _.pick(component, [
        'portal_screen_component_pk',
        'system_access_name',
        'reload_keypaths',
        'type_description',
        'endpoints',
        'component_type_fk',
        'portal_screen_fk',
        'layout_region_index',
        'layout_region_sort_key',
        'width',
        'configuration',
      ]);
    });

    $.ajax({
      type: 'POST',
      url: Routes.screen_save_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
        screen_name: Portals.config.screen_name,
      }),
      data: { screen_json: JSON.stringify(screen_data) },
      context: this,
    })
      .then(function (data) {
        trace.log('save success!');
        this.screen_toolbar.set({
          error_shown: false,
          saving: false,
          is_dirty: false,
          last_saved: moment().format(),
          in_draft: true,
        });
      })
      .fail(function () {
        trace.error('there was an error!');
        if (!this.screen_toolbar.get('error_shown')) {
          Swal.fire(
            'Unable to Save',
            'Something went wrong! Check your internet connection and try saving again. If the problem persists contact Veracross support.',
            'error'
          );
          this.set('error_shown', true);
        }

        this.screen_toolbar.set('saving_error', true);
        this.screen_toolbar.set('saving', false);
      });
  },

  transitionSidebarIn: function () {
    $('.es-SidebarConfig').css({ opacity: 0 }).animate({ opacity: 1 }, 300);
  },

  showSidebarRoot: function () {
    if (this.sidebar) {
      this.sidebar.teardown();
      this.sidebar = null;
    }

    this.sidebar = new Ractive({
      el: '.es-SidebarConfig',
      template: require('./screen-admin/templates/sidebar-intro.ractive'),
    });

    this.transitionSidebarIn();
  },
});
