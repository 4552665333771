import React, { memo } from 'react';
import { ElementProps } from '../types';

const HeaderElement = ({ element }: { element: ElementProps }) => (
  <div
    className="ae-grid"
    data-testid="header-element"
  >
    <div className="ae-grid__item item-sm-12">
      <h2 className="vx-heading-2">{element.description}</h2>
    </div>
  </div>
);

export default memo(HeaderElement);
