import React from 'react';
import ForkliftUploader from 'forklift-ui';

class ChecklistPhotoUploader extends React.Component {
  static defaultProps = {
    uploader_options: null,
    checklist_item: null,
    photo_url: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      checklist_ui: window.Sys.checklist_items[props.checklist_item.checklist_item_id],
    };
  }

  // refresh checklist item (replaces checklist dom)
  // ReactRailsUJS.mountComponents is needed so that the react component is reloaded
  // react-rails only intiates this for react components once during page load
  // https://github.com/reactjs/react-rails#mounting--unmounting
  refreshChecklistItem() {
    this.state.checklist_ui.refresh().then(() => {
      ReactRailsUJS.mountComponents();
    });
  }

  updateChecklistItem = () => {
    $.ajax({
      method: 'POST',
      url: Routes.admissions_checklist_update_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
        access_name: this.props.checklist_item.registration_season_access_name,
        person_fk: this.props.checklist_item.candidate_fk,
        school_year: this.props.checklist_item.school_year,
        checklist_item_id: this.props.checklist_item.checklist_item_id,
      }),
      data: {
        checklist_item_complete: 1,
      },
    }).then((_data) => {
      this.refreshChecklistItem();
    });
  };

  onFileAdded = () => {
    this.state.checklist_ui.showLoading();
  };

  onComplete = ({ successful, failed }) => {
    //update when there is no errors
    if (!failed.length) {
      this.updateChecklistItem();
    }
  };

  onUploadError = (file, error, response) => {
    Swal.fire({
      title: 'There was an error',
      text: 'An error occurred when trying to upload your file. Please check your internet connection and try again.',
      icon: 'error',
    });
    this.refreshChecklistItem();
  };

  render() {
    const current_photo = (
      <div className="photo-container">
        <div
          className="vx-Avatar_Square current-photo"
          style={{ backgroundImage: `url(${this.props.photo_url})` }}
        ></div>
      </div>
    );

    const uploader = (
      <React.Fragment>
        <div className="photo-upload-message">
          Select an image from your computer. This will remove a previously uploaded photo if you
          had one set.
        </div>
        <ForkliftUploader
          {...{
            ...this.props.uploader_options,
            onFileAdded: this.onFileAdded,
            onComplete: this.onComplete,
            onUploadError: this.onUploadError,
          }}
        />
      </React.Fragment>
    );

    return (
      <div className="checklist-item-photo-upload">
        {this.state.uploading ? uploader : current_photo}
        <div className="item-action-bar">
          <a
            className="vx-button upload-photo-button"
            onClick={() => this.setState({ uploading: !this.state.uploading })}
          >
            {this.state.uploading ? 'Cancel' : 'Upload new photo'}
          </a>
        </div>
      </div>
    );
  }
}

export default ChecklistPhotoUploader;
