import React, { memo } from 'react';
import { ElementProps } from '../types';

const ParagraphElement = ({ element }: { element: ElementProps }) => (
  <div
    className="ae-grid"
    data-testid="paragraph-element"
  >
    <div className="ae-grid__item item-sm-12">
      <h6 className="vx-heading-6">{element.description}</h6>
    </div>
  </div>
);

export default memo(ParagraphElement);
