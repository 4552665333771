// = require portal-components/portal-component

window.Portal.FacultyClassList.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.set({
      classUrl: this.classUrl,
      reportCardGradesUrl: this.reportCardGradesUrl,
      redirectTarget: this.redirectTarget,
    });
    this.load_data().then(this._dataLoaded);
  },

  data: {
    class_filter: 'my-classes',
    attendanceStatusDescription: function (status, translation) {
      // *** Note: this same logic is in faculty-todays-classes.js
      if (status === 'taken') {
        return `${translation} Taken`;
      } else if (status === 'not-taken') {
        return `${translation} Not Taken`;
      } else {
        return `No ${translation} Today`;
      }
    },
    classUrl: function (classPk, location, locationEnabled = null) {
      return this.classUrl(classPk, location, locationEnabled);
    },
  },

  _dataLoaded: function (data) {
    this.set({
      faculty_class_list: data.faculty_class_list,
    });

    this.componentDoneLoading();
  },

  setClassFilter: function (classFilter) {
    this.set('class_filter', classFilter);
  },

  redirectTarget: (attendanceEnabled) => (attendanceEnabled ? '_self' : '_blank'),

  reportCardGradesUrl: function (class_pk, portal_grades_layout) {
    const layouts = ['grades', 'report-cards/all-students', 'report-cards'];
    return this.classUrl(class_pk, layouts[portal_grades_layout]);
  },
});
