window.Control.LoadMore = Ractive.extend({
  onconstruct: function (options) {
    trace.log();
    options.template = require('./template.ractive');
    options.components = {
      Loader: Control.Loader,
    };
  },

  computed: {
    show_more: function () {
      var loaded_full_page =
        this.get('record_count') % this.root.get('configuration.pagination.records_per_page') == 0;
      return this.get('record_count') > 0 && !this.get('end_of_list') && loaded_full_page;
    },
  },

  oninit: function () {
    this.set({
      page_num: 0,
      end_of_list: false,
    });

    this.on({
      _loadMore: this._loadMore,
    });
  },

  _loadMore: function (e) {
    trace.log(e);
    var count_before_load = this.get('record_count');
    this.add('page_num');
    this.root
      .load_data({ page: this.get('page_num') })
      .then(this.root._dataLoaded)
      .then(
        _.bind(function () {
          if (count_before_load == this.get('record_count')) {
            this.set('end_of_list', true);
          }
        }, this)
      );
    return false;
  },
});
