// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ProfileCodeMultipleElement should render correctly with checkboxes when the component is "profile_code_multiple" 1`] = `
<div
  class="ae-grid"
  data-testid="profile_code_multiple-element"
>
  <div
    class="ae-grid__item item-sm-12"
  >
    <label
      class="vx-form-label"
      data-testid="vx-label"
      for="section-888#0__header"
    >
      A basic element
    </label>
    <div
      class="vx-form-label-instructions"
      data-testid="help-text"
    >
      A help text
    </div>
    <div
      class="vx-form-control"
    >
      <div
        class="vx-checkbox-group ae-grid"
      >
        <div
          class="ae-grid__item item-md-4 item-sm-6"
        >
          <label
            class="vx-checkbox-group__item"
          >
            <input
              class="vx-checkbox-input"
              name="section-888#0__header"
              type="checkbox"
              value="1"
            />
            <span
              class="vx-checkbox-group__label"
              data-option-value="1"
            >
              One
            </span>
          </label>
        </div>
        <div
          class="ae-grid__item item-md-4 item-sm-6"
        >
          <label
            class="vx-checkbox-group__item"
          >
            <input
              class="vx-checkbox-input"
              name="section-888#0__header"
              type="checkbox"
              value="2"
            />
            <span
              class="vx-checkbox-group__label"
              data-option-value="2"
            >
              Two
            </span>
          </label>
        </div>
        <div
          class="ae-grid__item item-md-4 item-sm-6"
        >
          <label
            class="vx-checkbox-group__item"
          >
            <input
              class="vx-checkbox-input"
              name="section-888#0__header"
              type="checkbox"
              value="3"
            />
            <span
              class="vx-checkbox-group__label"
              data-option-value="3"
            >
              Three
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
`;

exports[`ProfileCodeMultipleElement should render correctly with radio buttons when the component is "profile_code_multiple" 1`] = `
<div
  class="ae-grid"
  data-testid="profile_code_multiple-element"
>
  <div
    class="ae-grid__item item-sm-12"
  >
    <label
      class="vx-form-label"
      data-testid="vx-label"
      for="section-888#0__header"
    >
      A basic element
    </label>
    <div
      class="vx-form-label-instructions"
      data-testid="help-text"
    >
      A help text
    </div>
    <div
      class="vx-form-control vx-radio-group"
    >
      <label
        class="vx-radio-group__item"
        data-testid="section-888#0__header_0"
        for="section-888#0__header_0"
      >
        <input
          class="vx-radio-input"
          id="section-888#0__header_0"
          name="section-888#0__header"
          type="radio"
          value="1"
        />
        <span
          class="vx-radio-group__label"
          data-option-value="One"
        >
          One
        </span>
      </label>
      <label
        class="vx-radio-group__item"
        data-testid="section-888#0__header_1"
        for="section-888#0__header_1"
      >
        <input
          class="vx-radio-input"
          id="section-888#0__header_1"
          name="section-888#0__header"
          type="radio"
          value="2"
        />
        <span
          class="vx-radio-group__label"
          data-option-value="Two"
        >
          Two
        </span>
      </label>
      <label
        class="vx-radio-group__item"
        data-testid="section-888#0__header_2"
        for="section-888#0__header_2"
      >
        <input
          class="vx-radio-input"
          id="section-888#0__header_2"
          name="section-888#0__header"
          type="radio"
          value="3"
        />
        <span
          class="vx-radio-group__label"
          data-option-value="Three"
        >
          Three
        </span>
      </label>
    </div>
  </div>
</div>
`;
