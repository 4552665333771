import React from 'react';

import {
  DataField,
  SelectField,
  TextAreaField,
  TextField,
  DateField,
  Button,
  MessageBanner,
} from 'components/shared/form_elements';
import useForm from 'components/shared/useForm';

export default function OpportunityNoteForm(props) {
  const { initialFormValues, config } = props;

  const PORTALS_REQUEST_URL_PARAMS = {
    client: Portals.config.client,
    portal_name: Portals.config.portal_name,
  };

  const createOpportunityNoteUrl = Routes.create_solicitor_constituent_opportunity_note_path(
    PORTALS_REQUEST_URL_PARAMS
  );

  const fieldValidations = Object.entries(config)
    .filter(([fieldName, fieldConfig]) => {
      return fieldConfig.displayed && fieldConfig.required;
    })
    .reduce((obj, [fieldName, fieldConfig]) => {
      return { ...obj, [fieldName]: 'isRequired' };
    }, {});

  const buildSubmitData = (values) => {
    return {
      ...values,
      opportunity_note_date: new Date(values.opportunity_note_date).toISOString(),
    };
  };

  const {
    formState,
    formMessage,
    formValues,
    formErrors,
    handleFormFieldChange,
    handleFormSubmit,
  } = useForm({
    url: createOpportunityNoteUrl,
    initialFormValues: initialFormValues,
    config: {
      buildSubmitData: buildSubmitData,
      fieldValidations: fieldValidations,
      submitSuccessMessageText: 'Note was successfully added!',
      submitFailureMessageText:
        'Error: Unable to create the note. Please refresh the page and try again.',
    },
  });

  const isSubmitted = formState === 'SUBMITTED';
  const isSubmitting = formState === 'SUBMITTING';
  const isDisabled = isSubmitting || isSubmitted;
  const showSubmitButton = !isSubmitted;
  const submitButtonText = isSubmitting ? 'Adding Note...' : 'Add Note';
  const hasFormMessage = !!formMessage.text;

  return (
    <>
      <form
        className="opportunity-note-form"
        noValidate
      >
        <div className="ae-grid">
          {hasFormMessage && (
            <div className="ae-grid__item item-lg-12">
              <MessageBanner
                type={formMessage.type}
                text={formMessage.text}
              />
            </div>
          )}
          <div className="ae-grid__item item-lg-6">
            <TextField
              name="opportunity_note_description"
              value={formValues.opportunity_note_description}
              config={config.opportunity_note_description}
              error={formErrors.opportunity_note_description}
              disabled={isDisabled}
              onChange={handleFormFieldChange}
            />
          </div>
          <div className="ae-grid__item item-lg-3">
            <DateField
              name="opportunity_note_date"
              value={formValues.opportunity_note_date}
              config={config.opportunity_note_date}
              error={formErrors.opportunity_note_date}
              disabled={isDisabled}
              onChange={handleFormFieldChange}
            />
          </div>
          <div className="ae-grid__item item-lg-3">
            <SelectField
              name="opportunity_note_development_stage"
              value={formValues.opportunity_note_development_stage}
              config={config.opportunity_note_development_stage}
              error={formErrors.opportunity_note_development_stage}
              disabled={isDisabled}
              onChange={handleFormFieldChange}
            />
          </div>
          <div className="ae-grid__item item-lg-12">
            <TextAreaField
              name="opportunity_note_content"
              value={formValues.opportunity_note_content}
              config={config.opportunity_note_content}
              error={formErrors.opportunity_note_content}
              disabled={isDisabled}
              onChange={handleFormFieldChange}
            />
          </div>
          <div
            className="ae-grid__item item-sm--auto"
            style={{ textAlign: 'right' }}
          >
            <Button
              className="vx-button vx-button--success"
              disabled={isDisabled}
              displayed={showSubmitButton}
              onClick={handleFormSubmit}
              value={submitButtonText}
            />
          </div>
        </div>
      </form>
    </>
  );
}
