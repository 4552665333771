import React, { FC } from 'react';
import CheckboxField from '../fields/CheckboxField';
import RadioField from '../fields/RadioField';
import { ComponentElementMetadata } from '../types';
import { getFieldConfiguration } from '../utils/element-helpers';

const ProfileCodeMultipleElement: FC<ComponentElementMetadata> = (props) => {
  const config = getFieldConfiguration(props.element, 'select_multiple');
  const useCheckboxes = config === 'true';
  return (
    <div
      className="ae-grid"
      data-testid="profile_code_multiple-element"
    >
      <div className="ae-grid__item item-sm-12">
        {useCheckboxes ? <CheckboxField {...props} /> : <RadioField {...props} />}
      </div>
    </div>
  );
};

export default ProfileCodeMultipleElement;
