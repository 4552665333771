import React from 'react';

import { isObjectEmpty, redirectToRoute } from 'components/shared/utils';
import AuthTokenContext from 'components/shared/AuthTokenContext';
import useAsyncRequest from 'components/shared/hooks/useAsyncRequest';
import { Button } from 'components/shared/form_elements';

import SearchFilters from 'components/system/purchase_requests/SearchFilters';
import PurchaseRequestCardList from 'components/system/purchase_requests/PurchaseRequestCardList';

// prettier-ignore
const PAGE_STATE_VALUES = {
  LOADING:      Symbol('loading'),
  LOADING_MORE: Symbol('loading_more'),
  SEARCHING:    Symbol('searching'),
  SEARCHED:     Symbol('searched'),
  ERROR:        Symbol('error'),
};

const DEFAULT_PURCHASE_REQUEST_DESCRIPTION = 'Untitled';

const defaultConfig = {
  initialPage: 1,
  cardsPerPage: 50,
};

// prettier-ignore
function PurchaseRequestQueryPage(props) {
  const {
    authToken,
  } = props;

  const config = { ...defaultConfig, ...props.config };

  /**************************************************************************************************/

  const [pageStateValue, setPageStateValue] = React.useState(PAGE_STATE_VALUES.LOADING);

  const pageState = React.useMemo(() => ({
    value:         pageStateValue,
    isLoading:     pageStateValue === PAGE_STATE_VALUES.LOADING,
    isLoadingMore: pageStateValue === PAGE_STATE_VALUES.LOADING_MORE,
    isSearching:   pageStateValue === PAGE_STATE_VALUES.SEARCHING,
    isSearched:    pageStateValue === PAGE_STATE_VALUES.SEARCHED,
    isError:       pageStateValue === PAGE_STATE_VALUES.ERROR,
  }), [pageStateValue]);

  /***************************************************************************************************
  ** REQUEST: GET purchase-requests
  ***************************************************************************************************/

  const getPurchaseRequests = useAsyncRequest({
    pathName: 'purchase_requests_records_path',
    method: 'GET',
    authorizationToken: authToken,
  });

  /***************************************************************************************************
  ** Records
  ***************************************************************************************************/

  const [searchFilterValues, setSearchFilterValues] = React.useState({});

  const handleSearchFilterValueChange = React.useCallback((event) => {
    const { name, value } = event.target;
    setSearchFilterValues((values) => ({ ...values, [name]: value }));
  }, []);

  const [records, setRecords] = React.useState([]);
  const addRecords = (v) => setRecords((values) => values.concat(v));

  const [currentPage, setCurrentPage] = React.useState(config.initialPage);
  const [canLoadMoreRecords, setCanLoadMoreRecords] = React.useState(false);

  const getRecords = React.useCallback(async (filters = {}, page = config.initialPage) => {
    const records = await getPurchaseRequests({
      filters: filters,
      page: page,
    }).then((response) => {
      return response.records;
    }).catch((error) => {
      console.log('Error: ', error);
      return [];
    });

    setCanLoadMoreRecords(records.length > config.cardsPerPage);

    return records.slice(0, config.cardsPerPage);
  }, [
    config.cardsPerPage,
    config.initialPage,
    getPurchaseRequests,
  ]);

  // Initial Load
  React.useEffect(() => handleRecordsLoad(), []);

  // Records Load
  const handleRecordsLoad = (event) => {
    getRecords().then((records) => {
      setRecords(records);
      setPageStateValue(PAGE_STATE_VALUES.LOADED);
    });
  };

  // Records Load More
  const handleRecordsLoadMore = React.useCallback((event) => {
    setPageStateValue(PAGE_STATE_VALUES.LOADING_MORE);
    const page = currentPage + 1;
    const hasSearchFilterValues = isObjectEmpty(searchFilterValues);

    getRecords(searchFilterValues, page).then((records) => {
      setCurrentPage(page);
      addRecords(records);

      if (hasSearchFilterValues) {
        setPageStateValue(PAGE_STATE_VALUES.SEARCHED);
      } else {
        setPageStateValue(PAGE_STATE_VALUES.LOADED);
      }
    });
  }, [
    currentPage,
    getRecords,
    searchFilterValues,
  ]);

  // Records Search
  const handleRecordsSearch = React.useCallback((event) => {
    event.preventDefault();
    setPageStateValue(PAGE_STATE_VALUES.SEARCHING);
    const page = config.initialPage;

    getRecords(searchFilterValues, page).then((records) => {
      setCurrentPage(page);
      setRecords(records);
      setPageStateValue(PAGE_STATE_VALUES.SEARCHED);
    });
  }, [
    config.initialPage,
    getRecords,
    searchFilterValues,
  ]);

  // Clear Records Search
  const handleClearSearch = React.useCallback((event) => {
    setPageStateValue(PAGE_STATE_VALUES.LOADING);
    setSearchFilterValues({});
    const page = config.initialPage;

    getRecords({}, page).then((records) => {
      setCurrentPage(page);
      setRecords(records);
      setPageStateValue(PAGE_STATE_VALUES.LOADED);
    });
  }, [config.initialPage, getRecords]);

  /**************************************************************************************************/

  const [isCreatingPurchaseRequest, setIsCreatingPurchaseRequest] = React.useState(false);

  const handleCreatePurchaseRequestSuccess = (response) => {
    redirectToRoute('purchase_request_path', { public_id: response.public_id });
  };

  const handleCreatePurchaseRequestFailure = (response) => {
    setIsCreatingPurchaseRequest(false);
  };

  const createPurchaseRequestRequest = useAsyncRequest({
    pathName: 'create_purchase_request_path',
    method: 'POST',
    authorizationToken: authToken,
    onSuccess: handleCreatePurchaseRequestSuccess,
    onFailure: handleCreatePurchaseRequestFailure,
  });

  const handleClickCreateButton = React.useCallback((event) => {
    event.preventDefault();
    setIsCreatingPurchaseRequest(true);

    const submitData = {
      purchase_request: {
        description: DEFAULT_PURCHASE_REQUEST_DESCRIPTION,
      },
    };

    createPurchaseRequestRequest(submitData);
  }, []);

  /**************************************************************************************************/

  return (
    <div className="purchase-request-query-page">
      <AuthTokenContext.Provider value={authToken}>
        <SearchFilters
          config={config.search_filters}
          values={searchFilterValues}
          pageState={pageState}
          handleChange={handleSearchFilterValueChange}
          handleSearch={handleRecordsSearch}
          handleClearSearch={handleClearSearch}
        />

        <div className="query-results">
          <div className="query-results-header">
            <div className="query-results-header-summary" />
            <div className="query-results-header-actions">
              <Button
                className="vx-button vx-button--success"
                onClick={handleClickCreateButton}
                isLoading={isCreatingPurchaseRequest}
                loadingText="Creating Purchase Request..."
              >
                <i className="nc-icon-glyph ui-1_bold-add" />
                Create Purchase Request
              </Button>
            </div>
          </div>

          <PurchaseRequestCardList
            data={{
              records: records,
            }}
            pageState={pageState}
            canLoadMoreRecords={canLoadMoreRecords}
            handleRecordsLoadMore={handleRecordsLoadMore}
            setRecords={setRecords}
          />
        </div>
      </AuthTokenContext.Provider>
    </div>
  );
}

export default React.memo(PurchaseRequestQueryPage);
