$(document).ready(function () {
  trace.log('Portals Started...');

  var topBanner = $('body:not(.full-screen-bg) .portal-background');

  $(window).scroll(function () {
    var scrollTop = $(window).scrollTop() * 0.3;
    var percentOffset = scrollTop / 320;
    var opacity = Math.max(0, 1 - scrollTop * 0.005);
    var backgroundYPosition = 100 * (0.5 + percentOffset / 2);
    topBanner.css({
      opacity: opacity,
      transform: 'translateY(-' + scrollTop + 'px)',
      'background-position': '50% ' + backgroundYPosition + '%',
    });
  });
});
