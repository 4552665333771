import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import AuthTokenContext from '../../../components/shared/AuthTokenContext';
import AttendanceHeader from './AttendanceHeader';
import SidebarContainer from '../../shared/SidebarContainer';
import AttendanceDetail from './AttendanceDetail';
import { HTTP_METHOD, useFetch } from '../../../hooks/useFetch';
import Students from './Students';
import {
  ATTENDANCE_STATUS,
  ATTENDANCE_STATUS_IMPERFECT_CLASS_ATTENDANCE_ID,
  ATTENDANCE_STATUS_TAKEN,
  CHANGE_PERIOD,
  convertAttendanceListToObject,
  DATE_FORMAT,
  getPeriodOptions,
  initSettings,
  isAttendanceLocked,
  periodReducer,
  settingsReducer,
  UPDATE_PERIODS,
} from './utils';
import AttendanceContext from './AttendanceContext';
import { useScreenSizes, useUserState } from '../../../stores/useAppStore';
import { useHotkeys } from 'react-hotkeys-hook';
import { formatShortcut } from '../../shared/utils';

const Loader = () => (
  <div className="attendance-empty-container">
    <div className="attendance-loader">
      <div className="vx-form-loader__spinner vx-loader-spinner"></div>
      <div className="vx-form-loader__message">{'Loading...'}</div>
    </div>
  </div>
);

const Empty = () => (
  <div className="attendance-empty-container">
    <div className="vx-empty-state-read-only">
      <div className="vx-empty-state__icon">
        <i className="nc-icon-outline x2 files_archive"></i>
      </div>
      <h5>This class is not scheduled to meet on this date.</h5>
      <p>Please select another date.</p>
    </div>
  </div>
);

const ErrorMessage = ({ text }) => (
  <div className="attendance-empty-container error-message">{text}</div>
);

const StudentList = (props) => {
  const [selectedStudent, setSelectedStudent] = React.useState(null);
  const [sidebarActive, setSidebarActive] = React.useState(false);
  const [settings, setSettings] = useReducer(
    settingsReducer,
    initSettings(props.config['attendance']),
    (state) => ({
      ...state,
      hideSensitiveData: state.hideSensitiveData && window.innerWidth > 640,
    })
  );
  const { isMd: isMobile } = useScreenSizes();
  const [attendanceTakenStatus, setAttendanceTakenStatus] = React.useState(
    props.attendanceTakenStatus
  );
  const [scheduledDays, setScheduledDays] = React.useState(props.scheduledDays);
  const [date, setDate] = React.useState(moment(props.initialDate, DATE_FORMAT));
  const internalClassId = props.internalClassId;
  const {
    loading: attendanceLoading,
    data: attendanceData,
    error: attendanceError,
    sendFetchRequest: sendAttendanceFetchRequest,
  } = useFetch();
  const [periods, setPeriods] = useReducer(periodReducer, getPeriodOptions(props.periods));
  const selectedPeriodId = periods.selected && periods.selected.value;
  const [attendance, setAttendance] = React.useState(
    convertAttendanceListToObject(props.attendance)
  );
  const { moveFocusedStudentRow, setShortcutTrigger, setFocusedStudentRow } = useUserState();

  const filteredStudents = props.students.filter((student) => {
    return attendance[selectedPeriodId] && attendance[selectedPeriodId][student.id];
  });
  const hasAttendance = filteredStudents.length > 0;
  const attendanceStatusObj = props.attendance_statuses.reduce(
    (memo, attendanceStatus) => ({
      ...memo,
      [attendanceStatus.value]: attendanceStatus,
    }),
    {}
  );
  const isMaster = periods.selected && periods.selected.is_master;
  const [loadingPeriod, setLoadingPeriod] = useState(null);
  const shortcutsEnabled = settings.enableKeyboardShortcuts && !sidebarActive && !isMobile;

  const changeFocusedRow = (direction) => {
    moveFocusedStudentRow(filteredStudents.length, direction);
  };

  useHotkeys(
    'return, shift+return',
    (event) =>
      changeFocusedRow(
        event.shiftKey ? -1 : 1 // return goes up the list, shift+return goes down
      ),
    {
      enabled: !sidebarActive,
      enableOnFormTags: ['input'],
    }
  );

  props.attendance_statuses.forEach((status) => {
    useHotkeys(
      formatShortcut(status.keyboardShortcut),
      (e) => {
        if (e.key === 'Alt') {
          // Alt key by itself is not a valid shortcut, and is incorrectly triggering this callback
          // when hit after a multi-key shortcut
          return;
        }
        setShortcutTrigger({ type: ATTENDANCE_STATUS, id: status.value });
      },
      {
        enabled:
          Boolean(shortcutsEnabled) &&
          status.allowTeacherEntry &&
          Boolean(status.keyboardShortcut) &&
          (isMaster || status.value !== ATTENDANCE_STATUS_IMPERFECT_CLASS_ATTENDANCE_ID),
        enableOnFormTags: ['input'],
        ignoreModifiers: true,
        preventDefault: shortcutsEnabled,
        splitKey: status.keyboardShortcut === ',' ? '+' : ',',
      }
    );
  });

  const setStudent = React.useCallback(
    (studentId, rowIndex) => {
      if (settings.hideSensitiveData || isMobile) {
        return;
      }

      setFocusedStudentRow(rowIndex, false);
      setSelectedStudent(studentId);
      setSidebarActive(true);
    },
    [settings.hideSensitiveData, isMobile]
  );

  const closeSidebar = () => {
    setSidebarActive(false);
  };

  const clearSidebar = () => {
    setSelectedStudent(null);
  };

  useEffect(() => {
    if (settings.hideSensitiveData) {
      closeSidebar();
    }
  }, [settings.hideSensitiveData]);

  const handleDateChange = React.useCallback(
    async (date) => {
      if (attendanceLoading) {
        return;
      }
      setDate(date);
      const formattedDate = date.format(DATE_FORMAT);
      await switchDate(formattedDate);
      replaceDateParam(formattedDate);
    },
    [attendanceLoading]
  );

  const switchDate = async (formattedDate) => {
    const URL_PARAMS = {
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
      internal_class_id: internalClassId,
      date: formattedDate,
    };
    const url = Routes.attendance_data_path(URL_PARAMS);

    await sendAttendanceFetchRequest({ url, method: HTTP_METHOD.get });
  };

  const switchPeriod = (newPeriod) => {
    closeSidebar();
    setLoadingPeriod(newPeriod.value);
    const formattedDate = date.format(DATE_FORMAT);
    switchDate(formattedDate); // fetching current date data again
    setPeriods({ type: CHANGE_PERIOD, newPeriod, isMaster });
  };

  const replaceDateParam = (formatedDate) => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('date', formatedDate);
    history.pushState(null, null, newUrl.toString());
  };

  React.useEffect(() => {
    if (isMobile) {
      setSidebarActive(false);
      setSelectedStudent(null);
    }
  }, [isMobile]);

  React.useEffect(() => {
    window.onpopstate = () => {
      const url = new URL(window.location.href);
      const date = url.searchParams.get('date');
      switchDate(date);
    };

    return () => {
      window.onpopstate = null;
    };
  }, []);

  useEffect(() => {
    if (attendanceData) {
      const { attendance, attendance_status, date, periods, scheduled_days } = attendanceData;
      setAttendance(convertAttendanceListToObject(attendance));
      setAttendanceTakenStatus(attendance_status);
      setScheduledDays(scheduled_days);
      setDate(moment(date, DATE_FORMAT));

      const action = { type: UPDATE_PERIODS, periodData: periods };
      if (typeof loadingPeriod === 'number') {
        // To avoid defaulting to first period
        action.selectedPeriodId = loadingPeriod;
        setLoadingPeriod(null);
      }
      setPeriods(action);
    }
  }, [attendanceData]);

  useEffect(() => {
    if (loadingPeriod && !attendanceLoading) {
      // This is in case data fetching fails
      setLoadingPeriod(null);
    }
  }, [attendanceLoading]);

  let content;
  if (attendanceLoading && typeof loadingPeriod !== 'number') {
    content = <Loader />;
  } else if (attendanceError) {
    content = <ErrorMessage text={attendanceError.message} />;
  } else if (hasAttendance) {
    content = (
      <Students
        settings={settings}
        isMaster={isMaster}
        students={filteredStudents}
        attendanceStatuses={attendanceStatusObj}
        selectedStudent={selectedStudent}
        onClickStudent={setStudent}
      />
    );
  } else {
    content = <Empty />;
  }

  return (
    <AuthTokenContext.Provider value={props.authToken}>
      <AttendanceContext.Provider
        value={{
          attendance: attendance[selectedPeriodId],
          setAttendance,
          internalClassId,
          selectedPeriodId,
          students: filteredStudents,
        }}
      >
        <SidebarContainer
          isActive={sidebarActive}
          closeSidebar={closeSidebar}
          onClose={clearSidebar}
          renderSidebar={({ closeSidebar }) => {
            if (!hasAttendance) {
              return null;
            }
            const student = props.students.find((s) => s.id === selectedStudent);
            const studentAttendance = attendance[selectedPeriodId][selectedStudent];
            return (
              !_.isEmpty(student) && (
                <AttendanceDetail
                  isAttendanceLocked={isAttendanceLocked(studentAttendance, isMaster)}
                  attendance={studentAttendance}
                  student={student}
                  isMaster={isMaster}
                  closeSidebar={closeSidebar}
                  classesUrl={props.classesUrl}
                  settings={settings}
                />
              )
            );
          }}
        >
          <AttendanceHeader
            isMaster={isMaster}
            internalClassId={internalClassId}
            settings={settings}
            setSettings={setSettings}
            handleDateChange={handleDateChange}
            hasAttendance={hasAttendance}
            attendanceTakenStatus={attendanceTakenStatus}
            markAttendanceAsTaken={() => setAttendanceTakenStatus(ATTENDANCE_STATUS_TAKEN)}
            date={date}
            today={props.today}
            scheduledDays={scheduledDays}
            switchPeriod={switchPeriod}
            periods={periods}
            isLoadingPeriod={typeof loadingPeriod === 'number'}
          />
          {content}
        </SidebarContainer>
      </AttendanceContext.Provider>
    </AuthTokenContext.Provider>
  );
};

StudentList.propTypes = {
  attendance_statuses: PropTypes.array.isRequired,
  attendance: PropTypes.array.isRequired,
  attendanceTakenStatus: PropTypes.number.isRequired,
  authToken: PropTypes.string.isRequired,
  internalClassId: PropTypes.string.isRequired,
  config: PropTypes.shape({
    attendance: PropTypes.shape({
      enabled: PropTypes.bool.isRequired,
      include_class_notes_in_sensitive_mode: PropTypes.bool.isRequired,
      sensitive_data_mode_default: PropTypes.bool.isRequired,
      enable_keyboard_shortcuts: PropTypes.bool,
    }),
  }).isRequired,
  initialDate: PropTypes.string.isRequired,
  students: PropTypes.array.isRequired,
};

StudentList.defaultProps = {
  attendance_statuses: [],
};

export default StudentList;
