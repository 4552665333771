import React from 'react';
import { useWebForm } from './WebFormContext';
import { FORM_MODE_ADMIN_EDIT } from './utils/form-helpers';

const WebFormAdminEditWarning = () => {
  const { form } = useWebForm();

  if (form.mode !== FORM_MODE_ADMIN_EDIT) {
    return null;
  }

  return (
    <div
      className="vx-message-banner vx-message-banner--warning form-header-banner"
      data-testid="web-form-admin-edit-warning"
    >
      Admin Mode: You are editing another user&apos;s form
    </div>
  );
};

export default WebFormAdminEditWarning;
