window.ScreenPrintModal = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./templates/screen-print-modal.ractive');
    options.partials = {
      card_options: require('./templates/card.ractive'),
      grid_options: require('./templates/grid.ractive'),
      calendar_options: require('./templates/calendar.ractive'),
      completion: require('./templates/completion.ractive'),
    };
  },

  oninit: function () {
    trace.log(this.get());

    _.bindAllFunctions(this);

    window.addEventListener('message', this.receivePrintConfig);

    // ask the iframe for the results view type and card formatting info
    // the iframe will respond with its own message containing the data.
    $('iframe')[0].contentWindow.postMessage({ initiate: true }, '*');

    this.on({
      _print: this._print,
      _close: this._close,
    });
  },

  onrender: function () {
    $('body').addClass('vx-modal-open');
  },

  receivePrintConfig: function (e) {
    if (!e.origin.match(/.*\.veracross\.(com|eu|au|dev)$/) && location.hostname != 'localhost') {
      return;
    }

    this.set('results_view', e.data.results_view);
    this.set('card_format', e.data.config);

    // default print options
    this.set({
      print_options: {
        font_family: 'Arial',
        font_size: this.get('card_format.font_size') || 12,
        layout_position: this.get('results_view') == 'labels-envelopes' ? 'middle' : 'top',
        text_alignment: 'left',
        first_line_bold: false,
        first_line_font_size: null,
        shade_alt_rows: false,
        shade_alt_months: false,
        page_break_for_subtotal_headers: false,
        page_break_for_months: true,
        page_size: 'letter',
        page_orientation: 'landscape',
        display_page_numbers: true,
        display_date: true,
        show_gridlines: false,
        start_date: null,
        end_date: null,
        start_date_formatted: null,
        end_date_formatted: null,
      },
    });

    // for calendar results include start/end date fields that are part of the card format
    if (this.get('results_view') == 'calendar') {
      this.set('print_options', _.merge(this.get('print_options'), this.get('card_format')));

      // create date dropdowns
      this.start_date_picker = $(this.find('.start-date')).pickadate({
        format: 'mmm d, yyyy',
        clear: null,
        max: this.get('print_options.end_date_formatted'),
        onSet: this.update_start_date,
      });

      this.end_date_picker = $(this.find('.end-date')).pickadate({
        format: 'mmm d, yyyy',
        clear: null,
        min: this.get('print_options.start_date_formatted'),
        onSet: this.update_end_date,
      });
    }
  },

  _print: function (e) {
    e.original.preventDefault();

    this.set('loading', true);

    var print_options = _.merge(this.get('print_options'), {
      print_date: moment().local().format('MMMM DD, YYYY h:mma'),
    });

    // convert print options to query string
    // exclude null or undefined values
    var query_options = $.param(_.omitBy(print_options, _.isNil));

    $.ajax({
      method: 'POST',
      url: Routes.screen_print_results_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
        screen_name: Portals.config.screen_name,
      }),
      data: {
        html_source_url:
          [_.baseURL('axiom'), 'print', 'results', this.get('query_pk')].join('/') +
          '?' +
          query_options,
        document_filename: this.get('document_filename') || 'results',
      },
    })
      .done(this.print_request_complete)
      .fail(this.print_request_error);

    return false;
  },

  _close: function (e) {
    window.removeEventListener('message', this.receivePrintConfig);
    $(this.find('.start-date')).pickadate('stop');
    $(this.find('.end-date')).pickadate('stop');
    $('body').removeClass('vx-modal-open');
    this.teardown();
  },

  print_request_complete: function (response) {
    this.set({
      complete: true,
      loading: false,
      pdf_url: response.pdf_url,
    });

    _.defer(function () {
      $('a.pdf-url-link[download]')[0].click();
    });
  },

  print_request_error: function () {
    Swal.fire(
      'Unable to Print',
      'Something went wrong! Check your internet connection and try printing again.',
      'error'
    );
    this._close();
  },

  update_start_date: function (context) {
    if (context.select) {
      var start_picker = this.start_date_picker.pickadate('picker');
      this.set('start_date', start_picker.get('select', 'yyyy-mm-dd'));
      this.end_date_picker
        .pickadate('picker')
        .set('min', start_picker.get('select', 'mmm d, yyyy'));
    }
  },

  update_end_date: function (context) {
    if (context.select) {
      var end_picker = this.end_date_picker.pickadate('picker');
      this.set('end_date', end_picker.get('select', 'yyyy-mm-dd'));
      this.start_date_picker
        .pickadate('picker')
        .set('max', end_picker.get('select', 'mmm d, yyyy'));
    }
  },
});
