import React from 'react';
import VxLabel from '../fields/VxLabel';
import HelpText from '../fields/HelpText';
import { getFieldKeypath, getFieldMetadata, getResponseGridSize } from '../utils/element-helpers';
import { FastField, useField, useFormikContext } from 'formik';
import VxErrorMessage from '../fields/VxErrorMessage';
import { useWebForm } from '../WebFormContext';
import { ElementMetadata } from '../types';

type ResponseGridQuestionElementProps = ElementMetadata & {
  key: string;
  className: string;
};

const ResponseGridElement = ({ element, metadata }: ElementMetadata) => {
  const { form, submission } = useWebForm();
  const responseGridSize = getResponseGridSize(element);
  const elementValueListItems = element.element_value_list_items || [];

  const questionElements = React.useMemo(
    () =>
      element.children.map((questionElement) => ({
        ...questionElement,
        metadata: getFieldMetadata({
          element: questionElement,
          formMode: form.mode,
          operationType: submission.operation_type,
          submission,
        }),
      })),
    []
  );

  return (
    <div
      className="response-grid"
      data-testid="response_grid-element"
    >
      <div className="ae-grid">
        <div className="ae-grid__item item-sm-12 response-grid-information">
          <VxLabel
            element={element}
            htmlFor={metadata.dataKeypath}
          />
          <HelpText element={element} />
        </div>
      </div>

      <div className="ae-grid response-grid-header">
        <div className={`ae-grid__item item-sm-${responseGridSize}`} />
        {elementValueListItems.map((column) => (
          <div
            key={column.value}
            className="ae-grid__item item-sm-1 response-grid-header-value"
          >
            {column.description}
          </div>
        ))}
      </div>

      {questionElements
        .filter((questionElement) => questionElement.metadata.isFieldVisible)
        .map((child, index) => (
          <ResponseGridQuestionElement
            key={child.data_keypath}
            element={child}
            metadata={child.metadata}
            className={`vx-form-control vx-radio-group ${
              index % 2 === 0 ? 'vx-form-control--stripped' : ''
            } response-grid-question`}
          />
        ))}
    </div>
  );
};

const ResponseGridQuestionElement = ({
  element,
  metadata,
  ...props
}: ResponseGridQuestionElementProps) => {
  const { isSubmitting } = useFormikContext();
  const dataKeypath = getFieldKeypath(element);
  const [_field, meta] = useField(dataKeypath);
  const hasError = meta.touched && meta.error;
  const responseGridSize = getResponseGridSize(element);
  const elementValueListItems = element.element_value_list_items || [];

  return (
    <>
      <div {...props}>
        <div className="ae-grid">
          <div className={`ae-grid__item item-sm-${responseGridSize}`}>
            <VxLabel
              element={element}
              htmlFor={dataKeypath}
              hasError={hasError}
            />
          </div>

          {elementValueListItems.map((column, columnIndex) => (
            <div
              key={columnIndex}
              className="ae-grid__item item-sm-1 response-grid-question-option"
            >
              <label className="vx-radio-group__item">
                <FastField
                  type="radio"
                  className="vx-radio-input"
                  name={dataKeypath}
                  value={column.value}
                  disabled={isSubmitting || metadata.isPlainText}
                />
                <span className="vx-radio-group__label">{column.description}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
      <VxErrorMessage
        element={element}
        metadata={metadata}
      />
    </>
  );
};

export default ResponseGridElement;
