import React from 'react';
import WebFormSection, { SECTION_DISPLAY_DISABLED } from './WebFormSection';
import { useWebForm } from '../WebFormContext';
import { sortBy } from '../utils/form-helpers';

const WebFormSections = () => {
  const { form } = useWebForm();

  return (
    <>
      {form.sections
        .filter((section) => section.display !== SECTION_DISPLAY_DISABLED)
        .sort(sortBy('sort_key'))
        .map((section) => (
          <WebFormSection
            key={section.data_keypath}
            section={section}
          />
        ))}
    </>
  );
};

export default WebFormSections;
