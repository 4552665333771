window.RegistrationSeason.AdmissionVisitV1SlotPicker = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
    options.adapt = 'Ractive';
  },

  computed: {
    day_slots: {
      get: function () {
        return this.slotsForDate(this.get('selectedDate'));
      },
    },
    show_picker: {
      get: function () {
        return !this.get('readonly') && !this.get('slot_selected');
      },
    },
  },

  slotsForDate: function (date) {
    if (this.calendar) {
      return _.sortBy(
        _.filter(this.calendar.fullCalendar('clientEvents'), function (slot) {
          return date.isSame(slot.start, 'day');
        }),
        'start'
      );
    }
  },

  onrender: function () {
    this.calendar = $(this.find('.fullcalendar-element'));
    this.calendar.fullCalendar(this.calendarOptions);
  },

  refresh: function () {
    $.ajax({
      method: 'get',
      url: Routes.admissions_available_events_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
        access_name: this.get('access_name'),
        person_fk: this.get('person_fk'),
        school_year: this.get('school_year'),
        checklist_item_id: this.get('checklist_item_id'),
      }),
    }).then((events) => {
      // because we aren't using eventRender re-rendering the calendar doesn't work as expected
      // so we have to destroy and recreate the calendar when dynamically updating events
      this.calendar = $(this.find('.fullcalendar-element'));
      this.calendarOptions.defaultDate = this.calendar.fullCalendar('getDate');
      this.calendarOptions.events = events;
      this.calendar.fullCalendar('destroy');
      this.calendar.fullCalendar(this.calendarOptions);
      this.update('day_slots');
    });
  },

  oninit: function () {
    _.bindAllFunctions(this);

    this.observe('selectedDate', () => this.update('day_slots'));

    this.set('formatTime', this.formatTime);

    this.calendarOptions = {
      defaultView: 'threeWeek',
      // aspectRatio: 1.5,
      now: moment(this.get('today')),
      selectable: false,
      unselectAuto: false,
      header: {
        left: 'prev,next',
        center: 'title',
        right: '',
      },
      views: {
        threeWeek: {
          type: 'basic',
          duration: { weeks: 3 },
          rows: 3,
        },
      },
      eventBackgroundColor: 'transparent',
      eventBorderColor: 'transparent',
      dayRender: this.fc_dayRender,
      events: this.get('events'),
      dayClick: this.fc_dayClick,
      eventRender: this.fc_eventRender,
    };
  },

  fc_dayClick: function (date, jsEvent, view) {
    // TODO @bauer - use the 'this' binding pattern
    this.set('selectedDate', date);
    $('td.fc-day', this.calendar).removeClass('date-selected');
    $('td.fc-day[data-date=' + date.format('YYYY-MM-DD') + ']', this.calendar).addClass(
      'date-selected'
    );
    this.update('daySlots');
  },

  fc_dayRender: function (date, cell) {
    // Render the events as dots in the calendar cell
    $('<br><br>').appendTo(cell);
    const slots = Math.min(this.slotsForDate(date).length, 10); // limit the number of dots that show
    _.times(slots, function () {
      $('<i class="slot-dot nc-icon-glyph weather_moon-full"></i>').appendTo(cell);
    });
  },

  fc_eventRender: function (event, element, view) {
    // fullCalendar Event rendering is done with a table and is too difficult to massage into what we want.
    // So we are bypassing fullCalendar's event rendering entirely and doing it in the dayRender method
    return false;
  },

  select_slot: function (slotId) {
    const slot = _.find(this.calendar.fullCalendar('clientEvents'), function (e) {
      return e.id === slotId;
    });
    this.set('slot_selected', slot);
    this.fire('slot-selected', slotId);
  },

  deselect: function () {
    // if selecting another event set back to default error message and hide error messages
    $(this.el)
      .parent()
      .siblings('.error-message')
      .text(
        'There was a problem with your submission. Please try again or contact the Admissions Office.'
      );
    $(this.el).parent().siblings('.error-message').hide();

    this.set('slot_selected', null);
    this.fire('slot-deselected');
  },

  formatTime: function (time) {
    return time.format('h:mma');
  },
});
