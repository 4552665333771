import React from 'react';

import useAsyncRequest from 'components/shared/hooks/useAsyncRequest';
import AuthTokenContext from 'components/shared/AuthTokenContext';

const formatDate = (value) => moment(value).format('MMM DD, YYYY');
const formatTime = (value) => moment(value).format('h:mm:ss A');

// prettier-ignore
function PurchaseRequestActivityLogsSection(props) {
  const {
    data,
  } = props;

  const authToken = React.useContext(AuthTokenContext);

  const getPurchaseRequestActivityLogsRequest = useAsyncRequest({
    pathName: 'purchase_request_activity_logs_path',
    params: {
      purchase_request_id: data.id,
    },
    method: 'GET',
    authorizationToken: authToken,
  });

  const [activityLogs, setActivityLogs] = React.useState(undefined);
  const isLoadingActivityLogs = activityLogs === undefined;
  const hasNoActivityLogs = activityLogs?.length === 0;

  const loadActivityLogs = () => {
    getPurchaseRequestActivityLogsRequest().then((response) => {
      setActivityLogs(response.records);
    });
  };

  React.useEffect(() => loadActivityLogs(), []);

  /**************************************************************************************************/

  return (
    <div className="purchase-request-detail-section">
      <div className="purchase-request-detail-section-header">
        <div className="purchase-request-detail-section-header-title">
          Activity Logs
        </div>
      </div>
      <div className="ae-grid">
        <div className="ae-grid__item item-sm-12">
          <div className="purchase-requests__table_wrapper">
            <table className="purchase-requests__table">
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Log Item</th>
                </tr>
              </thead>
              <tbody>
                {isLoadingActivityLogs && (
                  <tr>
                    {Array.from({ length: 2 }, (element, index) => (
                      <td key={index}>
                        <div className="placeholder-loader" />
                      </td>
                    ))}
                  </tr>
                )}
                {hasNoActivityLogs && (
                  <tr>
                    <td colSpan={2}>
                      <div className="empty-list-message">
                        No log items
                      </div>
                    </td>
                  </tr>
                )}
                {activityLogs?.map((activityLog, index) => (
                  <tr className="purchase-request-activity-log" key={index}>
                    <td>
                      <div className="purchase-request-activity-log__timestamp-date">
                        {formatDate(activityLog.input_date)}
                      </div>
                      <div className="purchase-request-activity-log__timestamp-time">
                        {formatTime(activityLog.input_date)}
                      </div>
                    </td>
                    <td>
                      <div className="purchase-request-activity-log__log-type">
                        {activityLog.log_type_description}
                      </div>
                      <div className="purchase-request-activity-log__description">
                        {activityLog.description}
                      </div>
                      {activityLog.notes && (
                        <div
                          className="purchase-request-activity-log__notes"
                          dangerouslySetInnerHTML={{ __html: activityLog.notes }}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {props.children}
    </div>
  );
}

export default React.memo(PurchaseRequestActivityLogsSection);
