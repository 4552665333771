window.WebForm.SectionDisplay = function (webFormFk, elementDataKepath) {
  const form = $('form[data-web-form-fk="' + webFormFk + '"]');
  const radios = $('input[type="radio"][name="' + elementDataKepath + '"]', form);
  const sectionKeypaths = _.map(radios, (radio) => $(radio).val());

  radios.change(function (e) {
    // hide all sections
    _.each(sectionKeypaths, function (kp) {
      const section = $('.section-container[data-keypath=' + kp + ']', form);
      section.hide();
      // Disable everything so it is not submitted
      $('input, textarea, select', section).prop('disabled', true);
    });

    // make visible the selected section
    const section = $('.section-container[data-keypath=' + $(this).val() + ']', form);
    section.show();

    // show the repeat button if it is a repeatable section
    $('[data-repeat-section=' + $(this).val() + ']').show();

    // Enable everything so it is submitted
    $('input, textarea, select', section).prop('disabled', false);
  });

  $('input[name="' + elementDataKepath + '"]:checked', form).change();
};
