require('../portal-component');

window.Portal.AdvisorDashboard.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();

    this.set({
      weekStart: moment(Portals.config.today).startOf('week').format('YYYY-MM-DD'),
      lastWeekStart: moment(Portals.config.today)
        .startOf('week')
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
    });

    this.load_data().then(this._dataLoaded);

    this.observe('sort attendance assignments comments behavior', this.refreshList);
  },

  _dataLoaded: function (data) {
    this.set({
      student_list: data.student_list,
    });

    this.refreshList();

    this.componentDoneLoading();
  },

  data: {
    attendance: true,
    comments: true,
    assignments: true,
    behavior: true,
    sort: 'this_week',
    calculateWidth: function (value) {
      return Math.min(30, value * 5);
    },
  },

  openStudentProfileLink: function (student, page, param, paramVal) {
    let url = [_.baseURL('portals_app'), 'profile', student.student_pk, page].join('/');
    url = _.trimEnd(url, '/');
    const top = screen.height / 2 - 700 / 2;
    const left = screen.width / 2 - 550 / 2;

    if (param) {
      url += '?' + param;
      if (paramVal) {
        url += '=' + (this.get(paramVal) || paramVal);
      }
    }

    window.open(
      url,
      '_blank',
      'height=700,width=550,top=' +
        top +
        ',left=' +
        left +
        ',location=no,status=no,toolbar=no,resizable=no'
    );
  },

  refreshList: function () {
    // first sum up totals
    const self = this;
    const student_list = this.get('student_list');
    _.each(student_list, function (student) {
      _.each(student.totals, function (events_data, time_frame) {
        // calculate sums based on which checkboxes are checked and set the value on the student
        student[time_frame] = _.reduce(
          events_data,
          function (result, val, key) {
            return result + (self.get(key) ? val : 0);
          },
          0
        );
      });
    });

    const list = _.sortBy(student_list, function (student) {
      const val = student[self.get('sort')];
      // use descending sort for numerics
      return _.isNumber(val) ? -val : val;
    });

    this.set('sorted_list', list);
  },
});
