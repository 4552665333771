require('./glyph-list');

window.Control.IconPicker = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
  },

  onrender: function () {
    this.set({
      is_open: false,
      icons: GlyphIcons,
      search_term: null,
      num_per_page: 20,
      page_num: 0,
    });

    this.on({
      _openPicker: this._openPicker,
      _selectIcon: this._selectIcon,
      _pageLeft: this._pageLeft,
      _pageRight: this._pageRight,
      _searchKeyDown: this._searchKeyDown,
    });
  },

  computed: {
    searched_icons: function () {
      var search = this.get('search_term');
      if (search) {
        return _.filter(this.get('icons'), function (i) {
          return i.toLowerCase().indexOf(search) != -1;
        });
      }
      return this.get('icons');
    },
    page_start_index: function () {
      return this.get('page_num') * this.get('num_per_page') + 1;
    },
    page_end_index: function () {
      var end = (this.get('page_num') + 1) * this.get('num_per_page');
      if (end > this.get('searched_icons').length) {
        return this.get('searched_icons').length;
      }
      return end;
    },
    icons_for_page: function () {
      return _.slice(
        this.get('searched_icons'),
        this.get('page_start_index') - 1,
        this.get('page_end_index')
      );
    },
    disable_prev: function () {
      return this.get('page_num') == 0;
    },
    disable_next: function () {
      return (
        this.get('icons_for_page').length < this.get('num_per_page') ||
        page_end_index >= this.get('searched_icons').length
      );
    },
  },

  _openPicker: function (e) {
    trace.log(e);
    this.toggle('is_open');

    if (this.get('is_open')) {
      $(document).on('click.icon-picker', _.bind(this.hidePopover, this));
    }
    // propogate the event if we just closed the popover
    else {
      return true;
    }

    return false;
  },

  hidePopover: function (e) {
    var popover = $('.icon-popup');
    if (!popover.is(e.target) && popover.has(e.target).length === 0) {
      this.set('is_open', false);
      $(document).off('click.icon-picker');
    }
  },

  _selectIcon: function (e) {
    trace.log(e);
    this.set('field', e.context);
    this.set('is_open', false);
    $(document).off('click.icon-picker');
  },

  _pageLeft: function (e) {
    trace.log(e);
    if (!this.get('disable_prev')) {
      this.subtract('page_num');
    }
  },

  _pageRight: function (e) {
    trace.log(e);
    if (!this.get('disable_next')) {
      this.add('page_num');
    }
  },

  _searchKeyDown: function (e) {
    this.set('page_num', 0);
  },
});
