// TODO: Delete this file once the "date_of_birth" web form element uses "date_picker" instead

import React from 'react';
import InputMask from 'react-input-mask';

class DateOfBirth extends React.Component {
  componentDidMount(props) {
    const keypath = window.WebForm.FormInstance.createFormField(this.props.keypath);

    window.WebForm.FormInstance.push('form_fields', keypath);
  }

  render() {
    return (
      <InputMask
        mask="99/99/9999"
        keypath={this.props.keypath}
        defaultValue={this.props.value}
      >
        {(inputProps) => (
          <input
            name={inputProps.keypath}
            id={inputProps.keypath}
            type="text"
            className="vx-text-field__input"
            placeholder="MM/DD/YYYY"
          />
        )}
      </InputMask>
    );
  }
}

export default DateOfBirth;
