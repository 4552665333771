/* eslint-disable camelcase */
import React from 'react';
import { WebFormProvider, WebFormProviderType } from './WebFormContext';
import AuthTokenContext from '../../shared/AuthTokenContext';
import WebForm from './WebForm';

const WebFormPage = ({
  authToken,
  form,
  theKey,
  submissionUrl,
  data = [],
}: { authToken: string } & WebFormProviderType) => {
  return (
    <AuthTokenContext.Provider value={authToken}>
      <WebFormProvider
        form={form}
        data={data}
        theKey={theKey}
        submissionUrl={submissionUrl}
      >
        <WebForm />
      </WebFormProvider>
    </AuthTokenContext.Provider>
  );
};

export default WebFormPage;
