// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ResponseGridElement should render correctly when the component is "response_grid" 1`] = `
<div
  class="response-grid"
  data-testid="response_grid-element"
>
  <div
    class="ae-grid"
  >
    <div
      class="ae-grid__item item-sm-12 response-grid-information"
    >
      <label
        class="vx-form-label"
        data-testid="vx-label"
        for="generic-803#0__response_grid"
      >
        What's the temperature?
      </label>
      <div
        class="vx-form-label-instructions"
        data-testid="help-text"
      >
        A help text
      </div>
    </div>
  </div>
  <div
    class="ae-grid response-grid-header"
  >
    <div
      class="ae-grid__item item-sm-5"
    />
    <div
      class="ae-grid__item item-sm-1 response-grid-header-value"
    >
      Strongly Disagree
    </div>
    <div
      class="ae-grid__item item-sm-1 response-grid-header-value"
    >
      Disagree
    </div>
    <div
      class="ae-grid__item item-sm-1 response-grid-header-value"
    >
      Neutral
    </div>
    <div
      class="ae-grid__item item-sm-1 response-grid-header-value"
    >
      Agree
    </div>
    <div
      class="ae-grid__item item-sm-1 response-grid-header-value"
    >
      Strongly Disagree
    </div>
  </div>
  <div
    class="vx-form-control vx-radio-group vx-form-control--stripped response-grid-question"
  >
    <div
      class="ae-grid"
    >
      <div
        class="ae-grid__item item-sm-5"
      >
        <label
          class="vx-form-label"
          data-testid="vx-label"
          for="generic-803#0__response_grid:response_grid_question-5830"
        >
          New Hampshire
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5830"
            type="radio"
            value="1"
          />
          <span
            class="vx-radio-group__label"
          >
            Strongly Disagree
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5830"
            type="radio"
            value="2"
          />
          <span
            class="vx-radio-group__label"
          >
            Disagree
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5830"
            type="radio"
            value="3"
          />
          <span
            class="vx-radio-group__label"
          >
            Neutral
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5830"
            type="radio"
            value="4"
          />
          <span
            class="vx-radio-group__label"
          >
            Agree
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5830"
            type="radio"
            value="5"
          />
          <span
            class="vx-radio-group__label"
          >
            Strongly Disagree
          </span>
        </label>
      </div>
    </div>
  </div>
  <div
    class="vx-form-control vx-radio-group  response-grid-question"
  >
    <div
      class="ae-grid"
    >
      <div
        class="ae-grid__item item-sm-5"
      >
        <label
          class="vx-form-label"
          data-testid="vx-label"
          for="generic-803#0__response_grid:response_grid_question-5831"
        >
          Massachusetts
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5831"
            type="radio"
            value="1"
          />
          <span
            class="vx-radio-group__label"
          >
            Strongly Disagree
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5831"
            type="radio"
            value="2"
          />
          <span
            class="vx-radio-group__label"
          >
            Disagree
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5831"
            type="radio"
            value="3"
          />
          <span
            class="vx-radio-group__label"
          >
            Neutral
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5831"
            type="radio"
            value="4"
          />
          <span
            class="vx-radio-group__label"
          >
            Agree
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5831"
            type="radio"
            value="5"
          />
          <span
            class="vx-radio-group__label"
          >
            Strongly Disagree
          </span>
        </label>
      </div>
    </div>
  </div>
  <div
    class="vx-form-control vx-radio-group vx-form-control--stripped response-grid-question"
  >
    <div
      class="ae-grid"
    >
      <div
        class="ae-grid__item item-sm-5"
      >
        <label
          class="vx-form-label"
          data-testid="vx-label"
          for="generic-803#0__response_grid:response_grid_question-5834"
        >
          Texas
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5834"
            type="radio"
            value="1"
          />
          <span
            class="vx-radio-group__label"
          >
            Strongly Disagree
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5834"
            type="radio"
            value="2"
          />
          <span
            class="vx-radio-group__label"
          >
            Disagree
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5834"
            type="radio"
            value="3"
          />
          <span
            class="vx-radio-group__label"
          >
            Neutral
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5834"
            type="radio"
            value="4"
          />
          <span
            class="vx-radio-group__label"
          >
            Agree
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-sm-1 response-grid-question-option"
      >
        <label
          class="vx-radio-group__item"
        >
          <input
            class="vx-radio-input"
            name="generic-803#0__response_grid:response_grid_question-5834"
            type="radio"
            value="5"
          />
          <span
            class="vx-radio-group__label"
          >
            Strongly Disagree
          </span>
        </label>
      </div>
    </div>
  </div>
</div>
`;
