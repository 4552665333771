window.Control.DateControl = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
    options.twoway = false;
  },

  computed: {
    formatted_date: function () {
      if (!this.get('field')) {
        return '';
      }
      return moment(this.get('field'), 'MM/DD/YYYY').format('MMM D, YYYY');
    },
  },

  onrender: function () {
    var me = this;
    $(this.find('.date-picker')).pickadate({
      format: 'mmm d, yyyy',
      klass: {
        holder: 'picker__holder date',
      },
      onSet: function (context) {
        me.set('field', this.get('select', 'mm/dd/yyyy'));
      },
    });
  },

  onteardown: function () {
    $(this.find('.date-picker')).pickadate('stop');
  },
});
