window.WebForm.ToggleField = function (dataKeypath) {
  const toggle = document.getElementById(dataKeypath);
  if (toggle) {
    toggle.addEventListener('change', toggleListener);
    toggle.dispatchEvent(new Event('change'));
  }
};

const toggleListener = (event) => {
  // not checked inputs do not get sent by .submit() https://mzl.la/3pxxuH8
  // if input not checked we create a hidden input so backend receives the 0 value
  if (!event.target.checked) {
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.id = event.target.id;
    hiddenInput.name = event.target.name;
    hiddenInput.value = 0;
    event.target.after(hiddenInput);
  } else {
    // remove hidden input if checked
    const hiddenInputs = event.target.parentNode.querySelectorAll('input[type="hidden"]');
    if (hiddenInputs.length) {
      hiddenInputs.forEach((input) => input.remove());
    }
  }
};

export { toggleListener };
