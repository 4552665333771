window.WebForm.AdmissionEventSlotPicker = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
    options.adapt = 'Ractive';
  },

  computed: {
    day_slots: {
      get: function () {
        return this.slotsForDate(this.get('selectedDate'));
      },
    },
    show_picker: {
      get: function () {
        return !this.get('readonly') && !this.get('slot_selected');
      },
    },
  },

  slotsForDate: function (date) {
    if (this.calendar) {
      return _.filter(this.calendar.fullCalendar('clientEvents'), function (slot) {
        return date.isSame(slot.start, 'day');
      });
    }
  },

  onrender: function () {
    this.calendar = $(this.find('.fullcalendar-element'));
    this.calendar.fullCalendar(this.calendarOptions);
  },

  oninit: function () {
    _.bindAllFunctions(this);

    this.observe('selectedDate', function () {
      this.update('day_slots');
    });

    this.set('formatTime', this.formatTime);

    this.calendarOptions = {
      defaultView: 'threeWeek',
      aspectRatio: 2,
      now: moment(),
      selectable: false,
      unselectAuto: false,
      header: {
        left: 'prev,next',
        center: 'title',
        right: '',
      },
      views: {
        threeWeek: {
          type: 'basic',
          duration: { weeks: 3 },
          rows: 3,
        },
      },
      eventBackgroundColor: 'transparent',
      eventBorderColor: 'transparent',
      dayRender: this.fc_dayRender,
      events: this.get('events'),
      dayClick: this.fc_dayClick,
      eventRender: this.fc_eventRender,
    };
  },

  fc_dayClick: function (date, jsEvent, view) {
    // TODO @bauer - use the 'this' binding pattern
    this.set('selectedDate', date);
    $('td.fc-day', this.calendar).removeClass('date-selected');
    $('td.fc-day[data-date=' + date.format('YYYY-MM-DD') + ']', this.calendar).addClass(
      'date-selected'
    );
    this.update('daySlots');
  },

  fc_dayRender: function (date, cell) {
    // Render the events in a very simple way
    $('<br><br>').appendTo(cell);
    _.each(this.slotsForDate(date), function (slot) {
      $('<i class="slot-dot nc-icon-glyph weather_moon-full"></i>').appendTo(cell);
    });
  },

  fc_eventRender: function (event, element, view) {
    // fullCalendar Event rendering is done with a table and is too difficult to massage into what we want.
    // So we are bypassing fullCalendar's event rendering entirely and doing it in the dayRender method
    return false;
  },

  select_slot: function (slot_id) {
    const slot = _.find(this.calendar.fullCalendar('clientEvents'), function (e) {
      return e.id == slot_id;
    });
    this.set('slot_selected', slot);
    this.fire('slot-selected', slot_id);
  },

  deselect: function () {
    this.set('slot_selected', null);
    this.fire('slot-deselected');
  },

  formatTime: function (time) {
    return time.format('h:mma');
  },
});
