// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`TextboxElement should render correctly when the component is "textbox" 1`] = `
<div
  class="ae-grid"
  data-testid="textbox-element"
>
  <div
    class="ae-grid__item item-sm-6"
  >
    <label
      class="vx-form-label"
      data-testid="vx-label"
      for="section-888#0__header"
    >
      A basic element
    </label>
    <div
      class="vx-form-label-instructions"
      data-testid="help-text"
    >
      A help text
    </div>
    <div
      class="vx-form-control vx-text-field"
      data-field-type="textbox"
    >
      <input
        class="vx-text-field__input"
        id="section-888#0__header"
        name="section-888#0__header"
        placeholder="Some placeholder text"
        type="text"
        value=""
      />
    </div>
  </div>
</div>
`;
