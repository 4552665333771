import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { PARSE_DATE_FORMAT } from '../constants';
import { ZgStatus } from '../types';

type DatePickerDayProps = {
  day: moment.Moment;
  date: moment.Moment;
  gradingPeriods: ZgStatus[];
};
const DatePickerDay = ({ day, date, gradingPeriods }: DatePickerDayProps) => {
  const gradingPeriodStart = gradingPeriods?.find((gp) =>
    day.isSame(moment(gp.begin_date, PARSE_DATE_FORMAT), 'day')
  );
  const gradingPeriodEnd = gradingPeriods?.find((gp) =>
    day.isSame(moment(gp.end_date, PARSE_DATE_FORMAT), 'day')
  );

  const dayNumber = day.day();
  const dayString = day.format(PARSE_DATE_FORMAT);
  const isSelected = dayString === date.format(PARSE_DATE_FORMAT);
  const isTooltipLeftAligned = dayNumber > 3; // Wednesday is cutoff
  const ariaLabelBegin = gradingPeriodStart && gradingPeriodStart.description + ' begins';
  const ariaLabelEnd = gradingPeriodEnd && gradingPeriodEnd.description + ' ends';
  let ariaLabelFull = '';
  if (ariaLabelBegin && ariaLabelEnd) {
    ariaLabelFull = ariaLabelBegin + '\n' + ariaLabelEnd;
  } else {
    ariaLabelFull = ariaLabelBegin || ariaLabelEnd || '';
  }

  const classes = classNames('flex', {
    'vx-tooltipped': gradingPeriodStart || gradingPeriodEnd,
    'relative items-center justify-center w-full h-full border-transparent bg-white':
      !isSelected && (gradingPeriodStart || gradingPeriodEnd),
    'vx-tooltipped--w': isTooltipLeftAligned,
    'vx-tooltipped--e': !isTooltipLeftAligned,
    'border-r-2 border-y-2': !isSelected && gradingPeriodEnd,
    'border-l-2 border-y-2': !isSelected && gradingPeriodStart,
  });

  const lightBlue = '#38afff'; // blue-4 Pillar color
  const borderColor = isSelected ? 'white' : lightBlue;
  const leftArrowClasses = classNames({
    collapse: !gradingPeriodStart,
  });
  const rightArrowClasses = classNames({
    collapse: !gradingPeriodEnd,
  });

  let styleObj = {};
  if (!isSelected) {
    if (gradingPeriodStart) {
      styleObj = {
        borderImage: `linear-gradient(to left, white, ${borderColor}) 1`,
        borderImageOutset: 1,
      };
    }
    if (gradingPeriodEnd) {
      styleObj = {
        borderImage: `linear-gradient(to right, white, ${borderColor}) 1`,
        borderImageOutset: 1,
      };
    }
  }

  const leftArrow = (
    <svg
      className={leftArrowClasses}
      viewBox="0 0 24 24"
      fill={borderColor}
      stroke={borderColor}
      strokeWidth="1"
      strokeLinejoin="round"
      data-testid="daypicker-left-arrow"
    >
      <polygon points="2,12 13,5 13,19" />
    </svg>
  );
  const rightArrow = (
    <svg
      className={rightArrowClasses}
      viewBox="0 0 24 24"
      fill={borderColor}
      stroke={borderColor}
      strokeWidth="1"
      strokeLinejoin="round"
      data-testid="daypicker-right-arrow"
    >
      <polygon points="22,12 11,5 11,19" />
    </svg>
  );

  return (
    <div
      className={classes}
      aria-label={ariaLabelFull}
      style={styleObj}
      data-testid="daypicker-day"
    >
      {leftArrow}
      <span className="w-full">{day.format('D')}</span>
      {rightArrow}
    </div>
  );
};
export default DatePickerDay;
