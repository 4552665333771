require('../portal-component');

window.Portal.ClassListStudent.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.set({
      classWebsiteURL: this.classWebsiteURL,
      assignmentURL: this.assignmentURL,
      gradeDetailURL: this.gradeDetailURL,
    });
    this.load_data().then(this._dataLoaded);
  },

  data: {
    loads_test_data: true,
  },

  _dataLoaded: function (data) {
    this.set({
      courses: data.courses,
      hide_website_links: Portals.config.hide_website_links,
    });

    this.componentDoneLoading();
  },

  assignmentURL: function (enrollment_pk, filter) {
    return this.systemPageURL('class_assignments', {
      child_pk: Portals.config.user_id,
      class_pk: enrollment_pk,
      filter: filter,
    });
  },

  gradeDetailURL: function (enrollment_pk) {
    return this.systemPageURL('grade_detail', {
      child_pk: Portals.config.user_id,
      class_pk: enrollment_pk,
    });
  },

  classWebsiteURL: function (class_pk, page) {
    return [_.baseURL('classes'), 'course', class_pk, 'website', page].join('/');
  },
});
