import React from 'react';
import classNames from 'classnames';

import { clearCurrentUrlParams, formatMonetaryValue } from 'components/shared/utils';
import useAsyncRequest from 'components/shared/hooks/useAsyncRequest';
import AuthTokenContext from 'components/shared/AuthTokenContext';
import Dropdown from 'components/shared/dropdown/Dropdown';
import { TabsView, TabButton, TabButtonList, TabPanel, TabPanelList } from 'components/shared/tabs';
import {
  TextAreaField,
  TextField,
  DateField,
  NumberField,
  Button,
  SelectField,
  DataField,
  MessageBanner,
} from 'components/shared/form_elements';

import { REQUEST_STATUSES } from 'components/system/purchase_requests/constants';
import PurchaseRequestVendorSection from 'components/system/purchase_requests/PurchaseRequestVendorSection';
import PurchaseRequestItemsSection from 'components/system/purchase_requests/items/PurchaseRequestItemsSection';
import PurchaseRequestActivityLogsSection from 'components/system/purchase_requests/PurchaseRequestActivityLogsSection';
import Files from './purchase-request-detail-page/Files';

const isPresent = (value) => {
  return value !== undefined && value !== null && String(value).trim() !== '';
};

const UPDATABLE_REQUEST_STATUSES = [REQUEST_STATUSES.pending, REQUEST_STATUSES.changes_needed].map(
  (e) => e.value
);

// prettier-ignore
const SUBMITTABLE_REQUEST_STATUSES = [
  REQUEST_STATUSES.pending,
  REQUEST_STATUSES.changes_needed,
].map((e) => e.value);

const CANCELLABLE_REQUEST_STATUSES = [
  REQUEST_STATUSES.pending,
  REQUEST_STATUSES.submitted_for_approval,
  REQUEST_STATUSES.changes_needed,
  REQUEST_STATUSES.approved,
].map((e) => e.value);

const PURCHASE_ORDER_STATUSES = [REQUEST_STATUSES.approved, REQUEST_STATUSES.closed].map(
  (e) => e.value
);

const DEFAULT_PURCHASE_REQUEST_DESCRIPTION = 'Untitled';

const FIELD_CONFIG = {
  requester_description: {
    label: 'Requester',
    disabled: true,
  },
  public_id: {
    label: 'Public Id',
    disabled: true,
  },
  subtotal_amount: {
    label: 'Subtotal',
  },
  total_amount: {
    label: 'Total Amount',
  },
};

// prettier-ignore
const PurchaseRequestTag = (props) => {
  const {
    label,
    color,
    icon,
    href
  } = props;


  const styles = React.useMemo(() => ({
    '--background-color': color,
  }), [color]);
  if (href) {
    return (
      <a
        className="vx-tag purchase-request-status-tag"
        style={styles}
        href={href}
      >
        {icon && <i className={`nc-icon-glyph ${icon}`} />}
        {label}
      </a>
    );
  } else {
    return (
      <div
        className="vx-tag purchase-request-status-tag"
        style={styles}
      >
        {icon && <i className={`nc-icon-glyph ${icon}`} />}
        {label}
      </div>
    );
  }
};

// prettier-ignore
function PurchaseRequestDetailPage(props) {
  const {
    authToken,
    data,
    config,
  } = props;

  clearCurrentUrlParams();

  const updatePurchaseRequestRequest = useAsyncRequest({
    pathName: 'update_purchase_request_path',
    params: {
      purchase_request_id: data.purchase_request.id,
    },
    method: 'PATCH',
    authorizationToken: authToken,
  });

  const getPurchaseRequestItemsRequest = useAsyncRequest({
    pathName: 'purchase_request_items_path',
    params: {
      purchase_request_id: data.purchase_request.id,
    },
    method: 'GET',
    authorizationToken: authToken,
  });

  /***************************************************************************************************
  ** Form
  ***************************************************************************************************/

  const [initialFormValues, setInitialFormValues] = React.useState(data.purchase_request);
  const [formValues, setFormValues] = React.useState(initialFormValues);
  const [formChanges, setFormChanges] = React.useState({});
  const [formErrors, setFormErrors] = React.useState({});
  const [formMessage, setFormMessage] = React.useState({});

  const setFormError = React.useCallback(({ name, error }) => {
    setFormErrors((errors) => ({ ...errors, [name]: error }));
  }, []);

  const setFormValue = React.useCallback((name, value) => {
    setFormValues((values) => ({ ...values, [name]: value }));
    setFormChanges((changes) => ({ ...changes, [name]: (value !== initialFormValues[name]) }));
    setFormError({ name, error: undefined });
  }, [setFormError, initialFormValues]);

  const clearFormError = React.useCallback((name) => {
    delete formErrors[name];
  }, [formErrors]);

  const clearFormChanges = () => setFormChanges({});
  const clearFormMessage = () => setFormMessage({});

  const handleFormFieldChange = React.useCallback((event) => {
    const { name, value } = event.target;
    setFormValue(name, value);
    clearFormError(name);
  }, [setFormValue, clearFormError]);

  /**************************************************************************************************/

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const requestStatus = REQUEST_STATUSES[initialFormValues.request_status];
  const isUpdatable = UPDATABLE_REQUEST_STATUSES.includes(formValues.request_status);
  const isSubmittable = SUBMITTABLE_REQUEST_STATUSES.includes(formValues.request_status);
  const isCancellable = CANCELLABLE_REQUEST_STATUSES.includes(formValues.request_status);
  const isUpdatableOrPosted = isUpdatable || formValues.posted;
  const showPurchaseOrder = PURCHASE_ORDER_STATUSES.includes(formValues.request_status);

  const [items, setItems] = React.useState(undefined);
  const [subtotalAmount, setSubtotalAmount] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(formValues.total_amount);
  const [files, setFiles] = React.useState(props.data.uploadedFiles);

  const isAddingNewVendor = formValues.vendor_fk === 0 && !!formValues.vendor_name;

  const loadItems = React.useCallback(() => {
    getPurchaseRequestItemsRequest().then((response) => {
      const purchaseRequestItems = response.records;

      setItems(purchaseRequestItems);
      calculateSubtotalAmount(purchaseRequestItems);
    });
  }, []);

  const calculateSubtotalAmount = (items) => {
    const amount = items.reduce((sum, item) => {
      sum += item.quantity * item.unit_cost;
      return sum;
    }, 0);

    setSubtotalAmount(amount);
  };

  React.useEffect(() => {
    setTotalAmount(subtotalAmount + parseFloat(formValues.tax_amount) + parseFloat(formValues.shipping_amount));
  }, [
    subtotalAmount,
    formValues.tax_amount,
    formValues.shipping_amount,
  ]);

  /**************************************************************************************************/

  const validateSubmitPurchaseRequest = React.useCallback((values) => {
    let isValid = true;

    if (!isPresent(values.description) || values.description === DEFAULT_PURCHASE_REQUEST_DESCRIPTION) {
      isValid = false;

      setFormError({
        name: 'description',
        error: 'This field is required',
      });
    }

    if (!isPresent(values.request_date)) {
      isValid = false;

      setFormError({
        name: 'request_date',
        error: 'This field is required',
      });
    }

    if (!isPresent(values.purchase_request_workflow_fk) || values.purchase_request_workflow_fk === 0) {
      isValid = false;

      setFormError({
        name: 'purchase_request_workflow_fk',
        error: 'This field is required',
      });
    }

    if (isAddingNewVendor) {
      if (!isPresent(values.vendor_name)) {
        isValid = false;

        setFormError({
          name: 'vendor_name',
          error: 'This field is required',
        });
      }

      if (!isPresent(values.vendor_address_1)) {
        isValid = false;

        setFormError({
          name: 'vendor_address_1',
          error: 'This field is required',
        });
      }

      if (!isPresent(values.vendor_city)) {
        isValid = false;

        setFormError({
          name: 'vendor_city',
          error: 'This field is required',
        });
      }

      if (!isPresent(values.vendor_state_province)) {
        isValid = false;

        setFormError({
          name: 'vendor_state_province',
          error: 'This field is required',
        });
      }

      if (!isPresent(values.vendor_postal_code)) {
        isValid = false;

        setFormError({
          name: 'vendor_postal_code',
          error: 'This field is required',
        });
      }

      if (!isPresent(values.vendor_country) || values.vendor_country === 0) {
        isValid = false;

        setFormError({
          name: 'vendor_country',
          error: 'This field is required',
        });
      }
    } else {
      if (!values.vendor_fk) {
        isValid = false;

        setFormError({
          name: 'vendor_fk',
          error: 'This field is required',
        });
      }
    }

    if (items?.length === 0) {
      isValid = false;

      setFormError({
        name: 'items',
        error: 'Atleast one item is required for submission',
      });
    }

    return isValid;
  }, [
    isAddingNewVendor,
    items?.length,
    setFormError,
  ]);

  const validateSavePurchaseRequest = React.useCallback((values) => {
    let isValid = true;

    if (!isPresent(values.description) || values.description === DEFAULT_PURCHASE_REQUEST_DESCRIPTION) {
      isValid = false;

      setFormError({
        name: 'description',
        error: 'This field is required',
      });
    }

    return isValid;
  }, [setFormError]);

  /**************************************************************************************************/

  const handleClickSaveButton = React.useCallback((event) => {
    event.preventDefault();
    if (!isUpdatableOrPosted) return;

    clearFormMessage();

    const isValid = validateSavePurchaseRequest(formValues);

    if (!isValid) {
      setFormMessage({
        type: 'error',
        message: 'Error: Purchase Request was not saved because there was an issue with one of the field(s).',
      });
    } else {
      setIsSaving(true);
      updatePurchaseRequestRequest(formValues).then((_data) => {
        setInitialFormValues(formValues);
        clearFormChanges();
        setFormMessage({
          type: 'success',
          message: 'Purchase Request was successfully saved.',
        });
        setIsSaving(false);
      }).catch((_error) => {
        setFormMessage({
          type: 'error',
          message: _error?.message?.length ? _error.message : 'Error: There was an error saving the Purchase Request. Please try again.',
        });
        setIsSaving(false);
      });
    }
  }, [
    formValues,
    isUpdatableOrPosted,
    validateSavePurchaseRequest,
  ]);

  const handleClickSubmitButton = React.useCallback((event) => {
    event.preventDefault();
    if (!isSubmittable) return;

    clearFormMessage();

    Swal.fire({
      title: 'Are you sure you want to submit this purchase request for review?',
      text: 'This action cannot be undone.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#43B664',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (!result.value) return;

      const isValid = validateSubmitPurchaseRequest(formValues);

      if (!isValid) {
        setFormMessage({
          type: 'error',
          message: 'Error: Purchase Request was not submitted because there was an issue with one of the field(s).',
        });
      } else {
        setIsSubmitting(true);
        updatePurchaseRequestRequest({
          ...formValues,
          request_status: REQUEST_STATUSES.submitted_for_approval.value,
        }).then((_data) => {
          setInitialFormValues({
            ...formValues,
            request_status: REQUEST_STATUSES.submitted_for_approval.value,
          });
          setFormValues({
            ...formValues,
            request_status: REQUEST_STATUSES.submitted_for_approval.value,
          });
          clearFormChanges();
          setFormMessage({
            type: 'success',
            message: 'Purchase Request was successfully submitted for approval.',
          });
          setIsSubmitting(false);
        }).catch((_error) => {
          setFormMessage({
            type: 'error',
            message: _error?.message?.length ? _error.message : 'Error: There was an error submitting the Purchase Request for approval. Please try again.',
          });
          setIsSubmitting(false);
        });
      }
    });
  }, [
    formValues,
    isSubmittable,
    validateSubmitPurchaseRequest,
  ]);

  const handleClickCancelDropdownItem = React.useCallback((event) => {
    event.preventDefault();
    if (!isCancellable) return;

    clearFormMessage();

    Swal.fire({
      title: 'Are you sure you want to cancel this Purchase Request?',
      text: 'This action cannot be undone.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#43B664',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (!result.value) return;

      updatePurchaseRequestRequest({
        ...formValues,
        request_status: REQUEST_STATUSES.cancelled.value,
      }).then((_data) => {
        setFormMessage({
          type: 'success',
          message: 'Purchase Request has been successfully cancelled.',
        });
      }).catch((_error) => {
        setFormMessage({
          type: 'error',
          message: 'Error: There was an error cancelling the Purchase Request. Please try again.',
        });

      });
    });
  }, [
    formValues,
    isCancellable,
  ]);

  /**************************************************************************************************/

  const dropdownItems = React.useMemo(() => [
    {
      type: 'item',
      label: 'Cancel Purchase Request',
      icon: 'ui-1_circle-bold-remove',
      onClick: handleClickCancelDropdownItem,
      isEnabled: isCancellable,
    },
  ], [isCancellable, handleClickCancelDropdownItem]);

  const headerTitleClassNames = classNames(['purchase-request-detail-header-title'], {
    'purchase-request-detail-header-title--blank': initialFormValues.description === DEFAULT_PURCHASE_REQUEST_DESCRIPTION,
  });

  const handleFileOnDeleteClick = (filePk) => {
    setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile.file_pk !== filePk));
  };

  const handleFilesUploaded = (uploadedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  };

  return (
    <>
      <AuthTokenContext.Provider value={authToken}>
        <form className="purchase-request-detail-page vx-form vx-form--marginless" noValidate>
          <div className="purchase-request-detail-header">
            <div className="purchase-request-detail-header-left-side">
              <div className={headerTitleClassNames}>
                {initialFormValues.description}
              </div>
            </div>
            <div className="purchase-request-detail-header-right-side">
              {isUpdatableOrPosted && (
                <Button
                  className="vx-button vx-button--primary form-button"
                  value="Save"
                  onClick={handleClickSaveButton}
                  isLoading={isSaving}
                  loadingText="Saving..."
                />
              )}
              {isSubmittable && (
                <Button
                  className="vx-button vx-button--success form-button"
                  value="Submit for Approval"
                  onClick={handleClickSubmitButton}
                  isLoading={isSubmitting}
                  loadingText="Submitting..."
                />
              )}
              <Dropdown
                id="purchase-request-detail-dropdown"
                triggerButtonClassNames="vx-button vx-button--icon"
                triggerButtonIcon="ui-2_menu-dots"
                items={dropdownItems}
              />
            </div>
          </div>

          <TabsView defaultTab="details">
            <TabButtonList>
              <TabButton name="details">
                <i className="nc-icon-glyph shopping_list" />
                Details
              </TabButton>
              <TabButton name="activity-logs">
                <i className="nc-icon-glyph arrows-2_time" />
                Activity Logs
              </TabButton>
            </TabButtonList>

            <TabPanelList>
              <TabPanel name="details">
                {formMessage.message && (
                  <MessageBanner type={formMessage.type} text={formMessage.message} />
                )}

                {showPurchaseOrder && (
                  <div className="purchase-request-detail-section">
                  <div className="purchase-request-detail-section-header">
                    <div className="purchase-request-detail-section-header-title">
                      Purchase Order
                    </div>
                  </div>
                  <div className="ae-grid">
                    <div className="ae-grid__item item-sm-4">
                      <TextField
                          name="po_number"
                          config={config.po_number}
                          value={formValues.po_number}
                          disabled={true}
                        />
                    </div>
                    <div className="ae-grid__item item-sm-4">
                      <DateField
                          name="po_date"
                          config={config.po_date}
                          value={formValues.po_date}
                          disabled={true}
                          />
                    </div>
                    <div className="ae-grid__item item-sm-3">
                      <div className="vx-form-field">
                        <label className="vx-form-label">
                          PO Document
                        </label>
                        <div className="vx-form-control">
                        <PurchaseRequestTag
                            label='Click Here'
                            color='#0EA4B2'
                            href={formValues.document_url}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                )}

                <div className="purchase-request-detail-section">
                  <div className="purchase-request-detail-section-header">
                    <div className="purchase-request-detail-section-header-title">
                      General
                    </div>
                  </div>

                  <div className="ae-grid">
                    <div className="ae-grid__item item-sm-4">
                      <TextField
                        name="description"
                        value={formValues.description}
                        error={formErrors.description}
                        config={config.description}
                        onChange={handleFormFieldChange}
                        hasChange={formChanges.description}
                        disabled={!isUpdatable}
                      />
                    </div>
                    <div className="ae-grid__item item-sm-4">
                      <DateField
                        name="request_date"
                        value={formValues.request_date}
                        error={formErrors.request_date}
                        config={config.request_date}
                        onChange={handleFormFieldChange}
                        hasChange={formChanges.request_date}
                        disabled={!isUpdatable}
                      />
                    </div>
                    <div className="ae-grid__item item-sm-4">
                      <div className="vx-form-field">
                        <label className="vx-form-label">
                          Status
                        </label>
                        <div className="vx-form-control">
                          <PurchaseRequestTag
                            label={requestStatus.label}
                            color={requestStatus.color}
                            icon={requestStatus.icon}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ae-grid">
                    <div className="ae-grid__item item-sm-2">
                      <TextField
                        name="requester_description"
                        value={data.purchase_request.requester_description}
                        config={FIELD_CONFIG.requester_description}
                      />
                    </div>
                    <div className="ae-grid__item item-sm-4">
                      <SelectField
                        name="purchase_request_workflow_fk"
                        value={formValues.purchase_request_workflow_fk}
                        error={formErrors.purchase_request_workflow_fk}
                        config={config.purchase_request_workflow_fk}
                        onChange={handleFormFieldChange}
                        hasChange={formChanges.purchase_request_workflow_fk}
                        disabled={!isUpdatable}
                      />
                    </div>
                    <div className="ae-grid__item item-sm-2">
                      <SelectField
                        name="school_year"
                        value={formValues.school_year}
                        error={formErrors.school_year}
                        config={config.school_year}
                        onChange={handleFormFieldChange}
                        hasChange={formChanges.school_year}
                        disabled={!isUpdatable}
                      />
                    </div>
                    <div className="ae-grid__item item-sm-4">
                      <DateField
                        name="due_date"
                        value={formValues.due_date}
                        error={formErrors.due_date}
                        config={config.due_date}
                        onChange={handleFormFieldChange}
                        hasChange={formChanges.due_date}
                        disabled={!isUpdatable}
                      />
                    </div>
                  </div>

                  <div className="ae-grid">
                    <div className="ae-grid__item item-sm-12">
                      <TextField
                        name="public_id"
                        value={data.purchase_request.public_id}
                        config={FIELD_CONFIG.public_id}
                      />
                    </div>
                  </div>

                  <hr />

                  <div className="ae-grid">
                    <div className="ae-grid__item item-sm-12">
                      <TextField
                        name="shipping_address"
                        value={formValues.shipping_address}
                        error={formErrors.shipping_address}
                        config={config.shipping_address}
                        onChange={handleFormFieldChange}
                        hasChange={formChanges.shipping_address}
                        disabled={!isUpdatableOrPosted}
                      />
                    </div>
                  </div>
                  <div className="ae-grid">
                    <div className="ae-grid__item item-sm-12">
                      <TextAreaField
                        name="notes"
                        value={formValues.notes}
                        error={formErrors.notes}
                        config={config.notes}
                        onChange={handleFormFieldChange}
                        hasChange={formChanges.notes}
                        disabled={!isUpdatableOrPosted}
                      />
                    </div>
                  </div>
                </div>

                <PurchaseRequestVendorSection
                  data={data.purchase_request}
                  config={config}
                  formValues={formValues}
                  formErrors={formErrors}
                  formChanges={formChanges}
                  handleFormFieldChange={handleFormFieldChange}
                />

                <PurchaseRequestItemsSection
                  data={data.purchase_request}
                  items={items}
                  subtotalAmount={subtotalAmount}
                  error={formErrors.items}
                  loadItems={loadItems}
                  isDisabled={!isUpdatable}
                  purchaseRequestWorkflowFk={formValues.purchase_request_workflow_fk}
                />

                <div className="purchase-request-detail-section">
                  <div className="purchase-request-detail-section-header">
                    <div className="purchase-request-detail-section-header-title">
                      Invoice Details
                    </div>
                  </div>
                  <div className="ae-grid">
                    <div className="ae-grid__item item-sm-3">
                      <DataField
                        name="subtotal_amount"
                        value={formatMonetaryValue(subtotalAmount, {
                          locale: data.purchase_request.currency_locale,
                          code: data.purchase_request.currency_code,
                        })}
                        config={FIELD_CONFIG.subtotal_amount}
                        disabled={!isUpdatable}
                      />
                    </div>
                    <div className="ae-grid__item item-sm-3">
                      <NumberField
                        name="shipping_amount"
                        value={formValues.shipping_amount}
                        error={formErrors.shipping_amount}
                        config={config.shipping_amount}
                        onChange={handleFormFieldChange}
                        hasChange={formChanges.shipping_amount}
                        disabled={!isUpdatable}
                      />
                    </div>
                    <div className="ae-grid__item item-sm-3">
                      <NumberField
                        name="tax_amount"
                        value={formValues.tax_amount}
                        error={formErrors.tax_amount}
                        config={config.tax_amount}
                        onChange={handleFormFieldChange}
                        hasChange={formChanges.tax_amount}
                        disabled={!isUpdatable}
                      />
                    </div>
                    <div className="ae-grid__item item-sm-3">
                      <DataField
                        name="total_amount"
                        value={formatMonetaryValue(totalAmount, {
                          locale: data.purchase_request.currency_locale,
                          code: data.purchase_request.currency_code,
                        })}
                        config={FIELD_CONFIG.total_amount}
                      />
                    </div>
                  </div>
                </div>

                {props.config.uploaderOptions &&
                (
                <div className="purchase-request-detail-section">
                  <div className="purchase-request-detail-section-header">
                    <div className="purchase-request-detail-section-header-title">
                      Files
                    </div>
                  </div>
                  <div className="ae-grid">
                    <div className="ae-grid__item item-sm-12">
                        <Files
                          purchaseRequestPk={data.purchase_request.purchase_request_pk}
                          uploadedFiles={files}
                          uploaderOptions={props.config.uploaderOptions}
                          onDeleteClick={handleFileOnDeleteClick}
                          onFilesUploaded={handleFilesUploaded}
                        />
                    </div>
                  </div>
                </div>
                )}
              </TabPanel>

              <TabPanel name="activity-logs">
                <PurchaseRequestActivityLogsSection
                  data={data.purchase_request}
                />
              </TabPanel>
            </TabPanelList>
          </TabsView>
        </form>
      </AuthTokenContext.Provider>
    </>
  );
}

export default React.memo(PurchaseRequestDetailPage);
