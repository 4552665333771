import React from 'react';
import PropTypes from 'prop-types';

import SearchBox from './SearchBox';

const Navbar = ({ currentClassName, currentSection }) => {
  const [showSearchBox, setShowSearchBox] = React.useState(false);
  const [navText, setNavText] = React.useState('');

  React.useEffect(() => {
    setNavText(currentClassName);
  }, []);

  const handleClick = () => {
    setShowSearchBox(!showSearchBox);
  };

  const handleOnBlur = () => {
    setShowSearchBox(false);
  };

  const handleOnSelect = (classPk, optionLabel) => {
    setNavText(optionLabel);
    setShowSearchBox(false);
    location.href = redirectPath(classPk, currentSection);
  };

  const redirectPath = (classPk, section) => {
    const config = {
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
      internal_class_id: classPk,
    };

    switch (section) {
      case 'attendance':
        return Routes.attendance_path(config);
      case 'gradebook':
        return Routes.class_admin_gradebook_path(config);
      case 'report_cards':
        return Routes.class_admin_report_cards_path(config);
      case 'notifications':
        return Routes.class_admin_notifications_path(config);
      case 'calendar':
        return Routes.class_admin_calendar_path(config);
      case 'website':
        return Routes.class_admin_website_path(config);
      case 'photos':
        return Routes.class_admin_photos_path(config);
      case 'settings':
        return Routes.class_admin_settings_path(config);
      case 'students':
      default:
        return Routes.class_admin_students_path(config);
    }
  };

  return (
    <div className="w-full">
      <div className={`vx-tab-bar__class_information ${showSearchBox ? 'd-none' : 'd-flex'}`}>
        <span
          className="vx-call-to-action vx-tooltipped vx-tooltipped--n"
          aria-label="Click to search classes"
          onClick={() => handleClick()}
        >
          {navText}
          <i className="nc-icon-glyph arrows-3_small-down"></i>
        </span>
      </div>
      <div className={`w-md-50 z-index-2 relative ${showSearchBox ? 'd-block' : 'd-none'}`}>
        <SearchBox
          onFocus={showSearchBox}
          onBlur={handleOnBlur}
          onSelect={handleOnSelect}
        />
      </div>
    </div>
  );
};

Navbar.propTypes = {
  currentClassName: PropTypes.string.isRequired,
};

export default Navbar;
