// = require portal-components/portal-component

window.Portal.FacultyTodaysClasses.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.load_data().then(this._dataLoaded);
  },

  data: {
    classUrl: function (classPk, location, locationEnabled = null) {
      return this.classUrl(classPk, location, locationEnabled);
    },

    redirectTarget: (attendanceEnabled) => (attendanceEnabled ? '_self' : '_blank'),

    attendanceStatusDescription: function (status, translation) {
      // *** Note: this same logic is in faculty-class-list.js
      if (status === 'taken') {
        return `${translation} Taken`;
      } else if (status === 'not-taken') {
        return `${translation} Not Taken`;
      } else {
        return `No ${translation} Today`;
      }
    },
  },

  _dataLoaded: function (data) {
    this.set({
      todays_classes: data.todays_classes,
    });

    this.componentDoneLoading();
  },
});
