// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`DatePickerField should display correctly when there's an error 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label vx-form-label--required vx-form-label--error"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions vx-form-label-instructions--error"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control vx-date-field vx-form-control--error"
  >
    <div
      class="SingleDatePicker SingleDatePicker_1"
    >
      <div>
        <div
          class="SingleDatePickerInput SingleDatePickerInput_1"
        >
          <div
            class="DateInput DateInput_1"
          >
            <input
              aria-describedby="DateInput__screen-reader-message-section-888#0__header"
              aria-label="Some placeholder text"
              autocomplete="off"
              class="DateInput_input DateInput_input_1"
              id="section-888#0__header"
              name="section-888#0__header"
              placeholder="Some placeholder text"
              type="text"
              value=""
            />
            <p
              class="DateInput_screenReaderMessage DateInput_screenReaderMessage_1"
              id="DateInput__screen-reader-message-section-888#0__header"
            >
              Navigate forward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.
            </p>
          </div>
        </div>
      </div>
    </div>
    <i
      class="nc-icon-outline ui-1_calendar-grid-61 vx-date-field__icon"
    />
    <div
      class="vx-validation-message vx-validation-message--display"
      data-testid="vx-error-message"
    >
      Please enter a date in the format M/D/YYYY, ex. 10/20/2000
    </div>
  </div>
</div>
`;

exports[`DatePickerField should display plaintext if readonly 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control vx-date-field"
  >
    <div
      class="plaintext-data"
      data-testid="plain-text-field"
    >
      02/03/2021
    </div>
  </div>
</div>
`;

exports[`DatePickerField should not display a placeholder if it's empty 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control vx-date-field"
  >
    <div
      class="SingleDatePicker SingleDatePicker_1"
    >
      <div>
        <div
          class="SingleDatePickerInput SingleDatePickerInput_1"
        >
          <div
            class="DateInput DateInput_1"
          >
            <input
              aria-describedby="DateInput__screen-reader-message-section-888#0__header"
              aria-label="Date"
              autocomplete="off"
              class="DateInput_input DateInput_input_1"
              id="section-888#0__header"
              name="section-888#0__header"
              placeholder=""
              type="text"
              value="2/3/2021"
            />
            <p
              class="DateInput_screenReaderMessage DateInput_screenReaderMessage_1"
              id="DateInput__screen-reader-message-section-888#0__header"
            >
              Navigate forward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.
            </p>
          </div>
        </div>
      </div>
    </div>
    <i
      class="nc-icon-outline ui-1_calendar-grid-61 vx-date-field__icon"
    />
  </div>
</div>
`;

exports[`DatePickerField should not display a placeholder if it's not there 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control vx-date-field"
  >
    <div
      class="SingleDatePicker SingleDatePicker_1"
    >
      <div>
        <div
          class="SingleDatePickerInput SingleDatePickerInput_1"
        >
          <div
            class="DateInput DateInput_1"
          >
            <input
              aria-describedby="DateInput__screen-reader-message-section-888#0__header"
              aria-label="Date"
              autocomplete="off"
              class="DateInput_input DateInput_input_1"
              id="section-888#0__header"
              name="section-888#0__header"
              placeholder=""
              type="text"
              value="2/3/2021"
            />
            <p
              class="DateInput_screenReaderMessage DateInput_screenReaderMessage_1"
              id="DateInput__screen-reader-message-section-888#0__header"
            >
              Navigate forward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.
            </p>
          </div>
        </div>
      </div>
    </div>
    <i
      class="nc-icon-outline ui-1_calendar-grid-61 vx-date-field__icon"
    />
  </div>
</div>
`;

exports[`DatePickerField should not display help text if it's not there 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-control vx-date-field"
  >
    <div
      class="SingleDatePicker SingleDatePicker_1"
    >
      <div>
        <div
          class="SingleDatePickerInput SingleDatePickerInput_1"
        >
          <div
            class="DateInput DateInput_1"
          >
            <input
              aria-describedby="DateInput__screen-reader-message-section-888#0__header"
              aria-label="Some placeholder text"
              autocomplete="off"
              class="DateInput_input DateInput_input_1"
              id="section-888#0__header"
              name="section-888#0__header"
              placeholder="Some placeholder text"
              type="text"
              value="2/3/2021"
            />
            <p
              class="DateInput_screenReaderMessage DateInput_screenReaderMessage_1"
              id="DateInput__screen-reader-message-section-888#0__header"
            >
              Navigate forward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.
            </p>
          </div>
        </div>
      </div>
    </div>
    <i
      class="nc-icon-outline ui-1_calendar-grid-61 vx-date-field__icon"
    />
  </div>
</div>
`;

exports[`DatePickerField should render correctly 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control vx-date-field"
  >
    <div
      class="SingleDatePicker SingleDatePicker_1"
    >
      <div>
        <div
          class="SingleDatePickerInput SingleDatePickerInput_1"
        >
          <div
            class="DateInput DateInput_1"
          >
            <input
              aria-describedby="DateInput__screen-reader-message-section-888#0__header"
              aria-label="Some placeholder text"
              autocomplete="off"
              class="DateInput_input DateInput_input_1"
              id="section-888#0__header"
              name="section-888#0__header"
              placeholder="Some placeholder text"
              type="text"
              value="2/3/2021"
            />
            <p
              class="DateInput_screenReaderMessage DateInput_screenReaderMessage_1"
              id="DateInput__screen-reader-message-section-888#0__header"
            >
              Navigate forward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.
            </p>
          </div>
        </div>
      </div>
    </div>
    <i
      class="nc-icon-outline ui-1_calendar-grid-61 vx-date-field__icon"
    />
  </div>
</div>
`;
