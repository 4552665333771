//= require jquery-file-upload/vendor/jquery.ui.widget
//= require jquery-file-upload/jquery.fileupload

window.Control.FileControl = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
    options.adapt = 'Ractive';
  },

  oninit: function () {
    _.bindAllFunctions(this);
  },

  onrender: function () {
    trace.log();
    _.bindAll(this);

    if (this.get('field')) {
      this.set({
        status: 'complete',
        file_icon: this.get('file_type_icon') || 'files_single-content-03',
      });
    } else {
      this.set({
        status: 'waiting',
      });
      this.resetFile();
    }

    this.set('upload_progress', 0);

    this.on({
      _uploadFile: this._uploadFile,
      _confirmDeleteFile: this._confirmDeleteFile,
    });

    this.uploadInput().fileupload(
      _.merge(
        {
          url: FORKLIFT_UPLOAD_URL + '/files',
          dataType: 'json',
          paramName: 'file',
          dropZone: $(this.el),
          maxNumberOfFiles: 1,
          formData: {
            auth: FORKLIFT_AUTH_TOKEN,
          },
          add: this.fileUploadQueued,
        },
        this.get('fileupload_options')
      )
    );
  },

  uploadInput: function () {
    // Need to search for the element each time because fileupload dynamically replaces the input element.
    // And we don't want to use the option replaceFileInput = false because it doesn't let us upload the same file twice.
    return $(this.el).find('.file-upload-input');
  },

  onteardown: function () {
    trace.log();
    this.uploadInput().fileupload('destroy');
  },

  _uploadFile: function (e) {
    this.uploadInput().click();
  },

  _confirmDeleteFile: function (e) {
    e.original.preventDefault();

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.deleteFile();
      }
    });
  },

  deleteFile: function (e) {
    trace.log();

    this.fire('removed', {
      file_fk: this.get('file_fk'),
      file_name: this.get('file_name'),
      file_url: this.get('field'),
    });

    this.resetFile();

    this.set({
      status: 'waiting',
      upload_progress: 0,
    });
  },

  fileUploadQueued: function (e, data) {
    trace.log(e, data);

    $.each(
      data.files,
      function (index, file) {
        this.set('file_name', file.name);
        this.set('file_type', file.type);
      }.bind(this)
    );

    this.set('file_icon', 'arrows-1_cloud-upload-94');

    var jqXHR = data.submit().success(this.fileUploadSuccess).error(this.fileUploadError);

    this.set('status', 'processing');
    this.set('show_progress', true);
    this.refreshInterval = setInterval(this.refreshProgress, 100);
  },

  refreshProgress: function () {
    console.log('progress...');
    var progress = this.uploadInput().fileupload('progress');
    var percentComplete = progress.loaded / progress.total;
    this.animate('upload_progress', percentComplete * 100.0, {
      duration: 1000.0 * percentComplete,
    });
    if (percentComplete == 1) {
      clearInterval(this.refreshInterval);
    }
  },

  fileUploadSuccess: function (result, textStatus, jqXHR) {
    trace.log(result, textStatus, jqXHR);

    if (!result.url) {
      this.fileUploadError(jqXHR, textStatus, null);
      return;
    }

    this.set({
      field: result.url,
      file_name: result.name,
      file_fk: result.file_pk,
      file_icon: this.get('file_type_icon') || 'files_single-content-03',
      status: 'complete',
      show_progress: false,
      show_check: true,
    });

    this.fire('uploaded', {
      file_fk: this.get('file_fk'),
      file_name: this.get('file_name'),
      file_url: this.get('field'),
      mime_type: result.mime_type,
    });

    _.delay(
      _.bind(function () {
        this.set('show_check', false);
      }, this),
      2500
    );
  },

  fileUploadError: function (jqXHR, textStatus, errorThrown) {
    trace.log(jqXHR, textStatus, errorThrown);

    var status = this.get('field') ? 'complete' : 'waiting';
    var verb = this.get('field') ? 'replace' : 'upload';

    this.set({
      status: status,
      show_progress: false,
      show_check: false,
      error: true,
    });

    this.resetFile();

    Swal.fire({
      title: 'Oops...',
      text: 'There was an error trying to upload this file. Please check your internet connection and try again. If the problem persists contact Veracross Support.',
      icon: 'error',
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'OK',
      allowOutsideClick: true,
    });

    _.delay(
      _.bind(function () {
        this.set('error', false);
      }, this),
      2500
    );
  },

  resetFile: function () {
    this.set({
      field: null,
      file_name: "Press 'Choose File' or drag your file(s) into this box.",
      file_type: null,
      file_fk: null,
      file_icon: 'ui-2_alert-circle-',
    });
  },
});
