import React from 'react';

const WebFormSuccess = () => (
  <div className="admin-edit-form-success">
    <i className="nc-icon-outline ui-1_check-circle-08 check-icon"></i>
    <h2 className="vx-heading-2">The form has been submitted successfully!</h2>
    <div className="success-message">You can now close this window.</div>
  </div>
);

export default WebFormSuccess;
