// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`NumberElement should render correctly when the component is "number" 1`] = `
<div
  class="ae-grid"
  data-testid="number-element"
>
  <div
    class="ae-grid__item item-sm-6"
  >
    <label
      class="vx-form-label"
      data-testid="vx-label"
      for="section-888#0__header"
    >
      A basic element
    </label>
    <div
      class="vx-form-label-instructions"
      data-testid="help-text"
    >
      A help text
    </div>
    <div
      class="vx-form-control vx-number-field"
    >
      <input
        class="vx-number-field__input"
        name="section-888#0__header"
        placeholder="Some placeholder text"
        type="number"
        value=""
      />
    </div>
  </div>
</div>
`;
