import React from 'react';

import { Button } from 'components/shared/form_elements';
import PurchaseRequestCard from 'components/system/purchase_requests/PurchaseRequestCard';

const Loader = (_props) => {
  return (
    <div className="vx-form-loader">
      <div className="vx-form-loader__spinner vx-loader-spinner" />
      <div className="vx-form-loader__message">Loading...</div>
    </div>
  );
};

const EmptyList = (_props) => {
  return (
    <div className="vx-empty-state-read-only">
      <h2>No Purchase Requests found.</h2>
    </div>
  );
};

// prettier-ignore
function PurchaseRequestCardList(props) {
  const {
    data,
    pageState,
    canLoadMoreRecords,
    handleRecordsLoadMore,
  } = props;

  const records = data.records;
  const hasRecords = records.length > 0;

  /**************************************************************************************************/

  if (pageState.isLoading || pageState.isSearching) return <Loader />;
  if (!hasRecords) return <EmptyList />;

  return (
    <div className="purchase-request-card-list">
      {records.map((record) => (
        <PurchaseRequestCard
          key={record.id}
          data={record}
        />
      ))}

      {canLoadMoreRecords && (
        <Button
          className="vx-button vx-button--primary load-more-button"
          disabled={pageState.isLoadingMore}
          onClick={handleRecordsLoadMore}
          value={pageState.isLoadingMore ? 'Loading...' : 'Load More'}
        />
      )}
    </div>
  );
}

export default React.memo(PurchaseRequestCardList);
