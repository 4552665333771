import React, { useState } from 'react';
import usePhotoGalleryStore from '../../../stores/usePhotoGalleryStore';
import Modal from '../../shared/Modal';
import { useFetch } from '../../../hooks/useFetch';
import { handleImageUpdateRequest } from './Endpoints';

function ImageCaptionModal({ internalClassId, galleryId, photo }) {
  const [editCaption, setEditCaption] = useState({ id: null, description: '' });
  const [errorMessage, setErrorMessage] = useState(null);
  const { data, sendFetchRequest } = useFetch();
  const { actions } = usePhotoGalleryStore();

  const handleCaptionChange = async () => {
    try {
      const requestFormData = {
        internal_class_id: internalClassId,
        gallery_id: galleryId,
        file_pk: editCaption.id,
        description: editCaption.description,
      };
      await handleImageUpdateRequest(
        requestFormData,
        'description',
        editCaption.description,
        sendFetchRequest
      );
    } catch (error) {
      setErrorMessage('Something went wrong while editing the caption. Please try again.');
    }
    actions.updatePhoto(editCaption);
    setEditCaption({ id: null, description: '' });
  };

  return (
    <div>
      <button
        onClick={() => setEditCaption({ id: photo.file_pk, description: photo.description })}
        className="ml-auto mr-1 py-1 px-2"
      >
        <i className="nc-icon-glyph ui-1_edit-72 text-med leading-7 pt-1 text-sky-700" />
      </button>
      <Modal
        isOpen={Boolean(editCaption.id === photo.file_pk)}
        onSave={() => handleCaptionChange()}
        overrideClasses="bg-blue-3 hover:bg-blue-4"
        label="Save"
        setIsOpen={() => setEditCaption({ id: null, description: '' })}
      >
        <h2 className="text-lg font-bold text-sky-700">Edit Caption</h2>
        <label className="block text-sm font-bold text-gray-700">PHOTO</label>
        <div style={{ width: '100px', height: '100px' }}>
          <img
            src={photo.url}
            alt={photo.name}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>
        <label className="block text-sm font-bold text-gray-700">CAPTION</label>
        <input
          type="text"
          id="caption"
          value={editCaption.description}
          placeholder={editCaption.description}
          onChange={(e) => setEditCaption({ ...editCaption, description: e.target.value })}
          className="block w-full px-1 py-1 border border-gray-300 shadow-sm focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm rounded"
        />
      </Modal>
      <Modal
        isOpen={Boolean(errorMessage)}
        onSave={() => {}}
        setIsOpen={() => setErrorMessage(null)}
        showButtons={false}
      >
        <h2 className="text-lg font-bold text-sky-700">Error</h2>
        <p className="mb-2">{errorMessage}</p>
      </Modal>
    </div>
  );
}

export default ImageCaptionModal;
