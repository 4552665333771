// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`PlainTextField renders correctly 1`] = `
<div>
  <div
    class="plaintext-data"
    data-testid="plain-text-field"
  >
    Hello world
  </div>
</div>
`;
