// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`CheckboxesColumnsElement should render correctly when the component is "checkboxes_columns" 1`] = `
<div
  class="ae-grid"
  data-testid="checkboxes_columns-element"
>
  <div
    class="ae-grid__item item-sm-12"
  >
    <label
      class="vx-form-label"
      data-testid="vx-label"
      for="section-888#0__header"
    >
      A basic element
    </label>
    <div
      class="vx-form-label-instructions"
      data-testid="help-text"
    >
      A help text
    </div>
    <div
      class="vx-form-control"
    >
      <div
        class="vx-checkbox-group ae-grid"
      >
        <div
          class="ae-grid__item item-md-4 item-sm-6"
        >
          <label
            class="vx-checkbox-group__item"
          >
            <input
              class="vx-checkbox-input"
              name="section-888#0__header"
              type="checkbox"
              value="1"
            />
            <span
              class="vx-checkbox-group__label"
              data-option-value="1"
            >
              One
            </span>
          </label>
        </div>
        <div
          class="ae-grid__item item-md-4 item-sm-6"
        >
          <label
            class="vx-checkbox-group__item"
          >
            <input
              class="vx-checkbox-input"
              name="section-888#0__header"
              type="checkbox"
              value="2"
            />
            <span
              class="vx-checkbox-group__label"
              data-option-value="2"
            >
              Two
            </span>
          </label>
        </div>
        <div
          class="ae-grid__item item-md-4 item-sm-6"
        >
          <label
            class="vx-checkbox-group__item"
          >
            <input
              class="vx-checkbox-input"
              name="section-888#0__header"
              type="checkbox"
              value="3"
            />
            <span
              class="vx-checkbox-group__label"
              data-option-value="3"
            >
              Three
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
`;
