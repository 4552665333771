/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// javascripts
_ = require('lodash');
require('moment');
Promise = require('bluebird');
Promise.config({ warnings: false }); // Bluebird throws warnings for valid JS code in Google's reCAPTCHA API
flatten = require('flat');
unflatten = require('flat').unflatten;
require('jquery');

// the sortable plugin for jquery ui
require('jquery-ui/ui/version');
require('jquery-ui/ui/widget');
require('jquery-ui/ui/widgets/mouse');
require('jquery-ui/ui/data');
require('jquery-ui/ui/scroll-parent');
require('jquery-ui/ui/widgets/sortable');

require('blueimp-file-upload');
require('jquery.scrollintoview');
require('fullcalendar');
require('pickadate/lib/picker');
require('pickadate/lib/picker.date');
require('pickadate/lib/picker.time');
require('react-dates');
require('select2');
Ractive = require('ractive');
Ractive.adaptors.Ractive = require('ractive-ractive');
Ractive.transitions.fade = require('ractive-transitions-fade');
Ractive.transitions.fly = require('ractive-transitions-fly');
require('ractive-transitions-scale');
require('rangeslider.js');
Swal = require('sweetalert2');
autocomplete = require('autocomplete.js');
require('vendor/microtrace');
require('vendor/redactor-fontsize');
require('vendor/redactor-fullscreen');
require('vendor/spectrum');
BigPicture = require('bigpicture');
numeral = require('numeral');
require('numeral/locales');
validate = require('validate.js');

// Initialize i18next for React and JS
require('i18n/index');

const unminified = /unminified/.test(function () {
  /* unminified*/
});
Ractive.DEBUG = unminified;
if (!unminified) {
  trace.deactivate();
}

require('system/system-namespace');
require('controls/control-namespace');
require('portal-components/components-namespace');
require('web-form/web-form-namespace');
require('registration-season/registration-season-namespace');

require('lib/app-domains.js.erb');
require('lib/mobile-check');
require('lib/bluebird_defer');
require('lib/lodash_extensions');

require('portals');
require('lib/modal-link-handler');
require('mobile-nav');
require('account-nav/account-nav');
require('portal-screen');
require('portal-screen-admin');
require('screen-printing/screen-print-modal');
require('component-test');
require('system/calendar/calendar');
require('system/billing_preferences/billing-preferences');
require('system/student/overview');
require('system/invoice/pay');
require('system/billing/payment_amount');
require('web-form');
require('web-form/validation-library');
require('web-form/form');
require('registration-season');
require('system/resource_reservations/resource_reservations');
require('system/directory_preferences/directory_preferences');

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
