import React, { Fragment } from 'react';
import { CategoryMap, Categories, PersonPermissionsByRole, FieldValueGroup } from './types';
import { Formik, Form } from 'formik';
import Category from './Category';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useClassSettingsActions } from './stores/useClassSettingStore';
import PermissionsModal from './permissions/PermissionsModal';
import PermissionsTable from './permissions/PermissionsTable';
import { SettingsValidation } from './validation_schema';
import FormikNavigationBlocker from './FormikNavigationBlocker';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 20, // TODO: set to 20s for testing, will increase to 2 minutes?
    },
  },
});

type SettingsTypes = {
  authToken: string;
  class_id: number;
  config: Categories;
  initialValues: Record<string, string | boolean | number>;
  categoryMap: CategoryMap;
  permissions: PersonPermissionsByRole;
  fvgs: FieldValueGroup;
};

const SettingCategories = ({
  authToken,
  class_id,
  config,
  initialValues,
  categoryMap,
  permissions,
  fvgs,
}: SettingsTypes) => {
  const { initialize } = useClassSettingsActions();
  initialize({
    authToken,
    class_id,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={SettingsValidation}
      >
        <Form>
          {/* Prevent navigating away from a form with unsaved changes or errors */}
          <FormikNavigationBlocker />
          {/* Category Sections */}
          {Object.keys(config).map((system_category) => {
            return (
              <Category
                key={system_category}
                systemCategory={system_category}
                categorySettings={config[system_category]}
                categoryMap={categoryMap}
              />
            );
          })}

          {/* Permissions */}
          <PermissionsModal />
          <PermissionsTable
            permissions={permissions}
            fvgs={fvgs}
          />
        </Form>
      </Formik>
    </QueryClientProvider>
  );
};

export default SettingCategories;
