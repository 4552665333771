window.Control.ProfileSearch = Ractive.extend({
  onconstruct: function (options) {
    trace.log();
    options.template = require('./template.ractive');
  },

  computed: {
    placeholder: function () {
      var placeholders = ['students', 'faculty or staff', 'students, faculty or staff'];
      return 'Find ' + placeholders[parseInt(this.get('person_type'))];
    },
  },

  onrender: function () {
    var me = this;
    this.search = autocomplete(
      this.find('.search-field'),
      {
        hint: true,
        autoselect: true,
        minLength: 3,
      },
      [
        {
          source: _.debounce(function (query, cb) {
            me.set('loader', true);

            $.ajax({
              type: 'GET',
              url: Routes.profile_search_path({
                client: Portals.config.client,
                portal_name: Portals.config.portal_name,
                q: query,
                type: me.get('person_type'),
              }),
            }).then(function (data) {
              me.set('loader', false);
              cb(data.people);
            });
          }, 750),
          displayKey: function (suggestion) {
            return suggestion.last_name + ', ' + suggestion.first_name;
          },
          templates: {
            empty:
              '<div class="vx-empty-state"><div class="vx-heading-4"><i class="nc-icon-outline ui-1_zoom-split"></i> No Results Found</div><p>There were no people that matched your search</p></div>',
            suggestion: function (suggestion) {
              return (
                '<strong>' +
                suggestion.last_name +
                '</strong>, ' +
                suggestion.first_name +
                ' ' +
                '<span class="record-type">' +
                suggestion.record_type +
                '</span>'
              );
            },
          },
        },
      ]
    ).on('autocomplete:selected', function (e, suggestion, dataset) {
      var top = screen.height / 2 - 700 / 2;
      var left = screen.width / 2 - 550 / 2;
      var url = [_.baseURL('portals_app'), 'profile', suggestion.person_pk].join('/');
      window.open(
        url,
        '_blank',
        'height=700,width=550,top=' +
          top +
          ',left=' +
          left +
          ',location=no,status=no,toolbar=no,resizable=no'
      );
    });
  },

  onteardown: function () {
    if (this.search) {
      this.search.autocomplete.destroy();
    }
  },
});
