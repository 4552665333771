require('../portal-component');

window.Portal.FacebookTimeline.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.componentDoneLoading();
  },

  computed: {
    encoded_url: function () {
      return encodeURIComponent('https://www.facebook.com/' + this.get('configuration.url'));
    },
  },

  onrender: function () {
    this.set('calculated_width', $(this.find('.component-facebook-timeline')).width());

    // reload the facebook plugin when width is changed in the admin editor
    if (this.get('admin')) {
      this.observe('width', this.widthChanged, { init: false });
    }
  },

  widthChanged: function (newValue, oldValue, keypath) {
    var container = $(this.find('.component-facebook-timeline'));
    this.set('calculated_width', container.width());
    container.find('iframe').attr('src', container.find('iframe').attr('src'));
  },
});
