// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`DatePickerElement should render correctly when the component is "date_picker" 1`] = `
<div
  class="ae-grid"
  data-testid="date_picker-element"
>
  <div
    class="ae-grid__item item-sm-6"
  >
    <label
      class="vx-form-label"
      data-testid="vx-label"
      for="section-888#0__header"
    >
      A basic element
    </label>
    <div
      class="vx-form-label-instructions"
      data-testid="help-text"
    >
      A help text
    </div>
    <div
      class="vx-form-control vx-date-field"
    >
      <div
        class="SingleDatePicker SingleDatePicker_1"
      >
        <div>
          <div
            class="SingleDatePickerInput SingleDatePickerInput_1"
          >
            <div
              class="DateInput DateInput_1"
            >
              <input
                aria-describedby="DateInput__screen-reader-message-section-888#0__header"
                aria-label="Some placeholder text"
                autocomplete="off"
                class="DateInput_input DateInput_input_1"
                id="section-888#0__header"
                name="section-888#0__header"
                placeholder="Some placeholder text"
                type="text"
                value=""
              />
              <p
                class="DateInput_screenReaderMessage DateInput_screenReaderMessage_1"
                id="DateInput__screen-reader-message-section-888#0__header"
              >
                Navigate forward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.
              </p>
            </div>
          </div>
        </div>
      </div>
      <i
        class="nc-icon-outline ui-1_calendar-grid-61 vx-date-field__icon"
      />
    </div>
  </div>
</div>
`;
