require('../portal-component');

window.Portal.OnlineGivingButton.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.reload();
  },

  computed: {
    block_styles: function () {
      var styles = {
        height: this.get('configuration.dimensions.height') + 'px',
        'background-color': this.get('configuration.styles.backgroundColor'),
        color: this.get('configuration.styles.color'),
      };
      return _.map(styles, function (value, key) {
        return key + ':' + value;
      }).join('; ');
    },
  },

  reload: function () {
    this.set('gift_form_url', '#');
    if (this.get('configuration.gift_form_pk') != '0') {
      this.load_data().then(_.bind(this._dataLoaded, this));
    } else {
      this.componentDoneLoading();
    }
  },

  _dataLoaded: function (data) {
    this.set('gift_form_url', data.url);
    this.componentDoneLoading();
  },
});
