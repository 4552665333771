// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`SelectField should display correctly when there's an error 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label vx-form-label--required vx-form-label--error"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions vx-form-label-instructions--error"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control vx-select "
    data-testid="select-field"
  >
    <div
      class=" css-2b097c-container"
    >
      <span
        aria-atomic="false"
        aria-live="polite"
        aria-relevant="additions text"
        class="css-1f43avz-a11yText-A11yText"
      />
      <div
        class=" css-yk16xz-control"
      >
        <div
          class=" css-g1d714-ValueContainer"
        >
          <div
            class=" css-1wa3eu0-placeholder"
          >
            Some placeholder text
          </div>
          <div
            class="css-b8ldur-Input"
          >
            <div
              class=""
              style="display: inline-block;"
            >
              <input
                aria-autocomplete="list"
                autocapitalize="none"
                autocomplete="off"
                autocorrect="off"
                id="react-select-4-input"
                spellcheck="false"
                style="box-sizing: content-box; width: 2px; border: 0px; opacity: 1; outline: 0; padding: 0px;"
                tabindex="0"
                type="text"
                value=""
              />
              <div
                style="position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-family: -webkit-small-control; letter-spacing: normal; text-transform: none;"
              />
            </div>
          </div>
        </div>
        <div
          class=" css-1hb7zxy-IndicatorsContainer"
        >
          <span
            class=" css-1okebmr-indicatorSeparator"
          />
          <div
            aria-hidden="true"
            class=" css-tlfecz-indicatorContainer"
          >
            <svg
              aria-hidden="true"
              class="css-tj5bde-Svg"
              focusable="false"
              height="20"
              viewBox="0 0 20 20"
              width="20"
            >
              <path
                d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="vx-validation-message vx-validation-message--display"
    data-testid="vx-error-message"
  >
    Required.
  </div>
</div>
`;

exports[`SelectField should display plaintext if readonly 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control vx-select "
    data-testid="select-field"
  >
    <div
      class="plaintext-data"
      data-testid="plain-text-field"
    >
      Three
    </div>
  </div>
</div>
`;

exports[`SelectField should not display help text if it's not there 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-control vx-select "
    data-testid="select-field"
  >
    <div
      class=" css-2b097c-container"
    >
      <span
        aria-atomic="false"
        aria-live="polite"
        aria-relevant="additions text"
        class="css-1f43avz-a11yText-A11yText"
      />
      <div
        class=" css-yk16xz-control"
      >
        <div
          class=" css-g1d714-ValueContainer"
        >
          <div
            class=" css-1uccc91-singleValue"
          >
            Two
          </div>
          <div
            class="css-b8ldur-Input"
          >
            <div
              class=""
              style="display: inline-block;"
            >
              <input
                aria-autocomplete="list"
                autocapitalize="none"
                autocomplete="off"
                autocorrect="off"
                id="react-select-3-input"
                spellcheck="false"
                style="box-sizing: content-box; width: 2px; border: 0px; opacity: 1; outline: 0; padding: 0px;"
                tabindex="0"
                type="text"
                value=""
              />
              <div
                style="position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-family: -webkit-small-control; letter-spacing: normal; text-transform: none;"
              />
            </div>
          </div>
        </div>
        <div
          class=" css-1hb7zxy-IndicatorsContainer"
        >
          <div
            aria-hidden="true"
            class=" css-tlfecz-indicatorContainer"
          >
            <svg
              aria-hidden="true"
              class="css-tj5bde-Svg"
              focusable="false"
              height="20"
              viewBox="0 0 20 20"
              width="20"
            >
              <path
                d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
              />
            </svg>
          </div>
          <span
            class=" css-1okebmr-indicatorSeparator"
          />
          <div
            aria-hidden="true"
            class=" css-tlfecz-indicatorContainer"
          >
            <svg
              aria-hidden="true"
              class="css-tj5bde-Svg"
              focusable="false"
              height="20"
              viewBox="0 0 20 20"
              width="20"
            >
              <path
                d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;

exports[`SelectField should render correctly 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control vx-select "
    data-testid="select-field"
  >
    <div
      class=" css-2b097c-container"
    >
      <span
        aria-atomic="false"
        aria-live="polite"
        aria-relevant="additions text"
        class="css-1f43avz-a11yText-A11yText"
      />
      <div
        class=" css-yk16xz-control"
      >
        <div
          class=" css-g1d714-ValueContainer"
        >
          <div
            class=" css-1uccc91-singleValue"
          >
            Two
          </div>
          <div
            class="css-b8ldur-Input"
          >
            <div
              class=""
              style="display: inline-block;"
            >
              <input
                aria-autocomplete="list"
                autocapitalize="none"
                autocomplete="off"
                autocorrect="off"
                id="react-select-2-input"
                spellcheck="false"
                style="box-sizing: content-box; width: 2px; border: 0px; opacity: 1; outline: 0; padding: 0px;"
                tabindex="0"
                type="text"
                value=""
              />
              <div
                style="position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-family: -webkit-small-control; letter-spacing: normal; text-transform: none;"
              />
            </div>
          </div>
        </div>
        <div
          class=" css-1hb7zxy-IndicatorsContainer"
        >
          <div
            aria-hidden="true"
            class=" css-tlfecz-indicatorContainer"
          >
            <svg
              aria-hidden="true"
              class="css-tj5bde-Svg"
              focusable="false"
              height="20"
              viewBox="0 0 20 20"
              width="20"
            >
              <path
                d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
              />
            </svg>
          </div>
          <span
            class=" css-1okebmr-indicatorSeparator"
          />
          <div
            aria-hidden="true"
            class=" css-tlfecz-indicatorContainer"
          >
            <svg
              aria-hidden="true"
              class="css-tj5bde-Svg"
              focusable="false"
              height="20"
              viewBox="0 0 20 20"
              width="20"
            >
              <path
                d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
