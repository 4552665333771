import React from 'react';

class DemographicsSectionUpdate extends React.Component {
  static defaultProps = {
    demographics_section: null,
    demograhpics_item_access_name: null,
    person_fk: null,
    school_year: null,
    button_message: null,
    button_label: null,
    button_enabled: false,
    confirmed: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      disableSubmission: !this.props.button_enabled,
      toggleChecked: this.props.confirmed,
      sectionComplete: this.props.confirmed,
      errorMessage: false,
    };
  }

  toggleChange = (e) => {
    this.setState({ toggleChecked: e.target.checked });
  };

  buttonClick = (e) => {
    if (this.state.toggleChecked && !this.state.sectionComplete) {
      this.setState({ sectionComplete: true }, this.completeSection);
    }
  };

  completeSection() {
    setTimeout('', 500);
    $.ajax({
      method: 'POST',
      url: Routes.admissions_demographics_update_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
        section: this.props.demographics_section,
      }),
      data: {
        access_name: this.props.demograhpics_item_access_name,
        person_fk: this.props.person_fk,
        school_year: this.props.school_year,
      },
    })
      .then(() => {
        this.setState({ disableSubmission: true });
      })
      .fail((err) => {
        this.setState({ errorMessage: true });
      });
  }

  render() {
    return (
      <li className="vx-list__item vx-List_Item--no-border">
        <div className="vx-message-banner page-confirmation-banner">
          <h6>{this.props.button_message}</h6>
          <label className="vx-form-control vx-switch">
            <input
              className="vx-switch__input"
              type="checkbox"
              id="section-confirm-switch"
              defaultChecked={this.state.toggleChecked}
              onClick={this.toggleChange}
              disabled={this.state.disableSubmission}
            />
            <div className="vx-switch__slider">
              <span className="vx-switch__text-on">YES</span>
              <span className="vx-switch__text-off">NO</span>
            </div>
          </label>
          <button
            className={`vx-button ${this.state.sectionComplete && ' vx-button--success'}`}
            name="section-confirm-button"
            onClick={this.buttonClick}
            disabled={this.state.disableSubmission}
          >
            {this.state.sectionComplete ? 'Completed' : this.props.button_label}
          </button>
        </div>
        {this.state.errorMessage && (
          <div
            className="vx-badge vx-badge--red"
            id="errors"
          >
            There was an error completing this section.
          </div>
        )}
      </li>
    );
  }
}

export default DemographicsSectionUpdate;
