require('../portal-component');

window.Portal.Heading.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.componentDoneLoading();
  },

  computed: {
    styles: function () {
      var styles = {
        'text-align': this.get('configuration.display'),
        border: 'none', // override for box styling defaults
        'border-bottom':
          this.get('configuration.styles.borderWidth') +
          'px ' +
          this.get('configuration.styles.borderStyle') +
          ' ' +
          this.get('configuration.styles.borderColor'),
      };

      return _.map(styles, function (value, key) {
        return key + ':' + value;
      }).join('; ');
    },
  },
});
