// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`CheckboxField should display blank value if data is blank 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control"
  >
    <div
      class="plaintext-data"
      data-testid="plain-text-field"
    />
  </div>
</div>
`;

exports[`CheckboxField should display blank value if no boxes were checked 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control"
  >
    <div
      class="plaintext-data"
      data-testid="plain-text-field"
    />
  </div>
</div>
`;

exports[`CheckboxField should display correctly when there's an error 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label vx-form-label--required vx-form-label--error"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions vx-form-label-instructions--error"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control"
  >
    <div
      class="vx-checkbox-group ae-grid"
    >
      <div
        class="ae-grid__item item-md-4 item-sm-6"
      >
        <label
          class="vx-checkbox-group__item"
        >
          <input
            class="vx-checkbox-input"
            name="section-888#0__header"
            type="checkbox"
            value="1"
          />
          <span
            class="vx-checkbox-group__label"
            data-option-value="1"
          >
            One
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-md-4 item-sm-6"
      >
        <label
          class="vx-checkbox-group__item"
        >
          <input
            class="vx-checkbox-input"
            name="section-888#0__header"
            type="checkbox"
            value="2"
          />
          <span
            class="vx-checkbox-group__label"
            data-option-value="2"
          >
            Two
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-md-4 item-sm-6"
      >
        <label
          class="vx-checkbox-group__item"
        >
          <input
            class="vx-checkbox-input"
            name="section-888#0__header"
            type="checkbox"
            value="3"
          />
          <span
            class="vx-checkbox-group__label"
            data-option-value="3"
          >
            Three
          </span>
        </label>
      </div>
    </div>
  </div>
  <div
    class="vx-validation-message vx-validation-message--display"
    data-testid="vx-error-message"
  >
    You must select at least one from these values
  </div>
</div>
`;

exports[`CheckboxField should display plaintext if readonly 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control"
  >
    <div
      class="plaintext-data"
      data-testid="plain-text-field"
    >
      One, Three
    </div>
  </div>
</div>
`;

exports[`CheckboxField should not display help text if it's not there 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-control"
  >
    <div
      class="vx-checkbox-group ae-grid"
    >
      <div
        class="ae-grid__item item-md-4 item-sm-6"
      >
        <label
          class="vx-checkbox-group__item"
        >
          <input
            class="vx-checkbox-input"
            name="section-888#0__header"
            type="checkbox"
            value="1"
          />
          <span
            class="vx-checkbox-group__label"
            data-option-value="1"
          >
            One
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-md-4 item-sm-6"
      >
        <label
          class="vx-checkbox-group__item"
        >
          <input
            class="vx-checkbox-input"
            name="section-888#0__header"
            type="checkbox"
            value="2"
          />
          <span
            class="vx-checkbox-group__label"
            data-option-value="2"
          >
            Two
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-md-4 item-sm-6"
      >
        <label
          class="vx-checkbox-group__item"
        >
          <input
            class="vx-checkbox-input"
            name="section-888#0__header"
            type="checkbox"
            value="3"
          />
          <span
            class="vx-checkbox-group__label"
            data-option-value="3"
          >
            Three
          </span>
        </label>
      </div>
    </div>
  </div>
</div>
`;

exports[`CheckboxField should render correctly 1`] = `
<div
  data-testid="web-form-field-test"
>
  <label
    class="vx-form-label"
    data-testid="vx-label"
    for="section-888#0__header"
  >
    A basic element
  </label>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    A help text
  </div>
  <div
    class="vx-form-control"
  >
    <div
      class="vx-checkbox-group ae-grid"
    >
      <div
        class="ae-grid__item item-md-4 item-sm-6"
      >
        <label
          class="vx-checkbox-group__item"
        >
          <input
            class="vx-checkbox-input"
            name="section-888#0__header"
            type="checkbox"
            value="1"
          />
          <span
            class="vx-checkbox-group__label"
            data-option-value="1"
          >
            One
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-md-4 item-sm-6"
      >
        <label
          class="vx-checkbox-group__item"
        >
          <input
            class="vx-checkbox-input"
            name="section-888#0__header"
            type="checkbox"
            value="2"
          />
          <span
            class="vx-checkbox-group__label"
            data-option-value="2"
          >
            Two
          </span>
        </label>
      </div>
      <div
        class="ae-grid__item item-md-4 item-sm-6"
      >
        <label
          class="vx-checkbox-group__item"
        >
          <input
            class="vx-checkbox-input"
            name="section-888#0__header"
            type="checkbox"
            value="3"
          />
          <span
            class="vx-checkbox-group__label"
            data-option-value="3"
          >
            Three
          </span>
        </label>
      </div>
    </div>
  </div>
</div>
`;
