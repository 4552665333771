import React from 'react';
import Sidebar from './Sidebar.js';
import AssignmentDetails from './AssignmentDetails.js';
import ErrorBoundary from '../../ErrorBoundary.js';

const EmptyAssignments = () => {
  return (
    <div className="vx-empty-state-read-only">
      <div className="vx-empty-state__icon">
        <i className="nc-icon-outline ui-1_zoom-split"></i>
      </div>
      <h1>No Outstanding Assignments</h1>
    </div>
  );
};

const AssignmentSubmissionWithEmpty = ({ ...props }) => {
  if (props.assignments.length) {
    return (
      <ErrorBoundary>
        <AssignmentSubmission {...props} />
      </ErrorBoundary>
    );
  }
  return <EmptyAssignments />;
};

class AssignmentSubmission extends React.Component {
  constructor(props) {
    super(props);

    let defaultActiveAssignment = this.props.assignments.find(
      (assignment) => assignment.default_active === true
    );
    const activeAssignmentId = defaultActiveAssignment
      ? defaultActiveAssignment.class_assignment_person_pk
      : 0;

    let assignmentStatusToggleValues = this.props.assignments.map((assignment) => {
      return {
        id: assignment.class_assignment_person_pk,
        status: assignment.assignment_submission_status,
      };
    });

    this.state = {
      activeAssignmentId: activeAssignmentId,
      assignments: this.props.assignments,
      windowHeightExpanded: false,
      assignmentStatusToggleValues: assignmentStatusToggleValues,
    };
  }

  onUpdateActiveAssignmentId = (newActiveAssignmentId) => {
    this.setState({ activeAssignmentId: newActiveAssignmentId });
  };

  activeAssignment = () => {
    return this.state.assignments.find(
      (assignment) => assignment.class_assignment_person_pk === this.state.activeAssignmentId
    );
  };

  // meant to reflect the actual status value as in the database
  onUpdateAssignmentStatus = (id, status) => {
    let updatedAssignments = this.state.assignments.map((assignment) => {
      if (assignment.class_assignment_person_pk === id) {
        assignment.assignment_submission_status = status;
      }
      return assignment;
    });

    this.setState({ assignments: updatedAssignments });
  };

  activeAssignmentStatusToggle = () => {
    let toggle = this.state.assignmentStatusToggleValues.find(
      (toggle) => toggle.id === this.state.activeAssignmentId
    );
    return toggle.status;
  };

  // reflects the current assignment status of the yes/no toggle
  // has not necessarily been posted to the database yet
  onUpdateAssignmentStatusToggle = (id, status) => {
    let assignmentStatusToggleValues = this.state.assignmentStatusToggleValues.map((value) => {
      if (value.id === id) {
        value.status = status;
      }
      return value;
    });

    this.setState({
      assignmentStatusToggleValues: assignmentStatusToggleValues,
    });
  };

  onToggleAttachFilePicker = () => {
    this.setState((prevState) => ({
      windowHeightExpanded: !prevState.windowHeightExpanded,
    }));
  };

  render() {
    return (
      <div className="assignment-submission">
        <div className="ae-grid ae-grid--collapse">
          <div className="ae-grid__item--collapse item-xs-12">
            <div className="vx-record-header">
              <h2 className="vx-record_header__title text_align-left">Assignment Submission</h2>
              <div className="vx-subtitle">Select an Assignment Below</div>
            </div>
          </div>
          <Sidebar
            assignments={this.state.assignments}
            activeAssignmentId={this.state.activeAssignmentId}
            updateActiveAssignmentId={this.onUpdateActiveAssignmentId}
            windowHeightExpanded={this.state.windowHeightExpanded}
          />
          <AssignmentDetails
            assignment={this.activeAssignment()}
            toggleStatus={this.activeAssignmentStatusToggle()}
            uploaderOptions={this.props.uploaderOptions}
            updateAssignmentStatus={this.onUpdateAssignmentStatus}
            updateAssignmentStatusToggle={this.onUpdateAssignmentStatusToggle}
            windowHeightExpanded={this.state.windowHeightExpanded}
            toggleAttachFilePicker={this.onToggleAttachFilePicker}
          />
        </div>
      </div>
    );
  }
}

export default AssignmentSubmissionWithEmpty;
