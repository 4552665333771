require('../portal-component');

window.Portal.Iframely.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.componentDoneLoading();

    if (this.get('admin')) {
      this.observe('configuration.url', this.refreshIframely, {
        init: false,
        defer: true,
      });
    }
  },

  onrender: function () {
    iframely.load(this.find('a'));
  },

  refreshIframely: function (newValue, oldValue, keypath) {
    $(this.find('.component-iframely')).children().not('a').remove();
    $(this.find('a')).removeAttr('data-iframely-loading');
    iframely.load(this.find('a'));
  },
});
