Routes = {
  class_admin_photos_index_path: jest
    .fn()
    .mockImplementation(({ internal_class_id }) => `/class/${internal_class_id}/photos`),
  class_admin_photos_gallery_path: jest
    .fn()
    .mockImplementation(
      ({ internal_class_id, gallery_id }) => `class/${internal_class_id}/photos/${gallery_id}`
    ),
};

module.exports = Routes;
