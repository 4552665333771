import React, { useEffect, useReducer } from 'react';
import ConferencesList from './ConferencesList';
import MySchedule from './MySchedule';
import {
  getSchedule,
  makeRequest,
  getTimeConflicts,
  reducer,
  isAttendanceOptionAvailable,
} from './helpers';
import {
  FAILED,
  REMOVE_CONFERENCE_SELECTION,
  UPDATE_TEACHER_SLOTS,
  SUCCESS,
  OUTDATED,
  UNAVAILABLE,
  CHANGE_CONFERENCE_LOCATION,
} from './constants';

const PORTALS_REQUEST_URL_PARAMS = {
  client: Portals.config.client,
  portal_name: Portals.config.portal_name,
};

function parseMaxSelections(maxSelectionsString) {
  if (isNaN(maxSelectionsString) || maxSelectionsString === null) {
    return 0; // unlimited selections
  }
  return parseInt(maxSelectionsString, 10);
}

const ConferencesPage = ({
  conferenceUrl,
  students,
  authToken,
  maxTeachersPerStudent,
  instructionText,
  dateFormat,
  timeFormat,
  allowConflicts,
  isReadOnly,
}) => {
  const [conferencesState, dispatch] = useReducer(reducer, {
    students,
    hasAttendanceOption: isAttendanceOptionAvailable(students),
  });

  if (!conferencesState.students.length) {
    return (
      <>
        <div className="conferencesPage-container noStudent-container">
          <div className="ae-grid conferencesPage-header">
            <div className="ae-grid__item item-md-12 conferencesPage-instructions">
              <h4 className="vx-heading-4">You have no children eligible for conferences</h4>
            </div>
          </div>
        </div>
      </>
    );
  }

  const schedule = getSchedule(conferencesState.students);
  const timeConflicts = getTimeConflicts(schedule);
  const maxSelections = parseMaxSelections(maxTeachersPerStudent);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 640);

  // Used for removal animation, needs to update all lists
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSelectionChange = async (studentKey, teacherKey, newConfKey, prevConfKey) => {
    if (prevConfKey === newConfKey) {
      // nothing changed
      return;
    }
    const data = { studentKey, teacherKey, newConfKey, prevConfKey };
    const request = getRequestData(data);
    if (prevConfKey && !newConfKey) {
      const response = await removeSelection(data, request);
      return response;
    }

    const response = await updateSelection(data, request);
    return response;
  };

  const removeSelection = async (data, request) => {
    const responseUpdate = await makeRequest(request.updateUrl, 'PUT', authToken, request.body);
    if (responseUpdate && responseUpdate.status === 'success') {
      dispatch({ type: REMOVE_CONFERENCE_SELECTION, data });
      return SUCCESS;
    }
    return responseUpdate?.message || FAILED;
  };

  const toggleLocation = async (conference, location) => {
    const updateUrl = Routes.teacher_conferences_update_conference_location_path({
      ...PORTALS_REQUEST_URL_PARAMS,
    });
    const data = {
      student_id: conference.studentId,
      teacher_id: conference.teacherId,
      event_fk: conference.event_fk,
      location,
    };
    const responseUpdate = await makeRequest(updateUrl, 'PUT', authToken, data);
    if (responseUpdate && responseUpdate.status === 'success') {
      dispatch({ type: CHANGE_CONFERENCE_LOCATION, data });
    }
    return responseUpdate?.status;
  };

  const getRequestData = (data) => {
    const refreshUrl = Routes.teacher_conferences_slots_data_path({
      ...PORTALS_REQUEST_URL_PARAMS,
      student_id: data.studentKey,
      teacher_id: data.teacherKey,
    });
    const updateUrl = Routes.teacher_conferences_update_conference_slot_path({
      ...PORTALS_REQUEST_URL_PARAMS,
    });
    const body = {
      existing_event_id: data.prevConfKey,
      new_event_id: data.newConfKey,
      student_id: data.studentKey,
      teacher_id: data.teacherKey,
    };

    return {
      body,
      updateUrl,
      refreshUrl,
    };
  };

  const updateSelection = async (data, request) => {
    const responseUpdate = await makeRequest(request.updateUrl, 'PUT', authToken, request.body);
    if (!responseUpdate || !responseUpdate.status || responseUpdate.status === FAILED) {
      return responseUpdate?.message || FAILED;
    }

    let status = responseUpdate.status;
    data.newConferences = responseUpdate.data;
    if (status !== SUCCESS) {
      const attemptedSelection = data.newConfKey;
      // set to null to not select a new conference
      data.newConfKey = null;
      if (status === UNAVAILABLE) {
        const selectedConf = data.newConferences.find((conference) => conference.selected);
        if (selectedConf && selectedConf.event_fk === attemptedSelection) {
          // another household member selected the same conference already, don't show error
          status = SUCCESS;
        } else if (selectedConf && selectedConf.event_fk !== data.prevConfKey) {
          // another household member selected a different conference
          status = OUTDATED;
        }
      }
    }
    dispatch({ type: UPDATE_TEACHER_SLOTS, data });
    return status;
  };

  return (
    <>
      <div className="conferences-nav">
        <h1 className="conferences-heading">Teacher Conferences</h1>
      </div>
      <div className="conferencesPage-container">
        <div className="ae-grid conferencesPage-header">
          <div className="ae-grid__item item-md-12 conferencesPage-instructions">
            <h4 className="vx-heading-4">Instructions</h4>
            <div dangerouslySetInnerHTML={{ __html: instructionText }}></div>
          </div>
        </div>
        <div className="ae-grid conferencesPage-content">
          <div className="ae-grid__item item-md-4 item-md--end">
            <MySchedule
              conferenceUrl={conferenceUrl}
              schedule={schedule}
              timeConflicts={timeConflicts}
              dateFormat={dateFormat}
              timeFormat={timeFormat}
              forceUpdate={forceUpdate}
            />
          </div>
          <div className="ae-grid__item item-md-8">
            <ConferencesList
              students={conferencesState.students}
              handleSelectionChange={handleSelectionChange}
              toggleLocation={toggleLocation}
              schedule={schedule}
              timeConflicts={timeConflicts}
              maxSelections={maxSelections}
              dateFormat={dateFormat}
              timeFormat={timeFormat}
              allowConflicts={allowConflicts}
              isReadOnly={isReadOnly}
              isMobile={isMobile}
              hasAttendanceOption={conferencesState.hasAttendanceOption}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConferencesPage;
