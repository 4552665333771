// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`DateOfBirthElement should render correctly when the component is "date_of_birth" 1`] = `
<div
  class="ae-grid"
  data-testid="date_of_birth-element"
>
  <div
    class="ae-grid__item item-sm-3"
  >
    <label
      class="vx-form-label"
      data-testid="vx-label"
      for="section-888#0__header"
    >
      A basic element
    </label>
    <div
      class="vx-form-label-instructions"
      data-testid="help-text"
    >
      A help text
    </div>
    <div
      class="vx-form-control vx-text-field"
    >
      <input
        class="vx-text-field__input"
        id="section-888#0__header"
        name="section-888#0__header"
        placeholder="MM/DD/YYYY"
        type="text"
      />
    </div>
  </div>
</div>
`;
