import React from 'react';
import VxLabel from './VxLabel';
import HelpText from './HelpText';
import PlainTextField from './PlainTextField';
import { FastField, useField, useFormikContext } from 'formik';
import VxErrorMessage from './VxErrorMessage';
import { ElementMetadata } from '../types';

const ToggleField = ({ element, metadata }: ElementMetadata) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(metadata.dataKeypath);
  const hasError = meta.touched && meta.error;

  return (
    <>
      <VxLabel
        element={element}
        htmlFor={metadata.dataKeypath}
        hasError={hasError}
      />
      <HelpText
        element={element}
        hasError={hasError}
      />
      <div className="vx-form-control">
        {metadata.isPlainText ? (
          <PlainTextField>
            {field.value === '1' || field.value === true ? 'Yes' : 'No'}
          </PlainTextField>
        ) : (
          <>
            <label className="vx-switch">
              <FastField
                name={metadata.dataKeypath}
                type="checkbox"
                className="vx-switch__input"
                disabled={isSubmitting}
              />
              <div className="vx-switch__slider">
                <span
                  data-option-value="on"
                  className="vx-switch__text-on"
                >
                  YES
                </span>
                <span
                  data-option-value="off"
                  className="vx-switch__text-off"
                >
                  NO
                </span>
              </div>
            </label>
            <VxErrorMessage
              element={element}
              metadata={metadata}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ToggleField;
