window.Control.GkcSelectControl = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
  },

  computed: {
    intial_multiple_value: function () {
      if (this.get('field')) {
        return _.compact(this.get('field').split(';'));
      }
      return [];
    },
  },

  oninit: function () {
    this.set('loading', true);
    this.on('_selectAll', this._selectAll);
  },

  onrender: function () {
    // provides good defaults and overrides if options are supplied
    var options = _.defaults(
      {
        placeholder: this.get('placeholder'),
        width: this.get('width'),
        minimumResultsForSearch: this.get('minimumResultsForSearch'),
        multiple: this.get('multiple'),
      },
      {
        placeholder: 'Select an option',
        width: '100%',
        minimumResultsForSearch: 10,
        multiple: true,
      }
    );

    this.set(options);

    $.ajax({
      type: 'GET',
      url: Routes.gkc_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
      }),
      data: {
        table: this.get('table'),
      },
      context: this,
    }).then(function (data) {
      this.set('loading', false);
      this.set('options', data);

      // make sure the value is initialized to the correct values
      if (this.get('multiple')) {
        $(this.find('.custom-select')).val(this.get('intial_multiple_value'));
      }

      $(this.find('.custom-select'))
        .select2(options)
        .on('change', _.bind(this.select2Changed, this));
    });
  },

  onteardown: function () {
    if (this.select2Exists()) {
      $(this.find('.custom-select')).select2('destroy');
    }
  },

  select2Exists: function () {
    return $(this.find('.custom-select')).data('select2');
  },

  select2Changed: function (e) {
    trace.log(e);
    if ($(e.currentTarget).val()) {
      if (this.get('multiple')) {
        this.set('field', $(e.currentTarget).val().join(';'));
      } else {
        this.set('field', $(e.currentTarget).val());
      }
    } else {
      this.set('field', null);
    }
  },

  _selectAll: function (e) {
    $(this.find('.custom-select')).find('option:enabled').prop('selected', true);
    // tell select2 to update
    $(this.find('.custom-select')).trigger('change');
    return false;
  },
});
