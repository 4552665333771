require('../portal-component');

window.Portal.AddBehaviorButton.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.on({
      _openPopup: this._openPopup,
    });
    this.componentDoneLoading();
  },

  _openPopup: function (e) {
    var url = [
      _.baseURL('portals_app'),
      'behavior',
      'new?type=' + this.get('configuration.type'),
    ].join('/');
    var top = screen.height / 2 - 600 / 2;
    var left = screen.width / 2 - 575 / 2;
    window.open(
      url,
      '_blank',
      'height=600,width=575,top=' + top + ',left=' + left + ',menubar=0,toolbar=0,resizable=0'
    );
    return false;
  },
});
