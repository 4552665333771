import React, { FC } from 'react';
import { WebFormField } from './MultiFieldElement';
import { ComponentElementMetadata } from '../types';

const EmailElement: FC<ComponentElementMetadata> = (props) => {
  return (
    <div
      className="ae-grid"
      data-testid="email-element"
    >
      <div className="ae-grid__item item-sm-6">
        <WebFormField {...props} />
      </div>
    </div>
  );
};

export default EmailElement;
