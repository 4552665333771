window.Control.RadioControl = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
  },

  computed: {
    all_icons: function () {
      return this.get('icon').split('|');
    },
  },
});
