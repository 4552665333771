window.WebForm.AdmissionEventOtherAttendees = function (webFormElementId) {
  const element = $('.attendees-other-' + webFormElementId);

  $('.add-attendee-link', element).click(function (el) {
    const next = $('.attendee-row-' + (parseInt($(this).data('attendee-record-num')) + 1));
    $(next).show();
    $('input', next).attr('disabled', false);
    $(this).parent().hide();
  });
};
