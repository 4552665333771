require('../portal-component');

window.Portal.NotificationLink.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    if (
      (this.get('configuration.data.filter.by') == 'grade_levels' &&
        this.get('configuration.data.filter.grade_levels')) ||
      (this.get('configuration.data.filter.by') == 'group' &&
        this.get('configuration.data.filter.group'))
    ) {
      this.load_data().then(this._dataLoaded);
    } else {
      this.componentDoneLoading();
    }
  },

  data: {
    loads_test_data: true,
  },

  _dataLoaded: function (data) {
    this.componentDoneLoading();

    // only show this component to the user if we're supposed to
    // based on filtering settings
    if (!data.display) {
      $(this.el).closest('.ae-grid__item').remove();
      this.teardown();
    }
  },
});
