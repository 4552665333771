import React, { FC } from 'react';
import { ComponentElementMetadata } from '../types';
import { WebFormField } from './MultiFieldElement';

const CheckboxesColumnsElement: FC<ComponentElementMetadata> = (props) => (
  <div
    className="ae-grid"
    data-testid="checkboxes_columns-element"
  >
    <div className="ae-grid__item item-sm-12">
      <WebFormField {...props} />
    </div>
  </div>
);

export default CheckboxesColumnsElement;
