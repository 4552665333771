require('./billing-preference');

window.Sys.BillingPreferences = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
    options.components = {
      billingPreference: Sys.BillingPreference,
    };
  },

  oninit: function () {
    trace.log();

    _.bindAllFunctions(this);
    this.set({
      billing_preference: this.billing_preference,
    });
  },

  // find the corresponding billing preference record and create one if it doesn't exist
  // no records will exist in the database if the user has not set up their preferences
  billing_preference: function (invoice_type_pk, person_pk) {
    var preference = _.find(this.get('billing_preferences'), {
      invoice_type_fk: invoice_type_pk,
      person_fk: person_pk,
    });
    if (!preference) {
      return {
        invoice_type_fk: invoice_type_pk,
        checkout_payment_account_fk: 0,
        household_fk: this.get('household_pk'),
        school_year_fk: this.get('school_year'),
        person_fk: person_pk,
      };
    }
    return preference;
  },
});
