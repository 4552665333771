// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly 1`] = `
Array [
  <div
    className="max-md:hidden h-full"
  >
    <div
      className=" md:w-fit md:mt-0 border-r border-gray-200 bg-gray-100 h-full md:rounded-l-2xl relative md:max-w-64 lg:w-64"
    >
      <div
        aria-label="Collapse sidebar"
        className="flex items-center justify-center border border-gray-500 hover:border-gray-600 hover:bg-gray-300 transition-colors rounded-full bg-white absolute w-6 h-6 top-[13px] -right-3 cursor-pointer z-50 vx-tooltipped vx-tooltipped--s"
        onClick={[Function]}
      >
        <i
          className="nc-icon-glyph font-black text-xs arrows-1_bold-left"
        />
      </div>
      <div
        className="w-full items-center"
      >
        <div
          className="float-left p-3 w-fit flex md:m-3 mb-0 bg-neutral-3 md:bg-gray-100 items-center text-neutral-6 md:text-gray-900 group font-medium rounded-md cursor-pointer"
          onClick={[Function]}
        >
          <div
            aria-label="Change class"
            className="vx-tooltipped vx-tooltipped--s self-start"
          >
            <i
              className="nc-icon-outline education_chalkboard text-neutral-6 md:text-gray-600 text-xl md:group-hover:text-black"
            />
          </div>
          <div
            className="ml-3 text-left leading-3 md:leading-5"
          >
            <div
              className="font-semibold text-neutral-6 md:text-black"
            >
              
            </div>
            <div
              className="text-xs pointer-events-auto text-neutral-6 md:text-gray-700 md:group-hover:text-black"
            >
              change class
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>,
  <div
    className="h-12 md:hidden fixed justify-between top-0 z-40 w-full bg-gray-600 text-white flex"
  >
    <div
      className="flex"
    >
      <i
        className="p-2 nc-icon-outline  text-2xl"
      />
    </div>
    <div
      className="flex-1 flex-col py-2 overflow-hidden"
    >
      <div
        className="text-xs leading-3 truncate"
      >
        
      </div>
      <div
        className="text-base leading-5 truncate"
      >
        
      </div>
    </div>
    <div
      className="flex"
    >
      <i
        className="p-2 nc-icon-glyph ui-2_menu-34 text-2xl cursor-pointer"
        onClick={[Function]}
      />
    </div>
  </div>,
]
`;
