require('../portal-component');
require('../../controls/load-more/load-more');

window.Portal.EventVerticalList.Component = PortalComponent.extend({
  onconstruct: function (options) {
    this._super(options);
    _.merge(options.components, {
      LoadMore: Control.LoadMore,
    });
  },

  computed: {
    grouped_events: function () {
      if (this.get('configuration.data.group_by') == 'month') {
        return _.groupBy(this.get('events'), 'month_long');
      } else if (this.get('configuration.data.group_by') == 'week') {
        return _.groupBy(this.get('events'), 'week');
      } else {
        return this.get('events');
      }
    },
  },

  oninit: function () {
    trace.log();
    this.reload();
  },

  reload: function () {
    trace.log();
    this.set('events', []);
    this.load_data().then(this._dataLoaded);
  },

  _dataLoaded: function (data) {
    this.set('events', _.concat(this.get('events'), data.events));
    this.componentDoneLoading();
  },
});
