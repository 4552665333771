window.Sys.CalendarEventRenderer = _.bindAllFunctions({
  render: function (event) {
    return {
      event_pk: event.id,
      record_identifier: event.record_identifier,
      record_type: event.record_type,
      title: this._eventDescription(event),
      start: this._eventStartDate(event),
      end: this._eventEndDate(event),
      url: this._eventUrl(event),
      className: '',
      allDay: this._eventIsAllDay(event),
      textColor: this._eventTextColor(event),
      backgroundColor: this._eventBackgroundColor(event),
      borderColor: 'transparent',
      tooltip: event.tooltip,
    };
  },

  _eventDescription: function (event) {
    var d = event.description;
    if (this._eventIsAssignment(event)) {
      d = '* ' + d;
    }
    return d;
  },

  _eventStartDate: function (event) {
    var d = event.start_date;
    if (!_.isNull(event.start_time)) {
      d += ' ' + event.start_time;
    }

    return d;
  },

  _eventEndDate: function (event) {
    var d = event.end_date;

    // add one day for all day events
    // fullcalendar.js v2 changed end dates to be exclusive. We use the same data as portals does
    // so don't want to update the sql procedures to handle this. Instead we update it here.
    if (this._eventIsAllDay(event)) {
      d = moment(event.end_date, 'MM/DD/YYYY').add(1, 'day').format('MM/DD/YYYY');
    }

    if (!_.isNull(event.end_time)) {
      d += ' ' + event.end_time;
    }

    return d;
  },

  _eventColor: function (event) {
    var c = event.link_style.match(/color:[ ]?([#\w\d]{1,10});?/);
    var color = c ? c[1] : '#000';

    return color;
  },

  _eventTextColor: function (event) {
    if (this._eventIsAllDay(event) && !this._eventIsAssignment(event)) {
      return '#fff';
    } else {
      return this._eventColor(event);
    }
  },

  _eventBackgroundColor: function (event) {
    if (this._eventIsAllDay(event) && !this._eventIsAssignment(event)) {
      return this._eventColor(event);
    } else {
      return 'transparent';
    }
  },

  _eventIsAllDay: function (event) {
    return event.start_time == null;
  },

  _eventIsAssignment: function (event) {
    return event.record_type == 3;
  },

  _eventUrl: function (event) {
    if (event.event_url) {
      switch (event.record_type) {
        case 1:
          return Routes.event_path({
            client: Portals.config.client,
            portal_name: Portals.config.portal_name,
            record_pk: event.id,
          });
        case 3:
          return Routes.assignment_path({
            client: Portals.config.client,
            portal_name: Portals.config.portal_name,
            record_pk: event.id,
          });
        default:
          return null;
      }
    }
    return null;
  },
});
