window.console = window.console || {
  log: function () {},
  info: function () {},
  warn: function () {},
  error: function () {},
};
window.trace =
  window.trace ||
  (function () {
    'use strict';

    var levels = {
      log: 4,
      info: 3,
      warn: 2,
      error: 1,
    };

    var fn = {
      4: console.log,
      3: console.info,
      2: console.warn,
      1: console.error,
    };

    var initialized_at = new Date();

    var active = true;
    var level = levels.log;

    //test cases: running in a window context (as the call below does), from chrome's dev tools, from an object member function, from an anonymous closure
    //we don't get the fully qualified function name in safari or IE, but it's still enough to be useful
    var stackRegex = {
      chrome:
        /(?:\s{3,4}at\s.*(?:log|info|warn|error|write) \(.*)\n(\s{3,4}at\s((?:\w|\.|<anonymous>|Global code|Anonymous function)*(?=\s\())?(?:\s\()?(http.*|<anonymous>):(\d+):(\d+))/,
      safari:
        /(?:(?:log|info|warn|error|write|getCallerInfo)@(?:http.*|<anonymous>):\d+:\d+\n){2}((\w+|global code)?@?(http.*|<anonymous>):(\d+):(\d+))/,
    };

    var browserStackRegex = stackRegex.chrome;
    if (
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1 &&
      navigator.userAgent.indexOf('Android') == -1
    ) {
      browserStackRegex = stackRegex.safari;
    }

    //cf. http://stackoverflow.com/a/2998874
    var pad = function (num, places) {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join('0') + num;
    };

    var getCallerInfo = function (level) {
      var e = new Error();

      if (e.stack) {
        var stack = e.stack;
      } else {
        try {
          throw new Error();
        } catch (e) {
          var stack = e.stack;
        }
      }

      var matches = stack.match(browserStackRegex) || new Array(6);

      var caller_info = {
        //name can possibly be undefined, when write() is called from the global context, ex: below
        name: (matches[2] || '').split('.').slice(-1)[0],
        full_name: matches[2] || '',

        file: matches[3],
        row: +matches[4],
        col: +matches[5],

        abs_t: new Date(),

        level: level,
      };

      caller_info.delta_t = new Date(caller_info.abs_t - initialized_at);
      caller_info.dt =
        pad(caller_info.delta_t.getMinutes(), 2) +
        ':' +
        pad(caller_info.delta_t.getSeconds(), 2) +
        ':' +
        pad(caller_info.delta_t.getMilliseconds(), 3);

      return caller_info;
    };

    var consoleEmitter = function () {
      if (!active || level < this.level) return;

      var args = ['<+' + this.dt + '>'];
      var no_caller =
        this.name == '<anonymous>' ||
        this.name == 'Global code' ||
        this.name == 'Anonymous function' ||
        this.name == '';

      if (!no_caller) {
        var obj = this.full_name.split('.').slice(1, 2);
        if (obj == 'window') {
          obj = this.full_name.split('.').slice(2, 3);
        }

        args.push('[' + (obj == this.name || obj == '' ? '' : obj + '.') + this.name + ']');
      }

      //turn array-like object into an actual array cf. http://stackoverflow.com/a/3865808
      fn[this.level].apply(console, args.concat(Array.prototype.slice.call(arguments)));

      return;
    };

    //call trace.setEmitter to create your own
    var emitter = consoleEmitter;

    return {
      log: function () {
        if (!active || level < levels.log) return;

        var caller = getCallerInfo(levels.log);
        emitter.apply(caller, arguments);
      },

      info: function () {
        if (!active || level < levels.info) return;

        var caller = getCallerInfo(levels.info);
        emitter.apply(caller, arguments);
      },

      warn: function () {
        if (!active || level < levels.warn) return;

        var caller = getCallerInfo(levels.warn);
        emitter.apply(caller, arguments);
      },

      error: function () {
        if (!active || level < levels.error) return;

        var caller = getCallerInfo(levels.error);
        emitter.apply(caller, arguments);
      },

      write: function () {
        var caller = getCallerInfo(levels.log);
        emitter.apply(caller, arguments);
      },

      setEmitter: function (fn) {
        emitter = fn;
      },

      activate: function () {
        active = true;
      },

      deactivate: function () {
        active = false;
      },

      setLevel: function (l) {
        level = l;
      },
    };
  })();

window.trace.log('Microtrace loaded...');
