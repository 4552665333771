function requireAll(r) {
  r.keys().forEach(r);
}
requireAll(require.context('../controls/', true, /\.js$/));

window.ComponentEditor = Ractive.extend({
  magic: true,

  onconstruct: function (options) {
    options.adapt = 'Ractive';
    options.components = {
      colorControl: Control.ColorControl,
      richTextControl: Control.RichTextControl,
      radioControl: Control.RadioControl,
      imageControl: Control.ImageControl,
      selectControl: Control.SelectControl,
      gkcSelectControl: Control.GkcSelectControl,
      rangeControl: Control.RangeControl,
      dateControl: Control.DateControl,
      toggleControl: Control.ToggleControl,
      iconPicker: Control.IconPicker,
    };
    options.partials = {
      article_data: require('./templates/article-data-partial.ractive'),
      event_data: require('./templates/event-data-partial.ractive'),
      box_styles: require('./templates/box-styles-partial.ractive'),
    };
  },

  data: {
    // Translate function for i18next
    t: (key) => i18next.t(key),
  },

  onrender: function () {
    const reloadKeypaths = this.get('component').get('reload_keypaths');
    if (reloadKeypaths) {
      const keypathArr = reloadKeypaths.split(' ');
      const configKeypaths = _.map(keypathArr, function (k) {
        return 'configuration.' + k;
      });
      this.observe(configKeypaths.join(' '), _.debounce(this.reloadKeypathUpdated, 500), {
        init: false,
      });
    }
    this.on('_fixWidth', this._fixWidth);
  },

  reloadKeypathUpdated: function (newValue, oldValue, keypath) {
    if (oldValue === undefined) return;
    this.get('component').reload();
  },

  _fixWidth: function (e) {
    this.set('component.width', '12');
    return false;
  },
});
