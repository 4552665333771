// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`WebFormSections and WebFormSection should render correctly 1`] = `
<div>
  <form
    action="#"
  >
    <div
      class="section-container"
      data-testid="general-111"
    >
      <div
        class="vx-field-group"
      >
        <div
          class="element-container"
          data-testid="web-form-element"
        >
          <div
            class="ae-grid"
            data-testid="header-element"
          >
            <div
              class="ae-grid__item item-sm-12"
            >
              <h2
                class="vx-heading-2"
              >
                One
              </h2>
            </div>
          </div>
        </div>
        <div
          class="element-container"
          data-testid="web-form-element"
        >
          <div
            class="ae-grid"
            data-testid="header-element"
          >
            <div
              class="ae-grid__item item-sm-12"
            >
              <h2
                class="vx-heading-2"
              >
                Two
              </h2>
            </div>
          </div>
        </div>
        <div
          class="element-container"
          data-testid="web-form-element"
        >
          <div
            class="ae-grid"
            data-testid="header-element"
          >
            <div
              class="ae-grid__item item-sm-12"
            >
              <h2
                class="vx-heading-2"
              >
                Three
              </h2>
            </div>
          </div>
        </div>
        <div
          class="element-container"
          data-testid="web-form-element"
        >
          <div
            class="ae-grid"
            data-testid="section_display-element"
          >
            <div
              class="ae-grid__item item-sm-12"
            >
              <label
                class="vx-form-label"
                data-testid="vx-label"
                for="general-111#0__five_hundred"
              >
                Five
              </label>
              <div
                class="vx-form-label-instructions"
                data-testid="help-text"
              >
                A help text
              </div>
              <div
                class="vx-form-control vx-radio-group"
              >
                <label
                  class="vx-radio-group__item"
                  data-testid="general-111#0__five_hundred_0"
                  for="general-111#0__five_hundred_0"
                >
                  <input
                    class="vx-radio-input"
                    id="general-111#0__five_hundred_0"
                    name="general-111#0__five_hundred"
                    type="radio"
                    value="skip"
                  />
                  <span
                    class="vx-radio-group__label"
                    data-option-value="Hide section general-222"
                  >
                    Hide section general-222
                  </span>
                </label>
                <label
                  class="vx-radio-group__item"
                  data-testid="general-111#0__five_hundred_1"
                  for="general-111#0__five_hundred_1"
                >
                  <input
                    class="vx-radio-input"
                    id="general-111#0__five_hundred_1"
                    name="general-111#0__five_hundred"
                    type="radio"
                    value="general-222"
                  />
                  <span
                    class="vx-radio-group__label"
                    data-option-value="Show section general-222"
                  >
                    Show section general-222
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="section-container hidden"
      data-testid="general-222"
    >
      <div
        class="vx-field-group"
      >
        <div
          class="element-container"
          data-testid="web-form-element"
        >
          <div
            class="ae-grid"
            data-testid="header-element"
          >
            <div
              class="ae-grid__item item-sm-12"
            >
              <h2
                class="vx-heading-2"
              >
                This is an element in a hidden section.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
`;
