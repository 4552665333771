import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import DatePickerMonth from './DatePickerMonth';

// isOutsideRange prop expects a function to determine if a date is outside range
// for our purposes we want to allow dates in the past so all dates are within range
const DEFAULT_PLACEHOLDER_TEXT = 'Currently Attending';

const CurrentlyAttendingCheckbox = ({ checked, onChange, placeholderText }) => (
  <div className="vx-form-control">
    <label className="vx-checkbox-group__item">
      <input
        type={'checkbox'}
        className="vx-checkbox-input present-attending"
        checked={checked}
        onChange={onChange}
      />
      <span className="vx-checkbox-group__label">{placeholderText}</span>
    </label>
  </div>
);

const DatePicker = ({
  keypath,
  placeholderText,
  initialDate,
  disabled,
  isSchoolEndDate,
  dateFormat,
  minDate,
  maxDate,
}) => {
  minDate = moment(minDate, 'YYYY-MM-DD');
  maxDate = moment(maxDate, 'YYYY-MM-DD');
  const [focused, setFocused] = React.useState(false);
  const [isPresent, setIsPresent] = React.useState(!initialDate);
  const [date, setDate] = React.useState(() => {
    let theDate = initialDate;

    if (theDate !== null) {
      if (theDate.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
        theDate = moment(initialDate, dateFormat);
      } else {
        // better to have user re-enter in correct format then try to parse all other possible formats
        // previously used formats should have been converted in DB when this component was deployed
        theDate = null;
      }
    }

    return theDate;
  });
  const stillAttendingText = React.useMemo(() => placeholderText || DEFAULT_PLACEHOLDER_TEXT, []);

  React.useEffect(() => {
    const formField = window.WebForm.FormInstance.createFormField(keypath);
    window.WebForm.FormInstance.push('form_fields', formField);
  });

  const renderUndoButton = React.useMemo(() => {
    if (isSchoolEndDate) {
      return (
        <a
          className={'undo-present-attending'}
          onClick={() => {
            setIsPresent(true);
            setDate(null);
          }}
        >
          {stillAttendingText}
        </a>
      );
    }
  }, []);

  return (
    <>
      {/*
        Cases:
          1. Always display the Date Picker if keypath is not a school:end_date.
          2. Otherwise, display the Date Picker if the user unchecks the
             "Currently Attending?" checkbox.
      */}
      <div className={classnames({ hidden: isSchoolEndDate && isPresent })}>
        <SingleDatePicker
          date={date}
          focused={focused}
          id={keypath}
          isOutsideRange={(day) => day.isBefore(minDate) || day.isAfter(maxDate)}
          displayFormat={dateFormat}
          placeholder={placeholderText || dateFormat}
          noBorder={true}
          numberOfMonths={1}
          onDateChange={(date) => setDate(date)}
          onFocusChange={({ focused }) => setFocused(focused)}
          renderMonthElement={(props) => (
            <DatePickerMonth
              date={props.month}
              onMonthSelect={props.onMonthSelect}
              onYearSelect={props.onYearSelect}
              minDate={minDate}
              maxDate={maxDate}
            />
          )}
          disabled={disabled}
        />
        <i
          onClick={() => setFocused(true)}
          className="nc-icon-outline ui-1_calendar-grid-61 vx-date-field__icon"
        />
        {renderUndoButton}
      </div>

      {/* Show "Currently Attending" checkbox if it's a "School End Date" */}
      {isSchoolEndDate && isPresent && (
        <CurrentlyAttendingCheckbox
          checked={isPresent}
          onChange={(e) => {
            setIsPresent(e.target.checked);
            setFocused(true);
          }}
          placeholderText={stillAttendingText}
        />
      )}
    </>
  );
};

DatePicker.propTypes = {
  keypath: PropTypes.string.isRequired,
  placeholderText: PropTypes.string,
  disabled: PropTypes.bool,
  initialDate: PropTypes.instanceOf(Date),
  isSchoolEndDate: PropTypes.bool,
  dateFormat: PropTypes.string,
  minDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
};

DatePicker.defaultProps = {
  initialDate: null,
  disabled: false,
  required: false,
  isSchoolEndDate: false,
  dateFormat: 'MM/DD/YYYY',
  minDate: null,
  maxDate: null,
};

export default DatePicker;
