import * as Yup from 'yup';
import { getAllElements } from './form-helpers';
import { getFieldConfiguration, getFieldKeypath, isFieldRequired } from './element-helpers';
import { ElementProps, FormType, SubmissionInfo } from '../types';
import { is } from 'immer/dist/internal';
import { filter } from 'lodash';

type FieldYupSchema = {
  element: ElementProps;
  formMode: string;
  operationType: string;
  sectionDisplayMap: {};
  submission: SubmissionInfo;
};
type ValidationSchema = {
  form: FormType;
  submission?: SubmissionInfo;
  sectionDisplayMap?: {};
  operationType?: string;
};

export const ERROR_INVALID_TYPE = 'Invalid type.';
export const ERROR_MESSAGE_REQUIRED = 'Required.';
export const ERROR_MESSAGE_AT_LEAST_ONE = 'You must select at least one from these values';
export const ERROR_MESSAGE_DATE_FORMAT =
  'Please enter a date in the format M/D/YYYY, ex. 10/20/2000';

export const ERROR_MESSAGE_PARENTAL_RELATIONSHIP =
  'You must select a parental relationship for at least one of the candidates.';

export const COMPONENTS_TO_IGNORE_IN_INITIAL_VALUES = new Set([
  'header',
  'paragraph',
  'response_grid',
  'relationship',
]);

const DEFAULT_REQUIREMENT = () => Yup.string().required(ERROR_MESSAGE_REQUIRED);

const DEFAULT_YUP = () => Yup.string();

const COMPONENT_TYPE = {
  checkboxes_columns: () => Yup.array(Yup.string()),
  data_consent_policy_checkbox: () => Yup.array(Yup.string()),
  date_picker: () => Yup.string(),
  number: () => Yup.number(),
  profile_code_multiple: (element: ElementProps) => {
    const config = getFieldConfiguration(element, 'select_multiple');
    const useCheckboxes = config === 'true';
    return useCheckboxes ? Yup.array().of(Yup.string()) : Yup.string();
  },
  radio: () => Yup.string(),
  select: () => Yup.string(),
  textbox: () => Yup.string(),
  textarea: () => Yup.string(),
  toggle: () => Yup.boolean(),
};

const COMPONENT_REQUIREMENT_MAP = {
  checkboxes_columns: (element: ElementProps) =>
    Yup.array()
      .of(
        Yup.string().oneOf(
          element.element_value_list_items.map((v) => v.value),
          ERROR_MESSAGE_AT_LEAST_ONE
        )
      )
      .min(1, ERROR_MESSAGE_AT_LEAST_ONE)
      .required(ERROR_MESSAGE_REQUIRED),
  date_picker: () =>
    Yup.string()
      .matches(/^\d{1,2}\/\d{1,2}\/\d{4}$/, ERROR_MESSAGE_DATE_FORMAT)
      .required(ERROR_MESSAGE_DATE_FORMAT),
  number: () =>
    Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(ERROR_MESSAGE_REQUIRED),
  radio: (element: ElementProps) =>
    Yup.string()
      .oneOf(
        element.element_value_list_items.map((v) => v.value),
        ERROR_MESSAGE_AT_LEAST_ONE
      )
      .required(ERROR_MESSAGE_REQUIRED),
  select: (element: ElementProps) =>
    Yup.string()
      .oneOf(
        element.element_value_list_items.map((v) => v.value),
        ERROR_MESSAGE_AT_LEAST_ONE
      )
      .required(ERROR_MESSAGE_REQUIRED),
  profile_code_single: (element: ElementProps) => {
    return Yup.string()
      .oneOf(
        element.element_value_list_items.map((v) => v.value),
        ERROR_MESSAGE_AT_LEAST_ONE
      )
      .required(ERROR_MESSAGE_REQUIRED);
  },
  profile_code_multiple: (element: ElementProps) => {
    const config = getFieldConfiguration(element, 'select_multiple');
    const useCheckboxes = config === 'true';

    if (useCheckboxes) {
      return Yup.array()
        .of(
          Yup.string().oneOf(
            element.element_value_list_items.map((v) => v.value),
            ERROR_MESSAGE_AT_LEAST_ONE
          )
        )
        .min(1, ERROR_MESSAGE_AT_LEAST_ONE)
        .typeError(ERROR_INVALID_TYPE)
        .required(ERROR_MESSAGE_REQUIRED);
    }

    return Yup.string()
      .oneOf(
        element.element_value_list_items.map((v) => v.value),
        ERROR_MESSAGE_AT_LEAST_ONE
      )
      .required(ERROR_MESSAGE_REQUIRED);
  },
  relationship: (_element: ElementProps) => {
    return Yup.number();
  },
  response_grid_question: (element: ElementProps) =>
    Yup.string()
      .oneOf(
        element.element_value_list_items.map((v) => v.value),
        ERROR_MESSAGE_AT_LEAST_ONE
      )
      .required(ERROR_MESSAGE_REQUIRED),
  section_display: (element: ElementProps) =>
    Yup.string()
      .oneOf(
        element.element_value_list_items.map((v) => v.value),
        ERROR_MESSAGE_AT_LEAST_ONE
      )
      .required(ERROR_MESSAGE_REQUIRED),
  toggle: () =>
    Yup.boolean()
      .transform((value) => (typeof value !== 'boolean' ? undefined : value))
      .required(ERROR_MESSAGE_REQUIRED),
  textbox: () => Yup.string().required(ERROR_MESSAGE_REQUIRED),
  textarea: () => Yup.string().required(ERROR_MESSAGE_REQUIRED),
};

const getFieldYupSchema = ({
  element,
  formMode,
  operationType,
  sectionDisplayMap,
  submission,
}: FieldYupSchema) => {
  const componentSchema = COMPONENT_TYPE[element.component] || DEFAULT_YUP;
  let yupSchema = componentSchema(element);

  if (isFieldRequired(element, formMode, operationType, submission)) {
    const createYupRequirement =
      COMPONENT_REQUIREMENT_MAP[element.component] || DEFAULT_REQUIREMENT;
    yupSchema = createYupRequirement(element);

    const prefix = sectionDisplayMap[element.section_data_keypath];
    if (prefix) {
      yupSchema = yupSchema.when(prefix, {
        is: element.section_data_keypath,
        then: createYupRequirement(element),
      });
    }
  }

  return yupSchema;
};

// generates both the relationship validation rules and dependency array.
export const getRelationshipValidation = (form: FormType) => {
  const relationshipElement = getAllElements(form).find(
    (elm) => elm.data_keypath === 'relationships'
  );

  const people = relationshipElement.element_value_list_items.filter(
    (item) => item.table_name === 'related_people'
  );

  const dependencies = [];

  const candidates = people.filter((person) => person.custom_data.is_candidate);

  // at least one of the relationships must be one of: son,
  // daugther, child, step-son, step-daughter, step-child, ward, admissions agent client
  const validParentRelationships = [6, 7, 601, 34, 35, 606, 45, 68];
  const rules: { [key: string]: any } = candidates.reduce((validations, person) => {
    const relationshipNames = candidates
      .filter((per) => per.value !== person.value)
      .map((per) => getFieldKeypath(relationshipElement, per.value));

    relationshipNames.forEach((name) => {
      dependencies.push([name, getFieldKeypath(relationshipElement, person.value)]);
    });

    return {
      ...validations,
      [getFieldKeypath(relationshipElement, person.value)]: Yup.number().when(relationshipNames, {
        is: (...args) =>
          args.reduce((names, name) => names && !validParentRelationships.includes(name), true),
        then: Yup.number()
          .required(ERROR_MESSAGE_PARENTAL_RELATIONSHIP)
          .oneOf(validParentRelationships, ERROR_MESSAGE_PARENTAL_RELATIONSHIP),
      }),
    };
  }, {});

  return { rules, dependencies };
};

const createValidationSchema = ({
  form,
  submission,
  sectionDisplayMap = {},
  operationType = '1',
}: ValidationSchema) => {
  const formMode = form.mode;
  const isParentForm = form.form_type_system_name === 'parent';
  const filterFunction = (e) => !COMPONENTS_TO_IGNORE_IN_INITIAL_VALUES.has(e.component);

  let relationshipValidation: { rules: { [key: string]: any }; dependencies: [string, string][] } =
    { rules: {}, dependencies: [] };
  if (isParentForm) {
    relationshipValidation = getRelationshipValidation(form);
  }

  return Yup.object().shape(
    {
      ...getAllElements(form)
        .filter(filterFunction)
        .reduce(
          (memo, element) => ({
            ...memo,
            [getFieldKeypath(element)]: getFieldYupSchema({
              element,
              formMode,
              operationType,
              sectionDisplayMap,
              submission,
            }),
          }),
          {}
        ),
      ...relationshipValidation.rules,
    },
    [...relationshipValidation.dependencies]
  );
};

export default createValidationSchema;
