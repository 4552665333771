import React from 'react';

import { Button } from 'components/shared/form_elements';
import OpportunityHistoryItem from './OpportunityHistoryItem';

export default function OpportunityHistory(props) {
  const { data, isActiveTab } = props;

  const INITIAL_PAGE = 1;
  const CARDS_PER_PAGE = 10;

  const [opportunityHistoryItems, setOpportunityHistoryItems] = React.useState([]);
  const hasOpportunityHistoryItems = opportunityHistoryItems.length > 0;
  const addOpportunityHistoryItems = (v) =>
    setOpportunityHistoryItems((values) => values.concat(v));

  const [currentPage, setCurrentPage] = React.useState(INITIAL_PAGE);
  const [loadingState, setLoadingState] = React.useState('LOADING');
  const [canLoadMoreHistoryItems, setCanLoadMoreHistoryItems] = React.useState(false);

  /***************************************************************************************************
   ** Request's helpers
   ***************************************************************************************************/

  const REQUEST_HEADERS = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache, no-store',
  };

  const PORTALS_REQUEST_URL_PARAMS = {
    client: Portals.config.client,
    portal_name: Portals.config.portal_name,
  };

  /***************************************************************************************************
   ** REQUEST: GET solicitor-constituents/:id/history
   ***************************************************************************************************/

  const sendGetHistoryItems = async (params) => {
    const url = Routes.solicitor_constituents_opportunity_history_items_path(data.opportunity_id, {
      ...PORTALS_REQUEST_URL_PARAMS,
      ...params,
    });

    const response = await fetch(url, {
      method: 'GET',
      headers: REQUEST_HEADERS,
    });

    const responseBody = await response.json();

    if (response.ok) {
      return responseBody;
    } else {
      throw new Error(responseBody.status);
    }
  };

  // Get initial history items
  const getHistoryItems = async (page = INITIAL_PAGE) => {
    const historyItems = await sendGetHistoryItems({ page: page })
      .then((response) => {
        return response.historyItems;
      })
      .catch((error) => {
        console.log('Error: ', error);
        return [];
      });
    setCanLoadMoreHistoryItems(historyItems.length > CARDS_PER_PAGE);
    return historyItems.slice(0, CARDS_PER_PAGE);
  };

  // Load more history items
  const handleLoadMoreHistoryItems = (event) => {
    setLoadingState('LOADING_MORE');
    const page = currentPage + 1;

    getHistoryItems(page).then((historyItems) => {
      setCurrentPage(page);
      addOpportunityHistoryItems(historyItems);
      setLoadingState('LOADED');
    });
  };

  // Initial load history items
  React.useEffect(() => {
    setLoadingState('LOADING');
    getHistoryItems().then((historyItems) => {
      setOpportunityHistoryItems(historyItems);
      setLoadingState('LOADED');
    });
  }, []);

  // HACK: Reload if tab had been switched.
  // Currently accomodates adding note and returning to this tab to see updated history items
  // 'LOADING' is not shown to minimize UX issues with reloading
  React.useEffect(() => {
    if (isActiveTab) {
      getHistoryItems().then((historyItems) => {
        setOpportunityHistoryItems(historyItems);
        setLoadingState('LOADED');
      });
    }
  }, [isActiveTab]);

  const showLoader = loadingState === 'LOADING';
  const Loader = (
    <div className="vx-form-loader">
      <div className="vx-form-loader__spinner vx-loader-spinner" />
      <div className="vx-form-loader__message">Loading...</div>
    </div>
  );

  const OpportunityHistoryItems = opportunityHistoryItems.map((historyItem, index) => (
    <OpportunityHistoryItem
      key={historyItem.id}
      data={historyItem}
      precedingItemDate={
        opportunityHistoryItems[index - 1] && opportunityHistoryItems[index - 1].date
      }
    />
  ));

  const showLoadMoreButton = canLoadMoreHistoryItems;
  const isLoadingMore = loadingState === 'LOADING_MORE';
  const loadMoreButtonText = isLoadingMore ? 'Loading...' : 'Load More';
  const LoadMoreButton = (
    <Button
      className="vx-button vx-button--primary load-more-button"
      disabled={isLoadingMore}
      onClick={handleLoadMoreHistoryItems}
      value={loadMoreButtonText}
    />
  );

  if (showLoader) {
    return Loader;
  } else if (hasOpportunityHistoryItems) {
    return (
      <div className="opportunity-history-scrollbox">
        <div className="opportunity-history">
          {OpportunityHistoryItems}
          {showLoadMoreButton && LoadMoreButton}
        </div>
      </div>
    );
  } else {
    return (
      <div className="vx-empty-state-read-only">
        <h4>No activity found</h4>
      </div>
    );
  }
}
