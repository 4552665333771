// TODO @ebauer - recommendation ractive object that handles the ajax
window.RegistrationSeason.Recommendation = Ractive.extend({
  oninit: function () {
    this.get('start_button_el').click(
      function (e) {
        e.preventDefault();
        this.get('checklist_el').addClass('recommendation-item_form');
        this.get('start_button_el').hide();
      }.bind(this)
    );

    var form_el = $(this.get('checklist_el'), 'form');
    form_el.submit(
      function (e) {
        e.preventDefault(); //prevent form from submitting
        var data = _.reduce(
          _.map($(':input', form_el).serializeArray(), function (d) {
            return { [d.name]: d.value };
          }),
          _.merge
        );
        this.submitForm(data);
      }.bind(this)
    );
  },

  submitForm: function (data) {
    $.ajax({
      type: 'POST',
      url: Routes.admissions_checklist_update_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
        access_name: this.get('access_name'),
        person_fk: this.get('person_fk'),
        school_year: this.get('school_year'),
        checklist_item_id: this.get('checklist_item'),
      }),
      data: {
        name: data.name,
        email_address: data.email_address,
        admission_recommendation_fk: this.get('admission_recommendation_fk'),
        web_form_submission_request_fk: this.get('web_form_submission_request_fk'),
      },
    })
      .then(
        function (res) {
          this.fire('success');
        }.bind(this)
      )
      .fail(
        function (error) {
          this.get('checklist_el').html(error.message).show();
        }.bind(this)
      );
  },
});
