import React from 'react';
import { useWebForm } from './WebFormContext';

const WebFormErrors = () => {
  const { errors } = useWebForm();

  if (errors.length === 0) {
    return null;
  }

  return (
    <div className="vx-form-error">
      <h4 className="vx-heading-4">
        <i className="nc-icon-glyph ui-2_alert-circle- vx-form-error__icon"></i> Please fix the
        following to continue
      </h4>
      <ul>
        {errors.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </div>
  );
};

export default WebFormErrors;
