window.Sys.StudentOverview = {
  init: function () {
    $('.class-tabs .vx-page-tabs__tab').click(function (e) {
      // toggle active state for tabs
      $('.class-tabs .vx-page-tabs__tab').removeClass('vx-page-tabs__tab--active');
      $(this).addClass('vx-page-tabs__tab--active');

      // show correct course list
      $('.course-list').hide();
      $('.course-list.' + $(this).text().trim().toLowerCase()).show();
    });
  },
};
