require('../portal-component');
require('../../controls/pagination/pagination');

window.Portal.ArticleHorizontalCardList.Component = PortalComponent.extend({
  onconstruct: function (options) {
    this._super(options);
    _.merge(options.components, {
      Pagination: Control.Pagination,
    });
  },

  oninit: function () {
    trace.log();
    this.reload();
  },

  reload: function () {
    trace.log();
    this.set('articles', []);
    this.findComponent('Pagination').set('page_num', 0);
    this.load_data().then(this._dataLoaded);
  },

  _dataLoaded: function (data) {
    this.set({
      articles: data.articles,
    });

    this.componentDoneLoading();
  },
});
