import React, { FC } from 'react';
import SelectField from '../fields/SelectField';
import { getFieldKeypath, getFieldMetadata } from '../utils/element-helpers';
import { useWebForm } from '../WebFormContext';
import { ElementProps } from '../types';

const RelationshipElement: FC<{ element: ElementProps }> = ({ element }) => {
  const { form, submission, initialValues } = useWebForm();

  const people = React.useMemo(
    () =>
      element.element_value_list_items.filter(
        (item) =>
          item.table_name === 'related_people' &&
          item.value !== initialValues[`system#0__person_fk`]
      ),
    [element]
  );
  const relatedPeople = people.map((person) => ({
    person,
    metadata: {
      ...getFieldMetadata({
        element,
        formMode: form.mode,
        operationType: submission.operation_type,
        submission,
      }),
      dataKeypath: getFieldKeypath(element, person.value),
    },
  }));

  const relationshipElement = {
    ...element,
    element_value_list_items: element.element_value_list_items.filter(
      (item) => item.table_name === 'zg_Person_Relationship'
    ),
  };

  return (
    <div
      className="ae-grid relationship-element"
      data-testid="relationship-element"
    >
      {relatedPeople.map(({ person, metadata }) => (
        <div
          key={person.value}
          className="ae-grid__item item-sm-6"
        >
          <div className="vx-card">
            <div className="vx-card__title vx-card__title--no-border">
              <span
                className="vx-avatar relationship-image"
                title={person.description}
                style={{ backgroundImage: `url(${person.custom_data.photo_url})` }}
              ></span>
              <h4 className="relationship-header">
                <span className="name">{person.description}</span> is this person&apos;s
              </h4>
              <div className="vx-subtitle">
                <SelectField
                  element={relationshipElement}
                  metadata={metadata}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RelationshipElement;
