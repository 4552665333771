import React, { FC } from 'react';
import { ComponentElementMetadata } from '../types';
import { WebFormField } from './MultiFieldElement';

const CreateAccount: FC<ComponentElementMetadata> = (props) => {
  const CREATE_ACCOUNT_YES_NO = {
    '0': 'No, do not create account',
    '1': 'Yes, create account',
  };
  const valueListItems = props.element.element_value_list_items.map((item) => ({
    ...item,
    description: CREATE_ACCOUNT_YES_NO[item.value],
  }));
  props.element.element_value_list_items = valueListItems;

  return (
    <div
      className="ae-grid"
      data-testid="create_account-element"
    >
      <div className="ae-grid__item item-sm-6">
        <WebFormField {...props} />
      </div>
    </div>
  );
};

export default CreateAccount;
