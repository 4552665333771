// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`HeaderElement should render correctly when the component is "header" 1`] = `
<div
  class="ae-grid"
  data-testid="header-element"
>
  <div
    class="ae-grid__item item-sm-12"
  >
    <h2
      class="vx-heading-2"
    >
      A basic element
    </h2>
  </div>
</div>
`;
