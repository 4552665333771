// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`VxErrorMessage should render nothing where there's no error 1`] = `
<div
  data-testid="web-form-field-test"
/>
`;

exports[`VxErrorMessage should render when there's an error 1`] = `
<div
  data-testid="web-form-field-test"
>
  <div
    class="vx-validation-message vx-validation-message--display"
    data-testid="vx-error-message"
  >
    Required.
  </div>
</div>
`;
