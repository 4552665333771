// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`HelpText renders the component correctly 1`] = `
<div>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    I like turtles.
  </div>
</div>
`;

exports[`HelpText renders the component correctly with HTML 1`] = `
<div>
  <div
    class="vx-form-label-instructions"
    data-testid="help-text"
  >
    
        
    <h1>
      This is h1 tag
    </h1>
    
        
    <h2>
      This is h2 tag
    </h2>
    
        
    <h3>
      This is h3 tag
    </h3>
    
        
    <h4>
      This is h4 tag
    </h4>
    
        
    <h5>
      This is h5 tag
    </h5>
    
        
    <h6>
      This is h6 tag
    </h6>
    
        
    <h7>
      This is h7 tag
    </h7>
    
        
    <p>
      Paragraph including 
      <a
        href="https://veracross.com"
      >
        links!
      </a>
    </p>
    
      
  </div>
</div>
`;
