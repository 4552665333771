import React, { useEffect } from 'react';
import ReactSelect from 'react-select';

const createReactSelectOption = (valueListItem, isOptionDisabled) => {
  const reactSelectOption = {
    label: valueListItem.description,
    value: valueListItem.id,
    customData: valueListItem.custom_data,
  };
  if (!isOptionDisabled) {
    return reactSelectOption;
  } else {
    return {
      ...reactSelectOption,
      isDisabled: isOptionDisabled(valueListItem),
    };
  }
};

const getOptions = (groupByCategory, valueList, isOptionDisabled) => {
  let reactSelectOptions = [];
  if (groupByCategory) {
    // group options following the next format:
    // [{ label: 'group1', options: [{ value: 1, label: 'option1'}, { value: 2, label: 'option2'}] }]
    const sortedValueList = _.sortBy(valueList, ['categorySortKey', 'sortKey']);
    reactSelectOptions = sortedValueList.reduce((groupedOptions, valueListItem) => {
      if (!groupedOptions.some((group) => group.label === valueListItem.category)) {
        groupedOptions.push({
          label: valueListItem.category,
          options: [],
        });
      }
      const currentGroup = groupedOptions.find((group) => group.label === valueListItem.category);
      currentGroup.options.push(createReactSelectOption(valueListItem, isOptionDisabled));
      return groupedOptions;
    }, []);
  } else {
    reactSelectOptions = valueList.map((valueListItem) =>
      createReactSelectOption(valueListItem, isOptionDisabled)
    );
  }
  return reactSelectOptions;
};

const Select = ({
  dataKeypath,
  placeholderText = '-- Select One --',
  groupByCategory = false,
  valueList = [],
  customOptionTemplate,
  isClearable = true,
  isOptionDisabled,
  defaultValue,
  customStyles,
}) => {
  const reactSelectOptions = getOptions(groupByCategory, valueList, isOptionDisabled);
  let defaultReactSelectOption;
  if (defaultValue) {
    // TODO: cover groupByCategory scenario
    defaultReactSelectOption = reactSelectOptions.find(({ value }) => value === defaultValue);
  }

  useEffect(() => {
    // create a ractive field instance. This way ractive will be aware of the react field when validating
    const formField = window.WebForm.FormInstance.createFormField(dataKeypath);
    window.WebForm.FormInstance.push('form_fields', formField);
  }, []);

  const styles = {
    ...customStyles,
    menu: (provided) => ({
      ...provided,
      zIndex: 120,
    }),
  };

  return (
    <ReactSelect
      name={dataKeypath}
      placeholder={placeholderText}
      options={reactSelectOptions}
      formatOptionLabel={customOptionTemplate}
      isClearable={isClearable}
      defaultValue={defaultReactSelectOption}
      styles={styles}
    />
  );
};

export default Select;
