import React from 'react';
import classnames from 'classnames';
import VxLabel from './VxLabel';
import HelpText from './HelpText';
import PlainTextField from './PlainTextField';
import { FastField, useField, useFormikContext } from 'formik';
import VxErrorMessage from './VxErrorMessage';
import { ElementMetadata } from '../types';

const NumberField = ({ element, metadata }: ElementMetadata) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(metadata.dataKeypath);
  const hasError = meta.touched && meta.error;

  const validateNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
    ['e', 'E', '+', '-', '.'].includes(e.key) &&
      ['degree_year', 'language_years'].includes(element.element_system_name) &&
      e.preventDefault();
  };

  return (
    <>
      <VxLabel
        element={element}
        htmlFor={metadata.dataKeypath}
        hasError={hasError}
      />
      <HelpText
        element={element}
        hasError={hasError}
      />
      <div
        className={classnames('vx-form-control vx-number-field', {
          'vx-form-control--error': hasError,
        })}
      >
        {metadata.isPlainText ? (
          <PlainTextField>{field.value}</PlainTextField>
        ) : (
          <>
            <FastField
              type="number"
              onKeyDown={validateNumber}
              name={metadata.dataKeypath}
              className="vx-number-field__input"
              placeholder={element.placeholder_text}
              disabled={isSubmitting}
            />
            <VxErrorMessage
              element={element}
              metadata={metadata}
            />
          </>
        )}
      </div>
    </>
  );
};

export default NumberField;
