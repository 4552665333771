require('../portal-component');

window.Portal.SubmitAssignments.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.on({
      _handleClick: this._handleClick,
    });
    this.componentDoneLoading();
  },

  _handleClick: function (e) {
    this.load_data().then(this._dataLoaded);
  },

  _dataLoaded: function (data) {
    if (data.portals_3_enabled) {
      var url = [_.baseURL('portals'), 'student', 'submit-assignments'].join('/');
      window.location = url;
    } else {
      this._openPopup();
    }
  },

  _openPopup: function (e) {
    var url = [_.baseURL('portals_app'), 'student', 'dropbox'].join('/');
    var top = screen.height / 2 - 600 / 2;
    var left = screen.width / 2 - 575 / 2;
    window.open(
      url,
      '_blank',
      'height=600,width=575,top=' + top + ',left=' + left + ',menubar=0,toolbar=0,resizable=0'
    );
    return false;
  },
});
