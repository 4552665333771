import React from 'react';
import AuthTokenContext from '../../shared/AuthTokenContext';
import StudentHeader from './shared/StudentHeader';
import { ClassList, Student, StudentInfo, PortalConfigValues } from './types';
import CourseRequests from './shared/CourseRequests';
import { useCourseRequestActions } from '../../../stores/useCourseRequestsStore';
import { initStudent, isReadOnlyConfig } from './helpers';

type CourseRequestsPageProps = {
  personPk: number;
  students: Student[];
  advisees: Student[];
  studentInfo: StudentInfo[];
  classes: ClassList[];
  isFacultyPortal: boolean;
  adviseesOnly: boolean;
  version: number;
  portalConfig: PortalConfigValues;
  authToken: string;
  readOnly: boolean;
  activeGradingPeriod: number;
};

const CourseRequestsPage = ({
  personPk,
  students,
  advisees,
  studentInfo,
  classes,
  isFacultyPortal,
  adviseesOnly,
  version,
  portalConfig,
  authToken,
  activeGradingPeriod,
}: CourseRequestsPageProps) => {
  const { initialize } = useCourseRequestActions();
  initialize({
    isFacultyPortal,
    studentData: studentInfo[0],
    student: initStudent(personPk),
    version,
    portalConfig,
    readOnly: isReadOnlyConfig(portalConfig),
    activeGradingPeriod,
  });

  return (
    <AuthTokenContext.Provider value={authToken}>
      <div className="p-2.5 min-h-screen">
        <StudentHeader
          students={students}
          advisees={advisees}
          classes={classes}
          adviseesOnly={adviseesOnly}
        />
        <CourseRequests />
      </div>
    </AuthTokenContext.Provider>
  );
};

export default CourseRequestsPage;
