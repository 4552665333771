require('../portal-component');

window.Portal.PinterestProfile.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.componentDoneLoading();
  },

  onrender: function () {
    // load the pinterest script once
    if ($('script[src*="pinit.js"]').length == 0) {
      (function (d) {
        var f = d.getElementsByTagName('SCRIPT')[0],
          p = d.createElement('SCRIPT');
        p.type = 'text/javascript';
        p.async = true;
        p.src = '//assets.pinterest.com/js/pinit.js';
        f.parentNode.insertBefore(p, f);
      })(document);
    }

    if (this.get('admin')) {
      this.observe(
        'configuration.dimensions.height configuration.url',
        _.debounce(this.refreshEmbed, 500),
        {
          init: false,
        }
      );
    }
  },

  refreshEmbed: function (newValue, oldValue, keypath) {
    trace.log();

    var component_root = this.find('.component-pinterest-profile');
    $(component_root).empty();
    $(component_root).append(
      '<a data-pin-do="embedUser" ' +
        'data-pin-scale-height="' +
        this.get('configuration.dimensions.height') +
        '" ' +
        'href="https://www.pinterest.com/' +
        this.get('configuration.url') +
        '">' +
        '</a>'
    );

    // PinUtils is defined by the //assets.pinterest.com/js/pinit.js script loaded on the page
    // this rebuilds the pinterest widget we created above
    PinUtils.build(component_root);
  },
});
