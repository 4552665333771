import React from 'react';
import classNames from 'classnames';

import { Button } from 'components/shared/form_elements';
import OpportunityCard from './opportunity_card/OpportunityCard';
import AuthTokenContext from 'components/shared/AuthTokenContext';

export default function OpportunityCardList(props) {
  const {
    config,
    data,
    pageState,
    canLoadMoreOpportunities,
    handleLoadMoreOpportunities,
    setOpportunities,
  } = props;

  const opportunities = data.opportunities;
  const hasOpportunities = opportunities.length > 0;

  const isPageLoading = pageState === 'LOADING';
  const isPageLoadingMore = pageState === 'LOADING_MORE';
  const isPageSearching = pageState === 'SEARCHING';

  const showLoader = isPageLoading || isPageSearching;
  const showLoadMoreButton = canLoadMoreOpportunities;
  const loadMoreButtonText = isPageLoadingMore ? 'Loading...' : 'Load More';

  const [opportunityKeysSaving, setOpportunityKeysSaving] = React.useState(0);
  const savingState = opportunityKeysSaving > 0 ? 'SAVING' : 'NOT SAVING';

  const [changes, setChanges] = React.useState({});
  const changesRef = React.useRef(changes);

  React.useEffect(() => {
    changesRef.current = changes;
  }, [changes]);

  const [errors, setErrors] = React.useState({});
  const [successes, setSuccesses] = React.useState({});

  const hasChanges = !_.isEmpty(changes);
  const isSaving = savingState === 'SAVING';
  const showSaveButton = hasChanges || isSaving;
  const saveButtonText = isSaving ? 'Saving Changes...' : 'Save Changes';

  /***************************************************************************************************
   ** REQUEST: PUT solicitor-constituents/opportunity/:id
   ***************************************************************************************************/

  const authToken = React.useContext(AuthTokenContext);
  const REQUEST_HEADERS = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache, no-store',
    'X-CSRF-Token': authToken,
  };

  const PORTALS_REQUEST_URL_PARAMS = {
    client: Portals.config.client,
    portal_name: Portals.config.portal_name,
  };

  const sendUpdateOpportunityRequest = async (opportunity_id, keypath, value) => {
    setOpportunityKeysSaving((prevOpportunityKeysSaving) => {
      return prevOpportunityKeysSaving + 1;
    });

    const url = await Routes.update_solicitor_constituent_opportunity_path(opportunity_id, {
      ...PORTALS_REQUEST_URL_PARAMS,
    });

    const response = await fetch(url, {
      method: 'PUT',
      headers: REQUEST_HEADERS,
      body: JSON.stringify({ keypath: keypath, value: value }),
    });

    const responseBody = await response.json();

    if (response.ok) {
      return responseBody;
    } else {
      throw new Error(responseBody.status);
    }
  };

  const sendOpportunityChangeNotificationRequest = async (id, type, fields_updated) => {
    const url = Routes.solicitor_constituents_send_notification_path(id, {
      ...PORTALS_REQUEST_URL_PARAMS,
    });

    const word_map = {
      opportunity_giving_category: 'Giving Category',
      opportunity_giving_status: 'Giving Status',
      opportunity_phase: 'Opportunity Phase',
      opportunity_solicitor_notes: 'Solicitor Notes',
    };
    const readable = _.map(fields_updated, (key) => word_map[key]).join(', ');

    const response = await fetch(url, {
      method: 'POST',
      headers: REQUEST_HEADERS,
      body: JSON.stringify({ type: type, fields_updated: readable }),
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Failed to send notification');
    }
  };

  // Decrease the OpportunityKeysSaving by one, wait one second before resetting to zero
  const decreaseOpportunityKeysSaving = () => {
    setOpportunityKeysSaving((prevOpportunityKeysSaving) => {
      const newOpportunityKeysSaving = prevOpportunityKeysSaving - 1;
      if (newOpportunityKeysSaving === 0) {
        setTimeout(function () {
          setOpportunityKeysSaving(newOpportunityKeysSaving);
        }, 1000);
        return prevOpportunityKeysSaving;
      } else {
        return newOpportunityKeysSaving;
      }
    });
  };

  // Save opportunity changes to DB
  const saveOpportunities = () => {
    opportunities.forEach((opportunity) => {
      const fields_to_update = _.keys(changes[opportunity.id]);
      if (_.isEmpty(fields_to_update)) return;

      Promise.map(fields_to_update, (key) => {
        return sendUpdateOpportunityRequest(opportunity.id, key, opportunity[key])
          .then((response) => {
            clearOpportunity(opportunity.id, key);
            decreaseOpportunityKeysSaving();
            return key;
          })
          .catch((error) => {
            console.log('Error: ', error);
            addErrorOpportunity(opportunity.id, key);
            decreaseOpportunityKeysSaving();
            return false;
          });
      }).then((fields_updated) => {
        // don't send notification if all requests failed
        if (_.isEmpty(_.compact(fields_updated))) return;

        // send a notification about the change for all the fields that were successfully updated.
        // Silent failure is ok as it will be reported server side
        sendOpportunityChangeNotificationRequest(
          opportunity.id,
          'opportunity',
          _.compact(fields_updated)
        ).catch((error) => {
          console.error(error);
        });
      });
    });
  };

  // Event listener for beforeunload
  const handleWindowBeforeUnload = (e) => {
    if (!(Object.keys(changesRef.current).length === 0)) {
      e.returnValue = 'Your recent changes to this page will be lost if you continue.';
      return 'Your recent changes to this page will be lost if you continue.';
    }
  };

  // Register beforeunload listener
  React.useEffect(() => {
    window.addEventListener('beforeunload', handleWindowBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleWindowBeforeUnload);
  }, []);

  // Handle click on save changes
  const handleSaveOpportunities = (event) => {
    saveOpportunities();
  };

  // Add a key to the aux object (successes, changes, errors) for the opportunity
  const addKeyToAuxObject = (object, opportunityId, key) => {
    const newObject = { ...object };
    if (newObject[opportunityId] === undefined) {
      newObject[opportunityId] = {};
    }
    newObject[opportunityId][key] = true;
    return newObject;
  };

  // Remove a key from the aux object (successes, changes, errors) for the opportunity
  const removeKeyFromAuxObject = (object, opportunityId, key) => {
    const newObject = { ...object };
    if (newObject[opportunityId] !== undefined) {
      delete newObject[opportunityId][key];
      if (Object.keys(newObject[opportunityId]).length === 0) delete newObject[opportunityId];
    }
    return newObject;
  };

  // Register changes to an opportunity
  const handleChangeOpportunity = (event, id) => {
    if (isSaving) return;
    const key = event.target.name;
    const value = event.target.value;

    setChanges((prevChanges) => {
      return addKeyToAuxObject(prevChanges, id, key);
    });

    setOpportunities((prevOpportunities) => {
      const newOpportunities = [...prevOpportunities];
      const opportunityIndex = newOpportunities.findIndex((co) => {
        return co.id === id;
      });
      const opportunity = { ...newOpportunities[opportunityIndex] };
      opportunity[key] = value;
      newOpportunities[opportunityIndex] = opportunity;
      return newOpportunities;
    });
  };

  // Clear sucesses from opportunity timeout
  const clearSuccessesFromOpportunity = (id, key) => {
    setSuccesses((prevSuccesses) => {
      return removeKeyFromAuxObject(prevSuccesses, id, key);
    });
  };

  // Clear errors and changes from opportunity after save
  const clearOpportunity = (id, key) => {
    setSuccesses((prevSuccesses) => {
      return addKeyToAuxObject(prevSuccesses, id, key);
    });

    setChanges((prevChanges) => {
      return removeKeyFromAuxObject(prevChanges, id, key);
    });

    setErrors((prevErrors) => {
      return removeKeyFromAuxObject(prevErrors, id, key);
    });

    setTimeout(() => clearSuccessesFromOpportunity(id, key), 5000);
  };

  // Add errors to unsuccessfully saved opportunity
  const addErrorOpportunity = (id, key) => {
    setErrors((prevErrors) => {
      return addKeyToAuxObject(prevErrors, id, key);
    });
  };

  const Loader = (
    <div className="vx-form-loader">
      <div className="vx-form-loader__spinner vx-loader-spinner"></div>
      <div className="vx-form-loader__message">{'Loading...'}</div>
    </div>
  );

  const OpportunityCards =
    hasOpportunities &&
    opportunities.map((opportunity) => (
      <OpportunityCard
        key={opportunity.id}
        data={opportunity}
        config={config}
        handleChange={(event) => handleChangeOpportunity(event, opportunity.id)}
        changes={changes[opportunity.id]}
        errors={errors[opportunity.id]}
        successes={successes[opportunity.id]}
        isSaving={isSaving}
      />
    ));

  const saveButtonClassNames = classNames({
    'floating-save-button': true,
    'vx-button': true,
    'vx-button--success': true,
    disabled: isSaving,
  });

  const SaveButton = (
    <div
      onClick={handleSaveOpportunities}
      className={saveButtonClassNames}
    >
      {saveButtonText}
    </div>
  );

  if (showLoader) {
    return Loader;
  } else if (hasOpportunities) {
    return (
      <>
        <div className="opportunity-card-list">
          {OpportunityCards}
          {showLoadMoreButton && (
            <Button
              className="vx-button vx-button--primary load-more-button"
              disabled={isPageLoadingMore}
              onClick={handleLoadMoreOpportunities}
              value={loadMoreButtonText}
            />
          )}
        </div>
        {showSaveButton && SaveButton}
      </>
    );
  } else {
    return (
      <div className="vx-empty-state-read-only">
        <h4>No Constituents found.</h4>
      </div>
    );
  }
}
