import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthTokenContext from '../../shared/AuthTokenContext';
import AddGalleryHeader from './AddGalleryHeader';
import GalleryList from './GalleryList';
import GalleryView from './GalleryView';
import usePhotoGalleryStore from '../../../stores/usePhotoGalleryStore';

function PhotosGalleryPage({
  internalClassId,
  photoGalleries,
  allPhotos,
  uploaderOptions,
  authToken,
}) {
  const client = Portals.config.client;
  const portalName = Portals.config.portal_name;
  const { setPhotoGalleries, setAllPhotos } = usePhotoGalleryStore();

  useEffect(() => {
    const fetchedGalleries = photoGalleries;
    setPhotoGalleries(fetchedGalleries);
  }, [setPhotoGalleries]);

  useEffect(() => {
    const fetchedAllPhotos = allPhotos;
    setAllPhotos(fetchedAllPhotos);
  }, [setAllPhotos]);

  return (
    <AuthTokenContext.Provider value={authToken}>
      <Router>
        <Routes>
          <Route
            path={`/${client}/${portalName}/class/${internalClassId}/photos`}
            element={
              <div>
                <AddGalleryHeader internalClassId={internalClassId} />
                <GalleryList
                  internalClassId={internalClassId}
                  uploaderOptions={uploaderOptions}
                />
              </div>
            }
          />
          <Route
            path={`/${client}/${portalName}/class/${internalClassId}/photos/:galleryId`}
            element={
              <div className="">
                <GalleryView
                  internalClassId={internalClassId}
                  uploaderOptions={uploaderOptions}
                />
              </div>
            }
          />
        </Routes>
      </Router>
    </AuthTokenContext.Provider>
  );
}

export default PhotosGalleryPage;
