window.ChecklistConfirmDeleteModal = Ractive.extend({
  data: {
    error: '',
    key: '',
    loading: false,
    rowTitle: '',
    rowType: '',
    cancelButtonLabel: '',
    deleteButtonLabel: '',
  },

  onconstruct: function (options) {
    options.template = require('./templates/checklist-confirm-delete-modal.ractive');
  },

  oninit: function () {
    if (this.get('deleteButtonLabel').trim() === '') {
      this.set('deleteButtonLabel', 'Delete');
    }

    if (this.get('cancelButtonLabel').trim() === '') {
      this.set('cancelButtonLabel', 'Cancel');
    }

    this.on({ _close: this._close, _delete: this._delete });
  },

  onrender: function () {
    $('body').addClass('vx-modal-open');
  },

  computed: {
    isLoading: function () {
      return this.get('loading') === 'true';
    },
  },

  _delete: function () {
    this.set('loading', true);

    $.ajax({ url: this.get('key'), type: 'DELETE' })
      .success(() => {
        this.onDelete();
        this._close();
        this.set('loading', false);
      })
      .error((xhr) => {
        console.log(xhr);
        const { responseJSON = {} } = xhr;
        const { message } = responseJSON;
        this.set('error', message || 'Something went wrong, please try again.');
        this.set('loading', false);
      });
  },

  _close: function (e) {
    $('body').removeClass('vx-modal-open');
    this.teardown();
  },
});
