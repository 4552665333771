window.WebForm.FacultyStaffStatus = (dataKeyPath, statusField, relationshipField) => {
  const statusRadioSelector = `input[name="${dataKeyPath}:${statusField}"]`;
  const relationshipRadioSelector = `input[name="${dataKeyPath}:${relationshipField}"]`;
  const statusRadios = $(statusRadioSelector);

  statusRadios.on('change', (e) => {
    const { value } = e.target;
    const relationshipContainer = $(
      `[data-field-container="${dataKeyPath}:${relationshipField}"]`
    ).parent('.ae-grid__item');

    if (value > 0) {
      relationshipContainer.show();
    } else {
      // remove check to avoid sending its value to backend
      // and trigger change so a hidden input with null value is created
      $(`${relationshipRadioSelector}:checked`).prop('checked', false).trigger('change');
      relationshipContainer.hide();
    }
  });

  // first render
  const checkedRadio = $(`${statusRadioSelector}:checked`);
  if (checkedRadio.length) {
    checkedRadio.trigger('change');
  } else {
    // trigger change to hide the relationship section if
    // there is no selected status in the first render
    statusRadios.first().trigger('change');
  }
};

// if faculty status = 1, relationship should be present
export const validateSelectedFacultyRelationship = (relationshipField) => {
  const relationshipKeypath = relationshipField.get('field_keypath');
  const statusKeypath = relationshipKeypath.replace(
    ':employment_relationship',
    ':employment_status'
  );
  const checkedStatus = $(`input[name="${statusKeypath}"]:checked`);

  if (checkedStatus.length && checkedStatus.val() === '1') {
    const checkedRelationship = $(`input[name="${relationshipKeypath}"]:checked`);
    if (!checkedRelationship.length) {
      relationshipField.set('errors', ['You must select a relationship to the school']);
    }
  } else {
    relationshipField.set('errors', []);
  }
};
