import React from 'react';

import { DataField } from 'components/shared/form_elements';
import { redirectToRoute, formatMonetaryValue } from 'components/shared/utils';

import { REQUEST_STATUSES } from 'components/system/purchase_requests/constants';

const CONFIG = {
  vendor_description: {
    dataType: 'text',
    label: 'Vendor',
  },
  request_date: {
    dataType: 'date',
    label: 'Request Date',
  },
  total_amount: {
    label: 'Amount',
  },
};

// prettier-ignore
function PurchaseRequestCard(props) {
  const {
    data,
  } = props;

  const requestStatus = REQUEST_STATUSES[data.request_status];

  const handleClick = React.useCallback((event) => {
    event.preventDefault();

    redirectToRoute('purchase_request_path', { public_id: data.public_id });
  }, [data.public_id]);

  /**************************************************************************************************/

  return (
    <div className="purchase-request-card" onClick={handleClick}>
      <div className="ae-grid">
        <div className="ae-grid__item item-lg-5">
          <div
            className="vx-tag purchase-request-card__status-tag"
            style={{ '--background-color': requestStatus?.color }}
          >
            {requestStatus.label}
          </div>
          <div className="purchase-request-card__description">
            {data.description}
          </div>
          <div className="purchase-request-card__workflow-description">
            {data.workflow_description}
          </div>
        </div>
        <div className="ae-grid__item item-lg-3">
          <DataField
            value={data.vendor_description}
            config={CONFIG.vendor_description}
          />
        </div>
        <div className="ae-grid__item item-lg-2">
          <DataField
            value={data.request_date}
            config={CONFIG.request_date}
          />
        </div>
        <div className="ae-grid__item item-lg-2">
          <DataField
            value={formatMonetaryValue(data.total_amount, {
              locale: data.currency_locale,
              code: data.currency_code,
            })}
            config={CONFIG.total_amount}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(PurchaseRequestCard);
