import React, { FC } from 'react';
import { WebFormField } from './MultiFieldElement';
import { ComponentElementMetadata } from '../types';

const DataConsentPolicyCheckboxElement: FC<ComponentElementMetadata> = (props) => {
  return (
    <div
      className="ae-grid"
      data-testid="data_consent_policy_checkbox-element"
    >
      <div className="ae-grid__item item-sm-8">
        <WebFormField {...props} />
      </div>
    </div>
  );
};

export default DataConsentPolicyCheckboxElement;
