require('../portal-component');
require('../../controls/load-more/load-more');

window.Portal.ArticleSingleLineList.Component = PortalComponent.extend({
  onconstruct: function (options) {
    this._super(options);
    _.merge(options.components, {
      LoadMore: Control.LoadMore,
    });
  },

  oninit: function () {
    trace.log();
    this.reload();
  },

  reload: function () {
    trace.log();
    this.set('articles', []);
    this.load_data().then(_.bind(this._dataLoaded, this));
  },

  _dataLoaded: function (data) {
    this.set('articles', _.concat(this.get('articles'), data.articles));
    this.componentDoneLoading();
  },
});
