require('../portal-component');

window.Portal.PhotoLink.Component = PortalComponent.extend({
  onconstruct: function (options) {
    this._super(options);
    options.partials = {
      photo_content: require('./templates/photo-content-partial.ractive'),
    };
  },

  oninit: function () {
    trace.log();
    this.componentDoneLoading();
  },
});
