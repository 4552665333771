window.WebForm.FormField = Ractive.extend({
  onconstruct: function (options) {
    options.append = true;
  },

  onrender: function () {
    _.bindAllFunctions(this);

    // replace references to format rules
    const rules = this.get('field_rules');
    if (_.startsWith(_.get(rules, 'format'), '$')) {
      // Remove the $ from the beginning of the format rule, e.g., $phone_format -> phone_format
      // and set the format rule to the actual format rule from the VALIDATIONS object
      // See: app/javascript/web-form/validation-library.js
      const validator = rules.format.replace(/^\$/g, '');
      rules.format = VALIDATIONS[validator];
    }

    // figure out which required rule we need to use
    // toggle elements can be marked as required but they always have a valid value we shouldn't check them
    if (!this.get('required') || _.get(rules, 'toggle')) {
      this.set('required_rule', {});
    }
    // school:feeder_school is treated as a normal required field
    else if (this.get('field_keypath').endsWith('school:feeder_school')) {
      this.set('required_rule', VALIDATIONS.required);
    }
    // education:degree and eduction:degree_major dropdowns should not allow 'none' selection if required
    else if (
      _.get(rules, 'dropdown') &&
      (this.get('field_keypath').endsWith('education:degree') ||
        this.get('field_keypath').endsWith('education:degree_major'))
    ) {
      this.set('required_rule', VALIDATIONS.non_zero_dropdown_required);
    }
    // use select required rule for fields indicated as a dropdown
    else if (_.get(rules, 'dropdown')) {
      this.set('required_rule', VALIDATIONS.select_required);
    } else if (_.get(rules, 'react_select')) {
      this.set('required_rule', VALIDATIONS.react_select_required);
    } else {
      this.set('required_rule', VALIDATIONS.required);
    }

    // delete all the dropdown and toggle rules now that we have what we need
    // these rules are included for constructing presence rules only, not for validating against
    if (rules) {
      delete rules.dropdown;
      delete rules.toggle;
      delete rules.react_select;
    }

    // validate individual fields while the form is being filled out
    // if it's an email field validate all of them, if it isn't valide just this field
    if (this.get('is_email') === true) {
      this.get('input').on('change', this.get('parent_form').validateEmailFields);
    } else {
      this.get('input').on('change', this.validate);
    }

    // when errors are set, update the ui
    this.observe('errors', this.updateErrors);
  },

  currentValue: function () {
    const input = this.get('input');

    if (input.attr('type') === 'radio') {
      return input.filter(':checked').val();
    } else if (input.attr('type') === 'checkbox') {
      return _.reduce(
        input.filter(':checked'),
        function (vals, box) {
          vals.push($(box).val());
          return vals;
        },
        []
      );
    }

    return input.val();
  },

  validate: function () {
    // this is before the hidden section check in case the user switched sections
    // while errors were still present on the newly hidden section
    this.set('errors', []);

    // don't try to validate fields that are in hidden sections or child elements
    if (
      $(this.el).closest('.section-container').is(':hidden') ||
      $(this.el).closest('.child-element-container').is(':hidden')
    ) {
      return;
    }

    // don't validate school:end_date if "present-attending" is checked
    // see: app/javascript/components/system/web-forms/DatePicker.js
    if (
      this.get('field_keypath').endsWith('school:end_date') &&
      $(this.el).closest('.child-element-container').find('.present-attending').is(':checked')
    ) {
      return;
    }

    const current_value = this.currentValue();

    // only validate field rules if it has a value entered
    const field_rules = _.isBlank(current_value) ? {} : this.get('field_rules');

    // actually validate with the combined rules
    const errors = validate.single(
      current_value,
      _.merge({}, this.get('required_rule'), field_rules)
    );

    // update the errors
    this.set('errors', errors);
  },

  updateErrors: function (errors, oldErrors, keypath) {
    if (_.isEmpty(errors)) {
      $(this.el).removeClass('vx-form-control--error').next('.vx-validation-message').hide().html();
    } else {
      $(this.el)
        .addClass('vx-form-control--error')
        .next('.vx-validation-message')
        .html(errors.join('<br>'))
        .fadeIn('fast')
        .css('display', 'inline-block');
    }
  },
});
