require('../portal-component');

window.Portal.UnreadMessagesCount.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.load_data().then(this._dataLoaded);
  },

  data: {
    loads_test_data: true,
  },

  _dataLoaded: function (data) {
    this.set({
      unread_messages_count: data.unread_messages_count,
    });

    this.componentDoneLoading();
  },
});
