import React from 'react';
import classNames from 'classnames';

export default function OpportunityHistoryItem(props) {
  const { data, precedingItemDate } = props;

  const TYPE_CONFIG = {
    default: {
      typeDescription: 'N/A',
      typeColor: '#616161',
    },
    1: {
      typeDescription: 'Task',
      typeColor: '#880e4f',
    },
    2: {
      typeDescription: 'Action',
      typeColor: '#1a237e',
    },
    3: {
      typeDescription: 'Note',
      typeColor: '#006064',
    },
    4: {
      typeDescription: 'Engagement',
      typeColor: '#bf360c',
    },
  };

  const DEFAULT_SUBJECT = '<No Subject>';

  const typeConfig = TYPE_CONFIG[data.type] || TYPE_CONFIG.default;
  const typeDescription = typeConfig.typeDescription;
  const typeColor = typeConfig.typeColor;

  const subject = data.subject || DEFAULT_SUBJECT;

  const formatDate = (date) => {
    return moment(date).format('MMM DD, YYYY');
  };

  const formattedDate = formatDate(data.date);
  const hasDateHidden = precedingItemDate && moment(data.date).isSame(precedingItemDate, 'day');
  const displayDateDivider = precedingItemDate && !hasDateHidden;

  const dateClassNames = classNames({
    'opportunity-history-item__date': true,
    'opportunity-history-item__date--hidden': hasDateHidden,
  });

  const DateDivider = displayDateDivider && <hr />;

  return (
    <>
      {DateDivider}
      <div className="opportunity-history-item display-flex-sm">
        <div className={dateClassNames}>{formattedDate}</div>
        <div
          className="opportunity-history-item__content"
          style={{ borderLeftColor: typeColor }}
        >
          <span className="opportunity-history-item__subject">
            {typeDescription}: {subject}
          </span>
          <p className="opportunity-history-item__notes">{data.notes}</p>
        </div>
      </div>
    </>
  );
}
