window.Control.Pagination = Ractive.extend({
  onconstruct: function (options) {
    trace.log();
    options.template = require('./template.ractive');
  },

  computed: {
    show_prev: function () {
      return this.get('page_num') > 0;
    },
    show_next: function () {
      return (
        this.get('record_count') > 0 &&
        this.get('record_count') == this.root.get('configuration.pagination.records_per_page')
      );
    },
  },

  oninit: function () {
    this.set({
      page_num: 0,
    });

    this.on({
      _prevPage: this._prevPage,
      _nextPage: this._nextPage,
    });
  },

  _prevPage: function (e) {
    e.original.preventDefault();
    trace.log(e);
    this.subtract('page_num');
    this.root.load_data({ page: this.get('page_num') }).then(this.root._dataLoaded);
  },

  _nextPage: function (e) {
    e.original.preventDefault();
    trace.log(e);
    this.add('page_num');
    this.root.load_data({ page: this.get('page_num') }).then(this.root._dataLoaded);
  },
});
