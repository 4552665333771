import React from 'react';
import classNames from 'classnames';

import {
  DataField,
  SelectField,
  TextAreaField,
  TextField,
  Button,
} from 'components/shared/form_elements';
import { TabsView, TabButton, TabButtonList, TabPanel, TabPanelList, TabsContext } from '../tabs';
import PersonIdentitySection from './PersonIdentitySection';
import PersonContactSection from './PersonContactSection';
import PersonEmploymentSection from './PersonEmploymentSection';
import OpportunityHistory from './OpportunityHistory';
import OpportunityNoteForm from './OpportunityNoteForm';
import Collapsible from 'components/shared/Collapsible';

export default function OpportunityCard(props) {
  const { data, config, handleChange, changes, errors, successes } = props;

  const hasAnyErrors = !!errors;

  const displayOpportunityActivity = config.opportunity_activity.enabled;
  const displayOpportunityNoteForm = config.opportunity_note_form.enabled;

  const hasConstituent = data.constituent_id;
  const hasConstituentSpouse = !!data.constituent_spouse_id;

  const displayConstituentEmploymentSection =
    hasConstituent &&
    (!!config.constituent_employer.displayed ||
      !!config.constituent_job_title.displayed ||
      !!config.constituent_industry.displayed);
  const displayConstituentSpouseEmploymentSection =
    hasConstituentSpouse &&
    (!!config.constituent_spouse_employer.displayed ||
      !!config.constituent_spouse_job_title.displayed ||
      !!config.constituent_spouse_industry.displayed);
  const displayEmploymentSections =
    displayConstituentEmploymentSection || displayConstituentSpouseEmploymentSection;

  const opportunityGivingCategoryOptions =
    config.opportunity_giving_category.options &&
    config.opportunity_giving_category.options.filter(
      (option) =>
        option.group_id === data.opportunity_fundraising_activity_id || option.group_id === 0
    );

  const opportunityGivingCategoryConfig = {
    ...config.opportunity_giving_category,
    options: opportunityGivingCategoryOptions,
  };

  const opportunityHistoryData = {
    opportunity_id: data.id,
  };

  const initialNoteFormValues = {
    opportunity_id: data.id,
    opportunity_note_date: moment(),
  };

  /***************************************************************************************************
   ** Render
   ***************************************************************************************************/

  return (
    <div className="opportunity-card">
      {hasAnyErrors && (
        <div
          className="alert alert-danger"
          role="alert"
        >
          Something went wrong. Please try again.
        </div>
      )}

      <div className="ae-grid">
        <div className="ae-grid__item item-lg-8">
          <div className="ae-grid">
            <div className="ae-grid__item item-lg-6 item-lg--order-1">
              {hasConstituent && (
                <PersonIdentitySection
                  personPhotoUrl={data.constituent_photo_url}
                  personFullName={data.constituent_full_name}
                  personTags={data.constituent_tags}
                />
              )}
            </div>
            <div className="ae-grid__item item-lg-6 item-lg--order-3">
              {hasConstituent && (
                <PersonContactSection
                  email={data.constituent_email}
                  emailConfig={config.constituent_email}
                  phoneMobile={data.constituent_phone_mobile}
                  phoneMobileConfig={config.constituent_phone_mobile}
                  phoneHome={data.constituent_phone_home}
                  phoneHomeConfig={config.constituent_phone_home}
                />
              )}
            </div>
            <div className="ae-grid__item item-lg-6 item-lg--order-2">
              {hasConstituentSpouse && (
                <PersonIdentitySection
                  personPhotoUrl={data.constituent_spouse_photo_url}
                  personFullName={data.constituent_spouse_full_name}
                  personTags={data.constituent_spouse_tags}
                />
              )}
            </div>
            <div className="ae-grid__item item-lg-6 item-lg--order-4">
              {hasConstituentSpouse && (
                <PersonContactSection
                  email={data.constituent_spouse_email}
                  emailConfig={config.constituent_spouse_email}
                  phoneMobile={data.constituent_spouse_phone_mobile}
                  phoneMobileConfig={config.constituent_spouse_phone_mobile}
                  phoneHome={data.constituent_spouse_phone_home}
                  phoneHomeConfig={config.constituent_spouse_phone_home}
                />
              )}
            </div>
          </div>
        </div>
        <div className="ae-grid__item item-lg-4">
          <div>
            <DataField
              name="opportunity_fundraising_activity"
              value={data.opportunity_fundraising_activity}
              config={config.opportunity_fundraising_activity}
            />
            <DataField
              name="constituent_location"
              value={data.constituent_location}
              config={config.constituent_location}
            />
          </div>
        </div>
      </div>

      <Collapsible isCollapsedByDefault={true}>
        <TabsView defaultTab={'opportunityDetails'}>
          <TabButtonList>
            <TabButton
              name="opportunityDetails"
              value="Details"
            />
            {displayOpportunityActivity && (
              <TabButton
                name="opportunityHistory"
                value="Activity"
              />
            )}
            {displayOpportunityNoteForm && (
              <TabButton name="opportunityNoteForm">
                <i className="nc-icon-glyph ui-2_square-add-11" /> Add a Note
              </TabButton>
            )}
          </TabButtonList>

          <TabPanelList>
            <TabPanel name="opportunityDetails">
              {displayEmploymentSections && (
                <div className="employment-sections">
                  <div className="ae-grid">
                    <div className="ae-grid__item item-lg-12">
                      {displayConstituentEmploymentSection && (
                        <PersonEmploymentSection
                          personName={data.constituent_full_name}
                          employer={data.constituent_employer}
                          employerConfig={config.constituent_employer}
                          jobTitle={data.constituent_job_title}
                          jobTitleConfig={config.constituent_job_title}
                          industry={data.constituent_industry}
                          industryConfig={config.constituent_industry}
                          phoneBusiness={data.constituent_phone_business}
                          phoneBusinessConfig={config.constituent_phone_business}
                        />
                      )}
                    </div>
                    <div className="ae-grid__item item-lg-12">
                      {displayConstituentSpouseEmploymentSection && (
                        <PersonEmploymentSection
                          personName={data.constituent_spouse_full_name}
                          employer={data.constituent_spouse_employer}
                          employerConfig={config.constituent_spouse_employer}
                          jobTitle={data.constituent_spouse_job_title}
                          jobTitleConfig={config.constituent_spouse_job_title}
                          industry={data.constituent_spouse_industry}
                          industryConfig={config.constituent_spouse_industry}
                          phoneBusiness={data.constituent_spouse_phone_business}
                          phoneBusinessConfig={config.constituent_spouse_phone_business}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}

              <hr />

              <div className="ae-grid">
                <div className="ae-grid__item item-lg-6">
                  <SelectField
                    name="opportunity_giving_status"
                    value={data.opportunity_giving_status}
                    config={config.opportunity_giving_status}
                    onChange={handleChange}
                    hasChange={changes && changes.opportunity_giving_status}
                    hasError={errors && errors.opportunity_giving_status}
                    hasSuccess={successes && successes.opportunity_giving_status}
                  />
                  <SelectField
                    name="opportunity_giving_category"
                    value={data.opportunity_giving_category}
                    config={opportunityGivingCategoryConfig}
                    onChange={handleChange}
                    hasChange={changes && changes.opportunity_giving_category}
                    hasError={errors && errors.opportunity_giving_category}
                    hasSuccess={successes && successes.opportunity_giving_category}
                  />
                  <SelectField
                    name="opportunity_phase"
                    value={data.opportunity_phase}
                    config={config.opportunity_phase}
                    onChange={handleChange}
                    hasChange={changes && changes.opportunity_phase}
                    hasError={errors && errors.opportunity_phase}
                    hasSuccess={successes && successes.opportunity_phase}
                  />
                </div>
                <div className="ae-grid__item item-lg-6">
                  <TextAreaField
                    name="opportunity_solicitor_notes"
                    value={data.opportunity_solicitor_notes}
                    config={config.opportunity_solicitor_notes}
                    onChange={handleChange}
                    hasChange={changes && changes.opportunity_solicitor_notes}
                    hasError={errors && errors.opportunity_solicitor_notes}
                    hasSuccess={successes && successes.opportunity_solicitor_notes}
                  />
                </div>
              </div>

              <hr />

              <div className="ae-grid">
                <div className="ae-grid__item item-lg-6">
                  <DataField
                    name="opportunity_target_amount"
                    value={data.opportunity_target_amount}
                    config={{
                      ...config.opportunity_target_amount,
                      locale: data.currency_locale,
                      code: data.currency_code,
                    }}
                  />
                </div>
                <div className="ae-grid__item item-lg-6">
                  <DataField
                    name="opportunity_total_giving"
                    value={data.opportunity_total_giving}
                    config={{
                      ...config.opportunity_total_giving,
                      locale: data.currency_locale,
                      code: data.currency_code,
                    }}
                  />
                </div>
                <div className="ae-grid__item item-lg-6">
                  <DataField
                    name="opportunity_total_donations"
                    value={data.opportunity_total_donations}
                    config={{
                      ...config.opportunity_total_donations,
                      locale: data.currency_locale,
                      code: data.currency_code,
                    }}
                  />
                </div>
                <div className="ae-grid__item item-lg-6">
                  <DataField
                    name="opportunity_total_pledges"
                    value={data.opportunity_total_pledges}
                    config={{
                      ...config.opportunity_total_pledges,
                      locale: data.currency_locale,
                      code: data.currency_code,
                    }}
                  />
                </div>
              </div>
            </TabPanel>

            {displayOpportunityActivity && (
              <TabPanel name="opportunityHistory">
                <TabsContext.Consumer>
                  {({ isActiveTab }) => (
                    <OpportunityHistory
                      data={opportunityHistoryData}
                      isActiveTab={isActiveTab('opportunityHistory')}
                    />
                  )}
                </TabsContext.Consumer>
              </TabPanel>
            )}

            {displayOpportunityNoteForm && (
              <TabPanel name="opportunityNoteForm">
                <OpportunityNoteForm
                  config={config.opportunity_note_form}
                  initialFormValues={initialNoteFormValues}
                />
              </TabPanel>
            )}
          </TabPanelList>
        </TabsView>
      </Collapsible>
    </div>
  );
}
