import React from 'react';
import ReactSelect from 'react-select';

import { TextField, NumberField, Button } from 'components/shared/form_elements';
import { formatMonetaryValue } from 'components/shared/utils';
import useAsyncRequest from 'components/shared/hooks/useAsyncRequest';
import AuthTokenContext from 'components/shared/AuthTokenContext';

// prettier-ignore
function EditPurchaseRequestItem(props) {
  const {
    data,
    setShowEditItem,
    glAccounts,
    reloadItems,
    isPosted,
    isDisabled,
  } = props;

  const authToken = React.useContext(AuthTokenContext);

  /***************************************************************************************************
  ** Form Values
  ***************************************************************************************************/

  const [initialFormValues, setInitialFormValues] = React.useState(data);
  const [formValues, setFormValues] = React.useState(initialFormValues);
  const [formChanges, setFormChanges] = React.useState({});

  const setFormValue = React.useCallback((name, value) => {
    setFormValues((values) => ({ ...values, [name]: value }));
    setFormChanges((changes) => ({ ...changes, [name]: (value !== initialFormValues[name] && parseFloat(value) !== parseFloat(initialFormValues[name])) }));
  }, [initialFormValues]);

  const handleFormFieldChange = React.useCallback((event) => {
    setFormValue(event.target.name, event.target.value);
  }, [setFormValue]);

  const glAccountOptions = glAccounts.map((glAccount) => ({
    value: glAccount.gl_account_pk,
    label: `${glAccount.gl_number} - ${glAccount.gl_account_name}`,
  }));

  /**************************************************************************************************/

  const [totalCost, setTotalCost] = React.useState(0);

  React.useEffect(() => {
    setTotalCost(formValues.quantity * formValues.unit_cost);
  }, [formValues.quantity, formValues.unit_cost]);

  /***************************************************************************************************
  ** Update
  ***************************************************************************************************/

  const updatePurchaseRequestItemRequest = useAsyncRequest({
    pathName: 'update_purchase_request_item_path',
    params: {
      purchase_request_id: data.purchase_request_id,
      purchase_request_item_id: data.id,
    },
    method: 'PATCH',
    authorizationToken: authToken,
  });

  const handleClickSaveButton = React.useCallback((event) => {
    event.preventDefault();

    const submitData = {
      purchase_request_item: {
        ...formValues,
        gl_account_fk: formValues?.gl_account?.value,
        model_number: formValues?.model_number?.trim(),
        description: formValues?.description?.trim()
      },
    };

    updatePurchaseRequestItemRequest(submitData).then((_data) => {
      setInitialFormValues(formValues);
      setFormChanges({});

      setShowEditItem(false);
      reloadItems();
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }, [
    formValues,
    setShowEditItem,
    reloadItems,
  ]);

  /**************************************************************************************************/

  const handleClickCancelButton = React.useCallback((event) => {
    event.preventDefault();

    setShowEditItem(false);
  }, [setShowEditItem]);

  /**************************************************************************************************/

  const handleGlAccountChange = (selectedOption) => {
    setFormValue('gl_account', selectedOption);
  }

  return (
    <tr>
      <td>
        <TextField
          name="description"
          value={formValues.description}
          onChange={handleFormFieldChange}
          hasChange={formChanges.description}
          disabled={isDisabled && !isPosted }
        />
      </td>
      <td>
        <TextField
          name="model_number"
          value={formValues.model_number}
          onChange={handleFormFieldChange}
          hasChange={formChanges.model_number}
          disabled={isDisabled && !isPosted }
        />
      </td>
      <td >
        <ReactSelect
          name="gl_account"
          options={glAccountOptions}
          value={formValues.gl_account}
          onChange={handleGlAccountChange}
          menuPosition={'fixed'}
          isDisabled={isDisabled}
        />
      </td>
      <td>
        <NumberField
          name="unit_cost"
          value={formValues.unit_cost}
          onChange={handleFormFieldChange}
          hasChange={formChanges.unit_cost}
          disabled={isDisabled}
        />
      </td>
      <td>
        <NumberField
          name="quantity"
          value={formValues.quantity}
          onChange={handleFormFieldChange}
          hasChange={formChanges.quantity}
          disabled={isDisabled}
        />
      </td>
      <td>
        <TextField
          name="total_cost"
          value={formatMonetaryValue(totalCost, {
            locale: data.currency_locale,
            code: data.currency_code,
          })}
          hasChange={formChanges.quantity || formChanges.unit_cost}
          disabled={true}
        />
      </td>
      <td className="text-align-left">
        <Button
          className="action-button vx-button vx-button--primary"
          onClick={handleClickSaveButton}
          value="Save"
        />
        <Button
          className="action-button vx-button vx-button--neutral"
          onClick={handleClickCancelButton}
          value="Cancel"
        />
      </td>
    </tr>
  );
}

export default React.memo(EditPurchaseRequestItem);
