window.Control.RangeControl = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./template.ractive');
  },

  oninit: function () {
    this.observe('field', this.changedText, { init: false });

    this.set(
      _.defaults(
        {
          min: this.get('min'),
          max: this.get('max'),
          step: this.get('step'),
        },
        {
          min: 0,
          max: 1000,
          step: 10,
        }
      )
    );
  },

  onrender: function () {
    $(this.find('.ctrl-Range_Input')).rangeslider({
      polyfill: false,
      onSlide: _.bind(this._rangeChanged, this),
    });
  },

  _rangeChanged: function (position, value) {
    this.set('field', value);
  },

  changedText: function (newValue, oldValue, keypath) {
    $(this.find('.ctrl-Range_Input')).val(newValue).change();
  },
});
