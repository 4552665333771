require('../portal-component');

window.Portal.MyChildrenParent.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();
    this.on({
      setActiveTab: this.setActiveTab,
    });
    this.load_data().then(this._dataLoaded);
  },

  data: {
    loads_test_data: true,
  },

  _dataLoaded: function (data) {
    this.set({
      children: data.children,
      'children.*.active_section': 'info',
    });

    this.componentDoneLoading();
  },

  setActiveTab: function (e, tab_name) {
    this.set(e.keypath + '.active_section', tab_name);
  },
});
