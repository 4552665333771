window.Portal = {
  Youtube: {},
  TwitterTimeline: {},
  GoogleMaps: {},
  ArticleSingleLineList: {},
  ArticleMultiLineList: {},
  ArticleBannerCarousel: {},
  ArticleHorizontalCardList: {},
  ArticleVerticalCardList: {},
  ArticleMasonryCardList: {},
  Heading: {},
  RichText: {},
  Iframely: {},
  IconLink: {},
  PhotoLink: {},
  GoogleDriveFolder: {},
  GoogleForm: {},
  NotificationLink: {},
  EventVerticalCardList: {},
  EventHorizontalCardList: {},
  EventVerticalList: {},
  EventVerticalSchedule: {},
  EventHorizontalSchedule: {},
  FacebookTimeline: {},
  PinterestProfile: {},
  FeatureLink: {},
  PortalLinks: {},
  FacultyClassList: {},
  FacultyTodaysClasses: {},
  MyEventsParent: {},
  MyEventsStudent: {},
  MyChildrenParent: {},
  ClassListStudent: {},
  FindStudentFaculty: {},
  MyEventsFaculty: {},
  AdvisorDashboard: {},
  ButtonLink: {},
  OnlineGivingButton: {},
  AddBehaviorButton: {},
  UnreadMessagesCount: {},
  SubmitAssignments: {},
  GoogleAuthorization: {},
  CampaignReport: {},
};
