// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`WebFormAdminEditWarning renders correctly in admin_edit mode 1`] = `
<div
  class="vx-message-banner vx-message-banner--warning form-header-banner"
  data-testid="web-form-admin-edit-warning"
>
  Admin Mode: You are editing another user's form
</div>
`;
