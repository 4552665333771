import React from 'react';
import ForkliftUploader from 'forklift-ui';
// @ts-ignore
import AuthTokenContext from 'components/shared/AuthTokenContext';
import File from './File';

interface UploadedFile {
  file_pk: number;
  name: string;
  url: string;
  download_url: string;
  forklift_file_token: string;
}

interface UploaderOptions {
  auth: string;
  client: string;
  system: boolean;
  recodType: string;
  recordId: number;
  height: string;
  ui: string;
  autoProceed: boolean;
  allowMultiple: boolean;
  showNotes: boolean;
  forkliftHost: string;
}

interface AttachmentsProps {
  purchaseRequestPk: number;
  uploadedFiles: UploadedFile[];
  uploaderOptions: UploaderOptions;
  onDeleteClick: (filePk: number) => {};
  onFilesUploaded: (files: number[]) => {};
}

const Files = ({
  purchaseRequestPk,
  uploadedFiles,
  uploaderOptions,
  onDeleteClick,
  onFilesUploaded,
}: AttachmentsProps) => {
  const authToken = React.useContext<string>(AuthTokenContext);

  const onComplete = async ({ successful, failed }) => {
    const sucessfulFilePks = successful.map(({ fileInfo }) => fileInfo.file_pk);
    const filePksParam = new URLSearchParams(
      sucessfulFilePks.map((filePk) => ['file_pks[]', filePk])
    ).toString();

    const response = await fetch(
      `${Routes.purchase_request_file_attributes_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
        purchase_request_id: purchaseRequestPk,
      })}?${filePksParam}`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-CSRF-Token': authToken,
        }),
        method: 'GET',
      }
    );
    const { files } = await response.json();

    onFilesUploaded(files);
  };

  const handleDeleteClick = (filePk: number) => {
    // @ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.value) {
        // we call the onDeleteClick function to give the parent component a chance to update the state
        // without waiting for the API call to complete
        onDeleteClick(filePk);

        await fetch(
          Routes.destroy_purchase_request_file_path({
            client: Portals.config.client,
            portal_name: Portals.config.portal_name,
            purchase_request_id: purchaseRequestPk,
            file_pk: filePk,
          }),
          {
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-CSRF-Token': authToken,
            }),
            method: 'DELETE',
          }
        );
      }
    });
  };

  return (
    <div className="files-container">
      <div className="files-list">
        {uploadedFiles.map((uploadedFile) => (
          <File
            key={uploadedFile.file_pk}
            filePk={uploadedFile.file_pk}
            downloadUrl={uploadedFile.download_url}
            name={uploadedFile.name}
            onDeleteClick={handleDeleteClick}
          />
        ))}
      </div>
      <ForkliftUploader
        {...uploaderOptions}
        onComplete={onComplete}
      />
    </div>
  );
};

export default Files;
