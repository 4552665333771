require('../portal-component');

window.Portal.MyEventsFaculty.Component = PortalComponent.extend({
  oninit: function () {
    trace.log();

    this.set({
      today: moment(Portals.config.today).format('MM/DD/YYYY'),
      compact_time: this.compact_time,
      events_for_day: this.events_for_day,
    });
    this.reload();
  },

  data: {
    loads_test_data: true,
  },

  reload: function () {
    trace.log();

    const start_date = moment(this.get('today'), 'MM/DD/YYYY').add(
      -1 * parseInt(this.get('configuration.days_past')),
      'days'
    );
    const end_date = moment(this.get('today'), 'MM/DD/YYYY').add(
      parseInt(this.get('configuration.days_future')),
      'days'
    );
    const days = [];

    for (let m = moment(start_date); m.diff(end_date, 'days') < 0; m.add(1, 'days')) {
      days.push(m.format('MM/DD/YYYY'));
    }

    this.set('days', days);

    this.load_data({
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD'),
    }).then(this._dataLoaded);
  },

  _dataLoaded: function (data) {
    this.set({
      events: data.events,
    });

    this.componentDoneLoading();
  },

  compact_time: function (time) {
    if (!time) {
      return '';
    }
    return moment(time, 'hh:mm A').format('h:mma').replace(/\:00/, '').replace('m', '');
  },

  events_for_day: function (day) {
    return _.filter(this.get('events'), { start_date: day });
  },
});
