window.AccountNav = Ractive.extend({
  onconstruct: function (options) {
    options.template = require('./templates/account-nav.ractive');
  },

  data: function () {
    return {
      portal_membership: [],
      change_password_url: null,
    };
  },

  computed: {
    full_name_special_chars: function () {
      // return name unescaped so that Ractive can escape in component template, ensuring that all names are escaped
      // before being shown without assuming they were escaped before being written to the DB
      return _.unescape(this.get('full_name'));
    },
    initials: function () {
      names = this.get('full_name').split(' ');
      initials = _.first(names)[0] + _.last(names)[0];
      return initials.toUpperCase();
    },
  },

  oninit: function () {
    trace.log(this.get());

    this.set('loading', true);
    // fetch the information for this modal
    $.ajax({
      url: Routes.account_modal_info_path({
        client: Portals.config.client,
        portal_name: Portals.config.portal_name,
      }),
      type: 'GET',
      context: this,
    })
      .success(function (data) {
        this.set('portal_membership', data.portal_list);
        this.set('change_password_url', data.settings_urls.change_password_url);
        this.set('manage_mfa_url', data.settings_urls.manage_mfa_url);
        this.set('loading', false);
      })
      .error(function () {
        trace.log('there was an error');
      });

    this.on({
      _changeTab: this._changeTab,
      _switchPortal: this._switchPortal,
      _logout: this._logout,
      _close: this._close,
    });
  },

  onrender: function () {
    $('body').addClass('vx-modal-open');

    this.set({
      communication_preferences_link: '#',
      portal_link: this.portal_link,
      logout_link: Routes.logout_path({ client: Portals.config.client }),
    });
  },

  portal_link: function (portal) {
    return Routes.portal_path({
      client: Portals.config.client,
      portal_name: portal.access_name,
    });
  },

  _changeTab: function (e) {
    this.set('selected', $(e.node).text().toLowerCase());
    return false;
  },

  _close: function (e) {
    $('body').removeClass('vx-modal-open');
    this.teardown();
  },
});
