import React from 'react';

import { SelectField, TextField, Button } from 'components/shared/form_elements';

export default function SearchFilters(props) {
  const { config, values, handleChange, handleSearch, handleClearSearch, pageState } = props;

  const isPageLoading = pageState === 'LOADING';
  const isPageSearching = pageState === 'SEARCHING';
  const isPageSearched = pageState === 'SEARCHED';

  const isDisabled = isPageLoading || isPageSearching;
  const searchButtonText = isPageSearching ? 'Searching...' : 'Search';
  const showClearSearchButton = isPageSearched;

  return (
    <form
      className="vx-form search-filters"
      onSubmit={handleSearch}
      noValidate
    >
      <div className="ae-grid">
        {config.person_first_name.displayed && (
          <div className="ae-grid__item item-sm-4">
            <TextField
              name="person_first_name"
              value={values.person_first_name}
              config={config.person_first_name}
              onChange={handleChange}
            />
          </div>
        )}
        {config.person_last_name.displayed && (
          <div className="ae-grid__item item-sm-4">
            <TextField
              name="person_last_name"
              value={values.person_last_name}
              config={config.person_last_name}
              onChange={handleChange}
            />
          </div>
        )}
        {config.person_role.displayed && (
          <div className="ae-grid__item item-sm-2">
            <SelectField
              name="person_role"
              value={values.person_role}
              config={config.person_role}
              onChange={handleChange}
            />
          </div>
        )}
        {config.opportunity_fundraising_activity.displayed && (
          <div className="ae-grid__item item-sm-2">
            <SelectField
              name="opportunity_fundraising_activity"
              value={values.opportunity_fundraising_activity}
              config={config.opportunity_fundraising_activity}
              onChange={handleChange}
            />
          </div>
        )}
        {config.opportunity_giving_status.displayed && (
          <div className="ae-grid__item item-sm-3">
            <SelectField
              name="opportunity_giving_status"
              value={values.opportunity_giving_status}
              config={config.opportunity_giving_status}
              onChange={handleChange}
            />
          </div>
        )}
        {config.opportunity_giving_category.displayed && (
          <div className="ae-grid__item item-sm-3">
            <SelectField
              name="opportunity_giving_category"
              value={values.opportunity_giving_category}
              config={config.opportunity_giving_category}
              onChange={handleChange}
            />
          </div>
        )}
        {config.opportunity_phase.displayed && (
          <div className="ae-grid__item item-sm-3">
            <SelectField
              name="opportunity_phase"
              value={values.opportunity_phase}
              config={config.opportunity_phase}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="ae-grid__item item-sm--auto">
          <input
            className="vx-button vx-button--primary"
            type="submit"
            value={searchButtonText}
            disabled={isDisabled}
          />
        </div>
        {showClearSearchButton && (
          <div className="ae-grid__item item-sm--auto">
            <Button
              className="vx-button vx-button--danger"
              disabled={isDisabled}
              onClick={handleClearSearch}
              value="Clear"
            />
          </div>
        )}
      </div>
    </form>
  );
}
