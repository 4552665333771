require('../portal-component');
require('../../controls/profile-search/profile-search');

window.Portal.FindStudentFaculty.Component = PortalComponent.extend({
  onconstruct: function (options) {
    this._super(options);
    _.merge(options.components, {
      ProfileSearch: Control.ProfileSearch,
    });
  },

  oninit: function () {
    this.on({
      _openSearchModal: this._openSearchModal,
    });
    this.componentDoneLoading();
  },

  _openSearchModal: function (e) {
    var modal = new Control.Modal({
      data: {
        show: true,
        custom_class: 'search-modal',
        width: 500,
        height: 50,
      },
    });

    $(modal.find('.vx-modal__content')).css('overflow', 'visible');

    var student_search = new Control.ProfileSearch({
      el: '.search-modal .vx-modal__content',
      data: {
        person_type: this.get('configuration.person_type'),
      },
    });

    // focus the student search input
    $(student_search.find('.search-field')).focus();
  },
});
