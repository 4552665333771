import i18next from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

i18next
  .use(initReactI18next) // We use the react-i18next package to make i18next available in React.
  .use(ChainedBackend) // We use the ChainedBackend to be able to use multiple backends.
  .init({
    // Get the language from the html tag, e.g. <html lang="en">.
    lng: document.documentElement.lang,

    // The fallback language is used when the translation for the current language is not available.
    fallbackLng: 'en',

    // The namespace is the name of the file where the translations are located.
    ns: ['words'],
    defaultNS: 'words',

    react: {
      // We disable the use of suspense since we don't use the Suspense component in our code.
      // Especially because Portals is not a single page application and we have multiple entry
      // points via rails-react, i.e. <%= react_component ... %>.
      useSuspense: false,
    },

    backend: {
      // The order of the backends is important.
      // The first backend is the one that will be used first.
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          // The expiration time is set to 1 day.
          expirationTime: 1 * 24 * 60 * 60 * 1000,
        },
        {
          // The loadPath is the path where the translation files are located.
          // See locales_controller.rb for implementation.
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
  });

// Immediately change the language to the one specified in the html tag.
i18next.changeLanguage(document.documentElement.lang);

// Set the i18next instance on window since we need it available as a global object since most
// of the time we will be using it outside of React components (e.g. jquery, ractive, etc.)
declare var window: any;
window.i18next = i18next;
