window.RegistrationSeason.AdmissionEventCommon = function (webFormElementId, slotId) {
  const el_class = '.admission_event_slot_element_' + webFormElementId;
  $(el_class).hide();

  $.ajax({
    method: 'get',
    url: Routes.admission_event_slot_path({
      client: Portals.config.client,
      portal_name: Portals.config.portal_name,
      admission_event_slot_pk: slotId,
    }),
  }).success(function (data) {
    $(el_class + '.event_date' + ' .vx-data-field__data').html(data.event_date);
    $(el_class + '.start_time' + ' .vx-data-field__data').html(data.start_time);
    $(el_class + '.end_time' + ' .vx-data-field__data').html(data.end_time);

    $('.admission_event_slot_element_' + webFormElementId).show();
    $('.admission_event_slot_element_spinner_' + webFormElementId).hide();
  });
};
