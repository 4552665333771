import { useState } from 'react';

const useNotification = () => {
  const [isNotifying, setIsNotifying] = useState(false);

  const sendNotification = (delay) => {
    setIsNotifying(true);
    setTimeout(() => {
      setIsNotifying(false);
    }, delay);
  };

  return { sendNotification, isNotifying };
};

export { useNotification };
