import React from 'react';
import HelpText from './HelpText';
import PlainTextField from './PlainTextField';
import VxLabel from './VxLabel';
import VxErrorMessage from './VxErrorMessage';

import { useField, useFormikContext } from 'formik';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import { ElementMetadata } from '../types';

const DateOfBirthField = ({ element, metadata }: ElementMetadata) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(metadata.dataKeypath);
  const hasError = meta.touched && meta.error;

  return (
    <>
      <VxLabel
        element={element}
        htmlFor={metadata.dataKeypath}
        hasError={hasError}
      />
      <HelpText
        element={element}
        hasError={hasError}
      />
      <div
        className={classNames('vx-form-control vx-text-field', {
          'vx-form-control--error': hasError,
        })}
      >
        {metadata.isPlainText ? (
          <PlainTextField>{field.value}</PlainTextField>
        ) : (
          <>
            <InputMask
              mask="99/99/9999"
              keypath={metadata.dataKeypath}
              defaultValue={field.value}
              disabled={isSubmitting}
              {...field}
            >
              {(inputProps) => (
                <input
                  type="text"
                  className="vx-text-field__input"
                  id={inputProps.keypath}
                  name={inputProps.keypath}
                  placeholder="MM/DD/YYYY"
                />
              )}
            </InputMask>
            <VxErrorMessage
              element={element}
              metadata={metadata}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DateOfBirthField;
