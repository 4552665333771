window.GlyphIcons = [
  'arrows-1_back-78',
  'arrows-1_back-80',
  'arrows-1_bold-direction',
  'arrows-1_bold-down',
  'arrows-1_bold-left',
  'arrows-1_bold-right',
  'arrows-1_bold-up',
  'arrows-1_circle-down-12',
  'arrows-1_circle-down-40',
  'arrows-1_circle-left-10',
  'arrows-1_circle-left-38',
  'arrows-1_circle-right-09',
  'arrows-1_circle-right-37',
  'arrows-1_circle-up-11',
  'arrows-1_circle-up-39',
  'arrows-1_cloud-download-93',
  'arrows-1_cloud-download-95',
  'arrows-1_cloud-upload-94',
  'arrows-1_cloud-upload-96',
  'arrows-1_curved-next',
  'arrows-1_curved-previous',
  'arrows-1_direction-53',
  'arrows-1_direction-56',
  'arrows-1_double-left',
  'arrows-1_double-right',
  'arrows-1_download',
  'arrows-1_enlarge-diagonal-43',
  'arrows-1_enlarge-diagonal-44',
  'arrows-1_enlarge-horizontal',
  'arrows-1_enlarge-vertical',
  'arrows-1_fit-horizontal',
  'arrows-1_fit-vertical',
  'arrows-1_fullscreen-70',
  'arrows-1_fullscreen-71',
  'arrows-1_fullscreen-76',
  'arrows-1_fullscreen-77',
  'arrows-1_fullscreen-double-74',
  'arrows-1_fullscreen-double-75',
  'arrows-1_fullscreen-split-72',
  'arrows-1_fullscreen-split-73',
  'arrows-1_log-in',
  'arrows-1_log-out',
  'arrows-1_loop-82',
  'arrows-1_loop-83',
  'arrows-1_minimal-down',
  'arrows-1_minimal-left',
  'arrows-1_minimal-right',
  'arrows-1_minimal-up',
  'arrows-1_redo-79',
  'arrows-1_redo-81',
  'arrows-1_refresh-68',
  'arrows-1_refresh-69',
  'arrows-1_round-down',
  'arrows-1_round-left',
  'arrows-1_round-right',
  'arrows-1_round-up',
  'arrows-1_share-66',
  'arrows-1_share-91',
  'arrows-1_share-92',
  'arrows-1_shuffle-97',
  'arrows-1_shuffle-98',
  'arrows-1_simple-down',
  'arrows-1_simple-left',
  'arrows-1_simple-right',
  'arrows-1_simple-up',
  'arrows-1_small-triangle-down',
  'arrows-1_small-triangle-left',
  'arrows-1_small-triangle-right',
  'arrows-1_small-triangle-up',
  'arrows-1_square-down',
  'arrows-1_square-left',
  'arrows-1_square-right',
  'arrows-1_square-up',
  'arrows-1_strong-down',
  'arrows-1_strong-left',
  'arrows-1_strong-right',
  'arrows-1_strong-up',
  'arrows-1_tail-down',
  'arrows-1_tail-left',
  'arrows-1_tail-right',
  'arrows-1_tail-triangle-down',
  'arrows-1_tail-triangle-left',
  'arrows-1_tail-triangle-right',
  'arrows-1_tail-triangle-up',
  'arrows-1_tail-up',
  'arrows-1_trend-down',
  'arrows-1_trend-up',
  'arrows-1_triangle-down-20',
  'arrows-1_triangle-down-65',
  'arrows-1_triangle-left-18',
  'arrows-1_triangle-left-63',
  'arrows-1_triangle-right-17',
  'arrows-1_triangle-right-62',
  'arrows-1_triangle-up-19',
  'arrows-1_triangle-up-64',
  'arrows-1_window-zoom-in',
  'arrows-1_window-zoom-out',
  'arrows-1_zoom-100',
  'arrows-1_zoom-88',
  'arrows-1_zoom-99',
  'arrows-2_block-down',
  'arrows-2_block-left',
  'arrows-2_block-right',
  'arrows-2_block-up',
  'arrows-2_circle-in',
  'arrows-2_circle-out',
  'arrows-2_circuit',
  'arrows-2_circuit-round',
  'arrows-2_computer-upload',
  'arrows-2_conversion',
  'arrows-2_corner-down',
  'arrows-2_corner-down-round',
  'arrows-2_corner-left',
  'arrows-2_corner-left-down',
  'arrows-2_corner-left-round',
  'arrows-2_corner-right',
  'arrows-2_corner-right-down',
  'arrows-2_corner-right-round',
  'arrows-2_corner-up',
  'arrows-2_corner-up-left',
  'arrows-2_corner-up-right',
  'arrows-2_corner-up-round',
  'arrows-2_cross-down',
  'arrows-2_cross-horizontal',
  'arrows-2_cross-left',
  'arrows-2_cross-right',
  'arrows-2_cross-up',
  'arrows-2_cross-vertical',
  'arrows-2_curve-circuit',
  'arrows-2_curve-directions',
  'arrows-2_curve-split',
  'arrows-2_delete-49',
  'arrows-2_delete-50',
  'arrows-2_direction',
  'arrows-2_dots-download',
  'arrows-2_dots-upload',
  'arrows-2_eject',
  'arrows-2_enlarge-circle',
  'arrows-2_file-download-87',
  'arrows-2_file-download-89',
  'arrows-2_file-download-94',
  'arrows-2_file-upload-86',
  'arrows-2_file-upload-88',
  'arrows-2_file-upload-93',
  'arrows-2_fork',
  'arrows-2_fork-round',
  'arrows-2_hit-down',
  'arrows-2_hit-left',
  'arrows-2_hit-right',
  'arrows-2_hit-up',
  'arrows-2_lines',
  'arrows-2_log-out',
  'arrows-2_loop',
  'arrows-2_merge',
  'arrows-2_merge-round',
  'arrows-2_move-05',
  'arrows-2_move-06',
  'arrows-2_move-92',
  'arrows-2_move-down',
  'arrows-2_move-down-right',
  'arrows-2_move-left',
  'arrows-2_move-right',
  'arrows-2_move-up',
  'arrows-2_move-up-left',
  'arrows-2_push-next',
  'arrows-2_push-previous',
  'arrows-2_reload',
  'arrows-2_replay',
  'arrows-2_rotate-left',
  'arrows-2_rotate-right',
  'arrows-2_round-left-down',
  'arrows-2_round-right-down',
  'arrows-2_round-up-left',
  'arrows-2_round-up-right',
  'arrows-2_select-83',
  'arrows-2_select-84',
  'arrows-2_separate',
  'arrows-2_separate-round',
  'arrows-2_share-left',
  'arrows-2_share-right',
  'arrows-2_skew-down',
  'arrows-2_skew-left',
  'arrows-2_skew-right',
  'arrows-2_skew-up',
  'arrows-2_small-left',
  'arrows-2_small-right',
  'arrows-2_split',
  'arrows-2_split-horizontal',
  'arrows-2_split-round',
  'arrows-2_split-vertical',
  'arrows-2_square-download',
  'arrows-2_square-upload',
  'arrows-2_time',
  'arrows-2_triangle-down',
  'arrows-2_triangle-left',
  'arrows-2_triangle-right',
  'arrows-2_triangle-up',
  'arrows-2_unite',
  'arrows-2_unite-round',
  'arrows-2_zoom',
  'arrows-3_circle-down',
  'arrows-3_circle-left',
  'arrows-3_circle-right',
  'arrows-3_circle-simple-down',
  'arrows-3_circle-simple-left',
  'arrows-3_circle-simple-right',
  'arrows-3_circle-simple-up',
  'arrows-3_circle-up',
  'arrows-3_cloud-refresh',
  'arrows-3_separate',
  'arrows-3_small-down',
  'arrows-3_small-up',
  'arrows-3_square-corner-down-left-17',
  'arrows-3_square-corner-down-left-18',
  'arrows-3_square-corner-up-left',
  'arrows-3_square-corner-up-right',
  'arrows-3_square-down-06',
  'arrows-3_square-down-22',
  'arrows-3_square-enlarge',
  'arrows-3_square-left-04',
  'arrows-3_square-left-20',
  'arrows-3_square-right-03',
  'arrows-3_square-right-19',
  'arrows-3_square-simple-down',
  'arrows-3_square-simple-left',
  'arrows-3_square-simple-right',
  'arrows-3_square-simple-up',
  'arrows-3_square-up-05',
  'arrows-3_square-up-21',
  'arrows-3_square-zoom',
  'arrows-3_super-bold-down',
  'arrows-3_super-bold-left',
  'arrows-3_super-bold-right',
  'arrows-3_super-bold-up',
  'business_agenda',
  'business_atm',
  'business_award-48',
  'business_award-49',
  'business_award-74',
  'business_badge',
  'business_bank',
  'business_board-27',
  'business_board-28',
  'business_board-29',
  'business_board-30',
  'business_books',
  'business_briefcase-24',
  'business_briefcase-25',
  'business_briefcase-26',
  'business_building',
  'business_bulb-61',
  'business_bulb-62',
  'business_bulb-63',
  'business_business-contact-85',
  'business_business-contact-86',
  'business_business-contact-87',
  'business_business-contact-88',
  'business_business-contact-89',
  'business_businessman-03',
  'business_businessman-04',
  'business_calculator',
  'business_chair',
  'business_chart',
  'business_chart-bar-32',
  'business_chart-bar-33',
  'business_chart-growth',
  'business_chart-pie-35',
  'business_chart-pie-36',
  'business_cheque',
  'business_coins',
  'business_connect',
  'business_contacts',
  'business_currency-dollar',
  'business_currency-euro',
  'business_currency-pound',
  'business_currency-yen',
  'business_factory',
  'business_globe',
  'business_goal-64',
  'business_goal-65',
  'business_gold',
  'business_hammer',
  'business_handout',
  'business_handshake',
  'business_hat',
  'business_hierarchy-53',
  'business_hierarchy-54',
  'business_hierarchy-55',
  'business_hierarchy-56',
  'business_laptop-71',
  'business_laptop-72',
  'business_laptop-91',
  'business_law',
  'business_math',
  'business_money-11',
  'business_money-12',
  'business_money-13',
  'business_money-bag',
  'business_money-coins',
  'business_money-growth',
  'business_money-time',
  'business_net',
  'business_notes',
  'business_payment',
  'business_percentage-38',
  'business_percentage-39',
  'business_pig',
  'business_pin',
  'business_plug',
  'business_progress',
  'business_round-dollar',
  'business_round-euro',
  'business_round-pound',
  'business_round-yen',
  'business_safe',
  'business_scale',
  'business_sign',
  'business_signature',
  'business_stock',
  'business_strategy',
  'business_tie-01',
  'business_tie-02',
  'business_wallet-43',
  'business_wallet-44',
  'business_wallet-90',
  'clothes_baby',
  'clothes_backpack',
  'clothes_bag-21',
  'clothes_bag-22',
  'clothes_belt',
  'clothes_boot',
  'clothes_boot-woman',
  'clothes_bra',
  'clothes_button',
  'clothes_cap',
  'clothes_coat',
  'clothes_corset',
  'clothes_dress-man',
  'clothes_dress-woman',
  'clothes_flip',
  'clothes_glasses',
  'clothes_gloves',
  'clothes_hat',
  'clothes_hat-top',
  'clothes_hoodie',
  'clothes_iron',
  'clothes_iron-dont',
  'clothes_jeans-41',
  'clothes_jeans-43',
  'clothes_jeans-pocket',
  'clothes_kitchen',
  'clothes_long-sleeve',
  'clothes_makeup',
  'clothes_needle',
  'clothes_pajamas',
  'clothes_ring',
  'clothes_scarf',
  'clothes_shirt',
  'clothes_shirt-business',
  'clothes_shirt-buttons',
  'clothes_shirt-neck',
  'clothes_shoe-man',
  'clothes_shoe-sport',
  'clothes_shoe-woman',
  'clothes_skirt',
  'clothes_slacks-12',
  'clothes_slacks-13',
  'clothes_sock',
  'clothes_tie-bow',
  'clothes_tshirt-53',
  'clothes_tshirt-54',
  'clothes_tshirt-sport',
  'clothes_underwear',
  'clothes_underwear-man',
  'clothes_vest',
  'clothes_vest-sport',
  'clothes_wash',
  'clothes_wash-30',
  'clothes_wash-60',
  'clothes_wash-90',
  'clothes_wash-hand',
  'design_album',
  'design_align-bottom',
  'design_align-center-horizontal',
  'design_align-center-vertical',
  'design_align-left',
  'design_align-right',
  'design_align-top',
  'design_app',
  'design_artboard',
  'design_blend',
  'design_book-bookmark',
  'design_book-open',
  'design_brush',
  'design_bug',
  'design_bullet-list-67',
  'design_bullet-list-68',
  'design_bullet-list-69',
  'design_bullet-list-70',
  'design_clone',
  'design_code',
  'design_code-editor',
  'design_collection',
  'design_command',
  'design_compass',
  'design_contrast',
  'design_copy',
  'design_crop',
  'design_cursor-48',
  'design_cursor-49',
  'design_design',
  'design_design-dev',
  'design_distribute-horizontal',
  'design_distribute-vertical',
  'design_drag',
  'design_eraser-32',
  'design_eraser-33',
  'design_eraser-46',
  'design_flip-horizontal',
  'design_flip-vertical',
  'design_image',
  'design_magnet',
  'design_marker',
  'design_measure-02',
  'design_measure-17',
  'design_measure-big',
  'design_mobile-design',
  'design_mobile-dev',
  'design_mouse-08',
  'design_mouse-09',
  'design_mouse-10',
  'design_newsletter-dev',
  'design_note-code',
  'design_paint-16',
  'design_paint-37',
  'design_paint-38',
  'design_paint-bucket-39',
  'design_paint-bucket-40',
  'design_palette',
  'design_pantone',
  'design_paper-design',
  'design_paper-dev',
  'design_patch-19',
  'design_patch-34',
  'design_path-exclude',
  'design_path-intersect',
  'design_path-minus',
  'design_path-unite',
  'design_pen-01',
  'design_pen-23',
  'design_pen-tool',
  'design_phone',
  'design_photo-editor',
  'design_scissors',
  'design_scissors-dashed',
  'design_shape-adjust',
  'design_shape-circle',
  'design_shape-polygon',
  'design_shape-square',
  'design_shape-triangle',
  'design_shapes',
  'design_sharpener',
  'design_slice',
  'design_spray',
  'design_stamp',
  'design_tablet',
  'design_tablet-mobile',
  'design_text',
  'design_todo',
  'design_usb',
  'design_vector',
  'design_wand-11',
  'design_wand-99',
  'design_watch-dev',
  'design_web-design',
  'design_webpage',
  'design_window-code',
  'design_window-dev',
  'design_window-paragraph',
  'design_window-responsive',
  'education_abc',
  'education_agenda-bookmark',
  'education_atom',
  'education_award-55',
  'education_backpack-57',
  'education_backpack-58',
  'education_ball-basket',
  'education_ball-soccer',
  'education_board-51',
  'education_book-39',
  'education_book-bookmark',
  'education_book-open',
  'education_books-46',
  'education_chalkboard',
  'education_flask',
  'education_glasses',
  'education_grammar-check',
  'education_hat',
  'education_language',
  'education_microscope',
  'education_molecule',
  'education_notepad',
  'education_paper',
  'education_paper-diploma',
  'education_pencil-47',
  'education_school',
  'emoticons_alien',
  'emoticons_angry-10',
  'emoticons_angry-44',
  'emoticons_big-eyes',
  'emoticons_big-smile',
  'emoticons_bigmouth',
  'emoticons_bleah',
  'emoticons_blind',
  'emoticons_bomb',
  'emoticons_bored',
  'emoticons_cake',
  'emoticons_cry-15',
  'emoticons_cry-57',
  'emoticons_cute',
  'emoticons_devil',
  'emoticons_disgusted',
  'emoticons_fist',
  'emoticons_ghost',
  'emoticons_hannibal',
  'emoticons_happy-sun',
  'emoticons_kid',
  'emoticons_kiss',
  'emoticons_laugh-17',
  'emoticons_laugh-35',
  'emoticons_like',
  'emoticons_like-no',
  'emoticons_mad-12',
  'emoticons_mad-58',
  'emoticons_malicious',
  'emoticons_manga-62',
  'emoticons_manga-63',
  'emoticons_monster',
  'emoticons_nerd-22',
  'emoticons_nerd-23',
  'emoticons_ninja',
  'emoticons_no-words',
  'emoticons_parrot',
  'emoticons_penguin',
  'emoticons_pirate',
  'emoticons_poop',
  'emoticons_puzzled',
  'emoticons_quite-happy',
  'emoticons_robot',
  'emoticons_rock',
  'emoticons_sad',
  'emoticons_satisfied',
  'emoticons_shark',
  'emoticons_shy',
  'emoticons_sick',
  'emoticons_silly',
  'emoticons_skull',
  'emoticons_sleep',
  'emoticons_sloth',
  'emoticons_smart',
  'emoticons_smile',
  'emoticons_soldier',
  'emoticons_speechless',
  'emoticons_spiteful',
  'emoticons_sunglasses-48',
  'emoticons_sunglasses-49',
  'emoticons_surprise',
  'emoticons_upset-13',
  'emoticons_upset-14',
  'emoticons_virus',
  'emoticons_what',
  'emoticons_whiskers',
  'emoticons_wink-06',
  'emoticons_wink-11',
  'emoticons_wink-69',
  'envir_bulb',
  'envir_bulb-saver',
  'envir_car',
  'envir_fuel',
  'envir_fuel-electric',
  'envir_home',
  'envir_level',
  'envir_panel',
  'envir_radiation',
  'envir_recycling',
  'envir_save-planet',
  'envir_waste',
  'envir_waste-danger',
  'envir_waste-recycling',
  'envir_water',
  'envir_water-hand',
  'envir_water-sink',
  'envir_wind',
  'files_add',
  'files_archive',
  'files_archive-3d-check',
  'files_archive-3d-content',
  'files_archive-check',
  'files_archive-content',
  'files_archive-paper',
  'files_archive-paper-check',
  'files_audio',
  'files_book-07',
  'files_book-08',
  'files_bookmark',
  'files_box',
  'files_chart-bar',
  'files_chart-pie',
  'files_check',
  'files_cloud',
  'files_copy',
  'files_dev',
  'files_download',
  'files_drawer',
  'files_edit',
  'files_exclamation',
  'files_folder-13',
  'files_folder-14',
  'files_folder-15',
  'files_folder-16',
  'files_folder-17',
  'files_folder-18',
  'files_folder-19',
  'files_folder-add',
  'files_folder-audio',
  'files_folder-bookmark',
  'files_folder-chart-bar',
  'files_folder-chart-pie',
  'files_folder-check',
  'files_folder-cloud',
  'files_folder-dev',
  'files_folder-download',
  'files_folder-edit',
  'files_folder-exclamation',
  'files_folder-gallery',
  'files_folder-heart',
  'files_folder-image',
  'files_folder-info',
  'files_folder-link',
  'files_folder-locked',
  'files_folder-money',
  'files_folder-music',
  'files_folder-no-access',
  'files_folder-play',
  'files_folder-question',
  'files_folder-refresh',
  'files_folder-remove',
  'files_folder-search',
  'files_folder-settings-81',
  'files_folder-settings-97',
  'files_folder-shared',
  'files_folder-star',
  'files_folder-time',
  'files_folder-upload',
  'files_folder-user',
  'files_folder-vector',
  'files_gallery',
  'files_heart',
  'files_image',
  'files_info',
  'files_link',
  'files_locked',
  'files_money',
  'files_music',
  'files_no-access',
  'files_notebook',
  'files_paper',
  'files_play',
  'files_question',
  'files_refresh',
  'files_remove',
  'files_replace',
  'files_replace-folder',
  'files_search',
  'files_settings-46',
  'files_settings-99',
  'files_shared',
  'files_single',
  'files_single-content-02',
  'files_single-content-03',
  'files_single-copies',
  'files_single-copy-04',
  'files_single-copy-06',
  'files_single-folded',
  'files_single-folded-content',
  'files_single-paragraph',
  'files_star',
  'files_time',
  'files_upload',
  'files_user',
  'files_vector',
  'files_zip-54',
  'files_zip-55',
  'food_alcohol',
  'food_apple',
  'food_baby',
  'food_bacon',
  'food_baguette',
  'food_banana',
  'food_barbecue-02',
  'food_barbecue-15',
  'food_barbecue-tools',
  'food_beer-95',
  'food_beer-96',
  'food_beverage',
  'food_bottle',
  'food_bottle-wine',
  'food_bowl',
  'food_bread',
  'food_broccoli',
  'food_cake-100',
  'food_cake-13',
  'food_cake-slice',
  'food_candle',
  'food_candy',
  'food_carrot',
  'food_champagne',
  'food_cheese-24',
  'food_cheese-87',
  'food_cheeseburger',
  'food_chef-hat',
  'food_cherry',
  'food_chicken',
  'food_chili',
  'food_chinese',
  'food_chips',
  'food_chocolate',
  'food_cocktail',
  'food_coffe-long',
  'food_coffee',
  'food_coffee-long',
  'food_cookies',
  'food_course',
  'food_crab',
  'food_croissant',
  'food_cutlery-75',
  'food_cutlery-76',
  'food_cutlery-77',
  'food_dishwasher',
  'food_donut',
  'food_drink',
  'food_egg',
  'food_energy-drink',
  'food_fish',
  'food_fishbone',
  'food_fridge',
  'food_glass',
  'food_grape',
  'food_hob',
  'food_hot-dog',
  'food_ice-cream-22',
  'food_ice-cream-72',
  'food_jam',
  'food_kettle',
  'food_kitchen-fan',
  'food_knife',
  'food_lemon-slice',
  'food_lighter',
  'food_lobster',
  'food_matches',
  'food_measuring-cup',
  'food_meat-spit',
  'food_microwave',
  'food_milk',
  'food_moka',
  'food_muffin',
  'food_mug',
  'food_oven',
  'food_pan',
  'food_pizza',
  'food_pizza-slice',
  'food_plate',
  'food_pot',
  'food_prosciutto',
  'food_recipe-book-46',
  'food_recipe-book-47',
  'food_rolling-pin',
  'food_salt',
  'food_sausage',
  'food_scale',
  'food_scotch',
  'food_shrimp',
  'food_steak',
  'food_store',
  'food_strawberry',
  'food_sushi',
  'food_tacos',
  'food_tea',
  'food_temperature',
  'food_vest-07',
  'food_vest-31',
  'food_watermelon',
  'food_whisk',
  'furniture_air-conditioner',
  'furniture_armchair',
  'furniture_bath-tub',
  'furniture_bed-09',
  'furniture_bed-23',
  'furniture_bed-side',
  'furniture_cabinet',
  'furniture_cactus',
  'furniture_chair',
  'furniture_coat-hanger',
  'furniture_coffee',
  'furniture_cradle',
  'furniture_curtain',
  'furniture_desk',
  'furniture_desk-drawer',
  'furniture_door',
  'furniture_drawer',
  'furniture_fridge',
  'furniture_hanger',
  'furniture_hanger-clothes',
  'furniture_heater',
  'furniture_iron',
  'furniture_lamp',
  'furniture_lamp-floor',
  'furniture_library',
  'furniture_light',
  'furniture_mixer',
  'furniture_oven',
  'furniture_shower',
  'furniture_sink',
  'furniture_sink-wash',
  'furniture_sofa',
  'furniture_storage',
  'furniture_storage-hanger',
  'furniture_table',
  'furniture_toilet',
  'furniture_toilet-paper',
  'furniture_tv',
  'furniture_wardrobe',
  'furniture_wash',
  'gestures_2x-drag-down',
  'gestures_2x-drag-up',
  'gestures_2x-swipe-down',
  'gestures_2x-swipe-left',
  'gestures_2x-swipe-right',
  'gestures_2x-swipe-up',
  'gestures_2x-tap',
  'gestures_3x-swipe-left',
  'gestures_3x-swipe-right',
  'gestures_3x-swipe-up',
  'gestures_3x-tap',
  'gestures_4x-swipe-left',
  'gestures_4x-swipe-right',
  'gestures_4x-swipe-up',
  'gestures_active-38',
  'gestures_active-40',
  'gestures_camera',
  'gestures_double-tap',
  'gestures_drag-21',
  'gestures_drag-31',
  'gestures_drag-down',
  'gestures_drag-left',
  'gestures_drag-right',
  'gestures_drag-up',
  'gestures_flick-down',
  'gestures_flick-left',
  'gestures_flick-right',
  'gestures_flick-up',
  'gestures_grab',
  'gestures_hold',
  'gestures_pin',
  'gestures_pinch',
  'gestures_rotate-22',
  'gestures_rotate-23',
  'gestures_scan',
  'gestures_scroll-horitontal',
  'gestures_scroll-vertical',
  'gestures_stretch',
  'gestures_swipe-bottom',
  'gestures_swipe-left',
  'gestures_swipe-right',
  'gestures_swipe-up',
  'gestures_tap-01',
  'gestures_tap-02',
  'health_ambulance',
  'health_apple',
  'health_bag-49',
  'health_bag-50',
  'health_brain',
  'health_dna-27',
  'health_dna-38',
  'health_doctor',
  'health_flask',
  'health_heartbeat-16',
  'health_height',
  'health_hospital-32',
  'health_hospital-33',
  'health_hospital-34',
  'health_humidity-26',
  'health_humidity-52',
  'health_intestine',
  'health_lungs',
  'health_molecule-39',
  'health_molecule-40',
  'health_notebook',
  'health_nurse',
  'health_patch-46',
  'health_pill-42',
  'health_pill-43',
  'health_pill-container-44',
  'health_pill-container-47',
  'health_pulse',
  'health_pulse-chart',
  'health_pulse-phone',
  'health_pulse-sleep',
  'health_pulse-watch',
  'health_sleep',
  'health_steps',
  'health_syringe',
  'health_temperature-23',
  'health_temperature-24',
  'health_tooth',
  'health_weed',
  'health_weight',
  'health_wheelchair',
  'health_woman',
  'holidays_bat',
  'holidays_biscuit',
  'holidays_bones',
  'holidays_boot',
  'holidays_candy',
  'holidays_cat',
  'holidays_cauldron',
  'holidays_chimney',
  'holidays_cockade',
  'holidays_coffin',
  'holidays_dead-hand',
  'holidays_decoration',
  'holidays_deer',
  'holidays_egg-38',
  'holidays_egg-39',
  'holidays_frankenstein',
  'holidays_ghost',
  'holidays_gift',
  'holidays_gift-exchange',
  'holidays_glove',
  'holidays_grave',
  'holidays_light',
  'holidays_message',
  'holidays_mistletoe',
  'holidays_owl',
  'holidays_pumpkin',
  'holidays_rabbit',
  'holidays_santa-hat',
  'holidays_sickle',
  'holidays_snow-ball',
  'holidays_snowman',
  'holidays_snowman-head',
  'holidays_soak',
  'holidays_spider',
  'holidays_tree',
  'holidays_tree-ball',
  'holidays_vampire',
  'holidays_witch-hat',
  'holidays_wolf',
  'holidays_zombie',
  'location_appointment',
  'location_bookmark',
  'location_bookmark-add',
  'location_bookmark-remove',
  'location_compass-04',
  'location_compass-05',
  'location_compass-06',
  'location_crosshair',
  'location_explore',
  'location_explore-user',
  'location_flag',
  'location_flag-complex',
  'location_flag-diagonal-33',
  'location_flag-diagonal-34',
  'location_flag-points-31',
  'location_flag-points-32',
  'location_flag-simple',
  'location_flag-triangle',
  'location_gps',
  'location_map',
  'location_map-big',
  'location_map-compass',
  'location_map-gps',
  'location_map-marker',
  'location_map-pin',
  'location_marker',
  'location_pin',
  'location_pin-add',
  'location_pin-copy',
  'location_pin-remove',
  'location_pins',
  'location_position-marker',
  'location_position-pin',
  'location_position-user',
  'location_radar',
  'location_road',
  'location_route-alert',
  'location_route-close',
  'location_route-open',
  'location_square-marker',
  'location_square-pin',
  'location_treasure-map-21',
  'location_treasure-map-40',
  'location_worl-marker',
  'location_world',
  'location_world-pin',
  'media-1_3d',
  'media-1_action-73',
  'media-1_action-74',
  'media-1_album',
  'media-1_audio-91',
  'media-1_audio-92',
  'media-1_balance',
  'media-1_brightness-46',
  'media-1_brightness-47',
  'media-1_button-circle-pause',
  'media-1_button-circle-play',
  'media-1_button-circle-stop',
  'media-1_button-eject',
  'media-1_button-next',
  'media-1_button-pause',
  'media-1_button-play',
  'media-1_button-power',
  'media-1_button-previous',
  'media-1_button-record',
  'media-1_button-rewind',
  'media-1_button-skip',
  'media-1_button-stop',
  'media-1_camera-18',
  'media-1_camera-19',
  'media-1_camera-20',
  'media-1_camera-ban-36',
  'media-1_camera-ban-37',
  'media-1_camera-compact',
  'media-1_camera-screen',
  'media-1_camera-square-57',
  'media-1_camera-square-58',
  'media-1_camera-time',
  'media-1_countdown-34',
  'media-1_countdown-35',
  'media-1_edit-color',
  'media-1_edit-contrast-42',
  'media-1_edit-contrast-43',
  'media-1_edit-saturation',
  'media-1_flash-21',
  'media-1_flash-24',
  'media-1_flash-29',
  'media-1_flash-auto-22',
  'media-1_flash-auto-25',
  'media-1_flash-off-23',
  'media-1_flash-off-26',
  'media-1_focus-32',
  'media-1_focus-38',
  'media-1_focus-40',
  'media-1_focus-circle',
  'media-1_frame-12',
  'media-1_frame-41',
  'media-1_grid',
  'media-1_image-01',
  'media-1_image-02',
  'media-1_image-05',
  'media-1_image-add',
  'media-1_image-delete',
  'media-1_image-location',
  'media-1_kid',
  'media-1_layers',
  'media-1_lens-31',
  'media-1_lens-56',
  'media-1_macro',
  'media-1_movie-61',
  'media-1_movie-62',
  'media-1_night',
  'media-1_picture',
  'media-1_play-68',
  'media-1_play-69',
  'media-1_player',
  'media-1_polaroid',
  'media-1_polaroid-add',
  'media-1_polaroid-delete',
  'media-1_polaroid-multiple',
  'media-1_polaroid-user',
  'media-1_roll',
  'media-1_rotate-left',
  'media-1_rotate-right',
  'media-1_sd',
  'media-1_selfie',
  'media-1_shake',
  'media-1_speaker',
  'media-1_sport',
  'media-1_ticket-75',
  'media-1_ticket-76',
  'media-1_touch',
  'media-1_tripod',
  'media-1_video-64',
  'media-1_video-65',
  'media-1_video-66',
  'media-1_video-67',
  'media-1_videocamera-71',
  'media-1_videocamera-72',
  'media-1_volume-93',
  'media-1_volume-97',
  'media-1_volume-98',
  'media-1_volume-ban',
  'media-1_volume-down',
  'media-1_volume-off',
  'media-1_volume-up',
  'media-2_guitar',
  'media-2_headphones',
  'media-2_headphones-mic',
  'media-2_knob',
  'media-2_mic',
  'media-2_music-album',
  'media-2_music-cloud',
  'media-2_note-03',
  'media-2_note-04',
  'media-2_piano',
  'media-2_radio',
  'media-2_remix',
  'media-2_sound-wave',
  'media-2_speaker-01',
  'media-2_speaker-05',
  'media-2_tape',
  'nature_bear',
  'nature_bee',
  'nature_butterfly',
  'nature_chicken',
  'nature_clover',
  'nature_collar',
  'nature_cow',
  'nature_dog',
  'nature_dog-house',
  'nature_flower-05',
  'nature_flower-06',
  'nature_flower-07',
  'nature_food',
  'nature_food-dog',
  'nature_forest',
  'nature_mountain',
  'nature_mushroom',
  'nature_panda',
  'nature_paw',
  'nature_pig',
  'nature_plant-ground',
  'nature_plant-vase',
  'nature_rat',
  'nature_sheep',
  'nature_snake',
  'nature_tree-01',
  'nature_tree-02',
  'nature_tree-03',
  'nature_turtle',
  'nature_wood',
  'objects_alien-29',
  'objects_alien-33',
  'objects_anchor',
  'objects_astronaut',
  'objects_axe',
  'objects_baby',
  'objects_baby-bottle',
  'objects_baloon',
  'objects_battery',
  'objects_bear',
  'objects_billiard',
  'objects_binocular',
  'objects_bow',
  'objects_bowling',
  'objects_broom',
  'objects_cone',
  'objects_controller',
  'objects_diamond',
  'objects_dice',
  'objects_globe',
  'objects_hut',
  'objects_key-25',
  'objects_key-26',
  'objects_lamp',
  'objects_leaf-36',
  'objects_leaf-38',
  'objects_light',
  'objects_pipe',
  'objects_planet',
  'objects_puzzle-09',
  'objects_puzzle-10',
  'objects_shovel',
  'objects_skull',
  'objects_spaceship',
  'objects_spray',
  'objects_support-16',
  'objects_support-17',
  'objects_umbrella-13',
  'objects_umbrella-14',
  'objects_wool-ball',
  'shopping_award',
  'shopping_bag-09',
  'shopping_bag-16',
  'shopping_bag-17',
  'shopping_bag-20',
  'shopping_bag-add-18',
  'shopping_bag-add-21',
  'shopping_bag-edit',
  'shopping_bag-remove-19',
  'shopping_bag-remove-22',
  'shopping_barcode',
  'shopping_barcode-scan',
  'shopping_bardcode-qr',
  'shopping_basket',
  'shopping_basket-add',
  'shopping_basket-edit',
  'shopping_basket-remove',
  'shopping_basket-simple',
  'shopping_basket-simple-add',
  'shopping_basket-simple-remove',
  'shopping_bitcoin',
  'shopping_board',
  'shopping_box',
  'shopping_box-3d-50',
  'shopping_box-3d-67',
  'shopping_box-ribbon',
  'shopping_cart',
  'shopping_cart-add',
  'shopping_cart-modern',
  'shopping_cart-modern-add',
  'shopping_cart-modern-in',
  'shopping_cart-modern-remove',
  'shopping_cart-remove',
  'shopping_cart-simple',
  'shopping_cart-simple-add',
  'shopping_cart-simple-in',
  'shopping_cart-simple-remove',
  'shopping_cash-register',
  'shopping_chart',
  'shopping_credit-card',
  'shopping_credit-card-in',
  'shopping_credit-locked',
  'shopping_delivery',
  'shopping_delivery-fast',
  'shopping_delivery-time',
  'shopping_delivery-track',
  'shopping_discount',
  'shopping_gift',
  'shopping_hand-card',
  'shopping_list',
  'shopping_mobile-card',
  'shopping_mobile-cart',
  'shopping_mobile-touch',
  'shopping_newsletter',
  'shopping_pos',
  'shopping_receipt',
  'shopping_receipt-list-42',
  'shopping_receipt-list-43',
  'shopping_shop',
  'shopping_shop-location',
  'shopping_stock',
  'shopping_tag',
  'shopping_tag-content',
  'shopping_tag-cut',
  'shopping_tag-line',
  'shopping_tag-sale',
  'shopping_wallet',
  'socials-1_logo-500px',
  'socials-1_logo-angellist',
  'socials-1_logo-behance',
  'socials-1_logo-blogger',
  'socials-1_logo-buffer',
  'socials-1_logo-buysellads',
  'socials-1_logo-codepen',
  'socials-1_logo-creative-market',
  'socials-1_logo-crunchbase',
  'socials-1_logo-deviantart',
  'socials-1_logo-dribbble',
  'socials-1_logo-dropbox',
  'socials-1_logo-envato',
  'socials-1_logo-evernote',
  'socials-1_logo-facebook',
  'socials-1_logo-fb-simple',
  'socials-1_logo-feedly',
  'socials-1_logo-flickr',
  'socials-1_logo-github',
  'socials-1_logo-google-plus',
  'socials-1_logo-instagram',
  'socials-1_logo-lastfm',
  'socials-1_logo-linkedin',
  'socials-1_logo-meetup',
  'socials-1_logo-myspace',
  'socials-1_logo-paypal',
  'socials-1_logo-pinterest',
  'socials-1_logo-product-hunt',
  'socials-1_logo-reddit',
  'socials-1_logo-rss',
  'socials-1_logo-shopify',
  'socials-1_logo-skype',
  'socials-1_logo-slack',
  'socials-1_logo-soundcloud',
  'socials-1_logo-spotify',
  'socials-1_logo-trello',
  'socials-1_logo-tumblr',
  'socials-1_logo-twitter',
  'socials-1_logo-vimeo',
  'socials-1_logo-vine',
  'socials-1_logo-whatsapp',
  'socials-1_logo-wordpress',
  'socials-1_logo-yelp',
  'socials-1_logo-youtube',
  'sport_badminton',
  'sport_baseball',
  'sport_baseball-ball',
  'sport_baseball-bat',
  'sport_basketball-12',
  'sport_basketball-13',
  'sport_boxing',
  'sport_cardio',
  'sport_cricket',
  'sport_crown',
  'sport_dart',
  'sport_dumbbells',
  'sport_energy-drink',
  'sport_energy-supplement',
  'sport_fencing',
  'sport_fishing',
  'sport_flag-finish',
  'sport_football-headguard',
  'sport_golf',
  'sport_helmet',
  'sport_hockey',
  'sport_kettlebell',
  'sport_ping-pong',
  'sport_podium',
  'sport_podium-trophy',
  'sport_rope',
  'sport_rugby',
  'sport_shaker',
  'sport_shoe-run',
  'sport_skateboard',
  'sport_snowboard',
  'sport_soccer-field',
  'sport_steering-wheel',
  'sport_supplement',
  'sport_surf',
  'sport_tactic',
  'sport_tennis',
  'sport_tennis-ball',
  'sport_trophy',
  'sport_user-balance',
  'sport_user-climb',
  'sport_user-meditation',
  'sport_user-run',
  'sport_user-snowboard',
  'sport_user-swim',
  'sport_volleyball',
  'sport_whistle',
  'tech_cable-49',
  'tech_cable-50',
  'tech_cd-reader',
  'tech_computer',
  'tech_computer-monitor',
  'tech_computer-old',
  'tech_controller',
  'tech_controller-modern',
  'tech_desktop',
  'tech_desktop-screen',
  'tech_disk',
  'tech_disk-reader',
  'tech_gopro',
  'tech_headphones',
  'tech_keyboard',
  'tech_keyboard-mouse',
  'tech_keyboard-wifi',
  'tech_laptop',
  'tech_laptop-camera',
  'tech_laptop-front',
  'tech_mobile',
  'tech_mobile-button',
  'tech_mobile-camera',
  'tech_mobile-recharger-08',
  'tech_mobile-recharger-09',
  'tech_mobile-toolbar',
  'tech_music',
  'tech_navigation',
  'tech_player-19',
  'tech_player-48',
  'tech_print',
  'tech_print-fold',
  'tech_print-round',
  'tech_print-round-fold',
  'tech_ram',
  'tech_remote',
  'tech_signal',
  'tech_socket',
  'tech_sync',
  'tech_tablet',
  'tech_tablet-button',
  'tech_tablet-reader-31',
  'tech_tablet-reader-42',
  'tech_tablet-toolbar',
  'tech_tv',
  'tech_tv-old',
  'tech_watch',
  'tech_watch-circle',
  'tech_watch-time',
  'tech_webcam-38',
  'tech_webcam-39',
  'tech_wifi',
  'tech_wifi-router',
  'text_align-center',
  'text_align-justify',
  'text_align-left',
  'text_align-right',
  'text_background',
  'text_bold',
  'text_capitalize',
  'text_caps-all',
  'text_caps-small',
  'text_color',
  'text_edit',
  'text_italic',
  'text_line-height',
  'text_list-bullet',
  'text_list-numbers',
  'text_margin-left',
  'text_margin-right',
  'text_quote',
  'text_scale-horizontal',
  'text_scale-vertical',
  'text_size',
  'text_strikethrough',
  'text_subscript',
  'text_superscript',
  'text_tracking',
  'text_underline',
  'transportation_air-baloon',
  'transportation_bike',
  'transportation_bike-sport',
  'transportation_boat',
  'transportation_boat-front',
  'transportation_boat-small-02',
  'transportation_boat-small-03',
  'transportation_bus',
  'transportation_bus-front-10',
  'transportation_bus-front-12',
  'transportation_car',
  'transportation_car-front',
  'transportation_car-simple',
  'transportation_car-sport',
  'transportation_car-taxi',
  'transportation_helicopter',
  'transportation_helmet',
  'transportation_light-traffic',
  'transportation_moto',
  'transportation_plane-17',
  'transportation_plane-18',
  'transportation_road',
  'transportation_skateboard',
  'transportation_tractor',
  'transportation_train',
  'transportation_train-speed',
  'transportation_tram',
  'transportation_truck-front',
  'transportation_vespa',
  'transportation_vespa-front',
  'travel_axe',
  'travel_backpack',
  'travel_bag',
  'travel_barbecue',
  'travel_beach-umbrella',
  'travel_berlin',
  'travel_binocular',
  'travel_camper',
  'travel_camping',
  'travel_castle',
  'travel_china',
  'travel_church',
  'travel_drink',
  'travel_explore',
  'travel_fire',
  'travel_hotel',
  'travel_hotel-bell',
  'travel_hotel-symbol',
  'travel_hut',
  'travel_igloo',
  'travel_info',
  'travel_istanbul',
  'travel_jellyfish',
  'travel_lamp',
  'travel_lighthouse',
  'travel_london',
  'travel_luggage',
  'travel_mosque',
  'travel_ny',
  'travel_octopus',
  'travel_paris-tower',
  'travel_passport',
  'travel_pickaxe',
  'travel_pool',
  'travel_pyramid',
  'travel_rackets',
  'travel_rio',
  'travel_road-sign-left',
  'travel_road-sign-right',
  'travel_rome',
  'travel_rowing',
  'travel_sea-mask',
  'travel_sf-bridge',
  'travel_shark',
  'travel_spa',
  'travel_sunglasses',
  'travel_surf',
  'travel_swimsuit',
  'travel_swimwear',
  'travel_swiss-knife',
  'travel_temple-02',
  'travel_temple-25',
  'travel_trolley',
  'travel_white-house',
  'travel_world',
  'travel_worldmap',
  'ui-1_analytics-88',
  'ui-1_analytics-89',
  'ui-1_attach-86',
  'ui-1_attach-87',
  'ui-1_bell-53',
  'ui-1_bell-54',
  'ui-1_bell-55',
  'ui-1_bold-add',
  'ui-1_bold-delete',
  'ui-1_bold-remove',
  'ui-1_bookmark-add',
  'ui-1_bookmark-remove',
  'ui-1_calendar-57',
  'ui-1_calendar-60',
  'ui-1_calendar-check-59',
  'ui-1_calendar-check-62',
  'ui-1_calendar-grid-58',
  'ui-1_calendar-grid-61',
  'ui-e_round-e-alert',
  'ui-e_round-e-info',
  'ui-1_check',
  'ui-1_check-bold',
  'ui-1_check-circle-07',
  'ui-1_check-circle-08',
  'ui-1_check-curve',
  'ui-1_check-simple',
  'ui-1_check-small',
  'ui-1_check-square-09',
  'ui-1_check-square-11',
  'ui-1_circle-add',
  'ui-1_circle-bold-add',
  'ui-1_circle-bold-remove',
  'ui-1_circle-delete',
  'ui-1_circle-remove',
  'ui-1_dashboard-29',
  'ui-1_dashboard-30',
  'ui-1_dashboard-half',
  'ui-1_dashboard-level',
  'ui-1_database',
  'ui-1_drop',
  'ui-1_edit-71',
  'ui-1_edit-72',
  'ui-1_edit-73',
  'ui-1_edit-74',
  'ui-1_edit-75',
  'ui-1_edit-76',
  'ui-1_edit-77',
  'ui-1_edit-78',
  'ui-1_email-83',
  'ui-1_email-84',
  'ui-1_email-85',
  'ui-1_eye-17',
  'ui-1_eye-19',
  'ui-1_eye-ban-18',
  'ui-1_eye-ban-20',
  'ui-1_flame',
  'ui-1_home-51',
  'ui-1_home-52',
  'ui-1_home-minimal',
  'ui-1_home-simple',
  'ui-1_leaf-80',
  'ui-1_leaf-81',
  'ui-1_leaf-edit',
  'ui-1_lock',
  'ui-1_lock-circle',
  'ui-1_lock-circle-open',
  'ui-1_lock-open',
  'ui-1_notification-69',
  'ui-1_notification-70',
  'ui-1_pencil',
  'ui-1_preferences',
  'ui-1_preferences-circle',
  'ui-1_preferences-circle-rotate',
  'ui-1_preferences-container',
  'ui-1_preferences-container-circle',
  'ui-1_preferences-container-circle-rotate',
  'ui-1_preferences-container-rotate',
  'ui-1_preferences-rotate',
  'ui-1_send',
  'ui-1_settings',
  'ui-1_settings-gear-63',
  'ui-1_settings-gear-64',
  'ui-1_settings-gear-65',
  'ui-1_settings-tool-66',
  'ui-1_settings-tool-67',
  'ui-1_simple-add',
  'ui-1_simple-delete',
  'ui-1_simple-remove',
  'ui-1_trash',
  'ui-1_trash-round',
  'ui-1_trash-simple',
  'ui-1_ui-03',
  'ui-1_ui-04',
  'ui-1_zoom',
  'ui-1_zoom-bold',
  'ui-1_zoom-bold-in',
  'ui-1_zoom-bold-out',
  'ui-1_zoom-in',
  'ui-1_zoom-out',
  'ui-1_zoom-split',
  'ui-1_zoom-split-in',
  'ui-1_zoom-split-out',
  'ui-2_alert',
  'ui-2_alert-',
  'ui-2_alert-circle',
  'ui-2_alert-circle-',
  'ui-2_alert-circle-i',
  'ui-2_alert-i',
  'ui-2_alert-square',
  'ui-2_alert-square-',
  'ui-2_alert-square-i',
  'ui-2_archive',
  'ui-2_ban',
  'ui-2_ban-bold',
  'ui-2_battery-81',
  'ui-2_battery-83',
  'ui-2_battery-half',
  'ui-2_battery-low',
  'ui-2_bluetooth',
  'ui-2_book',
  'ui-2_chart-bar-52',
  'ui-2_chart-bar-53',
  'ui-2_chat',
  'ui-2_chat-content',
  'ui-2_chat-round',
  'ui-2_chat-round-content',
  'ui-2_circle-bold-delete',
  'ui-2_cloud-25',
  'ui-2_cloud-26',
  'ui-2_disk',
  'ui-2_enlarge-57',
  'ui-2_enlarge-58',
  'ui-2_enlarge-59',
  'ui-2_fat-add',
  'ui-2_fat-delete',
  'ui-2_fat-remove',
  'ui-2_favourite-28',
  'ui-2_favourite-31',
  'ui-2_favourite-add-29',
  'ui-2_favourite-add-32',
  'ui-2_favourite-remove-30',
  'ui-2_favourite-remove-33',
  'ui-2_filter',
  'ui-2_fullsize',
  'ui-2_grid-45',
  'ui-2_grid-46',
  'ui-2_grid-48',
  'ui-2_grid-49',
  'ui-2_grid-50',
  'ui-2_grid-square',
  'ui-2_hourglass',
  'ui-2_lab',
  'ui-2_layers',
  'ui-2_like',
  'ui-2_link-66',
  'ui-2_link-67',
  'ui-2_link-68',
  'ui-2_link-69',
  'ui-2_link-71',
  'ui-2_link-72',
  'ui-2_link-broken-70',
  'ui-2_link-broken-73',
  'ui-2_menu-34',
  'ui-2_menu-35',
  'ui-2_menu-bold',
  'ui-2_menu-dots',
  'ui-2_menu-square',
  'ui-2_node',
  'ui-2_paragraph',
  'ui-2_phone',
  'ui-2_settings-90',
  'ui-2_settings-91',
  'ui-2_share',
  'ui-2_share-bold',
  'ui-2_small-add',
  'ui-2_small-delete',
  'ui-2_small-remove',
  'ui-2_square-add-08',
  'ui-2_square-add-11',
  'ui-2_square-delete-10',
  'ui-2_square-delete-13',
  'ui-2_square-remove-09',
  'ui-2_square-remove-12',
  'ui-2_target',
  'ui-2_tile-55',
  'ui-2_tile-56',
  'ui-2_time',
  'ui-2_time-alarm',
  'ui-2_time-clock',
  'ui-2_time-countdown',
  'ui-2_webpage',
  'ui-2_window-add',
  'ui-2_window-delete',
  'users_add-27',
  'users_add-29',
  'users_badge-13',
  'users_badge-14',
  'users_badge-15',
  'users_circle-08',
  'users_circle-09',
  'users_circle-10',
  'users_contacts',
  'users_delete-28',
  'users_delete-30',
  'users_man-20',
  'users_man-23',
  'users_man-glasses',
  'users_mobile-contact',
  'users_multiple-11',
  'users_multiple-19',
  'users_network',
  'users_parent',
  'users_single-01',
  'users_single-02',
  'users_single-03',
  'users_single-04',
  'users_single-05',
  'users_single-body',
  'users_single-position',
  'users_square-31',
  'users_square-32',
  'users_square-33',
  'users_woman-21',
  'users_woman-24',
  'users_woman-25',
  'users_woman-34',
  'users_woman-35',
  'users_woman-man',
  'weather_celsius',
  'weather_cloud-13',
  'weather_cloud-14',
  'weather_cloud-drop',
  'weather_cloud-fog-31',
  'weather_cloud-fog-32',
  'weather_cloud-hail',
  'weather_cloud-light',
  'weather_cloud-moon',
  'weather_cloud-rain',
  'weather_cloud-rainbow',
  'weather_cloud-snow-34',
  'weather_cloud-snow-42',
  'weather_cloud-sun-17',
  'weather_cloud-sun-19',
  'weather_compass',
  'weather_drop-12',
  'weather_drop-15',
  'weather_drops',
  'weather_eclipse',
  'weather_fahrenheit',
  'weather_fog',
  'weather_forecast',
  'weather_hurricane-44',
  'weather_hurricane-45',
  'weather_moon',
  'weather_moon-cloud-drop',
  'weather_moon-cloud-fog',
  'weather_moon-cloud-hail',
  'weather_moon-cloud-light',
  'weather_moon-cloud-rain',
  'weather_moon-cloud-snow-61',
  'weather_moon-cloud-snow-62',
  'weather_moon-fog',
  'weather_moon-full',
  'weather_moon-stars',
  'weather_rain',
  'weather_rain-hail',
  'weather_rainbow',
  'weather_snow',
  'weather_sun',
  'weather_sun-cloud',
  'weather_sun-cloud-drop',
  'weather_sun-cloud-fog',
  'weather_sun-cloud-hail',
  'weather_sun-cloud-light',
  'weather_sun-cloud-rain',
  'weather_sun-cloud-snow-54',
  'weather_sun-cloud-snow-55',
  'weather_sun-fog-29',
  'weather_sun-fog-30',
  'weather_sun-fog-43',
  'weather_wind',
];
