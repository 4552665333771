window.WebForm.FormWarning = Ractive.extend({
  onconstruct: function (options) {
    options.el = '.vx-form-warning';
    options.template = require('./form-warning.ractive');
  },

  onrender: function () {
    this.observe('warnings', this.showWarning, { init: false });
  },

  // fade the warning in if the warnings ever change
  showWarning: function (newValue, oldValue, keypath) {
    $(this.el).hide().fadeIn(300);
    $('body, html').animate({ scrollTop: $(this.el).offset().top - 20 }, 'fast');
  },
});
