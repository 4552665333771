// default messages
validate.validators.presence.message = '^Required';
validate.validators.email.message = '^Does not appear to be a valid email';
validate.validators.length.tooLong = '^Too long (%{count} characters max)';
validate.validators.length.tooShort = '^Too short (%{count} characters min)';

validate.validators.unique_email = function (value) {
  let result;
  const message = 'Email addresses must be unique per person';
  let count = 0;

  $('input[type=email]').each(function () {
    // eslint-disable-next-line no-invalid-this
    const currentValue = $(this).val();

    if (currentValue === value) {
      count += 1;

      if (count > 1) {
        result = validate.format(message, { value: value });
        return false;
      }
    }
  });

  return result;
};

validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function (value, options) {
    return moment.utc(value, window.WebForm.DATE_FORMAT);
  },
  // Input is a unix timestamp
  format: function (value, options) {
    const format = options.dateOnly
      ? window.WebForm.DATE_FORMAT
      : `${window.WebForm.DATE_FORMAT} hh:mm:ss`;
    return moment.utc(value).format(format);
  },
});

validate.validators.date = function (value, options) {
  const parsedDate = moment(value, window.WebForm.DATE_FORMAT, true);
  if (!parsedDate.isValid()) {
    return validate.format(`Please enter a valid date: ${window.WebForm.DATE_FORMAT}`, {
      value: parsedDate,
    });
  }

  options = {
    dateOnly: true,
    latest: moment.utc('06/06/2079', 'MM/DD/YYYY'),
    message: `^Please enter a valid date: ${window.WebForm.DATE_FORMAT}`,
  };

  return validate.validators.datetime.call(
    validate.validators.datetime,
    parsedDate.format(window.WebForm.DATE_FORMAT),
    options
  );
};

validate.validators.past_date = function (value, options) {
  const parsedDate = moment(value, window.WebForm.DATE_FORMAT, true);
  if (!parsedDate.isValid()) {
    return validate.format(`Please enter a valid date: ${window.WebForm.DATE_FORMAT}`, {
      value: parsedDate,
    });
  }

  options = {
    dateOnly: true,
    latest: moment(),
    earliest: moment.utc().subtract(121, 'years'), // oldest person ever was 122, T-SQL smalldatetime supports back to 1900
    message: '^Please enter a valid date in the past',
    tooEarly: `^Please enter a date after ${moment()
      .utc()
      .subtract(121, 'years')
      .format(window.WebForm.DATE_FORMAT)}`,
  };

  return validate.validators.datetime.call(
    validate.validators.datetime,
    parsedDate.format(window.WebForm.DATE_FORMAT),
    options
  );
};

// validation regular expressions
window.VALIDATIONS = {
  required: {
    presence: {
      allowEmpty: false,
    },
  },
  select_required: {
    exclusion: {
      within: [-1, '-1'],
      message: '^Required',
    },
  },
  react_select_required: {
    exclusion: {
      within: [''],
      message: '^Required',
    },
  },
  non_zero_dropdown_required: {
    exclusion: {
      within: [-1, '-1', '0'],
      message: '^Required',
    },
  },
  // https://stackoverflow.com/a/20971688
  phone_format: {
    pattern:
      /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
    message: '^Does not appear to be a valid phone number',
  },
  // https://stackoverflow.com/questions/578406/what-is-the-ultimate-postal-code-and-zip-regex
  zip_format: {
    pattern: /^[a-z0-9\- ]+$/i,
    message: '^Must be composed of letters, numbers, and dashes',
  },
  passport_format: {
    pattern: /^(?!^0+$)[a-z0-9\-]+$/i,
    message: '^Must be composed of letters, numbers, and dashes',
  },
  // https://gist.github.com/dperini/729294
  url_format: {
    pattern:
      /^(?:(?:(?:https?):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
    message: '^Does not appear to be a valid url',
  },
};
