window.RegionEditor = Ractive.extend({
  onconstruct: function (options) {
    options.magic = true;
    options.template = require('./templates/region-editor.ractive');
    options.adapt = 'Ractive';
  },

  oninit: function () {
    _.bindAllFunctions(this);
    this.start_index = 0;
    this.on({
      _changeWidth: this._changeWidth,
    });
  },

  onrender: function () {
    $('.es-RegionEditor_ReorderList').sortable({
      opacity: 0.75,
      placeholder: 'sortable-placeholder',
      start: this.startReorder,
      change: this.changeOrder,
      update: this.endReorder,
    });

    $('.portal-screen-region').removeClass('selected');
    $('.portal-screen-region[data-screen-region="' + this.get('region') + '"]').addClass(
      'selected'
    );

    $('.es-RegionEditor_ReorderList').on('mouseenter', '.es-RegionEditor_Component', this.hoverIn);
    $('.es-RegionEditor_ReorderList').on('mouseleave', '.es-RegionEditor_Component', this.hoverOut);
  },

  onteardown: function () {
    trace.log();
    $('.portal-screen-region').removeClass('selected');
    $('.es-RegionEditor_ReorderList').off('mouseenter mouseleave');
    $('.es-RegionEditor_ReorderList').sortable('destroy');
  },

  hoverIn: function (e) {
    var component_pk = $(e.currentTarget).data('component-pk');
    $('.screen-component').removeClass('active');
    $('.screen-component[data-component-pk="' + component_pk + '"]').addClass('active');
  },

  hoverOut: function (e) {
    $('.screen-component').removeClass('active');
  },

  startReorder: function (e, ui) {
    trace.log(ui.item.index());
    this.start_index = ui.item.index();
    ui.item.data('start-pos', this.start_index);
  },

  changeOrder: function (e, ui) {
    var new_index = ui.placeholder.index();
    var start_pos = ui.item.data('start-pos');
    if (new_index > start_pos) {
      new_index -= 1;
    }

    trace.log(this.start_index, ui.placeholder.index(), new_index);
    var item_to_move = this.get('components.' + this.start_index);

    // update the components list based on our drag/drop
    this.get('screen_admin').detachComponents();

    this.splice('components', this.start_index, 1);
    this.splice('components', new_index, 0, item_to_move);

    var me = this;
    _.each(this.get('components'), function (component, i) {
      me.set('components.' + i + '.layout_region_sort_key', i);
    });

    this.get('screen_admin').update('components');
    this.get('screen_admin').reattachComponents();

    // update active highlight and scroll into view
    $('.screen-component').removeClass('active');
    $('.screen-component[data-component-pk="' + item_to_move.portal_screen_component_pk + '"]')
      .addClass('active')
      .scrollintoview();

    this.start_index = new_index;
  },

  endReorder: function (e, ui) {
    ui.item.removeData('start-pos');
  },

  _changeWidth: function (e, direction) {
    trace.log(e, direction);
    var widths = [2, 3, 4, 6, 8, 10, 12];
    // we need to manually look up the component because e.context and e.keypath could be incorrect
    // due to the fact that we're only rendering the template once and repositioning items
    var component_pk = $(e.node).closest('.es-RegionEditor_Component').data('component-pk');
    var component = _.find(this.get('components'), {
      portal_screen_component_pk: component_pk,
    });
    var component_index = _.indexOf(this.get('components'), component);

    var current_index = _.indexOf(widths, parseInt(component.width));
    var next_index = _.modulo(current_index + direction, widths.length);

    this.set('components.' + component_index + '.width', widths[next_index]);
  },
});
