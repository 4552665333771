require('../controls/modal/modal');

$(document).ready(function () {
  if (!isMobile()) {
    $('body').on('click', 'a[data-detail-link]', function (e) {
      e.preventDefault();

      var modal = new Control.Modal({
        data: {
          show: true,
          custom_class: 'detail-modal',
          close_button: 'Close',
          margin: $(e.currentTarget).data('margin') || 200,
        },
      });

      $.ajax({
        type: 'GET',
        url: Routes[_.snakeCase($(e.currentTarget).data('detail-link') + '_path')]({
          client: Portals.config.client,
          portal_name: Portals.config.portal_name,
          record_pk: $(e.currentTarget).data('record-pk'),
        }),
      })
        .then(function (data) {
          modal.set('content', data);
        })
        .fail(function () {
          console.log('there was an error');
        });
    });
  }
});
